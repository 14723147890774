//Angular decorators list
import { Injectable } from '@angular/core';
import { Headers, RequestOptions } from '@angular/http';

//Third party decorators list
import { CookieService } from 'ngx-cookie';
import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map'

/**
 * handle headers for request
 * @param {'application/json' } 'Content-Type'
 */
let headers = new Headers({ 'Content-Type': 'application/json' });
let options = new RequestOptions({ headers: headers});
let fileHeaders = new Headers({ 'Content-Type': undefined});
let fileOptions = new RequestOptions({headers: headers });


@Injectable()
export class SessionService {

    // Initial setup
    accessToken;
    usrTyd;
    backuserId;
    userId;
    decuserId;
    userName;
    compId;
    setDetail={};
    workContractId;
    startDate;
    contractDetail;
    empType;
    expenseReportId;
    list=[];
  editUser;
  WorkContractData;
  VacationType;
  PendingVacationDetails;
  VacationDetails;
  VacationConfigDetails;
  backOfficemenu;
	constructor(private cookie:CookieService) { }

    /**
     * [initial cookie setup]
     */
    cookieIntial() {
        this.accessToken = this.cookie.get('_acctok');
        this.usrTyd = this.cookie.get('_usrtyd');
        this.userId = this.cookie.get('_usrId');
        this.backuserId = this.cookie.get('_busrId');
        this.decuserId = CryptoJS.AES.encrypt(this.usrTyd, 'e5(8`*DK8L`YW8T7');
        this.userName = this.cookie.get('_userName');
        this.compId = this.cookie.get('_compnyId');
        this.empType = this.cookie.get('_empType');
    }
   
    /**
     * [Get the access token details]
     */
    getDetails() {
        if(this.accessToken == ""|| this.accessToken == null) {
            this.cookieIntial();
        }
        this.setDetail = {
            accesstoken: this.cookie.get('_acctok'),
            usrtype: this.usrTyd, 
            userId :this.userId, 
            userName: this.userName,
            backuserId: this.backuserId, 
            compId : this.compId,
            empType : this.empType,
        }
        //////console.error(err);.log(this.accessToken);
        return this.setDetail;
    }

    /**
     * [Empty the access token when user loggedout without loading the page]
     */
    emptyDetails() {
        this.accessToken = "";
        this.setDetail = {};
    }

    /**
     * [Get a contractor detail when move to new page ]
     */
    getContracts() {
        if(this.contractDetail == '' || this.contractDetail == 'undefined') {
            return this.contractDetail;
        }else {
            return this.cookie.getObject('contractDetails');
        }
    }
    
    /**
     * [Set a contractor detail when move to new page]
     * @param {[Object]} val [contains contractor infomation]
     */
    setContract(val) {
        this.contractDetail = val;
       // ////console.error(err);.log(this.contractDetail)
        this.cookie.putObject('contractDetails', this.contractDetail);
        return 'Y';
    }


    /**
     * [Get a Expense detail when move to new page ]
     */
    getExpense() {
        if( this.expenseReportId == '' ||  this.expenseReportId == 'undefined') {
            return this.expenseReportId;
        }else {
            return this.cookie.get('expenseReportId');
        }
    }


    /**
     * [Set a Expense detail when move to new page]
     * @param {[Object]} val [contains Expense infomation]
     */
    setExpense(id,status) {
        this.expenseReportId = id;
        this.cookie.put('expenseReportId', this.expenseReportId);
        this.cookie.put('_expOpen', status);
        return 'Y';
    }

    /**
     * [Set a Expense detail when move to new page]
     * @param {[Object]} val [contains Expense infomation]
     */
    setExpenseclient(id,status,userId) {
        this.expenseReportId = id;
        this.cookie.put('expenseReportId', this.expenseReportId);
        this.cookie.put('_expOpen', status);
        this.cookie.put('_onbehalf', userId);
        return 'Y';
    }


    /**
     * [Get a User ID to Edit detail when move to new page ]
     */
    getUser() {
        if( this.editUser == '' ||  this.editUser == 'undefined') {
            return this.editUser;
        }else {
            return this.cookie.get('editUser');
        }
    }


    /**
     * [Set a User ID to Edit detail when move to new page]
     * @param {[Object]} val [contains User infomation]
     */
    setUser(id) {
        this.editUser = id;
         this.cookie.put('editUser', this.editUser);
        return 'Y';
    }

  GetBackOfficeMenulst(value) {
    this.backOfficemenu = value;
    // ////console.error(err);.log(this.contractDetail)
    this.cookie.putObject('backOfficemenu', this.backOfficemenu);

    return 'Y';
  }

  GetVacations(value) {
    this.VacationDetails = value;
    // ////console.error(err);.log(this.contractDetail)
    this.cookie.putObject('VacationDetails', this.VacationDetails);

    return 'Y';
  }

  GetVacationConfig(value) {
    this.VacationConfigDetails = value;
    // ////console.error(err);.log(this.contractDetail)
    this.cookie.putObject('VacationConfigDetails', this.VacationConfigDetails);

    return 'Y';
  }
  GetPendingApprovalVacations(value) {
    this.PendingVacationDetails = value;
    // ////console.error(err);.log(this.contractDetail)
    this.cookie.putObject('PendingVacationDetails', this.PendingVacationDetails);

    return 'Y';
  }
 
  GetVacationsType(value) {
    this.VacationType = value;
    // ////console.error(err);.log(this.contractDetail)
    this.cookie.putObject('VacationType', this.VacationType);

    return 'Y';
  }
  GetSelectedWorkcontract(value) {
    this.WorkContractData = value;
    // ////console.error(err);.log(this.contractDetail)
    this.cookie.putObject('WorkContractData', this.WorkContractData);

    return 'Y';
  }
}
