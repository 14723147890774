//Angular decorators list
import { Component, OnInit, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { InvokedataService } from './../services/invokedata.service';

@Component({
    selector: 'app-acc-header',
    templateUrl: './acc-header.component.html',
    styleUrls: ['./acc-header.component.css']
})
export class AccHeaderComponent implements OnInit {

    // Initial setup
    @Input('accMenu') menu:any;
    //hightlightMenu;
    list;
    backId;
  isOld;
  newMenu = [];
  constructor(location: Location, router: Router, private cookie: CookieService, private invokeData: InvokedataService) {

        
  	}

  ngOnInit() {
    this.list = this.invokeData.getModules();
      ////console.error(err);.log(this.list);
      this.backId = this.cookie.get('_busrId');
      if(this.backId) {
        this.isOld = true;
      } else {
        this.isOld = false;
      }
     // console.error(err);.log(this.menu);
    //console.log(this.menu);
    //for (let i = 0; i < this.menu.length; i++) {
    //  this.newMenu.push({ 'name': this.menu[i].name, 'routeUrl': this.menu[i].routeUrl, 'class': this.menu[i].class, 'menuId': this.menu[i].menuId})
    //}
    //this.newMenu.push({ 'name': 'Federal Tax Form', 'routeUrl': '/account/federaltaxforms', 'class': 'formdetails', 'menuId': 14 })

  }

  
}
