//Angular decorators list
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Location } from "@angular/common";
//Custom services list
import { CookieService } from "ngx-cookie";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "./../../shared/services/http.service";
import { SessionService } from "./../../shared/services/sessiontoken.service";
import { Message } from "primeng/api";
import * as moment from "moment";
import * as _swal from "sweetalert";
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = _swal as any;
import * as html2pdf from "html2pdf.js";
import { DatePipe } from "@angular/common";
import { ChangeDetectorRef } from "@angular/core";
import { SaveTimesheetVO } from "src/app/shared/models/timesheets";

import { HOUR } from "ngx-bootstrap/chronos/units/constants";

@Component({
  selector: "app-multiTimesheetcycle",
  templateUrl: "./multiTimesheetcycle.component.html",
  styleUrls: ["./multiTimesheetcycle.component.css"],
})
export class multiTimesheetcycleComponent implements OnInit {
  // Initial setup

  @ViewChild("fileElement") fileElement: any;
  @ViewChild("fileContent") fileContent: any;
  calenderIndex: number;
  calnderListIndex: number;
  note;
  approvernote: string[] = [];
  result;
  wrkContId;
  timesheetList: SaveTimesheetVO[] = [];
  buserId;
  isUpload;
  charmessage: boolean = false;
  daytimesheetarray0 = [];
  daytimesheetarray1 = [];
  daytimesheetarray2 = [];
  daytimesheetarray3 = [];
  daytimesheetarray4 = [];
  daytimesheetarray5 = [];
  daytimesheetarray6 = [];
  daytimesheetarray7 = [];
  daytimesheetarray8 = [];
  daytimesheetarray9 = [];
  daytimesheetarray10 = [];
  daytimesheetarray11 = [];
  daytimesheetarray12 = [];
  daytimesheetarray13 = [];
  contractdetails0 = [];
  contractdetails1 = [];
  contractdetails2 = [];
  contractdetails3 = [];
  contractdetails4 = [];
  contractdetails5 = [];
  contractdetails6 = [];
  workhour0: number;
  workhour1: number;
  workhour2: number;
  workhour3: number;
  workhour4: number;
  workhour5: number;
  workhour6: number;
  error_status = false;
  notespoup = false;
  approvernotes = false;
  mousemoveapprovernotes = false;
  projectnotespoup = false;
  isLoading = true;
  WorkContractList;
  WorkStreamList = [];
  companyName;
  startDate;
  endDate;
  calenderData;
  calenderDatanotes: any[];
  calenderdatalist = [];
  notesArray;
  daytimesheet = [];
  specDay;
  active;
  subActive;
  totalTime;
  msgs: Message[] = [];
  dateData = {};
  datekey = [];
  matrixlist = [];
  matrixlist0 = [];
  matrixlist1 = [];
  rowtotallist = [];
  daylist = [];
  datewiselist0: any;
  datewiselist1: any;
  datewiselist2: any;
  datewiselist3: any;
  datewiselist4: any;
  datewiselist5: any;
  rowstotal0;
  rowstotal1;
  rowstotal2;
  rowstotal3;
  rowstotal4;
  rowstotal5;
  rowstotal6;
  grandrowtotals;
  pdflogotext: string;
  hideindays: boolean;
  totalRegular;
  totalOvertime;
  LandingPageDetails;
  backOfficemenu;
  display: boolean = false;
  approverlist: string[] = ["Open", "Approve", "Reject"];
  selectlist: string[] = [];
  workcontractapproverlist = [];
  file = {
    doctype: null,
    docname: null,
    fileData: null,
  };
  uploadFile;
  fileExten;
  nxtDate;
  preDate;
  invoiceCid;
  isFile: boolean = false;
  docId;
  backId;
  puserId;
  docName;
  BOCompanyList = [];
  empType;
  isSoleCon: boolean = false;
  isEmpCon: boolean = false;
  backUrl;
  caleStatus;
  isSubmit;
  isError;
  temp;
  attachedDocId;
  attachedDocname;
  hadAttach: boolean = false;
  deleterFlag: boolean = false;
  invoiceContractorId;
  showInvoice: boolean = false;
  showcolumn: boolean = true;
  oldDocId;
  oldDocName;
  remainWorkContractUnit;
  usedWorkContractUnit;
  CalDate;
  Notes;
  Vacationlst = [];
  hoursstring: any = [];
  notestring: any = [];
  ColorConfig;
  hidecontractunit: boolean = true;
  VacationConfigDetails;
  backUserid;
  lstvacationTypes;
  HolidayColor;
  PendingColor;
  RangeList = [];
  TxtUtilizationHours;
  totalUtilizationHours;
  TotalHours;
  txtTotalHours;
  DivWorkStream: boolean = false;
  IsPendingVacation: boolean = false;
  isVacationMessage: boolean = false;
  vacationMessage;
  contratorname: any;
  contractnumber: any;
  contractponumber: any;
  downloadpdfhours = [];
  projects: any = [];
  projectstest: any = [];
  groups = [
    {
      id: "",
    },
  ];

  project = [];

  totalunits: any;
  unit: any;
  dates: any;
  subtotal: number;
  specDay1: any;
  grandTotal: any;
  workContractIDs: any;
  notedata: any;
  projectset: any = [];
  calanderday;
  notespoupicon: boolean = false;
  workcontractstartDate: any;
  workcontractendDate: any;
  isapproveAlready: boolean;
  userroleId: number;
  clientApproved: number;
  isEdit: boolean;
  submitSheet: boolean;
  approvernotesindex: number;
  dayhours: any;
  hoursdays: number;
  dayhourslist = [];
  totalhours: number = 0.0;

  // onChagetxtBoxUtiliz2(value, list) {

  //   if (value != "") {
  //     //  var TotolHrs = this.totalRegular + this.totalOvertime;
  //     var TotolHrs = this.totalUtilizationHours;
  //     if (parseFloat(value) <= TotolHrs) {
  //       var totPer = 0;
  //       var EnterVal = parseFloat(value)

  //       //totPer = (EnterVal * TotolHrs) / TotolHrs;
  //       totPer = (EnterVal * 100) / TotolHrs;
  //       this.TxtUtilizationHours = totPer;
  //       var Tot = 0;
  //       for (let i = 0; i < this.WorkStreamList.length; i++) {
  //         if (this.WorkStreamList[i].id == list.id) {
  //           var test2 = Math.round(this.TxtUtilizationHours);
  //           this.WorkStreamList[i].LabelUtilizationHours = test2.toFixed(2);;
  //         }
  //         if (this.WorkStreamList[i].txtUtilizationHours != "") {
  //           Tot = Tot + parseFloat(this.WorkStreamList[i].txtUtilizationHours);
  //         }
  //         if (Tot <= TotolHrs) {
  //           var test1 = Math.round(Tot);
  //           this.totalUtilizationHours = test1.toFixed(2);
  //           // this.totalUtilizationHours = Tot;

  //         }
  //         else {
  //           swal("Cannot Add utilization!", 'Utilization hour is greater than the Total Hours', "warning")
  //           for (let i = 0; i < this.WorkStreamList.length; i++) {
  //             if (this.WorkStreamList[i].id == list.id) {
  //               this.WorkStreamList[i].LabelUtilizationHours = 0;
  //               this.WorkStreamList[i].txtUtilizationHours = 0;
  //             }
  //           }
  //         }
  //       }
  //     }
  //     else {
  //       swal("Cannot Add utilization!", 'Utilization hour is greater than the Total Hours', "warning")
  //       for (let i = 0; i < this.WorkStreamList.length; i++) {
  //         if (this.WorkStreamList[i].id == list.id) {
  //           this.WorkStreamList[i].LabelUtilizationHours = 0;
  //           this.WorkStreamList[i].txtUtilizationHours = 0;
  //         }
  //       }
  //     }
  //   }
  //   else {
  //     for (let i = 0; i < this.WorkStreamList.length; i++) {
  //       if (this.WorkStreamList[i].id == list.id) {
  //         this.WorkStreamList[i].LabelUtilizationHours = 0;
  //         this.WorkStreamList[i].txtUtilizationHours = "";
  //       }
  //     }
  //     // if (parseFloat(value) <= TotolHrs) {
  //     var totPer = 0;
  //     var TotolHrs = this.totalRegular + this.totalOvertime;
  //     //totPer = (EnterVal * 100) / TotolHrs;
  //     //this.TxtUtilizationHours = totPer;
  //     var Tot = 0;
  //     for (let i = 0; i < this.WorkStreamList.length; i++) {

  //       if (this.WorkStreamList[i].txtUtilizationHours != "") {
  //         Tot = Tot + parseFloat(this.WorkStreamList[i].txtUtilizationHours);
  //       }
  //       if (Tot <= TotolHrs) {
  //         var test1 = Math.round(Tot);
  //         this.totalUtilizationHours = test1.toFixed(2);
  //       }
  //       else {
  //         swal("Cannot Add utilization!", 'Utilization hour is greater than the Total Hours', "warning")
  //         for (let i = 0; i < this.WorkStreamList.length; i++) {
  //           if (this.WorkStreamList[i].id == list.id) {
  //             this.WorkStreamList[i].LabelUtilizationHours = 0;
  //             this.WorkStreamList[i].txtUtilizationHours = 0;
  //           }
  //         }
  //       }
  //     }
  //     //}
  //   }
  // }
  constructor(
    private http: HttpService,
    private ref: ChangeDetectorRef,
    private session: SessionService,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private Datepipe: DatePipe
  ) {}

  /**
   * [Route data from app-routing module]
   */
  ngAfterViewInit() {}
  ngOnInit() {
    this.backUserid = this.cookie.get("_busrId");
    this.buserId = this.cookie.get("_busrId");
    window.document.title = "Connect - Timesheet Cycle";
    this.VacationConfigDetails = this.cookie.getObject("VacationConfigDetails");
    if (this.VacationConfigDetails != undefined) {
      this.ColorConfig = this.VacationConfigDetails[0].VacationType;
      this.HolidayColor = this.VacationConfigDetails[0].HolidayVacationColor;
      this.PendingColor = this.VacationConfigDetails[0].PendingVacationColor;
      var vacationAnnouncement =
        this.VacationConfigDetails[0].VacationAnnouncement[0].IsMessageExist;

      if (vacationAnnouncement == "Yes") {
        this.isVacationMessage = true;
        this.vacationMessage =
          this.VacationConfigDetails[0].VacationAnnouncement[0].Message;
      }
    }

    this.route.data.forEach((results: any) => {
      this.totalUtilizationHours = 0;
      this.GetJsonData();

      this.invoiceContractorId =
        results.result.response["invoiceContractor"]["invoiceContractorId"];
      for (let i = 0; i <= 100; i++) {
        this.RangeList.push({ Id: i, Range: i });
      }

      var IsUtlization = this.VacationConfigDetails[0].Utilization[0].IsExist;
      if (IsUtlization == "Yes") {
        if (
          results.result.response["workStreamUtilizationList"] != null &&
          results.result.response["workStreamUtilizationList"].length > 0
        ) {
          var lstGetWorkStream = [];
          this.DivWorkStream = true;
          lstGetWorkStream =
            results.result.response["workStreamUtilizationList"];

          var url = "getWorkContractList?userId=" + this.backUserid;
          var data = "";
          this.http.postMethod(url, data, true, true).subscribe((data) => {
            if (data["status"] == "Y") {
              this.WorkContractList = data["response"]["list"];
              this.WorkContractList[0].value;

              var url =
                "getWorkStreamList?userId=" +
                this.backUserid +
                "&workContractId=" +
                this.WorkContractList[0].value;
              var data1 = "";
              this.http
                .postMethod(url, data1, true, true)
                .subscribe((data1) => {
                  if (data1["status"] == "Y") {
                    var lstWorkStream = [];
                    lstWorkStream = data1["response"]["list"];
                    if (lstWorkStream.length > 0) {
                      var Tot1 = 0;
                      for (let i = 0; i < lstGetWorkStream.length; i++) {
                        var details = lstWorkStream.filter(
                          (x) => x.id === lstGetWorkStream[i].workStreamId
                        );
                        if (details.length > 0) {
                          var TotolHrs = this.totalRegular + this.totalOvertime;
                          var totPer1 = 0;
                          var EnterVal = parseFloat(lstGetWorkStream[i].hours);
                          totPer1 = (EnterVal * 100) / TotolHrs;
                          this.TxtUtilizationHours = totPer1;
                          var test3 = Math.round(this.TxtUtilizationHours);
                          this.WorkStreamList.push({
                            id: details[0].id,
                            description: details[0].description,
                            txtUtilizationHours: lstGetWorkStream[i].hours,
                          });
                          Tot1 = Tot1 + parseFloat(lstGetWorkStream[i].hours);
                        }
                      }
                      var test1 = Math.round(Tot1);
                      this.totalUtilizationHours = test1.toFixed(2);
                    }
                  }
                });
            }
          });
        } else {
          var url = "getWorkContractList?userId=" + this.backUserid;
          var data = "";
          this.http.postMethod(url, data, true, true).subscribe((data) => {
            if (data["status"] == "Y") {
              this.WorkContractList = data["response"]["list"];
              this.WorkContractList[0].value;

              var url =
                "getWorkStreamList?userId=" +
                this.backUserid +
                "&workContractId=" +
                this.WorkContractList[0].value;
              var data1 = "";
              this.http
                .postMethod(url, data1, true, true)
                .subscribe((data1) => {
                  if (data1["status"] == "Y") {
                    var lstWorkStream = [];
                    // this.WorkStreamList = data1['response']['list'];
                    lstWorkStream = data1["response"]["list"];
                    if (lstWorkStream.length > 0) {
                      this.DivWorkStream = true;
                      for (let i = 0; i < lstWorkStream.length; i++) {
                        //  var txtUtilizationHours = "txtUtilizationHours" + i;

                        this.WorkStreamList.push({
                          id: lstWorkStream[i].id,
                          description: lstWorkStream[i].description,
                          txtUtilizationHours: 0,
                          LabelUtilizationHours: 0,
                        });
                      }
                    } else {
                      this.DivWorkStream = false;
                    }
                  }
                });
            }
          });
        }
      } else {
        this.DivWorkStream = false;
      }

      var url = "getVacationType?userId=" + this.backUserid;
      var data = "";
      this.http.postMethod(url, data, true, true).subscribe((data) => {
        if (data["status"] == "Y") {
          this.lstvacationTypes = data["response"]["list"];
        }
      });

      //calculate tota hours

      //////console.error(err);.log(results);

      this.dataLoader(results.result);
      this.getMultitimeSheetdetails();
      if (this.isSubmit == true) {
        this.grandTotals();
      }

      this.GetVacationDetails();

      this.calculaterhours();

      // this.GetGrandTotal();
      //this.isLoading= false;
      //this.listFirst = results.result[0].ref_placement_summary_id;
    });
  }

  /**
	Data loader function
	 * @param {[Result with data]} result
	 */

  dataLoader(results) {
    ////console.error(err);.log(results);
    this.result = results;
    this.isLoading = false;
    this.hadAttach = false;
    this.backUrl = this.cookie.get("_previousUrl");
    if (this.result["status"] == "N") {
      this.msgs = [];
      this.msgs.push({ severity: "error", detail: this.result["message"] });

      this.router.navigate([this.backUrl]);
    } else {
      this.companyName =
        this.result.response.invoiceContractor["companyFullname"];
      //////console.error(err);.log(this.companyName);
      this.totalRegular =
        this.result.response.invoiceContractor["totalRegularHour"];
      this.totalOvertime =
        this.result.response.invoiceContractor["totalOvertimeHour"];
      this.caleStatus =
        this.result.response.timecardStatusCode["timecardStatusCode"];
      this.contratorname =
        this.result.response.invoiceContractor["contractorFirstName"] +
        this.result.response.invoiceContractor["contractorLastName"];
      this.contractnumber = this.result.response["financialCode4"];
      this.unit = this.result.response["unit"];
      ////console.error(err);.log(this.caleStatus);
      // if(this.caleStatus==4) {

      // }
      if (
        this.caleStatus == 4 ||
        this.caleStatus == 6 ||
        this.caleStatus != 1
      ) {
        this.isSubmit = true;
        this.showInvoice = true;
      } else {
        this.isSubmit = false;
        this.showInvoice = false;
        //this.isSubmit = false;
      }
      //var userId = this.session.backuserId;
      //let workContractId = this.result.response.invoiceContractor['workContractId'];
      //let invoiceContractorId = this.result.response.invoiceContractor['invoiceContractorId'];
      //let params = {userId,workContractId, targetDateStr,invoiceContractorId};
      //let period ={targetDateStr,toDateStr};
      //let responseDate = {params,period}
      // var result = this.session.setContract(responseDate);
      let preData = this.session.getContracts();
      this.empType = this.session.empType;
      //this.startDate = preData['period']['targetDateStr'];
      this.dates = this.result.response.invoiceContractor["invStartDt"];
    }
  }
  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  getMultitimeSheetdetails() {
    this.project = [];
    var workcontractids = [];
    let preData = this.session.getContracts();
    var startDate = preData["period"]["targetDateStr"];
    let Endate = preData["period"]["toDateStr"];
    let contractId = preData["params"]["contractId"];
    let status = preData["params"]["status"];
    Endate = Endate.replace("--", "-");
    this.project = JSON.parse(sessionStorage.getItem("_projectslist"));
    workcontractids = this.project.filter(
      (x) =>
        x.invStartDt == startDate &&
        x.invEndDt == Endate &&
        x.contractId == contractId &&
        x.status == status
    );
    if (workcontractids.length > 0) {
      this.startDate = startDate;
      this.endDate = Endate;

      var lstUtilization = [];
      for (let i = 0; i < workcontractids.length; i++) {
        var UtilizationUrl =
          "&workContractIds=" + "" + workcontractids[i].workContractId;
        lstUtilization.push(UtilizationUrl);
        if (workcontractids[i].status == "CLIENT_APPROVED") {
          this.selectlist.push("Approve");
        } else {
          this.selectlist.push("Open");
        }
      }

      var strUtilVar = lstUtilization.join("");

      var url =
        "getTimesheetDetailListByTargetDate?userId=" +
        this.buserId +
        strUtilVar +
        "&targetDateStr=" +
        startDate;
      var data1 = "";

      this.http.postBackMethod(url, data1, true, true).subscribe((result) => {
        if (result["status"] == "Y") {
          var datas = result;
          this.calenderData = datas["response"]["timesheetDetailList"];

          sessionStorage.setItem(
            "_projects",
            JSON.stringify(this.calenderData)
          );
          this.userroleId = this.result.response.identityBean["userTypeId"];
          this.clientApproved =
            this.calenderData[0].timecardStatusCode["timecardStatusCode"];
          this.empType = this.session.empType;
          ////console.error(err);.log(this.clientApproved);
          let isApprover = false;
          let isAdmin = false;
          let isManager = false;
          let isViewer = false;
          let isVendorcont = false;
          for (var roles of this.result.response.identityBean.roles) {
            if (roles["roleTypeId"] == 135) {
              //this.isapproveAlready = true;

              ////console.error(err);.log("135");
              isApprover = true;
              this.cookie.put("_roleTypename", "Approver");
              this.cookie.put("_roleType", roles["roleTypeId"]);
              this.checkApproved();
            } else {
              if (roles["roleTypeId"] == 131) {
                //this.isapproveAlready = true;
                ////console.error(err);.log("131");
                isManager = true;
                //this.isapproveAlready = false;
                this.cookie.put("_roleTypename", "Manager");
                this.cookie.put("_roleType", roles["roleTypeId"]);
                this.checkApproved();
                //break;
              } else if (roles["roleTypeId"] == 133) {
                ////console.error(err);.log("100");
                isViewer = true;
                this.isapproveAlready = true;
                this.cookie.put("_roleTypename", "Viewer");
                this.cookie.put("_roleType", roles["roleTypeId"]);
              } else if (roles["roleTypeId"] == 100) {
                ////console.error(err);.log("100");
                isAdmin = true;
                this.isapproveAlready = true;
                this.cookie.put("_roleTypename", "Viewer");
                this.cookie.put("_roleType", roles["roleTypeId"]);
              } else if (roles["roleTypeId"] == 138) {
                isVendorcont = true;
                this.cookie.put("_roleTypename", "Viewer");
                this.cookie.put("_roleType", roles["roleTypeId"]);
              }
            }
          }
          ////console.error(err);.log(isApprover,isAdmin,this.isapproveAlready)
          if (isApprover || (isApprover && isAdmin)) {
            if (this.userroleId == 400 && this.clientApproved == 1) {
              ////console.error(err);.log("approver", this.isEdit,this.submitSheet)
              this.isEdit = true;
              this.submitSheet = true;
            } else if (
              (this.userroleId == 400 && this.clientApproved == 2) ||
              (this.userroleId == 300 && this.clientApproved == 3)
            ) {
              this.submitSheet = false;
              this.isEdit = false;
              this.isapproveAlready = false;
            } else {
              this.submitSheet = false;
              this.isEdit = false;
              this.isapproveAlready = true;
            }
          } else if (isVendorcont || (isVendorcont && isAdmin)) {
            if (this.clientApproved == 1 && this.userroleId == 450) {
              this.isEdit = true;
              this.submitSheet = true;
            } else {
              this.submitSheet = false;
              this.isEdit = false;
              this.isapproveAlready = true;
            }
          } else if (isManager) {
            if (this.clientApproved == 3) {
              //this.submitSheet = false;
              // this.isEdit = false;
              this.isapproveAlready = false;
            } else {
              //this.submitSheet = false;
              //this.isEdit = false;
              this.isapproveAlready = true;
            }
          } else {
            this.isapproveAlready = true;
          }
          this.hourstodays();
          for (let i = 0; i < this.calenderData.length; i++) {
            var arr = [];
            var arr1 = [];
            var workcontractid = this.calenderData[i].workContractId;
            var workcontractDes = this.calenderData[i].workContractDesc;
            var contractponumber = this.calenderData[i].financialCode1;
            var contractnumber = this.calenderData[i].financialCode4;
            var endDate = this.calenderData[i].endDt;
            var startdate = this.calenderData[i].startDt;
            var noteList = this.calenderData[i].noteList;
            var contractId = this.calenderData[i].contractId;

            var EmptyStr = "&nbsp;";
            for (var note of noteList) {
              let now = moment(note["noteDate"]); // add this 2 of 4
              note["noteDate"] = now.format("DD-MMM-YYYY h:mm a");

              var FirstStr1 = note["note"].split(":")[0];
              var FirstStr2 = note["note"].split(":")[1];
              if (FirstStr2 == EmptyStr) {
                note["note"] = FirstStr1;
              } else {
                if (FirstStr2 == " undefined") {
                  note["note"] = FirstStr1;
                } else {
                  note["note"] = note["note"];
                }
              }
              if (note["note"] == "(SUBMISSION - VENDOR_APPROVED) ") {
                note["note"] = "(SUBMISSION - Submit for Approval)";
              }
              //}
            }
            for (let i = 0; i < noteList.length; i++) {
              let now = moment(noteList[i].notedate);
              noteList[i].notedate = now.format("dd-mmm-yyyy");
              let to = moment(noteList[i].notedate);
            }

            for (let j = 0; j < this.calenderData[i].calendarList.length; j++) {
              arr1 = arr1.concat(this.calenderData[i].calendarList[j]);
              arr = arr.concat(
                this.calenderData[i].calendarList[j].timeCardWeekList
              );
            }
            this.calenderdatalist.push({
              workContractId: workcontractid,
              workContractDesc: workcontractDes,
              calenderlist: arr1,
              timeCardWeekList: arr,
              contractponumber: contractponumber,
              contractnumber: contractnumber,
              noteList: noteList,
              endate: endDate,
              startdate: startdate,
              contractid: contractId,
            });
          }

          var weeklength = this.calenderdatalist[0].timeCardWeekList.length;
          for (let i = 0; i < this.calenderdatalist.length; i++) {
            var calendardatas = [];
            var calenderLength =
              this.calenderdatalist[i].timeCardWeekList.length;
            var matrix = "matrix" + i;
            if (weeklength !== calenderLength) {
              var daydiff = weeklength - calenderLength;

              for (let j = 0; j < daydiff; j++) {
                var day = this.calenderdatalist[i].timeCardWeekList[j].day;
                var cday =
                  this.calenderdatalist[i].timeCardWeekList[j].calendarDay;
                calendardatas.push({
                  IsVacation: "",
                  calendarDay: cday,
                  day: day,
                  hrWork: 0,
                });
              }
              for (
                let k = 0;
                k < this.calenderdatalist[i].timeCardWeekList.length;
                k++
              ) {
                var calendarday = this.calenderdatalist[i].timeCardWeekList[k];
                calendardatas.push(calendarday);
              }
              this.daytimesheet.push({
                workContractId: this.calenderdatalist[i].workContractId,
                workContractDesc: this.calenderdatalist[i].workContractDesc,
                calenderlist: this.calenderdatalist[i].calenderlist,
                timeCardWeekList: calendardatas,
                contractponumber: this.calenderdatalist[i].contractponumber,
                contractnumber: this.calenderdatalist[i].contractnumber,
                noteList: this.calenderdatalist[i].noteList,
                endate: this.calenderdatalist[i].endate,
                startdate: this.calenderdatalist[i].startdate,
                hours: 0,
                contractid: this.calenderdatalist[i].contractid,
                matrix: matrix,
              });
            } else {
              this.daytimesheet.push({
                workContractId: this.calenderdatalist[i].workContractId,
                workContractDesc: this.calenderdatalist[i].workContractDesc,
                calenderlist: this.calenderdatalist[i].calenderlist,
                timeCardWeekList: this.calenderdatalist[i].timeCardWeekList,
                contractponumber: this.calenderdatalist[i].contractponumber,
                contractnumber: this.calenderdatalist[i].contractnumber,
                noteList: this.calenderdatalist[i].noteList,
                endate: this.calenderdatalist[i].endate,
                startdate: this.calenderdatalist[i].startdate,
                hours: 0,
                contractid: this.calenderdatalist[i].contractid,
                matrix: matrix,
              });
            }
          }

          if (this.daytimesheet.length > 0) {
            if (this.isSubmit == true) {
              this.multimaxtrixtimesheet();
              this.dayWiseTimesheet();
              this.contractwisedownloadtimesheet();
            }

            let preData = this.session.getContracts();
            let userId = preData["params"]["userId"];
            let VstartDate = preData["period"]["targetDateStr"];
            let VEndDate = preData["period"]["toDateStr"];
            var VEndDates = this.Datepipe.transform(VEndDate, "dd-MMM-yyyy");
            this.buserId = this.cookie.get("_busrId");
            let workContractId = (this.wrkContId =
              preData["params"]["workContractId"]);
            var data1;
            var url =
              "getConsultantVacationCalendarList?userId=" +
              userId +
              "&vacationStart=" +
              VstartDate +
              "&vacationEnd=" +
              VEndDates +
              "&workContractId=" +
              workContractId;
            this.http.postMethod(url, data1, true, true).subscribe((data) => {
              if (data["status"] == "Y") {
                this.Vacationlst = data["response"]["list"];
                //holiday
                var data1 = "";
                var url =
                  "getHolidayList?userId=" +
                  this.backUserid +
                  "&workContractId=" +
                  workContractId +
                  "&dateStartStr=" +
                  VstartDate +
                  "&dateEndStr=" +
                  VEndDates;
                this.http
                  .postMethod(url, data1, true, true)
                  .subscribe((data1) => {
                    if (data1["status"] == "Y") {
                      var IsHoliday =
                        this.VacationConfigDetails[0].HolidayConfig[0]
                          .IsHolidayConfig;
                      if (IsHoliday == "Yes") {
                        var HolidayList = data1["response"]["list"];
                        if (HolidayList.length > 0) {
                          for (let j = 0; j < HolidayList.length; j++) {
                            var Hdate = HolidayList[j].holidayDate.substring(
                              0,
                              10
                            );
                            let now = moment(Hdate); // add this 2 of 4
                            HolidayList[j].holidayDate =
                              now.format("DD-MMM-YYYY");
                          }

                          if (this.daytimesheet.length > 0) {
                            for (let i = 0; i < this.daytimesheet.length; i++) {
                              for (
                                let j = 0;
                                j <
                                this.daytimesheet[i].timeCardWeekList.length;
                                j++
                              ) {
                                var CalendarDay =
                                  this.daytimesheet[i].timeCardWeekList[j].day;

                                var details;
                                details = HolidayList.filter(
                                  (x) => x.holidayDate === CalendarDay
                                );
                                if (details.length > 0) {
                                  this.daytimesheet[i].timeCardWeekList[
                                    j
                                  ].isCycle = false;
                                  this.daytimesheet[i].timeCardWeekList[j][
                                    "HolidayDt"
                                  ] = details[0].holidayDate;
                                  this.daytimesheet[i].timeCardWeekList[j][
                                    "IsHoliday"
                                  ] = "true";
                                  this.daytimesheet[i].timeCardWeekList[j][
                                    "description"
                                  ] = details[0].description;
                                  this.daytimesheet[i].timeCardWeekList[j][
                                    "HolidayColor"
                                  ] = this.HolidayColor;
                                }
                              }
                            }
                          }
                        }
                      }

                      //

                      var IsVacations =
                        this.VacationConfigDetails[0].VacationConfig[0]
                          .IsVacationConfig;
                      if (IsVacations == "Yes") {
                        if (this.Vacationlst.length > 0) {
                          //for (let v = 0; v > this.Vacationlst.length; v++) {
                          //  //this.VacationsLists.push(this.Vacationlst[v].vacationDateStr)
                          //  this.VacationsLists.push(this.Vacationlst[v].vacationDateStr);
                          //}
                        }
                        if (
                          this.daytimesheet.length > 0 &&
                          this.Vacationlst.length > 0
                        ) {
                          for (let i = 0; i < this.daytimesheet.length; i++) {
                            for (
                              let j = 0;
                              j < this.daytimesheet[i].timeCardWeekList.length;
                              j++
                            ) {
                              var CalendarDay =
                                this.daytimesheet[i].timeCardWeekList[j].day;
                              var CalendarDay1 = CalendarDay.toUpperCase();
                              var details;
                              details = this.Vacationlst.filter(
                                (x) =>
                                  x.vacationDateStr.toUpperCase() ===
                                  CalendarDay1
                              );
                              if (details.length > 0) {
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "Vdate"
                                ] = details[0].vacationDateStr;
                                this.daytimesheet[i].timeCardWeekList[
                                  j
                                ].isCycle = "true";
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "IsVacation"
                                ] = "true";
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "vacationType"
                                ] = details[0].vacationType;
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "unit"
                                ] = details[0].unit;
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "vacationStatus"
                                ] = details[0].vacationStatus;
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "createdDateStr"
                                ] = details[0].createdDateStr;
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "value"
                                ] = details[0].value;

                                var vacationclass =
                                  this.lstvacationTypes.filter(
                                    (x) => x.value === details[0].vacationType
                                  );
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "className"
                                ] = vacationclass[0].className;
                                if (this.ColorConfig != null) {
                                  var vacationColor = this.ColorConfig.filter(
                                    (x) =>
                                      x.className ===
                                      this.daytimesheet[i].timeCardWeekList[j]
                                        .className
                                  );
                                  // this.calenderData[i].timeCardWeekList[j]['Color'] = vacationColor[0].CalendarVacationColor;
                                  if (
                                    details[0].vacationStatus ==
                                    "Applied(Approval Pending)"
                                  ) {
                                    this.daytimesheet[i].timeCardWeekList[j][
                                      "Color"
                                    ] = this.PendingColor;
                                  } else {
                                    this.daytimesheet[i].timeCardWeekList[j][
                                      "Color"
                                    ] = vacationColor[0].CalendarVacationColor;
                                  }
                                }
                              } else {
                                this.daytimesheet[i].timeCardWeekList[j][
                                  "IsVacation"
                                ] = "false";
                              }
                            }
                          }
                        } else {
                          for (let i = 0; i < this.daytimesheet.length; i++) {
                            for (
                              let k = 0;
                              k < this.daytimesheet[i].timeCardWeekList.length;
                              k++
                            )
                              this.daytimesheet[i].timeCardWeekList[k][
                                "IsVacation"
                              ] = "false";
                          }
                        }
                        //

                        //
                      }
                      if (this.isSubmit == true) {
                        var TotalUtilHr = 0;
                        for (let i = 0; i < this.daytimesheet.length; i++) {
                          for (
                            let k = 0;
                            k < this.daytimesheet[i].timeCardWeekList.length;
                            k++
                          ) {
                            if (
                              this.daytimesheet[i].timeCardWeekList[k]
                                .isCycle == "true"
                            ) {
                              TotalUtilHr =
                                TotalUtilHr +
                                parseFloat(
                                  this.daytimesheet[i].timeCardWeekList[k]
                                    .hrWork
                                );
                            }
                          }
                        }
                        this.txtTotalHours = TotalUtilHr;
                      }

                      for (var i = 0; i < this.daytimesheet.length; i++) {
                        var total = 0;
                        for (
                          var k = 0;
                          k < this.daytimesheet[i].calenderlist.length;
                          k++
                        ) {
                          var time =
                            this.daytimesheet[i].calenderlist[k].weekWorkHr;

                          if (time) {
                            total += Number(time);
                          }
                        }
                        this.daytimesheet[i].weekWorkHr = total;
                      }
                      var total = 0;
                      for (let i = 0; i < this.daytimesheet.length; i++) {
                        if (!isNaN(this.daytimesheet[i].weekWorkHr)) {
                          total += Number(this.daytimesheet[i].weekWorkHr);
                        }
                      }
                      this.grandTotal = total.toFixed(2);

                      this.nxtDate = this.result.response["nextDate"];
                      this.preDate = this.result.response["priviousDate"];
                      this.invoiceCid =
                        this.result.response.invoiceContractor[
                          "invoiceContractorId"
                        ];
                      if (this.empType == "E") {
                        ////console.error(err);.log(this.empType);
                        this.isEmpCon = true;
                      } else {
                        ////console.error(err);.log(this.empType);
                        this.isSoleCon = true;
                      }
                      var mainIndex = 0;

                      for (var item of this.daytimesheet) {
                        this.totalTime = 0;

                        this.specDay = item["timeCardWeekList"];
                        var index = 0;
                        this.datekey = [];
                        for (var intem of this.specDay) {
                          //this.dateData.push(intem);

                          var str = intem.day.substring(3, 6);
                          var str1 = intem.day.substring(0, 2);
                          this.CalDate = str + " " + str1;
                          //let now = moment(intem.day); // add this 2 of 4
                          //this.CalDate = now.format('MMM-DD-YYYY');
                          this.dateData["cDate_" + mainIndex + "_" + index] =
                            intem.calendarDay.substring(0, 3);
                          this.dateData["cDate1_" + mainIndex + "_" + index] =
                            intem.calendarDay;
                          //this.dateData['cDay_' + mainIndex + "_" + index] = intem.day;
                          this.dateData["cDay_" + mainIndex + "_" + index] =
                            this.CalDate;
                          this.dateData["hrx_" + mainIndex + "_" + index] =
                            intem.hrExp;
                          this.dateData["hrOt_" + mainIndex + "_" + index] =
                            intem.hrOvertime;
                          this.dateData["hrReg_" + mainIndex + "_" + index] =
                            intem.hrReg;
                          this.dateData["iCycle_" + mainIndex + "_" + index] =
                            intem.isCycle;
                          this.dateData[
                            "IsVacation_" + mainIndex + "_" + index
                          ] = intem.IsVacation;
                          this.dateData[
                            "vacationType_" + mainIndex + "_" + index
                          ] = intem.vacationType;
                          this.dateData["unit_" + mainIndex + "_" + index] =
                            intem.value + " " + intem.unit;
                          this.dateData[
                            "vacationStatus_" + mainIndex + "_" + index
                          ] = intem.vacationStatus;
                          this.dateData[
                            "createdDateStr_" + mainIndex + "_" + index
                          ] = intem.createdDateStr;

                          this.dateData[
                            "className_" + mainIndex + "_" + index
                          ] = intem.className;
                          this.dateData["Color_" + mainIndex + "_" + index] =
                            intem.Color;

                          this.dateData[
                            "HolidayDt_" + mainIndex + "_" + index
                          ] = intem.HolidayDt;
                          this.dateData[
                            "IsHoliday_" + mainIndex + "_" + index
                          ] = intem.IsHoliday;
                          this.dateData[
                            "description_" + mainIndex + "_" + index
                          ] = intem.description;
                          this.dateData[
                            "HolidayColor_" + mainIndex + "_" + index
                          ] = intem.HolidayColor;

                          //console.log(this.dateData['iCycle_'+mainIndex+"_"+index]);
                          //////console.error(err);.log(intem.hrWork)
                          if (intem.isCycle == "true") {
                            this.dateData["note_" + mainIndex + "_" + index] =
                              intem.note;
                            // console.log("sdsd");
                            if (intem.hrWork) {
                              this.dateData[
                                "hrWrk_" + mainIndex + "_" + index
                              ] = intem.hrWork;
                            } else {
                              this.dateData[
                                "hrWrk_" + mainIndex + "_" + index
                              ] = 0;
                            }
                          }
                          //else {
                          //  this.dateData['IsVacation' + mainIndex + "_" + index] = true;
                          //  this.dateData['vacationType' + mainIndex + "_" + index] = intem.vacationType;
                          //}
                          //var splittedHr = '"'+intem.hrWork+'"';
                          // if(intem.hrWork){
                          // splittedHr = splittedHr.toString().split('.')[0];
                          // if(splittedHr.length==2) {
                          //     this.dateData['hrWrk_'+mainIndex+"_"+index] = intem.hrWork;

                          // } else {
                          //     this.dateData['hrWrk_'+mainIndex+"_"+index] = intem.hrWork;
                          // }
                          // } else {
                          //     //this.dateData['hrWrk_'+mainIndex+"_"+index] = 0.00;
                          // }

                          this.dateData["tCard_" + mainIndex + "_" + index] =
                            intem.timeCardStatus;
                          this.dateData["tError_" + mainIndex + "_" + index] =
                            -1;
                          if (intem.timeCardStatus == 1) {
                            //////console.error(err);.log(intem.hrWork );

                            this.totalTime =
                              this.totalTime + parseInt(intem.hrWork);
                            //////console.error(err);.log(intem,this.totalTime);
                          }
                          if (intem.IsVacation == "true") {
                            this.datekey.push({
                              calendarDay: "cDate_" + mainIndex + "_" + index,
                              calendarday1: "cDate1_" + mainIndex + "_" + index,
                              day: "cDay_" + mainIndex + "_" + index,
                              hrExp: "hrx_" + mainIndex + "_" + index,
                              hrOvertime: "hrOt_" + mainIndex + "_" + index,
                              hrReg: "hrReg_" + mainIndex + "_" + index,
                              hrWork: "hrWrk_" + mainIndex + "_" + index,
                              note: "note_" + mainIndex + "_" + index,
                              isCycle: "iCycle_" + mainIndex + "_" + index,
                              timeCardStatus:
                                "tCard_" + mainIndex + "_" + index,
                              timeCardError:
                                "tError_" + mainIndex + "_" + index,
                              IsVacation:
                                "IsVacation_" + mainIndex + "_" + index,
                              vacationType:
                                "vacationType_" + mainIndex + "_" + index,
                              Vunit: "unit_" + mainIndex + "_" + index,
                              vacationStatus:
                                "vacationStatus_" + mainIndex + "_" + index,
                              createdDateStr:
                                "createdDateStr_" + mainIndex + "_" + index,
                              className: "className_" + mainIndex + "_" + index,
                              Color: "Color_" + mainIndex + "_" + index,
                            });
                            index++;
                          } else if (intem.IsHoliday == "true") {
                            this.datekey.push({
                              calendarDay: "cDate_" + mainIndex + "_" + index,
                              calendarday1: "cDate1_" + mainIndex + "_" + index,
                              day: "cDay_" + mainIndex + "_" + index,
                              hrExp: "hrx_" + mainIndex + "_" + index,
                              hrOvertime: "hrOt_" + mainIndex + "_" + index,
                              hrReg: "hrReg_" + mainIndex + "_" + index,
                              hrWork: "hrWrk_" + mainIndex + "_" + index,
                              note: "note_" + mainIndex + "_" + index,
                              isCycle: "iCycle_" + mainIndex + "_" + index,
                              timeCardStatus:
                                "tCard_" + mainIndex + "_" + index,
                              timeCardError:
                                "tError_" + mainIndex + "_" + index,
                              HolidayDt:
                                "HolidayDt__" + mainIndex + "_" + index,
                              IsHoliday: "IsHoliday_" + mainIndex + "_" + index,
                              description:
                                "description_" + mainIndex + "_" + index,
                              HolidayColor:
                                "HolidayColor_" + mainIndex + "_" + index,
                            });
                            index++;
                          } else {
                            this.datekey.push({
                              calendarDay: "cDate_" + mainIndex + "_" + index,
                              calendarday1: "cDate1_" + mainIndex + "_" + index,
                              day: "cDay_" + mainIndex + "_" + index,
                              hrExp: "hrx_" + mainIndex + "_" + index,
                              hrOvertime: "hrOt_" + mainIndex + "_" + index,
                              hrReg: "hrReg_" + mainIndex + "_" + index,
                              hrWork: "hrWrk_" + mainIndex + "_" + index,
                              note: "note_" + mainIndex + "_" + index,
                              isCycle: "iCycle_" + mainIndex + "_" + index,
                              timeCardStatus:
                                "tCard_" + mainIndex + "_" + index,
                              timeCardError:
                                "tError_" + mainIndex + "_" + index,
                              IsVacation:
                                "IsVacation_" + mainIndex + "_" + index,
                              vacationType:
                                "vacationType_" + mainIndex + "_" + index,
                            });
                            index++;
                          }
                          //////console.error(err);.log(totalTime);
                          //////console.error(err);.log(this.datekey,this.dateData);
                        }
                        this.daytimesheet[mainIndex]["timeCardWeekList"] =
                          this.datekey;
                        this.temp = "12:12";
                        mainIndex++;
                      }
                    }
                  });
              }
            });
          }
        }
      });
    }

    //////console.error(err);.log(this.calenderData);
  }
  checkApproved() {
    if (
      this.clientApproved == 4 ||
      this.clientApproved == 6 ||
      this.clientApproved == 1
    ) {
      this.isapproveAlready = true;
    } else {
      ////console.error(err);.log("coming in");
      this.isapproveAlready = false;
    }
  }

  GetWorkContractList() {
    var url = "getWorkContractList?userId=" + this.backUserid;
    var data = "";
    this.http.postMethod(url, data, true, true).subscribe((data) => {
      if (data["status"] == "Y") {
        this.WorkContractList = data["response"]["list"];
        this.WorkContractList[0].value;
      }
    });
  }
  getvalue(val) {
    this.notedata = val;
  }

  ChangeDrpUtilize(value, list) {
    // alert(this.totalRegular);
    // alert(this.totalOvertime);
    //var TotolHrs = this.totalRegular + this.totalOvertime;
    var TotolHrs = 200;
    var totPer = 0;
    totPer = (parseInt(value) * TotolHrs) / 100;
    this.TxtUtilizationHours = totPer;

    for (let i = 0; i < this.WorkStreamList.length; i++) {
      if (this.WorkStreamList[i].id == list.id) {
        this.WorkStreamList[i].txtUtilizationHours = this.TxtUtilizationHours;
      }
    }
  }

  savenotes(val) {
    var test = [];
    //this.position[this.calnderListIndex]=val
    this.dateData[
      this.daytimesheet[this.calenderIndex].timeCardWeekList[
        this.calnderListIndex
      ].note
    ] = val;

    this.note = "";
    this.notespoup = false;
  }
  approverssavenotes(notes) {
    if (this.workcontractapproverlist.length > 0) {
      for (let i = 0; i < this.workcontractapproverlist.length; i++) {
        if (
          i == this.approvernotesindex &&
          this.workcontractapproverlist[i].notes == ""
        ) {
          this.workcontractapproverlist[this.approvernotesindex].notes = notes;
          notes = "";
        } else {
          if (this.workcontractapproverlist[i].notes == "") {
            this.workcontractapproverlist[i].notes = notes;
            notes = "";
          }
        }
      }

      this.approvernotes = false;
    }
  }

  //  downloadtimesheetpdf():void {

  //   document.getElementById('pdfTable').style.visibility="visible";

  //   const doc = new jsPDF('p','mm',[500,580]);
  //   var test={
  //     'background': '#FFFFFF',
  //     'color':'black',
  //     'pagesplit':true
  //   }

  // doc.addHTML(document.getElementById('pdfTable'),test, function() {
  //   doc.save('timesheet.pdf');

  // });
  // setTimeout(function()  {
  //   document.getElementById('pdfTable').style.visibility="hidden";
  // }, 50);

  // }
  test() {
    setTimeout(function () {}, 5000);
  }

  downloadtimesheetpdf() {
    document.getElementById("pdfTable").style.display = "block";
    var element = document.getElementById("pdfTable");
    var opt = {
      margin: 1,
      filename: "contract wise timesheet.pdf",
      image: { type: "jpeg", quality: 0.98 },

      html2canvas: { scale: 4, dpi: 192, letterRendering: true },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    // New Promise-based usage:
    html2pdf()
      .set(opt)
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var total = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= total; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(50);
          pdf.text(
            "Page " + i + " of " + total,
            pdf.internal.pageSize.getWidth() / 1.1,
            pdf.internal.pageSize.getHeight() - 1.5
          );
        }
        console.log(total);
      })
      .save();
    setTimeout(function () {
      document.getElementById("pdfTable").style.display = "none";
    }, 50);
  }
  downloaddaywisetimesheetpdf() {
    document.getElementById("pdfdaywiseTable").style.display = "block";
    var element = document.getElementById("pdfdaywiseTable");
    var opt = {
      margin: 1,
      filename: "day wise timesheet.pdf",
      image: { type: "jpeg", quality: 1 },

      html2canvas: { scale: 4, useCORS: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var total = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= total; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(50);
          pdf.text(
            "Page " + i + " of " + total,
            pdf.internal.pageSize.getWidth() / 1.1,
            pdf.internal.pageSize.getHeight() - 1.5
          );
        }
        console.log(total);
      })
      .save();
    setTimeout(function () {
      document.getElementById("pdfdaywiseTable").style.display = "none";
    }, 50);
  }

  downloadmatrxitimesheetpdf() {
    document.getElementById("pdfmatrixTable").style.display = "block";
    var element = document.getElementById("pdfmatrixTable");
    var opt = {
      margin: 1,
      filename: "matrix view timesheet.pdf",
      image: { type: "jpeg", quality: 0.98 },

      html2canvas: { scale: 2, dpi: 192, letterRendering: true },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    // New Promise-based usage:
    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var total = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= total; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(50);
          pdf.text(
            "Page " + i + " of " + total,
            pdf.internal.pageSize.getWidth() / 1.1,
            pdf.internal.pageSize.getHeight() - 1.5
          );
        }
        console.log(total);
      })
      .save();
    setTimeout(function () {
      document.getElementById("pdfmatrixTable").style.display = "none";
    }, 50);
  }

  onChagetxtBoxUtiliz(value, list) {
    if (value != "") {
      //  var TotolHrs = this.totalRegular + this.totalOvertime;
      var TotolHrs = this.txtTotalHours;
      if (parseFloat(value) <= TotolHrs) {
        var totPer = 0;
        var EnterVal = parseFloat(value);
        /*totPer = (EnterVal * 100) / TotolHrs*/ //this.TxtUtilizationHours = totPer;
        var Tot = 0;
        for (let i = 0; i < this.WorkStreamList.length; i++) {
          if (this.WorkStreamList[i].txtUtilizationHours != "") {
            Tot = Tot + parseFloat(this.WorkStreamList[i].txtUtilizationHours);
          }
          var test1 = Tot;
          this.totalUtilizationHours = test1.toFixed(2);
          //if (Tot <= TotolHrs) {
          //  var test1 = Tot;
          //  this.totalUtilizationHours = test1.toFixed(2);
          //}
          //else {
          //  swal("Cannot Add utilization!", 'Utilization hour is greater than the Total Hours', "warning")
          //  for (let i = 0; i < this.WorkStreamList.length; i++) {
          //    if (this.WorkStreamList[i].id == list.id) {
          //      this.WorkStreamList[i].LabelUtilizationHours = 0;
          //      this.WorkStreamList[i].txtUtilizationHours = 0;
          //    }
          //  }
          //}
        }
      } else {
        // swal("Cannot Add utilization!", 'Utilization hour is greater than the Total Hours', "warning")
        for (let i = 0; i < this.WorkStreamList.length; i++) {
          if (this.WorkStreamList[i].id == list.id) {
            this.WorkStreamList[i].LabelUtilizationHours = 0;
            this.WorkStreamList[i].txtUtilizationHours = 0;
          }
        }
      }
    } else {
      for (let i = 0; i < this.WorkStreamList.length; i++) {
        if (this.WorkStreamList[i].id == list.id) {
          this.WorkStreamList[i].LabelUtilizationHours = 0;
          this.WorkStreamList[i].txtUtilizationHours = "";
        }
      }
      // if (parseFloat(value) <= TotolHrs) {
      var totPer = 0;
      var TotolHrs = this.TotalHours + this.totalOvertime;
      //totPer = (EnterVal * 100) / TotolHrs;
      //this.TxtUtilizationHours = totPer;
      var Tot = 0;
      for (let i = 0; i < this.WorkStreamList.length; i++) {
        if (this.WorkStreamList[i].txtUtilizationHours != "") {
          Tot = Tot + parseFloat(this.WorkStreamList[i].txtUtilizationHours);
        }
        var test1 = Math.round(Tot);
        this.totalUtilizationHours = test1.toFixed(2);
        //if (Tot <= TotolHrs) {
        //  var test1 = Math.round(Tot);
        //  this.totalUtilizationHours = test1.toFixed(2);
        //}
        //else {
        //  swal("Cannot Add utilization!", 'Utilization hour is greater than the Total Hours', "warning")
        //  for (let i = 0; i < this.WorkStreamList.length; i++) {
        //    if (this.WorkStreamList[i].id == list.id) {
        //      this.WorkStreamList[i].LabelUtilizationHours = 0;
        //      this.WorkStreamList[i].txtUtilizationHours = 0;
        //    }
        //  }
        //}
      }
      //}
    }
  }

  onChagetxtBoxUtiliz1(value, list) {
    if (value != "") {
      var TotolHrs = this.totalRegular + this.totalOvertime;
      if (parseFloat(value) <= TotolHrs) {
        var totPer = 0;
        var EnterVal = parseFloat(value);

        totPer = (EnterVal * 100) / TotolHrs;
        this.TxtUtilizationHours = totPer;
        var Tot = 0;
        for (let i = 0; i < this.WorkStreamList.length; i++) {
          if (this.WorkStreamList[i].id == list.id) {
            var test2 = Math.round(this.TxtUtilizationHours);
            this.WorkStreamList[i].LabelUtilizationHours = test2.toFixed(2);
          }
          if (this.WorkStreamList[i].txtUtilizationHours != "") {
            Tot = Tot + parseFloat(this.WorkStreamList[i].txtUtilizationHours);
          }
          if (Tot <= TotolHrs) {
            var test1 = Math.round(Tot);
            this.totalUtilizationHours = test1.toFixed(2);
            // this.totalUtilizationHours = Tot;
          } else {
            swal(
              "Cannot Add utilization!",
              "Utilization hour is greater than the Total Hours",
              "warning"
            );
            for (let i = 0; i < this.WorkStreamList.length; i++) {
              if (this.WorkStreamList[i].id == list.id) {
                this.WorkStreamList[i].LabelUtilizationHours = 0;
                this.WorkStreamList[i].txtUtilizationHours = 0;
              }
            }
          }
        }
      } else {
        swal(
          "Cannot Add utilization!",
          "Utilization hour is greater than the Total Hours",
          "warning"
        );
        for (let i = 0; i < this.WorkStreamList.length; i++) {
          if (this.WorkStreamList[i].id == list.id) {
            this.WorkStreamList[i].LabelUtilizationHours = 0;
            this.WorkStreamList[i].txtUtilizationHours = 0;
          }
        }
      }
    } else {
      for (let i = 0; i < this.WorkStreamList.length; i++) {
        if (this.WorkStreamList[i].id == list.id) {
          this.WorkStreamList[i].LabelUtilizationHours = 0;
          this.WorkStreamList[i].txtUtilizationHours = "";
        }
      }
      // if (parseFloat(value) <= TotolHrs) {
      var totPer = 0;

      var TotolHrs = this.totalRegular + this.totalOvertime;
      //totPer = (EnterVal * 100) / TotolHrs;
      //this.TxtUtilizationHours = totPer;
      var Tot = 0;
      for (let i = 0; i < this.WorkStreamList.length; i++) {
        if (this.WorkStreamList[i].txtUtilizationHours != "") {
          Tot = Tot + parseFloat(this.WorkStreamList[i].txtUtilizationHours);
        }
        if (Tot <= TotolHrs) {
          var test1 = Math.round(Tot);
          this.totalUtilizationHours = test1.toFixed(2);
        } else {
          swal(
            "Cannot Add utilization!",
            "Utilization hour is greater than the Total Hours",
            "warning"
          );
          for (let i = 0; i < this.WorkStreamList.length; i++) {
            if (this.WorkStreamList[i].id == list.id) {
              this.WorkStreamList[i].LabelUtilizationHours = 0;
              this.WorkStreamList[i].txtUtilizationHours = 0;
            }
          }
        }
      }
      //}
    }
  }
  /**
   * [Enter Time]
   * @param {[String]} contId
   */
  enterTime(index, subindex) {
    //////console.error(err);.log(index,subindex);
    if (!this.isSubmit) {
      this.active = index;
      this.subActive = subindex;
    }
  }

  getTotals(marks: any) {
    console.log(marks.reduce((acc, val) => (acc += val.hrWork), 0));
  }
  checkHour(i, j, item) {
    //console.error(err);.log(item,i,j);
    if (!item) {
      //console.error(err);.log("ddd");
      this.addTime(i, j, 0);
    }
    if (item) {
      this.dateData["tError_" + i + "_" + j] = -1;
      if (Number(item) > 24) {
        this.dateData["tError_" + i + "_" + j] = i * 10 + j * 1;
        ////console.error(err);.log(this.dateData['tError_'+i+"_"+j], i, j)
        this.msgs = [];
        this.msgs.push({
          severity: "error",
          detail: "kindly enter below 24 hours",
        });
        this.isError = true;
      } else {
        this.isError = false;
        this.addTime(i, j, item);
      }
    }
  }

  grandTotals() {
    var total = 0;
    for (let i = 0; i < this.daytimesheet.length; i++) {
      if (!isNaN(this.daytimesheet[i].weekWorkHr)) {
        total += Number(this.daytimesheet[i].weekWorkHr);
      }
    }

    this.grandTotal = total.toFixed(2);
    this.txtTotalHours = total;
  }

  addTime(i, j, item) {
    var total = 0;
    var total1 = 0;
    for (var k = 0; k < this.daytimesheet[i]["timeCardWeekList"].length; k++) {
      var time =
        this.dateData[this.daytimesheet[i]["timeCardWeekList"][k].hrWork];

      if (time) {
        total += Number(time);
      }
    }

    this.daytimesheet[i].weekWorkHr = total;

    if (this.daytimesheet[i].weekWorkHr != null) {
      this.grandTotals();
    }
    // this.GetGrandColumnTotal(i,j)

    //For loop count total
    var TotalHourlst = [];
    var Totals = 0;
    for (let i = 0; i <= 6; i++) {
      for (let j = 0; j <= 6; j++) {
        var Timeslot = "hrWrk" + "_" + i + "_" + j;
        var Hour = this.dateData["hrWrk" + "_" + i + "_" + j];
        if (Hour) {
          TotalHourlst.push({ TimeSlot: Timeslot, Hours: Hour });
          Totals += Number(Hour);
        }
      }
    }
    //this.txtTotalHours = Totals;
  }
  selectvalue(workcontractId, value, startDate, endate) {
    if (this.workcontractapproverlist.length > 0) {
      var alreadyworkcontractid = this.workcontractapproverlist.filter(
        (x) => x.workcontractId == workcontractId
      );
      if (alreadyworkcontractid.length > 0) {
        this.workcontractapproverlist.push({
          workcontractId: workcontractId,
          value: value,
          startDate: startDate,
          endate: endate,
          notes: "",
        });
        this.workcontractapproverlist.splice(0, 1);
      } else {
        this.workcontractapproverlist.push({
          workcontractId: workcontractId,
          value: value,
          startDate: startDate,
          endate: endate,
          notes: "",
        });
      }
    } else {
      this.workcontractapproverlist.push({
        workcontractId: workcontractId,
        value: value,
        startDate: startDate,
        endate: endate,
        notes: "",
      });
    }
  }
  getTotal() {
    let total = 0;
    for (var i = 0; i < this.calenderData.length; i++) {
      if (this.calenderData[i].hrWork) {
        total += this.calenderData[i].hrWork;
        this.totalTime = total;
        //////console.error(err);.log(this.totalTime);
      }
    }
    return total;
  }
  acceptTimesheet() {
    var invoiceContractorId;
    if (this.workcontractapproverlist.length > 0) {
      for (let i = 0; i < this.workcontractapproverlist.length; i++) {
        if (this.workcontractapproverlist[i].value == "Approve") {
          invoiceContractorId = this.project.find(
            (x) =>
              x.workContractId ==
                this.workcontractapproverlist[i].workcontractId &&
              x.invStartDt == this.workcontractapproverlist[i].startDate &&
              x.invEndDt == this.workcontractapproverlist[i].endate
          );
          if (invoiceContractorId.status != "CLIENT_APPROVED") {
            this.acceptmultiTimesheet(
              invoiceContractorId.invoiceContractorId,
              this.workcontractapproverlist[i].notes
            );
          }
        } else if (this.workcontractapproverlist[i].value == "Reject") {
          invoiceContractorId = this.project.find(
            (x) =>
              x.workContractId ==
                this.workcontractapproverlist[i].workcontractId &&
              x.invStartDt == this.workcontractapproverlist[i].startDate &&
              x.invEndDt == this.workcontractapproverlist[i].endate
          );
          this.declinemultiTimesheet(
            invoiceContractorId.invoiceContractorId,
            this.workcontractapproverlist[i].notes
          );
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: "info",
            detail: "please select correct approvers",
          });
          //this.router.navigate(['/client/home']);
          setTimeout(() => this.location.back(), 3000);
        }
      }
    }
  }

  acceptmultiTimesheet(invoiceContractorId, notes) {
    var url;

    url =
      "approveTimesheet?userId=" +
      this.buserId +
      "&invoiceContractorId=" +
      invoiceContractorId +
      "&note=" +
      notes;

    ////console.error(err);.log(url);
    var data = "";
    this.isLoading = true;
    this.http.postMethod(url, data, true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        if (data["status"] == "Y") {
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: "Timesheet submit Successfully",
          });
          this.router.navigate(["/client/pending-approvals"]);
        } else if (data["status"] == "N") {
          this.msgs = [];
          this.msgs.push({ severity: "error", detail: data["message"] });
          //this.router.navigate(['/client/home']);
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: "error",
            detail: "Please try again later",
          });
        }
      },
      (err) => {
        //console.error(err);.error(err);
        this.msgs = [];
        this.msgs.push({ severity: "error", detail: "Server Side Failure" });
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  declinemultiTimesheet(invoiceContractorId, note) {
    if (note != null || note != "" || note != undefined) {
      var notesDetails = encodeURIComponent(note);
    } else {
      notesDetails = "";
    }
    var url;

    //url = "rejectTimesheet?userId="+this.buserId+"&invoiceContractorId="+this.invoiceContractorId+"&note=Rejected this timesheet"+"&appCode=3";
    url =
      "rejectTimesheet?userId=" +
      this.buserId +
      "&invoiceContractorId=" +
      invoiceContractorId +
      "&note=" +
      notesDetails +
      "&appCode=3";

    ////console.error(err);.log(url);
    var data = "";
    this.isLoading = true;
    this.http.postMethod(url, data, true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        if (data["status"] == "Y") {
          this.msgs = [];
          //this.msgs.push({severity:'success', detail:'Timesheet a Successfully'});
          this.router.navigate(["/client/pending-approvals"]);

          //setTimeout(() => this.router.navigate(['/client/home']), 3000)
        } else if (data["status"] == "N") {
          this.msgs = [];
          this.msgs.push({ severity: "error", detail: data["message"] });
          //this.router.navigate(['/client/home']);
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: "error",
            detail: "Please try again later",
          });
        }
      },
      (err) => {
        //console.error(err);.error(err);
        this.msgs = [];
        this.msgs.push({ severity: "error", detail: "Server Side Failure" });
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  /**
   * [Go back to contract]
   */
  goBack() {
    var reportGoback = this.cookie.get("_isReport");
    if (reportGoback == "on") {
      this.cookie.remove("_isReport");
      this.router.navigate(["/contractor/reports"]);
    } else {
      this.location.back();
    }
  }

  AddVacation() {
    if (this.Vacationlst.length > 0) {
      for (let v = 0; v > this.Vacationlst[v].length; v++) {
        //  this.VacationsLists.push(this.Vacationlst[v].vacationDateStr)
      }
    }
    if (this.daytimesheet.length > 0) {
      for (let i = 0; i < this.daytimesheet.length; i++) {
        for (let j = 0; j < this.daytimesheet[i].timeCardWeekList.length; j++) {
          var CalendarDay = this.daytimesheet[i].timeCardWeekList[j].day;
          var details = this.Vacationlst.filter(
            (x) => x.vacationDateStr === CalendarDay
          );
        }
      }
    }
  }

  /**
     File Drag and Drop Event
	*/

  /********
   ** Delete Old attachment
   **/
  deleteOldAttachInvoice() {
    ////console.error(err);.log("Going to Delete .. He hehe")
    //console.error(err);.log(this.isFile);
    this.isLoading = true;
    let url =
      "removeAttachment?userId=" +
      this.buserId +
      "&workContractId=" +
      this.wrkContId +
      "&targetDateStr=" +
      this.startDate;
    var data = "";
    //////console.error(err);.log(date);
    this.http.postBackMethod(url, data, true, true).subscribe(
      (data) => {
        if (data["status"] == "Y") {
          ////console.error(err);.log(data);
          ////console.error(err);.log(data['status']=='Y');
          this.isFile = false;
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: "Document deleted successfully !!!",
          });
        }
        //this.isLoading = true;
      },
      (err) => {},
      () => {
        this.isLoading = false;
      }
    );
  }
  /********
   ** Delete Old attachment
   **/
  deleteOldAttachraiseInvoice() {
    ////console.error(err);.log("Going to Delete .. He hehe")
    //console.error(err);.log(this.isFile);
    this.isLoading = true;
    let url =
      "removeAttachment?userId=" +
      this.buserId +
      "&workContractId=" +
      this.wrkContId +
      "&targetDateStr=" +
      this.startDate;
    var data = "";
    //////console.error(err);.log(date);
    this.http.postBackMethod(url, data, true, true).subscribe(
      (data) => {
        if (data["status"] == "Y") {
          ////console.error(err);.log(data);
          ////console.error(err);.log(data['status']=='Y');
          this.isFile = false;
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: "Document deleted successfully !!!",
          });
        }
        //this.isLoading = true;
      },
      (err) => {},
      () => {
        this.isLoading = false;
      }
    );
  }

  /**
   * [Convert base64 To ArrayBuffer]
   * @param {[Object]} data
   */
  base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  downDoc(docId) {
    var url = "downAttachemntInfo?userId=" + this.buserId + "&docId=" + docId;
    //////console.error(err);.log(datamain,"ggg");
    //var arrBuffer = this.base64ToArrayBuffer(data['document']);
    var blob;
    this.http.postMethod(url, "", true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        if (data["status"] == "Y") {
          var arrBuffer = this.base64ToArrayBuffer(
            data["response"]["contentBase64"]
          );
          var filename = data["response"]["fileName"];
          // var fileExtension = filename.split('.')[1];
          var fileExtension = filename.substr(filename.lastIndexOf(".") + 1);

          //console.log(fileExtension);

          switch (fileExtension) {
            case "png":
            case "jpeg":
            case "jpg":
            case "PNG":
            case "JPG":
            case "JPEG":
            case "gif":
            case "GIF":
              blob = new Blob([arrBuffer], { type: "image/" + fileExtension });
              break;
            case "pdf":
            case "PDF":
              blob = new Blob([arrBuffer], {
                type: "application/" + fileExtension,
              });
              break;
            case "txt":
              blob = new Blob([arrBuffer], { type: "text/plain" });
              break;
            case "doc":
            case "DOC":
              ////console.error(err);.log("click");
              blob = new Blob([arrBuffer], { type: "application/msword" });
              break;
            case "docx":
            case "DOCX":
              ////console.error(err);.log("click");
              blob = new Blob([arrBuffer], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              break;
            case "xls":
            case "XLS":
              //console.log("click");
              blob = new Blob([arrBuffer], {
                type: "application/vnd.ms-excel",
              });
              break;
            case "xlsx":
            case "XLSX":
              //console.log("fileExtension");
              ////console.error(err);.log("click");
              blob = new Blob([arrBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              break;
            case "zip":
            case "ZIP":
              blob = new Blob([arrBuffer], {
                type: "application/x-zip-compressed",
              });
              break;
            case "eml":
            case "EML":
              blob = new Blob([arrBuffer], { type: "message/rfc822" });
              break;
            case "msg":
              blob = new Blob([arrBuffer], {
                type: "application/vnd.ms-outlook",
              });
              break;
            case "mbox":
            case "MBOX":
              blob = new Blob([arrBuffer], { type: "application/mbox" });
              break;
            default:
            //$('#divFiles').text('File type: Unknown');
          }
          var url;
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            window.open(window.URL.createObjectURL(blob), "_blank");
          } else {
            url = window.URL.createObjectURL(blob);
            //window.open(objectUrl);
          }
          var link = document.createElement("a");
          link.href = url;
          link.download = filename;
          link.click();
          setTimeout(function () {
            //document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }, 100);
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: filename.split(".")[0] + " Downloaded Successfully",
          });
          this.isLoading = false;
        } else {
          this.msgs = [];
          this.msgs.push({ severity: "error", detail: data["Message"] });
        }
        //this.isLoading = true;
      },
      (err) => {},
      () => {
        this.isLoading = false;
      }
    );
  }

  disMisspopUp() {
    this.display = false;
    this.isUpload = false;
    //this.uploadForm.resetForm();
    this.file.fileData = "";
  }
  calculaterhours() {
    let totalhours;
    let grandtotal;
    var lstCalendar = this.calenderData;
    if (lstCalendar != null) {
      for (let i = 0; i < lstCalendar.length; i++) {
        for (let j = 0; j < lstCalendar[i].timeCardWeekList.length; j++) {
          totalhours += parseFloat(lstCalendar[i].timeCardWeekList[j].hrWork);

          this.downloadpdfhours.push({
            calendarDay: lstCalendar[i].timeCardWeekList[j].calendarDay,
            hrReg: lstCalendar[i].timeCardWeekList[j].hrWork,
            hrOvertime: lstCalendar[i].timeCardWeekList[j].hrOvertime,
            Totalhours: totalhours,
            GrandTotal: grandtotal,
          });
        }
      }
    }
    //   grandtotal=this.txtTotalHours

    //  this.grandtotelhours=grandtotal.toFixed(2)
  }
  GetGrandTotal() {
    var total = 0;
    for (let i = 0; i < this.downloadpdfhours.length; i++) {
      if (!isNaN(this.downloadpdfhours[i].Totalhours)) {
        total += Number(this.downloadpdfhours[i].Totalhours);
      }
    }
    return total;
  }

  GetJsonData() {
    this.http.GetJsonData().subscribe((res) => {
      this.backId = this.cookie.get("_busrId");
      this.puserId = this.cookie.get("_puserId");

      this.LandingPageDetails = res;

      var LandingDetails = this.LandingPageDetails;
      var tempMenus = [];
      const hideBankingInfo =
        this.LandingPageDetails[0].IsUnitsAndContractNumber;
      if (this.backId) {
        if (hideBankingInfo == undefined) {
          this.hidecontractunit = false;
        }
        this.backOfficemenu = this.cookie.getObject("backOfficemenu");
        const registerDomainId =
          this.result.response.identityBean.registeredMVDomain;
        this.BOCompanyList = hideBankingInfo[0].BOCompanyIDs;
        var isBOCompanyExists = this.BOCompanyList.indexOf(registerDomainId);
        for (let i = 0; i < this.BOCompanyList.length; i++) {
          if (this.BOCompanyList[i] == registerDomainId) {
            this.hidecontractunit = true;
          } else {
            this.hidecontractunit = false;
          }
        }
        if (registerDomainId == "34551") {
          this.pdflogotext = "Mindwire";
        } else if (registerDomainId == "2450" || registerDomainId == "31471") {
          this.pdflogotext = "BH-Connect";
        }
        if (registerDomainId == "34551" || registerDomainId == "2450") {
          this.hideindays = true;
        } else {
          this.hideindays = false;
        }
      }
    });
  }
  GetVacationDetails() {
    let preData = this.session.getContracts();
    let userId = preData["params"]["userId"];
    let VstartDate = preData["period"]["targetDateStr"];
    let VEndDate = preData["period"]["toDateStr"];
    var VEndDates = this.Datepipe.transform(VEndDate, "dd-MMM-yyyy");

    let workContractId = (this.wrkContId = preData["params"]["workContractId"]);

    var data;
    var url =
      "getConsultantVacationCalendarList?userId=" +
      userId +
      "&vacationStart=" +
      VstartDate +
      "&vacationEnd=" +
      VEndDates +
      "&workContractId=" +
      workContractId;
    this.http.postMethod(url, data, true, true).subscribe((data) => {
      if (data["status"] == "Y") {
        this.Vacationlst = data["response"]["list"];
        if (this.Vacationlst.length > 0) {
          //for (let v = 0; v > this.Vacationlst.length; v++) {
          //  //this.VacationsLists.push(this.Vacationlst[v].vacationDateStr)
          //  this.VacationsLists.push(this.Vacationlst[v].vacationDateStr);
          //}
        }
        if (this.daytimesheet.length > 0) {
          for (let i = 0; i < this.daytimesheet.length; i++) {
            for (
              let j = 0;
              j < this.daytimesheet[i].timeCardWeekList.length;
              j++
            ) {
              var CalendarDay = this.daytimesheet[i].timeCardWeekList[j].day;
              var details = this.Vacationlst.filter(
                (x) =>
                  x.vacationDateStr.toUpperCase() === CalendarDay.toUpperCase()
              );
            }
          }
        }
      }
    });
  }
  GetWorkUnitDetails(workContractId, endate, totalhours) {
    let preData = this.session.getContracts();
    let userId = preData["params"]["userId"];

    var data;
    var url =
      "getContractorMatrix?userId=" +
      userId +
      "&workContractorId=" +
      workContractId +
      "&invEndDtStr=" +
      endate;
    this.http.postMethod(url, data, true, true).subscribe((data) => {
      if (data["status"] == "Y") {
        this.totalunits =
          parseFloat(data["response"]["openWorkContractUnit"]) +
          parseFloat(data["response"]["workContractUnit"]);
        this.remainWorkContractUnit =
          data["response"]["remainWorkContractUnit"];
        this.remainWorkContractUnit = Math.abs(this.remainWorkContractUnit);
        this.usedWorkContractUnit = data["response"]["usedWorkContractUnit"];
        this.dayhours = data["response"]["hoursDay"];
        if (this.dayhours) {
          this.hoursdays = totalhours / this.dayhours;
        }
      }
    });
  }
  approveraddnotes(i, workcontracid, startDate, endate) {
    this.approvernotesindex = i;
    if (this.workcontractapproverlist.length > 0) {
      this.approvernotes = true;
      for (let i = 0; i < this.workcontractapproverlist.length; i++) {
        var workcontracids = this.workcontractapproverlist.find(
          (x) => x.workcontractId == workcontracid
        );
        if (workcontracids != undefined) {
          if (
            workcontracids.workcontractId == workcontracid &&
            workcontracids.notes != ""
          ) {
            if (i == this.approvernotesindex) {
              this.approvernote[this.approvernotesindex] =
                this.workcontractapproverlist[this.approvernotesindex].notes;
            } else {
              if (this.workcontractapproverlist[i].notes != "") {
                this.approvernote[i] = this.workcontractapproverlist[i].notes;
              } else {
                this.approvernote[i] = this.workcontractapproverlist[i].notes =
                  "";
              }
            }
          } else {
            this.approvernote[this.approvernotesindex] = "";
          }
        } else {
          this.approvernotes = false;
        }
      }
    } else {
      var approvedworkcontractid = this.project.find(
        (x) =>
          x.workContractId == workcontracid &&
          x.invStartDt == startDate &&
          x.invEndDt == endate
      );
      if (approvedworkcontractid.status == "CLIENT_APPROVED") {
        this.approvernotes = true;
        var noteelist = this.calenderData[i].noteList;
        var EmptyStr = "&nbsp;";
        for (var note of noteelist) {
          let now = moment(note["noteDate"]); // add this 2 of 4
          note["noteDate"] = now.format("DD-MMM-YYYY h:mm a");

          var FirstStr1 = note["note"].split(":")[0];
          var FirstStr2 = note["note"].split(":")[1];

          if (FirstStr2 == EmptyStr) {
            note["note"] = FirstStr1;
          } else {
            if (FirstStr2 == " undefined") {
              note["note"] = FirstStr1;
            } else {
              note["note"] = note["note"];
            }
          }
          if (FirstStr1 == "(APPROVED - CLIENT_APPROVED) ") {
            this.approvernote[i] = FirstStr2;
          }
          //}
        }
      }
    }
  }
  hourstodays() {
    let preData = this.session.getContracts();
    let userId = preData["params"]["userId"];
    var workContractId;

    var workcontractendDate;
    for (let i = 0; i < this.calenderData.length; i++) {
      var index = i;
      workContractId = this.calenderData[i].workContractId;
      var weekWorkHr = 0;
      workcontractendDate = this.calenderData[i].endDt;
      let to = moment(workcontractendDate);
      workcontractendDate = to.format("DD-MMM-YYYY");
      for (let j = 0; j < this.calenderData[i].calendarList.length; j++) {
        var weekhours = this.calenderData[i].calendarList[j].weekWorkHr;
        if (weekhours) {
          weekWorkHr += Number(weekhours);
        }
      }

      this.GethoursToDays(
        userId,
        workContractId,
        workcontractendDate,
        weekWorkHr,
        index
      );
    }
  }
  GethoursToDays(
    userId,
    workContractId,
    workcontractendDate,
    weekWorkHr,
    index
  ) {
    var data;
    var total = 0;
    var url =
      "getContractorMatrix?userId=" +
      userId +
      "&workContractorId=" +
      workContractId +
      "&invEndDtStr=" +
      workcontractendDate;
    this.http.postMethod(url, data, true, true).subscribe((data) => {
      if (data["status"] == "Y") {
        var dayhours = data["response"]["hoursDay"];
        if (dayhours) {
          var hoursdays = weekWorkHr / dayhours;
          total = Number(hoursdays);
          this.daytimesheet[index].hours = hoursdays;
          this.totalhours += total;
        }
      }
    });
  }

  addNotes(workcontractId, calanderday, i, j) {
    this.notespoup = true;
    this.charmessage = false;
    this.calenderIndex = i;
    this.calnderListIndex = j;

    var startDate = this.startDate.substring(this.startDate.length - 4);

    this.calanderday =
      this.dateData[
        this.daytimesheet[this.calenderIndex].timeCardWeekList[
          this.calnderListIndex
        ].day
      ];
    var day1 = calanderday.substring(0, 3);
    var day2 = calanderday.substring(calanderday.length - 2);

    this.calanderday = day2 + "-" + day1 + "-" + startDate;

    this.note =
      this.dateData[
        this.daytimesheet[this.calenderIndex].timeCardWeekList[
          this.calnderListIndex
        ].note
      ];
  }

  openproject(workContractId) {
    this.projectnotespoup = true;
    let url =
      "getTimesheetDetailByTargetDate?userId=" +
      this.buserId +
      "&workContractId=" +
      workContractId +
      "&targetDateStr=" +
      this.startDate;
    var data1 = "";

    this.http.postBackMethod(url, data1, true, true).subscribe((result) => {
      if (result["status"] == "Y") {
        var datas = result;

        this.calenderDatanotes = datas["response"];
        this.contractponumber = this.calenderDatanotes["financialCode1"];
        this.contractnumber = this.calenderDatanotes["financialCode4"];
        let calendarList = this.calenderDatanotes["calendarList"];
        this.workcontractstartDate = this.calenderDatanotes["startDt"];
        let now = moment(this.workcontractstartDate); // add this 2 of 4
        this.workcontractstartDate = now.format("DD-MMM-YYYY");
        this.workcontractendDate = this.calenderDatanotes["endDt"];

        let to = moment(this.workcontractendDate);
        this.workcontractendDate = to.format("DD-MMM-YYYY");
        var totalhours = 0;
        for (let i = 0; i < calendarList.length; i++) {
          var total = calendarList[i].weekWorkHr;
          totalhours += Number(total);
        }
        this.GetWorkUnitDetails(
          workContractId,
          this.workcontractendDate,
          totalhours
        );
        this.notesArray = this.calenderDatanotes["noteList"];

        var EmptyStr = "&nbsp;";
        for (var note of this.notesArray) {
          let now = moment(note["noteDate"]); // add this 2 of 4
          note["noteDate"] = now.format("DD-MMM-YYYY h:mm a");
          note["note"] = note["note"].replace(/&nbsp;/g, "");
          var FirstStr1 = note["note"].split(":")[0];
          var FirstStr2 = note["note"].split(":")[1];

          if (FirstStr2 == EmptyStr) {
            note["note"] = FirstStr1;
          } else {
            if (FirstStr2 == " undefined") {
              note["note"] = FirstStr1;
            } else {
              note["note"] = note["note"];
            }
          }
          if (note["note"] == "(SUBMISSION - VENDOR_APPROVED) ") {
            note["note"] = "(SUBMISSION - Submit for Approval)";
          }
          //}
        }
        for (var i = 0; i < this.notesArray.length; i++) {
          let now = moment(this.notesArray[i].notedate);
          this.notesArray[i].notedate = now.format("dd-mmm-yyyy");
          let to = moment(this.notesArray[i].notedate);
        }
      }
    });
  }

  closeproject() {
    this.projectnotespoup = false;
  }
  closenotes() {
    this.notespoup = false;
  }
  approverclosenotes() {
    this.approvernotes = false;
  }
  groupBy(array, f) {
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }
  multimaxtrixtimesheet() {
    var rowtoal = 0;
    var rowstotal0 = 0;
    var rowstotal1 = 0;
    var rowstotal2 = 0;
    var rowstotal3 = 0;
    var rowstotal4 = 0;
    var rowstotal5 = 0;
    var rowstotal6 = 0;
    var grandTotal = 0;
    if (this.daytimesheet.length > 0) {
      for (let i = 0; i < this.daytimesheet.length; i++) {
        var matrixvalue = this.daytimesheet[i].matrix;
        this.rowtotallist.push({ rowstotali: 0 });
        for (let j = 0; j < this.daytimesheet[i].timeCardWeekList.length; j++) {
          if (matrixvalue == "matrix0") {
            this.matrixlist.push({
              date: "",
              hours0: "",
              hours1: "",
              hours2: "",
              hours3: "",
              hours4: "",
              hours5: "",
              hours6: "",
              matrixtd0: "",
              matrixtd1: "",
              matrixtd2: "",
              matrixtd3: "",
              matrixtd4: "",
              matrixtd5: "",
              matrixtd6: "",
              projectwisetotal: 0,
              datewisetotal: 0,
            });

            this.matrixlist[j].date =
              this.daytimesheet[i].timeCardWeekList[j].calendarDay;
            if (
              this.daytimesheet[i]["timeCardWeekList"][j].hrWork &&
              this.daytimesheet[i]["timeCardWeekList"][j].isCycle == "true"
            ) {
              this.matrixlist[j].hours0 =
                this.daytimesheet[i]["timeCardWeekList"][j].hrWork;
            } else {
              this.matrixlist[j].hours0 = "0.00";
            }

            this.matrixlist[j].matrixtd0 = true;
          } else if (matrixvalue == "matrix1") {
            if (
              this.daytimesheet[i]["timeCardWeekList"][j].hrWork &&
              this.daytimesheet[i]["timeCardWeekList"][j].isCycle == "true"
            ) {
              this.matrixlist[j].hours1 =
                this.daytimesheet[i]["timeCardWeekList"][j].hrWork;
            } else {
              this.matrixlist[j].hours1 = "0.00";
            }

            this.matrixlist[j].matrixtd1 = true;
          } else if (matrixvalue == "matrix2") {
            if (
              this.daytimesheet[i]["timeCardWeekList"][j].hrWork &&
              this.daytimesheet[i]["timeCardWeekList"][j].isCycle == "true"
            ) {
              this.matrixlist[j].hours2 =
                this.daytimesheet[i]["timeCardWeekList"][j].hrWork;
            } else {
              this.matrixlist[j].hours2 = "0.00";
            }
            this.matrixlist[j].matrixtd2 = true;
          } else if (matrixvalue == "matrix3") {
            if (
              this.daytimesheet[i]["timeCardWeekList"][j].hrWork &&
              this.daytimesheet[i]["timeCardWeekList"][j].isCycle == "true"
            ) {
              this.matrixlist[j].hours3 =
                this.daytimesheet[i]["timeCardWeekList"][j].hrWork;
            } else {
              this.matrixlist[j].hours3 = "0.00";
            }

            this.matrixlist[j].matrixtd3 = true;
          } else if (matrixvalue == "matrix4") {
            if (
              this.daytimesheet[i]["timeCardWeekList"][j].hrWork &&
              this.daytimesheet[i]["timeCardWeekList"][j].isCycle == "true"
            ) {
              this.matrixlist[j].hours4 =
                this.daytimesheet[i]["timeCardWeekList"][j].hrWork;
            } else {
              this.matrixlist[j].hours4 = "0.00";
            }
            this.matrixlist[j].matrixtd4 = true;
          } else if (matrixvalue == "matrix5") {
            if (
              this.daytimesheet[i]["timeCardWeekList"][j].hrWork &&
              this.daytimesheet[i]["timeCardWeekList"][j].isCycle == "true"
            ) {
              this.matrixlist[j].hours5 =
                this.daytimesheet[i]["timeCardWeekList"][j].hrWork;
            } else {
              this.matrixlist[j].hours5 = "0.00";
            }
            this.matrixlist[j].matrixtd5 = true;
          } else if (matrixvalue == "matrix6") {
            if (
              this.daytimesheet[i]["timeCardWeekList"][j].hrWork &&
              this.daytimesheet[i]["timeCardWeekList"][j].isCycle == "true"
            ) {
              this.matrixlist[j].hours6 =
                this.daytimesheet[i]["timeCardWeekList"][j].hrWork;
            } else {
              this.matrixlist[j].hours6 = "0.00";
            }
            this.matrixlist[j].matrixtd6 = true;
          }
        }
      }
      rowtoal = 0;
      rowstotal0 = 0;
      for (let k = 0; k < this.matrixlist.length; k++) {
        rowtoal =
          Number(this.matrixlist[k].hours0) +
          Number(this.matrixlist[k].hours1) +
          Number(this.matrixlist[k].hours2) +
          Number(this.matrixlist[k].hours3) +
          Number(this.matrixlist[k].hours4) +
          Number(this.matrixlist[k].hours5) +
          Number(this.matrixlist[k].hours6);

        rowstotal0 += parseFloat(this.matrixlist[k].hours0);
        rowstotal1 += parseFloat(this.matrixlist[k].hours1);
        rowstotal2 += parseFloat(this.matrixlist[k].hours2);
        rowstotal3 += parseFloat(this.matrixlist[k].hours3);
        rowstotal4 += parseFloat(this.matrixlist[k].hours4);
        rowstotal5 += parseFloat(this.matrixlist[k].hours5);
        rowstotal6 += parseFloat(this.matrixlist[k].hours6);

        this.matrixlist[k].datewisetotal = rowtoal.toFixed(2);

        grandTotal += parseFloat(this.matrixlist[k].datewisetotal);
      }
      if (!isNaN(rowstotal0)) {
        this.rowstotal0 = rowstotal0.toFixed(2);
      } else {
        this.rowstotal0 = 0;
      }
      if (!isNaN(rowstotal1)) {
        this.rowstotal1 = rowstotal1.toFixed(2);
      } else {
        this.rowstotal1 = 0;
      }
      if (!isNaN(rowstotal2)) {
        this.rowstotal2 = rowstotal2.toFixed(2);
      } else {
        this.rowstotal2 = 0;
      }
      if (!isNaN(rowstotal3)) {
        this.rowstotal3 = rowstotal3.toFixed(2);
      } else {
        this.rowstotal3 = 0;
      }
      if (!isNaN(rowstotal4)) {
        this.rowstotal4 = rowstotal4.toFixed(2);
      } else {
        this.rowstotal4 = 0;
      }
      if (!isNaN(rowstotal5)) {
        this.rowstotal5 = rowstotal5.toFixed(2);
      } else {
        this.rowstotal5 = 0;
      }
      if (!isNaN(rowstotal6)) {
        this.rowstotal6 = rowstotal6.toFixed(2);
      } else {
        this.rowstotal6 = 0;
      }
      this.grandrowtotals = grandTotal.toFixed(2);

      // this.rowstotal0=rowstotal0
      // this.rowstotal1=rowstotal1
      // this.rowstotal2=rowstotal2
      // this.rowstotal3=rowstotal3
      // this.rowstotal4=rowstotal4
      // this.rowstotal5=rowstotal5
      // this.rowstotal6=rowstotal6
      this.matrixlist0 = this.matrixlist.slice(0, 18);
      this.matrixlist1 = this.matrixlist.slice(18, 50);
    }
  }
  dayWiseTimesheet() {
    var datevalue;
    if (this.daytimesheet.length > 0) {
      for (let i = 0; i < this.daytimesheet.length; i++) {
        var workcontractDes = this.daytimesheet[i].workContractDesc;

        for (let j = 0; j < this.daytimesheet[i].timeCardWeekList.length; j++) {
          if (
            this.daytimesheet[i]["timeCardWeekList"][j].hrWork &&
            this.daytimesheet[i]["timeCardWeekList"][j].isCycle == "true"
          ) {
            var hrWork = this.daytimesheet[i]["timeCardWeekList"][j].hrWork;
          } else {
            hrWork = "0.00";
          }
          if (i == 0) {
            datevalue = "true";
          } else {
            datevalue = "false";
          }

          if (
            this.daytimesheet[i].timeCardWeekList[j].isCycle == "true" &&
            this.daytimesheet[i].timeCardWeekList[j].note != undefined
          ) {
            var daynotes = this.daytimesheet[i].timeCardWeekList[j].note;
          } else {
            daynotes = "";
          }

          this.daylist.push({
            calanderday: this.daytimesheet[i].timeCardWeekList[j].calendarDay,
            hrWork: hrWork,
            workcontractDes: workcontractDes,
            datevalue: datevalue,
            daynotes: daynotes,
            isCycle: this.daytimesheet[i].timeCardWeekList[j].isCycle,
          });
        }
      }

      var te1 = this.groupBy(this.daylist, function (item) {
        return [item.calanderday];
      });

      this.datewiselist0 = te1.slice(0, 7);
      this.datewiselist1 = te1.slice(7, 14);
      this.datewiselist2 = te1.slice(14, 21);
      this.datewiselist3 = te1.slice(21, 29);
      this.datewiselist4 = te1.slice(29, 36);
      this.datewiselist5 = te1.slice(36, 50);
    }
  }
  contractwisedownloadtimesheet() {
    debugger;
    var daytimesheetarray0 = [];
    var daytimesheetarray1 = [];
    var daytimesheetarray2 = [];
    var daytimesheetarray3 = [];
    var daytimesheetarray4 = [];
    var daytimesheetarray5 = [];
    var daytimesheetarray6 = [];
    var data = this.daytimesheet;
    var workhour0 = 0;
    var workhour1 = 0;
    var workhour2 = 0;
    var workhour3 = 0;
    var workhour4 = 0;
    var workhour5 = 0;
    var workhour6 = 0;
    if (data.length > 0) {
      const chunkSize = 1;
      for (let i = 0; i < data.length; i++) {
        if (i == 0) {
          this.contractdetails0.push({
            contractponumber: data[i].contractponumber,
            contractnumber: data[i].contractnumber,
            contractid: data[i].contractid,
            startdate: data[i].startdate,
            endate: data[i].endate,
            workContractDesc: data[i].workContractDesc,
            noteList: data[i].noteList,
          });
        } else if (i == 1) {
          this.contractdetails1.push({
            contractponumber: data[i].contractponumber,
            contractnumber: data[i].contractnumber,
            contractid: data[i].contractid,
            startdate: data[i].startdate,
            endate: data[i].endate,
            workContractDesc: data[i].workContractDesc,
            noteList: data[i].noteList,
          });
        } else if (i == 2) {
          this.contractdetails2.push({
            contractponumber: data[i].contractponumber,
            contractnumber: data[i].contractnumber,
            contractid: data[i].contractid,
            startdate: data[i].startdate,
            endate: data[i].endate,
            workContractDesc: data[i].workContractDesc,
            noteList: data[i].noteList,
          });
        } else if (i == 3) {
          this.contractdetails3.push({
            contractponumber: data[i].contractponumber,
            contractnumber: data[i].contractnumber,
            contractid: data[i].contractid,
            startdate: data[i].startdate,
            endate: data[i].endate,
            workContractDesc: data[i].workContractDesc,
            noteList: data[i].noteList,
          });
        } else if (i == 4) {
          this.contractdetails4.push({
            contractponumber: data[i].contractponumber,
            contractnumber: data[i].contractnumber,
            contractid: data[i].contractid,
            startdate: data[i].startdate,
            endate: data[i].endate,
            workContractDesc: data[i].workContractDesc,
            noteList: data[i].noteList,
          });
        } else if (i == 5) {
          this.contractdetails5.push({
            contractponumber: data[i].contractponumber,
            contractnumber: data[i].contractnumber,
            contractid: data[i].contractid,
            startdate: data[i].startdate,
            endate: data[i].endate,
            workContractDesc: data[i].workContractDesc,
            noteList: data[i].noteList,
          });
        } else if (i == 6) {
          this.contractdetails6.push({
            contractponumber: data[i].contractponumber,
            contractnumber: data[i].contractnumber,
            contractid: data[i].contractid,
            startdate: data[i].startdate,
            endate: data[i].endate,
            workContractDesc: data[i].workContractDesc,
            noteList: data[i].noteList,
          });
        }

        for (let j = 0; j < data[i].timeCardWeekList.length; j++) {
          if (i == 0) {
            if (data[i].timeCardWeekList[j].hrWork) {
              workhour0 += parseFloat(data[i].timeCardWeekList[j].hrWork);
              this.workhour0 = workhour0;
            }
            daytimesheetarray0.push({
              day: data[i].timeCardWeekList[j].day,
              hrReg: data[i].timeCardWeekList[j].hrReg,
              hrOvertime: data[i].timeCardWeekList[j].hrOvertime,
              hrWork: data[i].timeCardWeekList[j].hrWork,
              note: data[i].timeCardWeekList[j].note,
            });
          } else if (i == 1) {
            if (data[i].timeCardWeekList[j].hrWork) {
              workhour1 += parseFloat(data[i].timeCardWeekList[j].hrWork);
              this.workhour1 = workhour1;
            }
            daytimesheetarray1.push({
              day: data[i].timeCardWeekList[j].day,
              hrReg: data[i].timeCardWeekList[j].hrReg,
              hrOvertime: data[i].timeCardWeekList[j].hrOvertime,
              hrWork: data[i].timeCardWeekList[j].hrWork,
              note: data[i].timeCardWeekList[j].note,
            });
          } else if (i == 2) {
            if (data[i].timeCardWeekList[j].hrWork) {
              workhour2 += parseFloat(data[i].timeCardWeekList[j].hrWork);
              this.workhour2 = workhour2;
            }
            daytimesheetarray2.push({
              day: data[i].timeCardWeekList[j].day,
              hrReg: data[i].timeCardWeekList[j].hrReg,
              hrOvertime: data[i].timeCardWeekList[j].hrOvertime,
              hrWork: data[i].timeCardWeekList[j].hrWork,
              note: data[i].timeCardWeekList[j].note,
            });
          } else if (i == 3) {
            if (data[i].timeCardWeekList[j].hrWork) {
              workhour3 += parseFloat(data[i].timeCardWeekList[j].hrWork);
              this.workhour3 = workhour3;
            }
            daytimesheetarray3.push({
              day: data[i].timeCardWeekList[j].day,
              hrReg: data[i].timeCardWeekList[j].hrReg,
              hrOvertime: data[i].timeCardWeekList[j].hrOvertime,
              hrWork: data[i].timeCardWeekList[j].hrWork,
              note: data[i].timeCardWeekList[j].note,
            });
          } else if (i == 4) {
            if (data[i].timeCardWeekList[j].hrWork) {
              workhour4 += parseFloat(data[i].timeCardWeekList[j].hrWork);
              this.workhour4 = workhour4;
            }
            daytimesheetarray4.push({
              day: data[i].timeCardWeekList[j].day,
              hrReg: data[i].timeCardWeekList[j].hrReg,
              hrOvertime: data[i].timeCardWeekList[j].hrOvertime,
              hrWork: data[i].timeCardWeekList[j].hrWork,
              note: data[i].timeCardWeekList[j].note,
            });
          } else if (i == 5) {
            if (data[i].timeCardWeekList[j].hrWork) {
              workhour5 += parseFloat(data[i].timeCardWeekList[j].hrWork);
              this.workhour5 = workhour5;
            }
            daytimesheetarray5.push({
              day: data[i].timeCardWeekList[j].day,
              hrReg: data[i].timeCardWeekList[j].hrReg,
              hrOvertime: data[i].timeCardWeekList[j].hrOvertime,
              hrWork: data[i].timeCardWeekList[j].hrWork,
              note: data[i].timeCardWeekList[j].note,
            });
          } else if (i == 6) {
            if (data[i].timeCardWeekList[j].hrWork) {
              workhour6 += parseFloat(data[i].timeCardWeekList[j].hrWork);
              this.workhour6 = workhour6;
            }
            daytimesheetarray6.push({
              day: data[i].timeCardWeekList[j].day,
              hrReg: data[i].timeCardWeekList[j].hrReg,
              hrOvertime: data[i].timeCardWeekList[j].hrOvertime,
              hrWork: data[i].timeCardWeekList[j].hrWork,
              note: data[i].timeCardWeekList[j].note,
            });
          }
        }
      }

      const daytarray0 = daytimesheetarray0.slice(0, 20);
      const daytarray1 = daytimesheetarray0.slice(20, 50);
      this.daytimesheetarray0 = daytarray0;
      this.daytimesheetarray1 = daytarray1;

      const daytarray2 = daytimesheetarray1.slice(0, 20);
      const daytarray3 = daytimesheetarray1.slice(20, 50);
      this.daytimesheetarray2 = daytarray2;
      this.daytimesheetarray3 = daytarray3;

      const daytarray4 = daytimesheetarray2.slice(0, 20);
      const daytarray5 = daytimesheetarray2.slice(20, 50);
      this.daytimesheetarray4 = daytarray4;
      this.daytimesheetarray5 = daytarray5;

      const daytarray6 = daytimesheetarray3.slice(0, 20);
      const daytarray7 = daytimesheetarray3.slice(20, 50);
      this.daytimesheetarray6 = daytarray6;
      this.daytimesheetarray7 = daytarray7;

      const daytarray8 = daytimesheetarray4.slice(0, 20);
      const daytarray9 = daytimesheetarray4.slice(20, 50);
      this.daytimesheetarray8 = daytarray8;
      this.daytimesheetarray9 = daytarray9;

      const daytarray10 = daytimesheetarray5.slice(0, 20);
      const daytarray11 = daytimesheetarray5.slice(20, 50);
      this.daytimesheetarray10 = daytarray10;
      this.daytimesheetarray11 = daytarray11;

      const daytarray12 = daytimesheetarray6.slice(0, 20);
      const daytarray13 = daytimesheetarray6.slice(20, 50);
      this.daytimesheetarray12 = daytarray12;
      this.daytimesheetarray13 = daytarray13;
    }
  }
  daynotecharlength(daynote) {
    if (daynote != undefined) {
      if (daynote.length == 100) {
        this.charmessage = true;
      } else {
        this.charmessage = false;
      }
    }
  }
}
