//Angular decorators list
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//Custom decorators list
import { HttpService } from './http.service';
import { SessionService } from './sessiontoken.service';
import { CookieService } from 'ngx-cookie';
import * as CryptoJS from 'crypto-js';
var backOffice, portal;
@Injectable()
export class ResolveService implements Resolve<object> {
    configData: any;
	constructor(private httpclient:HttpClient,private http: HttpService, private session: SessionService, private cookie:CookieService, private router: Router) { const promise = this.httpclient.get('assets/config/config.json').toPromise();
       promise.then( (value) => {
       this.configData = value;
       ////console.error(err);.log(this.configData,this.configData[0].portal_API);
       portal = this.configData[0].portal_API;
       backOffice=this.configData[0].backOffice_API;
       //console.error(err);.log(this.configData,this.configData[0].portal_API);
    });}

    /**
     * [Call the API before page loads - common resolve]
     * @param { ActivatedRouteSnapshot } route [get a url for respective page and handle routing]
     */
	resolve(route: ActivatedRouteSnapshot) {
        
        this.session.getDetails();
      
        var url, isApical = true;
        var data;
        var isBackoffice = route.data['isBackoffice'];
        var isHeader = route.data['isHeader'];
        if(route.data['type']=='account'){
           var userroleId = this.cookie.get('_usrtyd');
           var portStatus = Number(this.cookie.get('_puserId'));
           // var deuserId  = CryptoJS.AES.decrypt(userroleId, 'e5(8`*DK8L`YW8T7');
           // var userType = deuserId.toString(CryptoJS.enc.Utf8); 
           var userType = Number(userroleId);
          
           if(userType==500 && portStatus!=0) {
               //console.error(err);.log("Portal");
               isBackoffice = false;
               isHeader = true;
             url = "portal/GetProfileDetails/" + this.session.userId;
             
           } else {
               //console.error(err);.log("BackPortal");
               isBackoffice = true;
               isHeader = true;
               url = "getProfile?userId="+this.session.backuserId+"&userType="+userType;
           }
           //////console.error(err);.log(url);
        } else if(route.data['role']=='client'){
            url = route.data['url']+this.session.userId+'&appCode=3';
        } else if(route.data['role']=='contractor'){
          
            if(this.session.backuserId) {
                url = route.data['url']+this.session.backuserId+'&appCode=3';
            } else {
                isApical = false;
                ////console.error(err);.log("dyne",isApical);
            }
            
        } else if(route.data['backUser']==true) {
            ////console.error(err);.log("dyne");
             if(this.session.backuserId) {
                url = route.data['url']+this.session.backuserId;
            } else {
                isApical = false;
                ////console.error(err);.log("dyne",isApical);
            }
            //url = route.data['url']+this.session.backuserId;
        } else if(route.data['compId']==true) {
            url = route.data['url']+"/"+this.session.compId;
                //data = "{"+this.session.compId+"}";
        }  else if(route.data['type']=='bankdetails'){
            url = route.data['url']+"?userId="+this.session.backuserId+'&countryCode=CA';
        } else if(route.data['type']=='compdetails'){
            url = route.data['url']+"?userId="+this.session.backuserId;
        } else if(route.data['type']=='reports'){
            url = route.data['url']+"?userId="+this.session.backuserId+'&jsonSearchTimesheetVOStr= { "typeExpense":"Y", "typeTimesheet":"Y" }';
        } else if(route.data['onbehalf']== true) {
            ////console.error(err);.log("onbehalf");
            let backuser =this.cookie.get('_onbehalf')
            url = route.data['url']+backuser;
        } else {
            url = route.data['url']+this.session.userId;
        } 
        if(route.data['editUser']==true) {
           var userId = this.session.getUser();
           url = route.data['url']+userId; 
        }
        if(route.data['class']=='timesheet_view'){

            data = this.session.getContracts();
            //console.error(err);.log(data);
            url = route.data['url'];
            // url = route.data['url']+"?userId="+this.session.backuserId+"&workContractId="+this.session.workContractId+"&targetDateStr="+this.session.startDate;
           // ////console.error(err);.log(data['params'],url);
            data= data['params'];
            var queryString = Object.keys(data).map(key => { 
           
            return key + '=' + data[key]}).join('&');
            //data = ''
            url = route.data['url']+"?"+queryString;
        }
        if(route.data['class']=='timesheet_multiview'){
            data = this.session.getContracts();
            //console.error(err);.log(data);
            url = route.data['url'];
            // url = route.data['url']+"?userId="+this.session.backuserId+"&workContractId="+this.session.workContractId+"&targetDateStr="+this.session.startDate;
           // ////console.error(err);.log(data['params'],url);
            data= data['params'];
            var queryString = Object.keys(data).map(key => { 
           
            return key + '=' + data[key]}).join('&');
            //data = ''
            url = route.data['url']+"?"+queryString;
        }
        if(route.data['class']=='expinvoice_view'){

            let calldetail:any = this.cookie.get('_expOpen');
            let vendor:any = this.cookie.get('status');
            ////console.error(err);.log(calldetail,vendor);
            data = this.session.getExpense();

            url = route.data['url'];let backuser;
            backuser = this.session.backuserId;
            if(calldetail=="open")
            {
                
                if(route.data['onbehalf']== true) {
                    ////console.error(err);.log("onbehalf");
                    backuser =this.cookie.get('_onbehalf')
                    } 
                //////console.error(err);.log("hhh")
                url = route.data['url']+"?userId="+backuser+"&expenseReportId="+data;
            } else {
                if(vendor="VENDOR_APPROVED") {
                    if(route.data['user']=='client') {
                    url = "getExpenseInvoiceContractor?userId="+backuser+"&expenseReportId="+data;      
                    } else {
                    url = "getExpenseReport?userId="+backuser+"&expenseReportId="+data;  
                    //
                    } 
                    } else {     
                    url = "getExpenseReport?userId="+backuser+"&expenseReportId="+data;  
                    }
            }
            //data = '' 
            
        }
        //console.error(err);.log(url);
		// url = route.data['url']+this.session.userId;
        
       
        //////console.error(err);.log(url,isHeader);
        if(isApical) {
            if(route.data && route.data.method =="get")
            {
               // ////console.error(err);.log(route.data,route.data.method,isBackoffice);
                let promise  = new Promise((resolve , reject) => {
                    this.http.getMethod(url,isBackoffice).subscribe(

                        data => {
                            resolve(data);
                      //      ////console.error(err);.log(data);
                        },
                        error => {
                             if(error.status = 401)
                             {
                                    this.cookie.remove('_acctok');
                                    this.cookie.remove('_usrId');
                                    this.cookie.remove('_usrtyd');
                                    this.cookie.remove('_exttime');
                                    this.cookie.remove('_compnyId');
                                    this.cookie.remove('_userName');
                                    this.cookie.remove('_busrId');
                                    this.cookie.remove('_empType');
                                    this.cookie.remove('contractDetails');
                                    this.cookie.remove('_PSId');
                                    this.cookie.remove('expenseReportId');
                                    this.cookie.remove('_isNew');
                                    this.cookie.remove('_onbehalf');
                                    this.cookie.remove('_isReport');
                                    this.cookie.remove('_onbehalfType');
                                    this.cookie.remove('_previousUrl');
                                    this.cookie.remove("_addUser");
                                    this.cookie.remove("editUser");
                                    this.router.navigate(['/']);
                             }
                             else {
                                     this.router.navigate(['/']);
                             }
                            resolve(error);
                    });
            });
            return promise;
            } else {
                //////console.error(err);.log(url);
                let promise  = new Promise((resolve , reject) => {
                        this.http.postMethod(url, data,isBackoffice,isHeader).subscribe(
                            data => {
                                resolve(data);
                               // ////console.error(err);.log(data);
                            },
                            error => {
                                if(error.status = 401)
                             {
                                ////console.error(err);.log(data);
                                this.cookie.remove('_acctok');
                                this.cookie.remove('_usrId');
                                this.cookie.remove('_usrtyd');
                                this.cookie.remove('_busrId');
                                this.cookie.remove('_exttime');
                                this.cookie.remove('_compnyId');
                                this.cookie.remove('_userName');
                                this.cookie.remove('_empType');
                                this.cookie.remove('contractDetails');
                                this.cookie.remove('_PSId');
                                this.cookie.remove('expenseReportId');
                                this.cookie.remove('_isNew');
                                this.cookie.remove('_onbehalf');
                                this.cookie.remove('_onbehalfType');
                                this.cookie.remove('_previousUrl');
                                this.cookie.remove("editUser");
                                this.cookie.remove('_isReport');
                                this.router.navigate(['/']);
                             }
                             else {
                                     this.router.navigate(['/']);
                             }
                                resolve(error);
                        });
                });
                return promise;
            }
    	}
    }
}
