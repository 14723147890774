//Angular modules list
import { BrowserModule, Title } from '@angular/platform-browser';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http'; 
import { FormsModule } from '@angular/forms';



//Third party modules list
import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { CookieModule } from 'ngx-cookie';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { NgHttpLoaderModule } from 'ng-http-loader';


//Components list
import { AppComponent } from './app.component';
import { HomeComponent } from './core/home/home.component';
import { ReportComponent } from './core/report/report.component';
import { SignComponent } from './sign/sign.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponentOld } from './home/home.component'
import { DatePipe } from '@angular/common';
  // Authentication guard list
import { ClientAuthGuard, ContractorAuthGuard, LoginAuthGuard, AccountEmpGuard } from './shared/services/auth-guard.service'; 

// Services List
import { HttpService } from './shared/services/http.service';
import { ResolveService } from './shared/services/resolve.service';
import { CookieService } from 'ngx-cookie';
import { SessionService } from './shared/services/sessiontoken.service';
import { InvokedataService } from './shared/services/invokedata.service';
import { VerifyComponent } from './verify/verify.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { AppLoadService } from './shared/services/appload.service';



// export function init_app(appLoadService: AppLoadService) {
//     return () => appLoadService.initializeApp();
// }
 
// export function get_settings(appLoadService: AppLoadService) {
//     return () => appLoadService.getSettings();
// }

@NgModule({
    declarations: [
        AppComponent,
        SignComponent,
        NotFoundComponent,
        HomeComponent,
        ReportComponent,
        VerifyComponent,
        NoAccessComponent,
       CounterComponent,
    FetchDataComponent,
    NavMenuComponent,
    HomeComponentOld,
    
    ],
    imports: [
        SharedModule,
        NgbModule.forRoot(),
        BrowserModule,        
        HttpClientModule,
        NoopAnimationsModule,
        AppRoutingModule,
        ModalModule.forRoot(),
        CookieModule.forRoot(),
        ShowHidePasswordModule.forRoot(),
        FormsModule,
        LottieAnimationViewModule,
      NgHttpLoaderModule,
      
    ],
    providers: [ 
        HttpService, 
        ResolveService, 
        ClientAuthGuard,
        SessionService, 
        ContractorAuthGuard, 
        LoginAuthGuard,
        AccountEmpGuard,
        InvokedataService,
        Title,
        AppLoadService,
        DatePipe
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
