//Angular decorators list
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Headers, RequestOptions } from '@angular/http';
import { CookieService } from 'ngx-cookie';
//Angular Servies list
import { SessionService } from './sessiontoken.service';
import { AppLoadService } from './appload.service';


/**
 * handle headers for request
 * @param {'application/x-www-form-urlencoded' } 'Content-Type'
 */
var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')

var portal = window.location.origin;
//////console.error(err);.log(window.location.protocol,"ddd",window.location.hostname,window.location.port);
if (!portal) {
  portal = window.location.protocol + "//" 
    + window.location.hostname 
    + (window.location.port ? ':' + window.location.port : '');
}
////console.error(err);.log(portal)
var backOffice;
var authentication;


// if(portal == "http://bhconnect.heptagon.tech") {
//     portal = "http://216.13.131.122:81/api/";
//     backOffice = "http://qa-bo.brainhunter.com/backoffice/BHBOContractorService/";
// } else if(portal == "http://bhconnect-uat.heptagon.tech"){
//     portal = "http://dev-mtc01.brainhunter.com/api/";
//     backOffice = "http://uat-bo.brainhunter.com/backoffice/BHBOContractorService/";
// } else if(portal == 'http://localhost:4200') {
//    portal = "http://dev-mtc01.brainhunter.com/api/";
//    backOffice = "http://uat-bo.brainhunter.com/backoffice/BHBOContractorService/";
// }
@Injectable()
export class HttpService  {
configData: any;
constructor(private http:HttpClient,private session: SessionService, private cookie:CookieService, private appload : AppLoadService) {
    this.session.getDetails();
  
       const promise = this.http.get('assets/config/config.json').toPromise();
       promise.then( (value) => {
       this.configData = value;
       ////console.error(err);.log(this.configData,this.configData[0].portal_API);
       portal = this.configData[0].portal_API;
       backOffice=this.configData[0].backOffice_API;
       authentication=this.configData[0].authentication_API
       window.localStorage['BOurl'] = JSON.stringify(backOffice);
       window.localStorage['POurl'] = JSON.stringify(portal);
       ////console.error(err);.log(this.configData,this.configData[0].portal_API);
      
    });
 }

 ngOnInit() {
      const promise = this.http.get('assets/config/config.json').toPromise();
       promise.then( (value) => {
       this.configData = value;
       ////console.error(err);.log(this.configData,this.configData[0].portal_API);
       portal = this.configData[0].portal_API;
       backOffice=this.configData[0].backOffice_API;
       authentication=this.configData[0].authentication_API
       //window.localStorage['url'] = JSON.stringify(backOffice);
       ////console.error(err);.log(this.configData,this.configData[0].portal_API);
    });
     ////console.error(err);.log(this.configData);
 }
    
    /**
     * [HTTP Get method] 
     * @param {String} url [used to get data] 
     * @param {Boolean} isBackoffice  
     */
    
    getMethod(url, isBackoffice) {
        //this.session.getDetails();
        //console.log("DDddsssdd",isBackoffice);
        var accessToken = this.cookie.get('_acctok');
        //////console.error(err);.log(accessToken);
        var baseUrl;
        var headers;
        if(isBackoffice) {
            baseUrl = backOffice;
            headers = new HttpHeaders().set('token', accessToken)
        }else{
            baseUrl = portal;
            //window.localStorage['url'] = JSON.stringify(portal);
            headers = new HttpHeaders().set('Authorization', 'bearer '+accessToken)
           // console.log("DDddsssdd",headers,portal);
        }
        
        //console.log(headers, baseUrl);
      return this.http.get(baseUrl + url, { headers: headers });
  }

   getMethod1(url) {
    //this.session.getDetails();
    //console.log("DDddsssdd",isBackoffice);
    var accessToken = this.cookie.get('_acctok');
    //////console.error(err);.log(accessToken);
    var baseUrl ="http://192.168.18.153:81/api/";
    var headers;
      headers = new HttpHeaders().set('token', accessToken)
   
    //console.log(headers, baseUrl);
    return this.http.get(baseUrl + url, { headers: headers });
  }

    /**
     * 
     * [HTTP Post Method]    
     * @param {String} url  [used to send a data]
     * @param {Object} data  
     * @param {Boolean} isBackoffice  
     * @param {Boolean} isHeader  
     */
     
    postMethod(url, data, isBackoffice, isHeader) {
      
        var accessToken = this.cookie.get('_acctok');
        //////console.error(err);.log(isHeader);
        
        //////console.error(err);.log(queryString);
        var baseUrl;
        var headers;
        //console.error(err);.log(isBackoffice,portal);
        if(isBackoffice) {
            ////console.error(err);.log(isBackoffice);
            baseUrl = backOffice;
        } else {
            baseUrl = portal;
        }
        if(isHeader)
        {
            
            if(isBackoffice) {
            headers = new HttpHeaders({'token': accessToken,'Content-Type' : 'application/json; charset=UTF-8'});
            }
            else {
            headers = new HttpHeaders({'Authorization': 'bearer '+accessToken,'Content-Type' : 'application/json; charset=UTF-8'});
            }
            ////console.error(err);.log("with header",accessToken,headers);
        } else {

             headers = new HttpHeaders({ 'token': accessToken,'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'});
        }
        ////console.error(err);.log(JSON.parse(window.localStorage['BOurl']),baseUrl)
        if(baseUrl === undefined && isBackoffice) {
            baseUrl = JSON.parse(window.localStorage['BOurl']);
        } else if(baseUrl === undefined && !isBackoffice) { 
            baseUrl = JSON.parse(window.localStorage['POurl']);
        }
        return this.http.post(baseUrl+url, data, {headers: headers});
            
        
    }
    postMethodauth(url, data) {
      var header
      var accessToken = this.cookie.get('_acctok');
      header =new HttpHeaders({'token': accessToken,'Content-Type' : 'application/json; charset=UTF-8'});
      return this.http.post(authentication+url, data, {headers: header});
          
      
  }

    /**
     * 
     * [HTTP back office Post Method]    
     * @param {String} url  [used to send a data]
     * @param {Object} [data]  
     * @param {Boolean} [isBackoffice]  
     */
    
    postBackMethod(url,data,isBackoffice,isHeader) {
        var accessToken = this.cookie.get('_acctok');
        // var queryString = Object.keys(data).map(key => { 
        //     ////console.error(err);.log(key);
        //     return key + '=' + data[key]}).join('&');
        //var headers = new HttpHeaders().set('Content-Type', 'application/json');
        var baseUrl;var headers;
        if(isBackoffice) {
            
            baseUrl = backOffice;
        } else{
           
            baseUrl = portal;
        }
        if(isHeader) {
          //  ////console.error(err);.log(isHeader);
            headers = new HttpHeaders({'token': accessToken,'Content-Type' : 'application/json; charset=UTF-8'});
            //headers = new HttpHeaders({'content-type':'application/x-www-form-urlencoded'});
        } else {
             
            headers = new HttpHeaders({ 'token': accessToken,'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'});
        }
        if(baseUrl === undefined && isBackoffice) {
            baseUrl = JSON.parse(window.localStorage['BOurl']);
        } else if(baseUrl === undefined && !isBackoffice) { 
            baseUrl = JSON.parse(window.localStorage['POurl']);
        }
       return this.http.post(baseUrl+url, data, {headers:headers});
     }


     /**
     * 
     * [HTTP back office Post Method]    
     * @param {String} url  [used to send a data]
     * @param {Object} [data]  
     * @param {Boolean} [isBackoffice]  
     */
    
    postLoginBackMethod(url,data,isBackoffice,isHeader) {
        var accessToken = this.cookie.get('_acctok');
        // var queryString = Object.keys(data).map(key => { 
        //     ////console.error(err);.log(key);
        //     return key + '=' + data[key]}).join('&');
        //var headers = new HttpHeaders().set('Content-Type', 'application/json');
        var baseUrl;var headers;
        if(isBackoffice) {
            
            baseUrl = backOffice;
        } else{
           
            baseUrl = portal;
        }
        if(isHeader) {
          //  ////console.error(err);.log(isHeader);
            //headers = new HttpHeaders({'token': accessToken,'Content-Type' : 'application/json; charset=UTF-8'});
            headers = new HttpHeaders({'content-type':'application/x-www-form-urlencoded'});
        } else {
             
            headers =new HttpHeaders({});
        }
       return this.http.post(baseUrl+url, data, {headers:headers});
     }


    /**
     * 
     * [HTTP File Post Method]    
     * @param {string} url  [used to send a data]
     * @param {object} data  
     */
    
    filePostMethod(url,data) {
        
        // return this.http.post(origin+'/'+ url, data).map(res=>{
        //     //////console.error(err);.log(res)
        //      return res.json()
        // })
    }

  getApiLinks() {
    const promise = this.http.get('assets/config/config.json').toPromise();
           promise.then( (value) => {
           this.configData = value;
           ////console.error(err);.log(this.configData,this.configData[0].portal_API);
           portal = this.configData[0].portal_API;
           backOffice=this.configData[0].backOffice_API;
          // //console.error(err);.log(this.configData,this.configData[0].portal_API);
        });
           return promise;
    }

  GetVacationConfig() {
    return this.http.get('assets/config/VacationConfig.json');
  }
  GetJsonData() {
    return this.http.get('assets/config/LandingContractDetails.json');
  }
  GetConfigData(){
    return this.http.get('assets/config/config.json');
  }
  GetClientJsonData() {
    return this.http.get('assets/config/LandingClientDetails.json');
  }
  GetVenderContractorJsonData() {
    return this.http.get('assets/config/LandingVenderContractorDetails.json');
  }
  GetVenderApproverJsonData() {
    return this.http.get('assets/config/LandingVenderApproverDetails.json');
  }
  GetVenderClientJsonData() {
    return this.http.get('assets/config/LandingVenderClientDetails.json');
  }
  getLogo(logoPath:string){
    return this.http.get(logoPath, { responseType: 'blob' });
  }
}
