import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Message} from 'primeng/api';
import { CookieService } from 'ngx-cookie';
import { HttpService } from '../shared/services/http.service';
var portal,backOffice;
@Component({
  selector: 'app-report',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})

export class VerifyComponent implements OnInit {
  configData: any;

  constructor(private httpclient: HttpClient,private route : ActivatedRoute,private http: HttpService,private router:Router,private cookie:CookieService) { 
     

  }
  actData;
  isLoading: boolean = false;
  public lottieConfig: Object;
  public lottieConfigFalse: Object;
  private anim: any;
  private animationSpeed: number = 1;
  isSuccess;
  isFail;
  isAlready;
  alreadyMsg;
  ngOnInit() {
    window.document.title= "Verifying Account  - Connect ";
    ////console.error(err);.log("Verify Component")
    this.cookie.remove('_acctok');
    this.cookie.remove('_usrId');
    this.cookie.remove('_usrtyd');
    this.cookie.remove('_exttime');
    this.cookie.remove('_compnyId');
    this.cookie.remove('_userName');
    this.cookie.remove('_busrId');
    this.cookie.remove('_empType');
    this.cookie.remove('contractDetails');
    this.cookie.remove('_PSId');
    this.cookie.remove('expenseReportId');
    this.cookie.remove('_isNew');
    this.cookie.remove('_empType');
    this.cookie.remove('_onbehalf');
    this.cookie.remove('_onbehalfType');
    this.cookie.remove('_previousUrl');
    this.cookie.remove('_roleTypename');
    this.cookie.remove('_roleType');
    this.cookie.remove("_addUser");
    this.cookie.remove('_isFirst');
    ////console.error(err);.log(this.isSuccess);
  	this.route.queryParams.forEach((params: Params) => {
  		////console.error(err);.log(params);
            this.actData = params['userid'];
            ////console.error(err);.log(this.actData);
        if(this.actData!=null && this.actData!= undefined) {
           const promise = this.httpclient.get('assets/config/config.json').toPromise();
           promise.then( (value) => {
           this.configData = value;
           ////console.error(err);.log(this.configData,this.configData[0].portal_API);
           portal = this.configData[0].portal_API;
           backOffice=this.configData[0].backOffice_API;
           this.activateUser(this.actData);
          });  
            
        }
        else {
        	this.router.navigate(['/']);
        }

    });
	this.lottieConfig = {
        path: 'assets/images/success.json',
        autoplay: true,
        loop: false
    };
    this.lottieConfigFalse = {
        path: 'assets/images/cross.json',
        autoplay: true,
        loop: false
    };
  }

  activateUser(data) {
    this.isLoading=true;
  	var url="portal/ActivateUser/"+this.actData;
  	var data1="";
  	this.http.postMethod(url,data1,false,true).subscribe(

                    data => {
                       //console.error(err);.log(url);
                    	////console.error(err);.log(data);
                        ////console.error(err);.log(data['SuccessFlag']==true);
                        if(data['SuccessFlag']==true) {
	                        this.isSuccess= true;
                          this.isFail = false;
	                        setTimeout(() => this.router.navigate(['/']), 5000);
                        } else if(data['SuccessFlag']==false) {
                        	this.isAlready = true;
                          this.isSuccess= false;
                          this.isFail = true;
                        	//////console.error(err);.log(data['Message'])
                        	this.alreadyMsg = data['Message'];
                          setTimeout(() => this.router.navigate(['/']), 5000);
                        	//setTimeout(() => this.router.navigate(['/']), 5000);
                        	
                        }
                        else{
                        this.isSuccess= false;
                        //this.router.navigate(['/']);
                        //this.msgs = [];
                        //this.msgs.push({severity:'error', detail:data['Message']});
                        }
                        
                    //this.isLoading = true;
                    },err =>{
                      //console.error(err);.error(err);
                      },
                    () => {this.isLoading = false;});
  }

  homeRedirect() {
  	this.router.navigate(['/']);
  }

}
