//Angular decorators list
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot  } from '@angular/router';

//Custom services list
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie';
import { HttpService } from '../services/http.service';

//Client Authguard
@Injectable()
export class ClientAuthGuard implements CanActivate  {

    constructor(private cookie:CookieService, private route:ActivatedRoute,  private http: HttpService, private router: Router) {
    }

    /**
     * [Decide the components are belongs to client otherwise navigate to signin page after removing cookies ]
     */
    canActivate() {
        var userTyId = this.cookie.get('_usrtyd');
        ////console.error(err);.log(userTyId,"authgaurd client");
        if(userTyId) {
            // var deuserId  = CryptoJS.AES.decrypt(userTyId, 'e5(8`*DK8L`YW8T7');
            // var checkUser = deuserId.toString(CryptoJS.enc.Utf8);
            var checkUser = Number(userTyId);
            ////console.error(err);.log(checkUser);
            //return true;
            if(checkUser == 300 || checkUser==450 || checkUser==400) {
               //this.router.navigate(['/client']);
               return true;
            } else {
                // this.cookie.remove('_acctok');
                // this.cookie.remove('_usrId');
                // this.cookie.remove('_usrtyd');
                // this.cookie.remove('_exttime');
                this.router.navigate(['/']);
                return false;
            } 
         } else {
            this.cookie.remove('_acctok');
            this.cookie.remove('_usrId');
            this.cookie.remove('_usrtyd');
            this.cookie.remove('_exttime');
            this.cookie.remove('_busrId');
            this.cookie.remove('_empType');
            this.cookie.remove('_PSId');
            this.cookie.remove('expenseReportId');
            this.cookie.remove('contractDetails');
            this.cookie.remove('_compnyId');
            this.cookie.remove('_userName');
            this.cookie.remove('_isNew');
            this.cookie.remove('_onbehalf');
            this.cookie.remove('_onbehalfType');
            this.cookie.remove('_previousUrl');
            this.cookie.remove('_roleTypename');
            this.cookie.remove('_roleType');
            this.cookie.remove("_addUser");
            this.cookie.remove('_isFirst');
            this.cookie.remove('_expOpen');
             this.cookie.remove("editUser");
            //this.router.navigate(['/']);
            setTimeout(() => {this.router.navigate(['/']);
                             window.location.reload();}, 600);
            return false;
        }
    }
}

//Contractor Authguard
@Injectable()
export class ContractorAuthGuard implements CanActivate  {

    constructor(private cookie:CookieService, private route:ActivatedRoute,  private http: HttpService, private router: Router) {
    }
     /**
     * [Decide the components are belongs to contract otherwise navigate to signin page after removing cookies ]
     */
    canActivate() {
        var userTyId = this.cookie.get('_usrtyd');
        ////console.error(err);.log(userTyId,"authgaurd Contractor");
        if(userTyId) {
            // var deuserId  = CryptoJS.AES.decrypt(userTyId, 'e5(8`*DK8L`YW8T7');
            // var checkUser = deuserId.toString(CryptoJS.enc.Utf8);
            var checkUser = Number(userTyId);
            ////console.error(err);.log(checkUser);
            if(checkUser == 500) {
               return true;
            } else {
                this.cookie.remove('_acctok');
                this.cookie.remove('_usrId');
                this.cookie.remove('_usrtyd');
                this.cookie.remove('_exttime');
                this.cookie.remove('_busrId');
                this.cookie.remove('_empType');
                this.cookie.remove('_PSId');
                this.cookie.remove('expenseReportId');
                this.cookie.remove('contractDetails');
                this.cookie.remove('_compnyId');
                this.cookie.remove('_userName');
                this.cookie.remove('_isNew');
                this.cookie.remove('_onbehalf');
                this.cookie.remove('_onbehalfType');
                this.cookie.remove('_previousUrl');
                this.cookie.remove('_roleTypename');
                this.cookie.remove('_roleType');
                this.cookie.remove('_isFirst');
                this.cookie.remove('_expOpen');
                this.cookie.remove("editUser");
                //this.router.navigate(['/']);
                setTimeout(() => {this.router.navigate(['/']);
                             window.location.reload();}, 0);
                return false;
            } 
        } else {
            this.cookie.remove('_acctok');
            this.cookie.remove('_usrId');
            this.cookie.remove('_usrtyd');
            this.cookie.remove('_exttime');
            this.cookie.remove('_busrId');
            this.cookie.remove('_empType');
            this.cookie.remove('_PSId');
            this.cookie.remove('expenseReportId');
            this.cookie.remove('contractDetails');
            this.cookie.remove('_compnyId');
            this.cookie.remove('_userName');
            this.cookie.remove('_isNew');
            this.cookie.remove('_onbehalf');
            this.cookie.remove('_onbehalfType');
            this.cookie.remove('_previousUrl');
            this.cookie.remove('_roleTypename');
            this.cookie.remove('_roleType');
            this.cookie.remove('_isFirst');
            this.cookie.remove('_expOpen');
            this.cookie.remove("editUser");
           // this.router.navigate(['/']);
            setTimeout(() => {this.router.navigate(['/']);
                             window.location.reload();}, 0);
            return false;
        }
    }
}

// Login Authguard
@Injectable()
export class LoginAuthGuard implements CanActivate  {

    constructor(private cookie:CookieService, private route:ActivatedRoute,  private http: HttpService, private router: Router) {
    }
       
     /**
     * [Navigate to client or contract respective to the user type]
     */
    canActivate() {
        
        var userTyId = this.cookie.get('_usrtyd');
        var verfied = this.cookie.get('isverified');
        if(userTyId &&verfied ) {
            // var deuserId  = CryptoJS.AES.decrypt(userTyId, 'e5(8`*DK8L`YW8T7');
            // var checkUser = deuserId.toString(CryptoJS.enc.Utf8);
            var checkUser = Number(userTyId);
            ////console.error(err);.log(checkUser);
            if(checkUser  == 500 && verfied=='true' ||  checkUser  == 500 && verfied=='false') {
               this.router.navigate(['/contractor']);
               return true;
            } else if(checkUser == 300 && verfied=='true'|| checkUser == 400 && verfied=='true'|| checkUser == 450 || checkUser == 300 && verfied=='false'|| checkUser == 400 && verfied=='false'|| checkUser == 450 || checkUser == 400 && verfied=='false' ) {
               this.router.navigate(['/client']);
               return false;
            } else {
                this.router.navigate(['/']);
                return true;
            } 
        } else {
            //this.router.navigate(['/']);
            return true;
        }
    }
}


// MyAccount Authguard
@Injectable()
export class MyAccountAuthguard implements CanActivate  {

    constructor(private cookie:CookieService, private route:ActivatedRoute,  private http: HttpService, private router: Router) {
    }
       
     /**
     * [Navigate to client or contract respective to the user type]
     */
    canActivate() {
        var userTyId = this.cookie.get('_usrtyd');
        if(userTyId) {
            // var deuserId  = CryptoJS.AES.decrypt(userTyId, 'e5(8`*DK8L`YW8T7');
            // var checkUser = deuserId.toString(CryptoJS.enc.Utf8);
            var checkUser = Number(userTyId);
            ////console.error(err);.log(checkUser);
            if(checkUser == 500) {
               //this.router.navigate(['/contractor']);
               return true;
            } else if(checkUser == 300 || checkUser == 400 || checkUser == 450) {
               //this.router.navigate(['/client']);
               return true;
            } else {
                this.router.navigate(['/']);
                return true;
            } 
        } else {
            this.cookie.remove('_acctok');
                this.cookie.remove('_usrId');
                this.cookie.remove('_usrtyd');
                this.cookie.remove('_exttime');
                this.cookie.remove('_busrId');
                this.cookie.remove('_empType');
                this.cookie.remove('_PSId');
                this.cookie.remove('expenseReportId');
                this.cookie.remove('contractDetails');
                this.cookie.remove('_compnyId');
                this.cookie.remove('_userName');
                this.cookie.remove('_isNew');
                this.cookie.remove('_onbehalf');
                this.cookie.remove('_onbehalfType');
                this.cookie.remove('_previousUrl');
                this.cookie.remove('_roleTypename');
                this.cookie.remove('_roleType');
                this.cookie.remove('_isFirst');
                this.cookie.remove('_expOpen');
                this.cookie.remove("editUser");
                //this.router.navigate(['/']);
                setTimeout(() => {this.router.navigate(['/']);
                             window.location.reload();}, 0);
            return false;
        }
    }
}
// Account Employment page restrict authgaurd for vendor users
@Injectable()
export class AccountEmpGuard implements CanActivate {

  constructor(private cookie:CookieService, private router: Router,) {}

  canActivate() {
      var userTyId = this.cookie.get('_usrtyd');
        if(userTyId) {
            // var deuserId  = CryptoJS.AES.decrypt(userTyId, 'e5(8`*DK8L`YW8T7');
            // var checkUser = deuserId.toString(CryptoJS.enc.Utf8);
            var checkUser = Number(userTyId);
            ////console.error(err);.log(checkUser);
            if(checkUser == 500) {
               return true;
            } else 
            {
               this.router.navigate(['/restricted']); 
            }

          }
       //return true;
        }
}


