import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { HttpService } from './../../shared/services/http.service';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class AppLoadService {
 
  constructor(private httpClient: HttpClient) { 

  }
 
  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
          ////console.error(err);.log(`initializeApp:: inside promise`);
 
          setTimeout(() => {
            ////console.error(err);.log('initializeApp:: inside setTimeout');
            // doing something
 
            resolve();
          }, 3000);
        });
  }
 
  // getSettings(): Promise<any> {
  //   ////console.error(err);.log('getSettings:: before http.get call');
  //   let APP_SETTINGS;
  //   // const promise = this.httpClient.get('assets/config/config.json')
  //   //   .toPromise()
  //   //   .then(settings => {
  //   //    // ////console.error(err);.log('Settings from API:', settings);
 
  //   //     //APP_SETTINGS.connectionString = settings[0].value;
  //   //    // APP_SETTINGS.defaultImageUrl = settings[1].value;
        
  //   //     //////console.error(err);.log(`APP_SETTINGS: `, APP_SETTINGS);
 
  //   //     return settings;
  //   //   });
  //   let configUrls = {

  //   };
  //   const promise = this.httpClient.get('assets/config/config.json').toPromise();
  //   promise.then(function(value) {
  //     //console.error(err);.log(value);
  //   });
  //   //console.error(err);.log(promise);
  //   return promise;
  // }
}