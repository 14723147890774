
import { Component, Renderer2 ,ElementRef  } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router , NavigationStart } from '@angular/router';

import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie';
import { Spinkit } from 'ng-http-loader';
import { NotificationComponent } from './shared/notifications/notifications.component';
import { HttpService } from './shared/services/http.service';
import { SessionService } from './shared/services/sessiontoken.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent{

  
  title = 'app';
  data;
  accessToken; rtnTime;
  public curtime: Date = new Date();
  expiry_time = 0;
  isLoading2 = false;
  previousUrl;
  public spinkit = Spinkit;
  
  constructor(private elRef:ElementRef,private renderer: Renderer2, private cookie:CookieService,private http:HttpService,private router: Router,private session:SessionService) {
    window.addEventListener('offline', () => {
     
        console.log(this.cookie);
        this.cookie.remove('_acctok');
          this.cookie.remove('_usrId');
          this.cookie.remove('_usrtyd');
          this.cookie.remove('_exttime');
          this.cookie.remove('_compnyId');
          this.cookie.remove('_userName');
          this.cookie.remove('_busrId');
          this.cookie.remove('_empType');
          this.cookie.remove('_isNew');
          this.cookie.remove('_onbehalf');
          this.cookie.remove('_onbehalfType');
          this.cookie.remove('_previousUrl');
          this.cookie.remove('_roleTypename');
          this.cookie.remove('_roleType');
          this.cookie.remove('_isFirst');
          this.cookie.remove('_empType');
          this.cookie.remove('contractDetails');
          this.cookie.remove('_PSId');
          this.cookie.remove('expenseReportId');
          this.cookie.remove("_addUser");
          this.cookie.remove('_expOpen');
          this.cookie.remove('status');
          this.cookie.remove('_index');
          this.cookie.remove('_puserId');
          setTimeout(() => {this.router.navigate(['/']);
                   window.location.reload();}, 0);
      });
      // router.events.subscribe((val) => {
      //     this.isLoading2=true;
      //     if(val && val['urlAfterRedirects']) {
      //       setTimeout(()=>{this.isLoading2=false},300);
      //     }

      // });
       // setInterval(() => { this.refreshToken();
          
       //  }, 100000);
      setInterval(() => {
      router.events.subscribe((event) => {
        ////////console.error(err);.log(event);
        if (event instanceof NavigationStart) {
          if (this.previousUrl) {
            this.renderer.removeClass(document.body, this.previousUrl);
          }
          let currentUrlSlug = event.url.slice(1)
          currentUrlSlug=currentUrlSlug.split('/')[1];
          if (currentUrlSlug) {
            this.renderer.addClass(document.body, currentUrlSlug);
            // let val_link = this.elRef.nativeElement.querySelector('.nav-link');
            // //////console.error(err);.log(val_link);
            // //////console.error(err);.log(this.renderer.addClass(val_link,'menu_Active'));
          }
          this.previousUrl = currentUrlSlug;
        }
      });
    
          
      this.accessToken = this.cookie.get('_acctok');
      this.rtnTime = this.cookie.get('_exttime');
      ////////console.error(err);.log(this.rtnTime);
      this.expiry_time=this.expiry_time+1000;
      if(this.expiry_time>=1800000 && this.accessToken)
        {
          this.session.emptyDetails();
          this.cookie.remove('_acctok');
          this.cookie.remove('_usrId');
          this.cookie.remove('_usrtyd');
          this.cookie.remove('_exttime');
          this.cookie.remove('_compnyId');
          this.cookie.remove('_userName');
          this.cookie.remove('_busrId');
          this.cookie.remove('_empType');
          this.cookie.remove('_isNew');
          this.cookie.remove('_onbehalf');
          this.cookie.remove('_onbehalfType');
          this.cookie.remove('_previousUrl');
          this.cookie.remove('_roleTypename');
          this.cookie.remove('_roleType');
          this.cookie.remove('_isFirst');
          this.cookie.remove('_empType');
          this.cookie.remove('contractDetails');
          this.cookie.remove('_PSId');
          this.cookie.remove('expenseReportId');
          this.cookie.remove("_addUser");
          this.cookie.remove('_expOpen');
          this.cookie.remove('status');
          this.cookie.remove('_index');
          this.cookie.remove('_puserId');
          setTimeout(() => {this.router.navigate(['/']);
                   window.location.reload();}, 0);

          //this.router.navigate(['/']);
        }
          if(this.accessToken!="" && this.accessToken!=null)
        {
          var curtime =  new Date().getTime();

          if(this.rtnTime<=curtime && this.rtnTime!='') {

            this.refreshToken();
          }
              //this.data = [(Math.floor(Math.random() * (1000000 - 9999999)) + 9999999).toString(),...this.data];
        }
      }, 60000);
      //this.refreshToken();
   }

    offlineRemove(){
          console.log("Offline",this.cookie);
          //this.session.emptyDetails();
          
      }
  
   /**
    * [fetch the access token ever defined time when user interaction]
    */
     refreshToken() {
           //his.cookie.remove('_acctok');
           var url = "authentication/ReGenerateToken";
           this.http.getMethod(url,false).subscribe(
           data => {
                this.cookie.put('_acctok', data["access_token"]);
               // //////console.error(err);.log(data["access_token"]);
                var currentTime = new Date();
               // //////console.error(err);.log(currentTime.setTime(currentTime.getTime()));
                var exTime = data["expires_in"]-120;
                var newTime = new Date();
                var Time:any = newTime.setTime(newTime.getTime() + exTime *1000);
                this.cookie.put('_exttime', Time);
                
             },
            err => {
                ////console.error(err);.error(err,"refreshToken");
                this.session.emptyDetails();
                this.cookie.remove('_acctok');
                this.cookie.remove('_usrId');
                this.cookie.remove('_usrtyd');
                this.cookie.remove('_exttime');
                this.cookie.remove('_compnyId');
                this.cookie.remove('_userName');
                this.cookie.remove('_busrId');
                this.cookie.remove('_empType');
                this.cookie.remove('_isNew');
                this.cookie.remove('_onbehalf');
                this.cookie.remove('_onbehalfType');
                this.cookie.remove('_previousUrl');
                this.cookie.remove('_roleTypename');
                this.cookie.remove('_roleType');
                this.cookie.remove('_isFirst');
                this.cookie.remove('_empType');
                this.cookie.remove('contractDetails');
                this.cookie.remove('_PSId');
                this.cookie.remove('expenseReportId');
                this.cookie.remove("_addUser");
                this.cookie.remove('_expOpen');
                this.cookie.remove('status');
                this.cookie.remove('_index');
                this.cookie.remove('_puserId');
                setTimeout(() => {this.router.navigate(['/']);
                   window.location.reload();}, 0);
            },
            () => {});
     }

     /**
      * [refresh the counter when user interactions ]
      * @param {[event]} e [keyboard or mouse event]
      */
     refreshActivity(e) {
    this.expiry_time = 0;
  }

}
