import { Component, OnInit} from '@angular/core';
import { HttpService } from './../../shared/services/http.service';
import { InvokedataService } from './../../shared/services/invokedata.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import * as moment from 'moment';
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})

export class NotificationComponent implements OnInit {
    
    
	constructor(private cookie:CookieService,private http: HttpService,private router: Router,private invoke:InvokedataService) { }

	
	compId;
	notificationList;
	notificationLength;
	showRead;
	nowDate;
	isLoading: boolean = false;
	isEmpty = true;
  portalId;
  Counts: number;
	count;
	ncount;
	viewed = true;
	result;
	ngOnInit() {
		this.compId = this.cookie.get('_compnyId');
      this.portalId = this.cookie.get('_usrId');
     
		//console.log(this.portalId);
		if(this.compId != null || this.compId!=undefined) {
		this.isEmpty=false;
		this.invoke.notificationCount.subscribe( (value) => {
								 this.ncount = value;
								 if(this.ncount >= 0){
									//console.log("ncount",this.ncount);
									//console.log("count",this.count);
									setTimeout(() => {
									if(this.count >= this.ncount)
									{   
									this.count = this.ncount;
									//console.log(this.count);
									this.getNotifications();
									}
									}, 1000);
								}
								
								//console.log("notiget",this.count);
								});
									
		this.getNotifications();

		}
		else{
			this.isEmpty=true;
		}
	}

	getNotifications(){
	this.count=0;
	//console.log(this.ncount,this.count);
	//var url="notification/GetUserNotifications/"+this.portalId+"/portal/0/0";
      var url = "notification/GetUserNotifications/" + this.portalId + "/BH/0/0";
	//var url="portal/GetAnnouncements/"+this.compId;
	    this.http.getMethod(url,false).subscribe(
	                data => {
		                	var keys = Object.keys(data);
							this.notificationLength = keys.length;
							let now = moment(); // add this 2 of 4
							this.nowDate = now.format('YYYY-MM-DD');
	    					//this.invoiceObj[list.date] = now.format('DD-MMM-YYYY');
	                	if(this.notificationLength>0) {
	                		this.notificationList=data;
	                		//console.error(err);.log(this.announceList);
	                		for(var notification of this.notificationList) {
	                			let from = moment(notification.NotifcationDate);
	                			notification.NotifcationDate= from.format('YYYY-MM-DD');
	                			//console.log(notification.IsViewed);
                              if (notification.IsViewed == false) {
                                this.count = this.count + 1;
                                //console.log("count",this.count);
                                this.viewed = false;

                              }
                             
	                			
                          }
                          
                          
                          if (this.count == 0)
                          {
                            this.viewed = true;
                          }
                          
	                		//console.log(this.viewed);
	                		
	                		//console.log("getcount",this.count);

	                	}
	                	else{
	                    	this.isEmpty=false;
	                    }
          }
          , err => {
            console.error(err);
							},
	                () => {});
	   
	    
		
	}

	gotoPage(title,id){
			var url = "/notification/UpdateNotification";
			var data={
				'NotificationId':id,
				'IsViewed':true
			};
			this.isLoading = true;
			//console.log(data);
			this.http.postMethod(url, data, false, true).subscribe(
			    data => {
			    	this.result=data;
			    	//console.log(this.result['SuccessFlag']);
			    	if(this.result['SuccessFlag'] == true){
			    		this.getNotifications();
                      if (title == "reports") {
                        this.router.navigate(['/contractor/reports']);
                      } else if (title == "timesheet") {
                        this.router.navigate(['/contractor/timesheet']);
                      } else if (title == "expense") {
                        this.router.navigate(['/contractor/exp_list']);
                      } else if (title == "contracts") {
                        this.router.navigate(['/contractor/contracts']);
                      }
                      else if (title == "pending") {
                        this.router.navigate(['/client/pending-approvals']);
                      }
						if(this.count!=0){
						this.count = this.count - 1;
						}
						//console.log("count",this.count);
						

			    	}

			    	
			    },err => {
							////console.error(err);.error(err);

							
						},
			    () => {this.isLoading = false;});
			
	}

}
