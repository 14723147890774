//Angular decorators list
import { Component, OnInit, Input, Output } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SessionService } from './../../shared/services/sessiontoken.service';
import { CookieService } from 'ngx-cookie';
import { Message } from 'primeng/api';
import { HttpService } from './../../shared/services/http.service';
@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

    // Initial setup
	@Input('menu') menu:any;
    hightlightMenu;
    bottomBorder;
    isCollapsed = true;
    backId;
    isOld;
    activeLink;
  currentUrl;
  divContactdisplay: boolean = false;
  ticketData = {
    FirstName: '',
    LastName: '',
    Email: '',
    DistributionList: '',
    Subject: null,
    Description: '',

  }
  modList;
  ConnectRecruiterId;
  ConnectRecruiter;
  compId;
  isLoading = false;
  msgs: Message[] = [];
  userRole;
  constructor(private http: HttpService,location: Location,private router: Router, private cookie:CookieService,private session:SessionService) {
        // Change menu background respect to active menu 
      
    router.events.subscribe((val) => {
     
  			this.hightlightMenu = '';
        this.bottomBorder = '';
       // ////console.error(err);.log(val);
       this.currentUrl = val['urlAfterRedirects'];
       if(val['urlAfterRedirects']=="/client/users/adduser"){
         
       }
      if (val && val['urlAfterRedirects']) {
                  switch (val['urlAfterRedirects']) {
                      
      				case "/contractor/timesheet": case "/contractor/timesheet/timesheet_view" : case "/client/timesheet_list" : case "/client/emplist": case "contractor/invoice_view" :
      				this.hightlightMenu = 'menu_blu';

      				break;
              case "/contractor/expense": case "/client/expense": case "/client/expemp" : case "/contractor/exp_list" : case "/client/exp_list": case "contractor/exp_list/expinvoice_view" : case "/contractor/exp_list/expense_list": case "/client/expemp/exp_list": case "/client/exp_list/expemp/expense_list" : case "/contractor/exp_list/view_invoice" :
              this.hightlightMenu = 'menu_red';
              break;
              case "/contractor/reports":  case "/client/expense_list": case "/client/reports": case "/client/reports/invoice_view" : case "/client/reports/timesheet" :
              this.hightlightMenu = 'menu_purple';
              break;
              case "/contractor/contracts": case "/contractor/upload" : 
              this.hightlightMenu = 'menu_org';
              break;
              case "/client/mypending-approvals": case "/client/timesheet_list/timesheet" : case "/contractor/invoice_view" :
              this.hightlightMenu = 'menu_blu';
              break;
              case "/client/pending-approvals":  case "/client/invoices":
              this.hightlightMenu = 'menu_red';
              break;
              case "/client/users": case "/client/adduser": case "/client/users/adduser" : 
              this.hightlightMenu = 'menu_blu';
              break;
              case "/client/reports": 
              this.hightlightMenu = 'menu_purple';
              break;
              case "/client/contracts": case "/contractor/home":
              this.hightlightMenu = 'menu_org';
              break;
                    case "/contractor/vacation-view": case "/contractor/vacation-view/vacation-type": case "/contractor/vacation-view/apply-vacation": case "/contractor/vacation-view/vacation-history": case "/contractor/vacation-view/vacation-list": case "/client/pending-approval-vaction": case "/client/Vacation-report": case"/client/pending-approval-vaction/vacation-details" :
              //this.hightlightMenu = 'menublue';
                      this.hightlightMenu = 'menu_blu';

              break;

      				default:
      					this.hightlightMenu = 'default';
      					break;
      			}
            
      		}
            
          
    	});
  	}

  ngOnInit() {
    //////console.error(err);
    
    this.backId = this.cookie.get('_busrId');
    if(this.backId) {
      this.isOld = true;
    } else {
      this.isOld = false;
    }

    this.getBackProfileinfo();
    this.getContactOptions();
    
    // for(var menuitem of this.menu) {
    //   ////console.error(err);.log(this.currentUrl,menuitem.routeUrl);

    //        switch (menuitem.routeUrl) {
    //           case "/contractor/timesheet": case "/contractor/timesheet_view" : case "/client/timesheet_list" :
    //                 this.activeLink = "active";
    //                 break;
    //               }
    //       }

  }

  logOut() {
    //////console.error(err);.log("hh");
    this.session.emptyDetails();
    this.cookie.remove('_acctok');
    this.cookie.remove('_usrId');
    this.cookie.remove('_usrtyd');
    this.cookie.remove('_exttime');
    this.cookie.remove('_compnyId');
    this.cookie.remove('_userName');
    this.cookie.remove('_busrId');
    this.cookie.remove('_empType');
    this.cookie.remove('contractDetails');
    this.cookie.remove('_PSId');
    this.cookie.remove('expenseReportId');
    this.cookie.remove('_isFirst');
    this.router.navigate(['/']);

  }

  MenuClick(lst) {
    //if (lst.menuId==18) {
    //  this.divContactdisplay = true;
    //}
  }

  getContactOptions() {
    this.compId = this.cookie.get('_compnyId');
    this.isLoading = true;
    var url = "portal/GetContactUsOptions/" + this.compId;
    this.http.getMethod(url, false).subscribe(
      data => {
        //////console.error(err);.log(data);
        if (data) {
          this.modList = data;

          for (var i = 0; i < this.modList.length; i++) {
            //if (this.modList[i]['OptionId'] == 12) {
            //  this.ConnectRecruiterId = this.modList[i]['OptionId'];

            //}
            this.modList[i]['Distribution_List'] = this.modList[i]['Distribution_List'].toString();

            ////////console.error(err);.log(setTimeout(() =>this.ticketData['DistributionList']="ashok.lakshmanan@brainhunter.com,anand.babu@brainhunter.com", 500));
          }
        } else {
        }
      }, err => {
        ////console.error(err);.error(err);
      },
      () => { this.isLoading = false; });
  }

  //contactUs
  getBackProfileinfo() {
    var uerType = this.cookie.get('_usrtyd');
    this.backId = this.cookie.get('_busrId');
    if (this.backId != undefined) {
      this.userRole = uerType;
      //var userroleId = this.cookie.get('_usrtyd');
      var url;
      url = "getProfile?userId=" + this.backId + "&userType=" + this.userRole;;
      this.isLoading = true;
      var mainData = "";
      this.http.postMethod(url, mainData, true, true).subscribe(
        data => {
          //if(data['status']=='Y') {
          //////console.error(err);.log(data);

          let result: any = data;
          this.ticketData['ConsultantId'] = Number(this.backId);
          this.ticketData['FirstName'] = result['response']['user']['firstName'];
          this.ticketData['LastName'] = result['response']['user']['lastName'];
          this.ticketData['Email'] = result['response']['user']['emailAddr'];

        }, err => {
          //console.error(err);.error(err);

        },
        () => { this.isLoading = false; });
    }
  }

  sendQuery(form) {
    for (var i = 0; i < form._directives.length; i++) {
      ////////console.error(err);.log(form._directives[i].valid,form._directives[i]);
      //this.error_status = true;
    }
    ////////console.error(err);.log(form.valid);
    if (form.valid) {
      var url = "portal/SubmitContactUs";
      
      var data = this.ticketData;
      this.isLoading = true;
      //////console.error(err);.log(data);
      this.http.postMethod(url, data, false, true).subscribe(
        data => {
          //////console.error(err);.log(data);
          if (data['SuccessFlag'] = true) {
            this.msgs = [];
            this.msgs.push({ severity: 'success', detail: data['Message'] });

          } else {
            this.msgs = [];
            this.msgs.push({ severity: 'error', detail: data['Message'] });

          }
          this.divContactdisplay = false;

        }, err => {
          //console.error(err);.error(err);

        },
        () => {
          this.isLoading = false;
        });
    }

  }
}
