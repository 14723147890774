//Angular modules list
import { transition, trigger, useAnimation } from "@angular/animations";
import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";

// Services List
import { bounce } from "ng-animate";
import { ModalDirective } from "ngx-bootstrap/modal";
import { CookieService } from "ngx-cookie";
import { Message } from "primeng/api";
import { HttpService } from "./../shared/services/http.service";
import { SessionService } from "./../shared/services/sessiontoken.service";

@Component({
  selector: "app-sign",
  templateUrl: "./sign.component.html",
  styleUrls: ["./sign.component.css"],
  animations: [
    trigger("bounce", [
      transition(
        "* => *",
        useAnimation(bounce, {
          // Set the duration to 5seconds and delay to 2seconds
          params: { timing: 5, delay: 2 },
        })
      ),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SignComponent implements OnInit {
  // Initial setup
  //@ViewChild(ErrorMessageComponent) public errorMessage: ErrorMessageComponent;
  @ViewChild("staticModal") public staticModal: ModalDirective;

  errMsg = {
    emailError: "",
    userError: "",
    passError: "",
    passConError: "",
    userFirstError: "",
    userLastError: "",
    commonError: "",
  };
  commonError = null;
  msgs: Message[] = [];
  popupmsgs: Message[] = [];
  succmsgs: Message[] = [];
  user = {
    UserName: "",
    FirstName: "",
    LastName: "",
    Password: "",
    confirmpassword: "",
    EmailId: "",
  };
  User = {
    FullName: "",
    Email: "",
    RequestId: "",
    ExpireDate: "",
  };
  isLogin = true;
  isForget = false;
  verifytext;
  error_status = false;
  bounce: any;
  isLoading = false;
  authpopup = false;
  regeratebutton = false;
  otptxtvalidate = false;
  verifyotps: boolean;
  confoError;
  isNew = false;
  isBackoffice;
  isHeader;
  userType;
  usertEmpdetails;
  configDetails;
  BtnSignUp;
  emailaddress: string;
  timerInterval;
  timerInterval1;
  display: string;
  emailAddress: any;
  validateText: string = "";
  isverified: boolean = false;
  regenarateverifycount: number = 0;
  otpverifycount: number = 0;
  backOfficemenu: Object;
  consultaddId: any;
  firstname: string;
  constructor(
    private http: HttpService,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    private titleService: Title
  ) {}

  /* 
    Redirect to repective page when user type present in cookie
     */
  ngOnInit() {
    this.GetConfigJsonData();
    ////console.error(err);.log(window.location.origin);
    window.document.title = "Sign In - Connect ";
    //this.titleService.setTitle( "Connect sign In" );
    var userTyId = this.cookie.get("_usrtyd");
    if (userTyId) {
      //var deuserId  = CryptoJS.AES.decrypt(userTyId, 'e5(8`*DK8L`YW8T7');
      var checkUser = Number(userTyId);
      ////console.error(err);.log(checkUser);
      if (checkUser == 500) {
        this.router.navigate(["/client/home"]);
      } else {
        this.router.navigate(["/"]);
        return false;
      }
    } else {
      this.router.navigate(["/"]);
      return true;
    }
    this.isLoading = false;
  }

  /**
   * [login, signup and forgot password has handled]
   * @param {[formData]} form [connection of form data]
   */

  onLogin(form) {    
    var isBackoffice;
    var isheader;
    this.verifyotps = true;

    ////console.error(err);.log(form.valid);
    ////console.error(err);.log(form._directives);
    for (var i = 0; i < form._directives.length; i++) {
      ////console.error(err);.log(form._directives[i].valid);
      this.error_status = true;
    }
    if (this.user.Password != this.user.confirmpassword && !this.isLogin) {
      this.confoError = true;
      ////console.error(err);.log(this.confoError);
      return false;
    } else {
      this.confoError = false;
    }

    if (form.valid) {
      if (this.isLogin && !this.isForget) {
        isBackoffice = true;
        isheader = true;
        var password = encodeURIComponent(this.user.Password);
        //var url = 'userLogon?userName='+this.user.UserName+'&password='+this.user.Password;
        var url =
          "userLogon?userName=" + this.user.UserName + "&password=" + password;
      } else if (!this.isLogin) {
        isBackoffice = false;
        isheader = false;
        ////console.error(err);.log("signup");
        var url = "portal/Registration";
      } else {
        isheader = true;
        isBackoffice = true;
        var url =
          "forgotPassword?userName=" +
          this.user.UserName +
          "&email=" +
          this.user.EmailId +
          "&appCode=3";
      }
      //
      //
      var login = {};
      // var fd = new FormData();
      //     fd.append( 'pd_input', input );
      var userTypeId;
      var companyId;
      var roleType;
      this.isLoading = true;

      this.http
        .postLoginBackMethod(url, this.user, isBackoffice, isheader)
        .subscribe(
          (data) => {
            ////console.error(err);.log(data);
            if (isBackoffice == true) {
              if (this.isForget) {
                if (data["status"] == "Y") {
                  this.msgs = [];
                  this.msgs.push({
                    severity: "success",
                    detail: "Check your mail to reset password",
                  });
                  form.resetForm();
                  this.error_status = false;
                  this.isForget = false;
                } else {
                  this.msgs = [];
                  this.msgs.push({
                    severity: "error",
                    detail: data["message"],
                  });
                }
              } else {
                if (data["status"] == "Y") {
                  this.isLoading = false;
                  let BackOfficeMenu = this.session.GetBackOfficeMenulst(data);
                  var backOffice = data["response"].backofficeLogin;

                  ////console.error(err);.log(backOffice);
                  var userFlag = data["response"].authenticateUser.successFlag;
                  if (backOffice == "Y" || userFlag == true) {
                    //this.msgs = [];

                    ////console.error(err);.log(userFlag);
                    var userId;
                    var backuserId;
                    var userName;
                    var empType;
                    var puserId;
                    var projectModel;
                    if (
                      userFlag == true &&
                      data["response"].authenticateUser.userId != 0
                    ) {
                      if (backOffice == "N") {
                        this.isNew = true;
                        this.cookie.put("_isNew", "1");
                        this.cookie.put("_isFirst", "1");
                        userName = this.user.UserName;
                        userId = data["response"].authenticateUser.userId;
                        this.cookie.put("_usrId", userId);
                        // backuserId = data["response"].authenticateUser.userId;
                        // this.cookie.put('_busrId', userId);
                        // console.log(this.cookie.get('_busrId'));
                      } else {
                        this.isNew = false;
                        this.cookie.put("_isNew", "0");
                        this.cookie.put("_isFirst", "0");
                        projectModel =
                          data["response"].identityBean.projectModel;
                        backuserId = data["response"].identityBean.userId;
                        ////console.error(err);.log(userId);
                        empType = data["response"].identityBean.contractorType;
                        userName =
                          data["response"].authenticateUser.firstName +
                          " " +
                          data["response"].authenticateUser.lastName;
                      }
                      ////console.error(err);.log("ggg");
                      userId = data["response"].authenticateUser.userId;
                      puserId = data["response"].authenticateUser.userId;
                      //backuserId = data["response"].identityBean.userId;
                      userTypeId = 500;
                      //userTypeId = data["response"].identityBean.userTypeId;
                      companyId = data["response"].authenticateUser.companyID;
                      // if( projectModel = data["response"].identityBean.projectModel){
                      //   projectModel = data["response"].identityBean.projectModel
                      // }

                      ////console.error(err);.log(companyId);
                    } else {
                      companyId = data["response"].authenticateUser.companyID;
                      ////console.error(err);.log(companyId);
                      userTypeId = data["response"].identityBean.userTypeId;
                      userId = data["response"].identityBean.userId;
                      puserId = data["response"].authenticateUser.userId;
                      backuserId = data["response"].identityBean.userId;
                      userName = data["response"].identityBean.userName;
                      empType = data["response"].identityBean.contractorType;
                      projectModel = data["response"].identityBean.projectModel;
                      roleType =
                        data["response"].identityBean.roles[0]["roleTypeId"];

                      //this.cookie.put('_roleType', roleType);

                      ////console.error(err);.log(roleType,empType);
                    }

                    var accessTok =
                      data["response"].authenticateUser.accessToken;
                    if (userTypeId) {
                      ////console.error(err);.log(userTypeId);
                      //var usertype_id = CryptoJS.AES.encrypt(userTypeId.toString() , 'e5(8`*DK8L`YW8T7');
                      var usertype_id = userTypeId;
                      //var userId = CryptoJS.AES.encrypt(data.result.id.toString() , 'e5(8`*DK8L`YW8T7');
                      //this.cookie.put('_userd', usertype_id, {expires: expDate});
                      var currentTime = new Date();
                      ////console.error(err);.log(currentTime.setTime(currentTime.getTime()));
                      var exTime =
                        data["response"].authenticateUser.expiresIn - 120;
                      var newTime = new Date();
                      var Time: any = newTime.setTime(
                        newTime.getTime() + exTime * 1000
                      );

                      this.cookie.put("_usrId", userId);
                      this.cookie.put("_usrtyd", usertype_id);
                      this.cookie.put("_busrId", backuserId);
                      this.cookie.put("_puserId", puserId);
                      this.cookie.put("_exttime", Time);

                      ////console.error(err);.log(accessTok);
                      this.cookie.put("_acctok", accessTok);
                      this.cookie.put("_compnyId", companyId);
                      this.cookie.put("_userName", userName);
                      this.cookie.put("_empType", empType);
                      this.cookie.put("_projectModel", projectModel);

                      this.GetConfigMFA(
                        data["response"],
                        userTypeId,
                        projectModel
                      );

                      if (userTypeId == 500) {
                        ////console.error(err);.log("contractor");
                        if (this.isNew == true) {
                          ////console.error(err);.log("new");
                          //this.getProfileinfo();
                          //this.router.navigate(['/account/']);
                        } else {
                          ////console.error(err);.log("old");
                        }

                        // }
                      }
                    }
                  } else {
                    this.isLoading = false;
                    this.error_status = true;
                    this.msgs = [];
                    ////console.error(err);.log(data["response"].message);
                    if (
                      data["response"].message == "" ||
                      data["response"].message == undefined
                    ) {
                      data["response"].message =
                        "Please enter a valid user name and password to signin.";
                    }
                    this.msgs.push({
                      severity: "error",
                      summary: "",
                      detail:
                        "Please enter a valid user name and password to signin.",
                    });
                    //this.msgs.push({severity:'error', summary:'Info Message', detail:'PrimeNG rocks'});
                    ////console.error(err);.log(data["response"].authenticateUser.successFlag);
                    if (
                      data["response"].authenticateUser.successFlag == false
                    ) {
                      ////console.error(err);.log("error");
                      this.commonError =
                        "Please enter a valid user name and password to signin.";
                    }
                  }
                } else {
                  this.msgs = [];
                  this.msgs.push({
                    severity: "error",
                    summary: "",
                    detail: data["message"],
                  });
                  //this.msgs.push({severity:'error', detail:data['message']});
                }
              }
            } else {
              if (data["SuccessFlag"] == true) {
                ////console.error(err);.log("register");
                this.succmsgs = [];
                this.succmsgs.push({
                  severity: "success",
                  detail:
                    "One more step to complete your Registration… <br/>We sent you a confirmation email with a link to activate your Account. Please check your email and click the link.",
                });
                setTimeout(() => (this.isLogin = true), 3000);
                form.resetForm();
                this.error_status = false;
              } else {
                this.msgs = [];
                this.msgs.push({
                  severity: "error",
                  summary: "",
                  detail: data["Message"],
                });
              }
            }
          },
          (err) => {
            //console.error(err);.error(err);
            this.msgs = [];
            this.msgs.push({
              severity: "error",
              detail: "Server Side Failure",
            });
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }

  /**
   * [user Profile info]
   */
  getProfileinfo() {
    this.session.getDetails();
    var userroleId = this.cookie.get("_usrtyd");
    //var deuserId  = CryptoJS.AES.decrypt(userroleId, 'e5(8`*DK8L`YW8T7');
    this.userType = userroleId;
    var url;
    //var isBackoffice,isHeader;
    //////console.error(err);.log(this.userRole);
    //if(this.userType==500) {
    this.isBackoffice = false;
    this.isHeader = true;
    url = "portal/GetProfileDetails/" + this.session.userId;

    // } else {
    // this.isBackoffice = true;
    // this.isHeader = false;
    // url = "getProfile?userId="+this.session.backuserId+"&userType="+this.userType;
    // }
    this.isLoading = true;
    var mainData = "";
    this.http
      .postMethod(url, mainData, this.isBackoffice, this.isHeader)
      .subscribe(
        (data) => {
          //if(data['status']=='Y') {
          ////console.error(err);.log(data);

          let result: any = data;
          let consultaddId = result.ConsultantAddressId;
          var firstLastName = result.FirstName + " " + result.LastName;

          this.cookie.put("_userName", firstLastName);
          var isFirsttime = this.cookie.get("_isFirst");
          ////console.error(err);.log(consultaddId,isFirsttime);
          if (!consultaddId && isFirsttime != "0") {
            this.router.navigate(["/account"]);
          } else {
            this.getEmployeeinfo();
          }
          //this.countryList=data['response']['list'];
          //////console.error(err);.log(this.countryList);
          //this.usertProdetails['CountryId'] = this.usertProdetails['personalAddr']['countryCodeStr'];
          //////console.error(err);.log(this.usertProdetails['CountryId']);
          //this.loadBState(this.usertProdetails['CountryId']);

          //}
        },
        (err) => {
          //console.error(err);.error(err);
        },
        () => {
          this.isLoading = false;
        }
      );
  }
  getprofileEmergeuser() {
    this.session.getDetails();
    var userroleId = this.cookie.get("_usrtyd");
    //var deuserId  = CryptoJS.AES.decrypt(userroleId, 'e5(8`*DK8L`YW8T7');
    this.userType = userroleId;
    var url;

    this.isBackoffice = false;
    this.isHeader = true;
    url = "portal/GetProfileDetails/" + this.session.userId;
    this.isLoading = true;
    var mainData = "";
    this.http
      .postMethod(url, mainData, this.isBackoffice, this.isHeader)
      .subscribe(
        (data) => {
          let result: any = data;
          this.emailAddress = result.EMail;
          let consultaddId = result.ConsultantAddressId;
          var firstLastName = result.FirstName + " " + result.LastName;
          this.consultaddId = consultaddId;
          

          this.cookie.put("_userName", firstLastName);
          this.getsecuritycodeDetails();
        },
        (err) => {
          //console.error(err);.error(err);
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  closepopup() {
    this.authpopup = false;
    this.cookie.remove("_usrtyd");
  }
  verifyoptkeys(event): boolean {
    this.verifyotps = true;
    this.popupmsgs = [];
    const charcode = event.which ? event.which : event.keyCode;
    if (charcode > 31 && (charcode < 48 || charcode > 57)) {
      return false;
    }

    return true;
  }
  validateotptext(event: KeyboardEvent) {
    const key = event.keyCode;
    if (this.verifytext.length == 6) {
      this.otptxtvalidate = true;
    } else if (key == 8) {
      this.otptxtvalidate = false;
    }
  }
  verifyotp() {
    var userTyId = this.cookie.get("_usrtyd");
    var projectModel = this.cookie.get("_projectModel");
    var backId = this.cookie.get("_busrId");

    this.popupmsgs = [];
    this.isLoading = true;
    if (this.verifytext == undefined || this.verifytext == "") {
      this.popupmsgs.push({
        severity: "warn",
        summary: "warning",
        detail: "please enter the otp",
      });
    } else {
      var url;
      url =
        "otp/VerifyToken?RequestId=" +
        this.User.RequestId +
        "&Code=" +
        this.verifytext;

      var mainData = "";
      this.http.postMethodauth(url, mainData).subscribe(
        (data) => {
          var result = data;
          if (result["isVerified"] == true) {
            this.validateText = result["message"];
            this.isverified = result["isVerified"];
            this.cookie.put("true", "isverified");
            this.popupmsgs.push({
              severity: "success",
              detail: this.validateText,
            });

            setTimeout(() => (this.authpopup = false), 3000);
          }
          if (backId != undefined) {
            if (this.isverified == true) {
              this.succmsgs = [];
              this.succmsgs.push({
                severity: "success",
                detail: "Signed in Successfully",
              });
              this.router.navigate(["/contractor/home"]);
              this.cookie.remove("_ApproverProjectModel");

              if (userTyId) {
                //var deuserId  = CryptoJS.AES.decrypt(userTyId, 'e5(8`*DK8L`YW8T7');
                var checkUser = Number(userTyId);
                if (checkUser == 300 || checkUser == 450 || checkUser == 400) {
                  this.cookie.put("_ApproverProjectModel", projectModel);
                  this.cookie.remove("_projectModel");
                  this.router.navigate(["/client/home"]);
                }
              }
            }
          } else {
            var isFirsttime = this.cookie.get("_isFirst");
            this.succmsgs.push({
              severity: "success",
              detail: "Signed in Successfully",
            });
            if (!this.consultaddId && isFirsttime != "0") {
              this.router.navigate(["/account"]);
            } else {
              this.getEmployeeinfo();
            }
          }
        },
        (err) => {
          this.otpverifycount++;
          this.verifyotps = false;
          this.validateText = err.error.message;
          this.popupmsgs.push({ severity: "error", detail: this.validateText });

          if (this.otpverifycount == 5) {
            this.popupmsgs = [];
            this.popupmsgs.push({
              severity: "warn",
              detail: "you have" + this.otpverifycount + "attempts",
            });
            setTimeout(() => (this.authpopup = false), 5000);
          }
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }
  Regenerateotp() {
    this.otpexpiredtime(10);

    var userName = this.cookie.get("_userName");
    this.regenarateverifycount++;
    if (this.regenarateverifycount == 5) {
      this.popupmsgs.push({
        severity: "warn",
        detail: "you have" + this.regenarateverifycount + "attempts",
      });
      setTimeout(() => (this.authpopup = false), 3000);

      this.router.navigate(["./"]);

      this.regenarateverifycount = 0;
      return false;
    } else if (this.emailAddress != undefined) {
      var url;
      url = "otp/ReGenerateToken";
      this.isLoading = true;
      var date = new Date().toJSON().slice(0, 10);
      this.User.FullName = userName;
      this.User.Email = this.emailAddress;
      this.User.RequestId = this.User.RequestId;
      this.User.ExpireDate = date;
      this.http.postMethodauth(url, this.User).subscribe(
        (data) => {
          var result = data;
          this.User.RequestId = result["requestId"];
        },
        (err) => {},
        () => {
          this.isLoading = false;
        }
      );
    }
  }
  getprofile() {
    this.isLoading = true;
    var backId = this.cookie.get("_busrId");
    let result;

    var userRole = this.cookie.get("_usrtyd");
    //var userroleId = this.cookie.get('_usrtyd');
    var url;
    url = "getProfile?userId=" + backId + "&userType=" + userRole;

    var mainData = "";
    this.http.postMethod(url, mainData, true, true).subscribe(
      (data) => {
        if (data["status"] == "Y") {
          ////console.error(err);.log(data);

          result = data;

          this.emailAddress = result["response"]["user"]["emailAddr"];
          this.getsecuritycodeDetails();
        }
      },
      (err) => {
        //console.error(err);.error(err);
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  otpexpiredtime(minute) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    clearInterval(this.timerInterval);
    const prefix = minute < 10 ? "0" : "";
    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;
      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}m${textSec}s`;

      if (seconds == 0) {
        clearInterval(this.timerInterval);

        //this.authpopup=false
      }
    }, 1000);
  }
  Regenarateexpiredtime() {
    let seconds: number = 3 * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    clearInterval(this.timerInterval1);
    const prefix = 3 < 10 ? "0" : "";
    this.timerInterval1 = setInterval(() => {
      seconds--;
      if (seconds == 0) {
        this.regeratebutton = true;
        clearInterval(this.timerInterval1);

        //this.authpopup=false
      }
    }, 1000);
  }
  /**
   * [user Employee info]
   */
  getsecuritycodeDetails() {
    this.popupmsgs = [];
    var userName = this.cookie.get("_userName");
    this.verifytext = "";
    if (this.emailAddress != undefined) {
      this.authpopup = true;
      this.regeratebutton = false;
      this.otpexpiredtime(10);
      this.Regenarateexpiredtime();
      var useremail = this.emailAddress;
      var emailsplit = useremail.split("@");
      var firstchar = useremail.substring(0, 1);
      var lastchar = useremail.substr(emailsplit[0].length - 1);
      this.emailaddress = firstchar + "xxxxxxxx" + lastchar;

      var url;
      url = "otp/GenerateToken";
      this.isLoading = true;
      var date = new Date().toJSON().slice(0, 10);
      this.User.FullName = userName;
      this.User.Email = this.emailAddress;
      this.User.RequestId = "";
      this.User.ExpireDate = date;
      this.http.postMethodauth(url, this.User).subscribe(
        (data) => {
          ////console.error(err);.log(data);

          var result = data;
          this.User.RequestId = result["requestId"];
        },
        (err) => {
          //console.error(err);.error(err);
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }
  getEmployeeinfo() {
    //  this.getsecuritycodeDetails()
    this.isLoading = true;
    var mainData = "";
    this.isBackoffice = false;
    this.isHeader = false;
    let url = "portal/GetPayrollInfo/" + this.session.userId;
    this.http.getMethod(url, this.isBackoffice).subscribe(
      (data) => {
        let result: any = data;
        //this.empDone = true;
        this.usertEmpdetails = result;
        ////console.error(err);.log(this.usertEmpdetails);
        if (
          this.usertEmpdetails["EmploymentTypeId"] == "" ||
          this.usertEmpdetails["EmploymentTypeId"] == null
        ) {
          // this.router.navigate(['/account']);
          this.router.navigate(["/account/empdetails"]);
        } else {
          //////console.error(err);.log("ddd");

          this.router.navigate(["/contractor/home"]);
        }
        // if(this.usertEmpdetails['DOB']) {
        // //let dob = moment(this.usertEmpdetails['DOB']);
        //  // this.usertEmpdetails['DOB'] = dob.format('DD-MMM-YYYY');
        //   }
        //   if(this.usertEmpdetails['ExpiryDate']) {
        //   let expiry = moment(this.usertEmpdetails['ExpiryDate']);
        //   this.usertEmpdetails['ExpiryDate'] = expiry.format('DD-MMM-YYYY');
        //}
        //this.getEmploymenttype();
      },
      (err) => {
        //console.error(err);.error(err);
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  GetConfigMFA(backOfficemenu, userTypeId, projectModel) {    
    this.http.GetConfigData().subscribe((res) => {
      var LandingPageDetails = res;
      if (backOfficemenu != null) {
        if (backOfficemenu.backofficeLogin == "Y") {
          //check for the bo logins
          var RegisterDomainId = backOfficemenu.identityBean.registeredMVDomain;
          var BOCompanyList = LandingPageDetails[0].AllowMFAVendorIds;
          var isBOCompanyExists = BOCompanyList.indexOf(RegisterDomainId);
          if (isBOCompanyExists >= 0) {
            this.getprofile();
          } else {
            this.succmsgs.push({
              severity: "success",
              detail: "Signed in Successfully",
            });
            this.cookie.put("false", "isverified");
            this.router.navigate(["/contractor/home"]);
            this.cookie.remove("_ApproverProjectModel");
            if (userTypeId == 300 || userTypeId == 450 || userTypeId == 400) {
              this.cookie.put("_ApproverProjectModel", projectModel);
              this.cookie.remove("_projectModel");
              this.router.navigate(["/client/home"]);
            }
          }
        } else {
          var EmgergeCompanyId = backOfficemenu.authenticateUser.companyID;
          var BOCompanyList = LandingPageDetails[0].AllowPortalComapanyIds;
          var isInArray = BOCompanyList.indexOf(EmgergeCompanyId);
          if (isInArray >= 0) {
            this.getprofileEmergeuser();
          } else {
            this.succmsgs.push({
              severity: "success",
              detail: "Signed in Successfully",
            });
            this.cookie.put("false", "isverified");
            this.getProfileinfo();
          }
        }
      }
    });
  }

  //get the config.json values
  GetConfigJsonData() {
    this.http.getApiLinks().then((res) => {
      this.configDetails = res;
      this.BtnSignUp = this.configDetails[0].AllowRegistration;
    });
  }
  clear() {
    this.msgs = [];
  }
}
