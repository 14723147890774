//Angular decorators list
import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
//Custom services list
import { CookieService } from "ngx-cookie";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { HttpService } from "./../../shared/services/http.service";
import { SessionService } from "./../../shared/services/sessiontoken.service";
import { Message } from "primeng/api";
import { DialogModule } from "primeng/dialog";
import {
  UploadEvent,
  UploadFile,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from "ngx-file-drop";
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import * as html2pdf from "html2pdf.js";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-timesheetcycle",
  templateUrl: "./timesheetcycle.component.html",
  styleUrls: ["./timesheetcycle.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class TimesheetcycleComponent implements OnInit {
  // Initial setup
  @ViewChild("fileElement") fileElement: any;
  @ViewChild("fileContent") fileContent: any;
  note = "";
  result;
  userId = this.cookie.get("_usrId");
  accessToken = this.cookie.get("_acctok");
  multitimesheet: boolean = false;
  unitsFlag: boolean=false;
  notespoup: boolean = false;
  wrkContId;
  buserId;
  listFirst;
  isUpload;
  error_status = false;
  isLoading = true;
  isBackoffice = false;
  isHeader = true;
  companyName;
  startDate;
  endDate;
  calenderData;
  isBulkTimesheet:boolean
  bulkTimesheetTotalHour;
  specDay;
  active;
  subActive;
  totalTime;
  dateData = {};
  msgs: Message[] = [];
  datekey = [];
  sendData;
  totalRegular;
  totalOvertime;
  isVendorcont: boolean;
  isAdmin: boolean;
  remainWorkContractUnit;
  usedWorkContractUnit;
  display: boolean = false;
  file = {
    doctype: null,
    docname: null,
    fileData: "",
  };
  uploadFile;
  fileExten;
  nxtDate;
  preDate;
  invoiceCid;
  isFile: boolean = false;
  fileName;
  docId;
  docName;
  notesArray;
  empType;
  isEdit: boolean = false;
  isSoleCon: boolean = false;
  invoiceContractorId;
  userroleId;
  submitSheet;
  clientApproved;
  isapproveAlready: boolean = false;
  isError = false;
  errorPos = -1;
  submitDisable: boolean = false;
  test;
  attachedDocId;
  attachedDocname;
  hadAttach: boolean = false;
  totalRegularHour;
  roleTypeId;
  oldDocId;
  oldDocName;
  deleterFlag;
  DivWorkStream;
  WorkStreamList;
  Notes;
  TxtUtilizationHours;
  divUtilize;
  VacationConfigDetails;
  Vacationlst;
  ColorConfig;
  HolidayColor;
  PendingColor;
  lstvacationTypes;
  vacations;
  downloadpdfhours = [];
  downloadpdfhours1 = [];
  downloadpdfhours2 = [];
  totalamount1: boolean;
  totalamount2: boolean;
  pdfdiv2: boolean;
  grandtotelhours;
  contratorname: any;
  contractnumber: any;
  workcontractid: any;
  contractponumber: any;
  LandingPageDetails;
  backOfficemenu;
  backId;
  puserId;
  BOCompanyList = [];
  hidecontractunit: boolean = true;
  totalunits: number;
  hideindays: boolean;
  calanderday: any;
  daynote: any;
  workcontractstartDate: any;
  workcontractendDate: any;
  workContractDesc: any;
  CalDate: string;
  isEmpCon: boolean;
  dayhours: any;
  hoursdays;
  contractid: any;
  constructor(
    private http: HttpService,
    private session: SessionService,
    private cookie: CookieService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private Datepipe: DatePipe
  ) {}

  /**
   * [Route data from app-routing module]
   */
  ngOnInit() {
    window.document.title = "Timehseet - Connect ";
   var multitimesheet = this.cookie.get("_ApproverProjectModel");
    this.VacationConfigDetails = this.cookie.getObject("VacationConfigDetails");
    if (this.VacationConfigDetails != undefined) {
      this.ColorConfig = this.VacationConfigDetails[0].VacationType;
      this.HolidayColor = this.VacationConfigDetails[0].HolidayVacationColor;
      this.PendingColor = this.VacationConfigDetails[0].PendingVacationColor;
    }
    this.buserId = this.cookie.get("_busrId");
    var url = "getVacationType?userId=" + this.buserId;
    var data = "";
    this.http.postMethod(url, data, true, true).subscribe((data) => {
      if (data["status"] == "Y") {
        this.lstvacationTypes = data["response"]["list"];
      }
    });

    this.route.data.forEach((results: any) => {
      ////console.error(err);.log(results);
      this.GetJsonData();
      this.dataLoader(results.result);
      this.calculaterhours();
      this.GetWorkUnitDetails();
      //this.isLoading= false;
      //this.listFirst = results.result[0].ref_placement_summary_id;
    });
    if(multitimesheet=='T'){
      this.isBulkTimesheet=true  
    }
    else{
      this.isBulkTimesheet=false
    }

  }

  /**
	Data loader function
	 * @param {[Result with data]} result
	 */
  dataLoader1(results) {
    ////console.error(err);.log(results);
    this.result = results;
    this.hadAttach = false;
    this.isLoading = false;
    if (this.result["status"] == "N") {
      this.msgs = [];
      this.msgs.push({ severity: "error", detail: this.result["message"] });
      let olderUrl = this.cookie.get("_previousUrl");

      setTimeout(() => this.router.navigate([olderUrl]), 3000);
    } else {
      //////console.error(err);.log(this.result.response.invoiceContractor['companyFullname']);
      if (
        this.result.response.invoiceContractor &&
        this.result.response.invoiceContractor["companyFullname"]
      ) {
        ////console.error(err);.log("hii");
        this.companyName =
          this.result.response.invoiceContractor["companyFullname"];
      } else {
        this.companyName = "";
      }
      //////console.error(err);.log(this.companyName);
      if (this.result.response.invoiceContractor) {
        if (this.result.response.invoiceContractor) {
          this.attachedDocId = this.result.response.invoiceContractor["docId"];
          this.attachedDocname =
            this.result.response.invoiceContractor["docName"];
          this.docName = this.result.response.invoiceContractor["docName"];
          this.docId = this.result.response.invoiceContractor["docId"];
          if (this.attachedDocId) {
            this.hadAttach = true;
            this.isFile = true;
          }
        } else {
          this.hadAttach = true;
        }

        this.totalRegular =
          this.result.response.invoiceContractor["totalRegularHour"];
        this.totalOvertime =
          this.result.response.invoiceContractor["totalOvertimeHour"];
        this.startDate = this.result.response.invoiceContractor["invStartDt"];

        //this.startDate = this.result.response.invoiceContractor['invStartDt'];
        let now = moment(this.startDate); // add this 2 of 4
        this.startDate = now.format("DD-MMM-YYYY");
        this.endDate = this.result.response.invoiceContractor["invEndDt"];
        let to = moment(this.endDate);
        this.endDate = to.format("DD-MMM-YYYY");
        this.invoiceContractorId =
          this.result.response.invoiceContractor["invoiceContractorId"];
        //this.endDate = this.result.response.invoiceContractor['invEndDt'];
        this.invoiceCid =
          this.result.response.invoiceContractor["invoiceContractorId"];
      } else {
        this.totalRegular = 0;
        this.totalOvertime = 0;
      }
      this.userroleId = this.result.response.identityBean["userTypeId"];
      this.clientApproved =
        this.result.response.timecardStatusCode["timecardStatusCode"];
      let preData = this.session.getContracts();
      this.empType = this.session.empType;
      ////console.error(err);.log(this.clientApproved);
      let isApprover = false;
      this.isAdmin = false;
      let isManager = false;
      let isViewer = false;
      this.isVendorcont = false;
      for (var roles of this.result.response.identityBean.roles) {
        if (roles["roleTypeId"] == 135) {
          //this.isapproveAlready = true;

          ////console.error(err);.log("135");
          isApprover = true;
          this.cookie.put("_roleTypename", "Approver");
          this.cookie.put("_roleType", roles["roleTypeId"]);
          this.checkApproved();
        } else {
          if (roles["roleTypeId"] == 131) {
            //this.isapproveAlready = true;
            ////console.error(err);.log("131");
            isManager = true;
            //this.isapproveAlready = false;
            this.cookie.put("_roleTypename", "Manager");
            this.cookie.put("_roleType", roles["roleTypeId"]);
            this.checkApproved();
            //break;
          } else if (roles["roleTypeId"] == 133) {
            ////console.error(err);.log("100");
            isViewer = true;
            this.isapproveAlready = true;
            this.cookie.put("_roleTypename", "Viewer");
            this.cookie.put("_roleType", roles["roleTypeId"]);
          } else if (roles["roleTypeId"] == 100) {
            ////console.error(err);.log("100");
            this.isAdmin = true;
            this.isapproveAlready = true;
            this.cookie.put("_roleTypename", "Viewer");
            this.cookie.put("_roleType", roles["roleTypeId"]);
          } else if (roles["roleTypeId"] == 138) {
            this.isVendorcont = true;
            this.cookie.put("_roleTypename", "Viewer");
            this.cookie.put("_roleType", roles["roleTypeId"]);
          }
        }
      }
      ////console.error(err);.log(isApprover,isAdmin,this.isapproveAlready)
      if (isApprover || (isApprover && this.isAdmin)) {
        if (this.userroleId == 400 && this.clientApproved == 1) {
          ////console.error(err);.log("approver", this.isEdit,this.submitSheet)
          this.isEdit = true;
          this.submitSheet = true;
        } else if (
          (this.userroleId == 400 && this.clientApproved == 2) ||
          (this.userroleId == 300 && this.clientApproved == 3)
        ) {
          this.submitSheet = false;
          this.isEdit = false;
          this.isapproveAlready = false;
        } else {
          this.submitSheet = false;
          this.isEdit = false;
          this.isapproveAlready = true;
        }
      } else if (this.isVendorcont || (this.isVendorcont && this.isAdmin)) {
        if (this.clientApproved == 1 && this.userroleId == 450) {
          this.isEdit = true;
          this.submitSheet = true;
        } else {
          this.submitSheet = false;
          this.isEdit = false;
          this.isapproveAlready = true;
        }
      } else if (isManager) {
        if (this.clientApproved == 3) {
          //this.submitSheet = false;
          // this.isEdit = false;
          this.isapproveAlready = false;
        } else {
          //this.submitSheet = false;
          //this.isEdit = false;
          this.isapproveAlready = true;
        }
      } else {
        this.isapproveAlready = true;
      }
      ////console.error(err);.log(this.clientApproved);

      //this.startDate = preData['period']['targetDateStr'];

      this.wrkContId = preData["params"]["workContractId"];

      var uerType = this.cookie.get("_usrtyd");
      this.roleTypeId = this.cookie.get("_roleType");
      //var deuserId  = CryptoJS.AES.decrypt(uerType, 'e5(8`*DK8L`YW8T7');

      ////console.error(err);.log(this.userroleId,this.clientApproved,this.submitSheet);
      // if(this.userroleId==450) {
      //     this.isEdit = true;
      //     this.submitSheet = true;
      //     if(this.clientApproved==3 || this.clientApproved == 2) {
      //          this.submitSheet = false;
      //          this.isEdit = false;
      //          this.isapproveAlready = true;

      //     } else  if(this.clientApproved==4) {
      //         this.submitSheet = false;
      //          this.isEdit = false;
      //          this.isapproveAlready = true;
      //     }

      // } else if(this.userroleId==400 && this.roleTypeId==135){
      //         //this.isEdit = true;
      //         //this.submitSheet = true;
      //     if(this.clientApproved==1 ) {
      //         this.submitSheet = true;
      //         this.isEdit = true;
      //     }
      //     else if(this.clientApproved==2 ) {
      //         this.submitSheet = false;
      //          this.isEdit = false;
      //          this.isapproveAlready = false;
      //     }
      //     else {
      //     this.submitSheet = false;
      //     this.isEdit = false;
      //     this.isapproveAlready = true;
      //     }

      // } else {
      //     this.submitSheet = false;
      //     this.isEdit = false;
      // }
      ////console.error(err);.log(this.isapproveAlready,this.submitSheet, this.isEdit);
      this.buserId = this.result.response.identityBean["contractorId"];
      if (!this.buserId) {
        this.buserId = this.result.response.identityBean["userId"];
        if (!this.buserId) {
          this.buserId = this.cookie.get("_usrId");
        }
      }
      //this.endDate = preData['period']['toDateStr'];
      ////console.error(err);.log(this.buserId);

      this.calenderData = this.result.response["calendarList"];

      this.nxtDate = this.result.response["nextDate"];
      this.preDate = this.result.response["priviousDate"];

      this.notesArray = this.result.response.noteList;
      //console.error(err);.log(this.notesArray);

      console.log(this.notesArray);
      var EmptyStr = "&nbsp;";
      for (var note of this.notesArray) {
        let now = moment(note["noteDate"]); // add this 2 of 4
        note["noteDate"] = now.format("DD-MMM-YYYY h:mm a");
        var FirstStr1 = note["note"].split(":")[0];
        var FirstStr2 = note["note"].split(":")[1];

        if (FirstStr2 == EmptyStr) {
          note["note"] = FirstStr1;
        } else {
          if (FirstStr2 == " undefined") {
            note["note"] = FirstStr1;
          } else {
            note["note"] = note["note"];
          }
        }
        if (note["note"] == "(SUBMISSION - VENDOR_APPROVED) ") {
          note["note"] = "(SUBMISSION - Submit for Approval)";
        }
        //note['note']= note['note'].split(':')[1];
        //note['note']= note['note'].split('&nbsp;')[0];
        // note['noteDate']=note['noteDate'];
        // note['user']=note['user'];
      }
      //console.error(err);.log(this.notesArray);
      if (this.empType == "S") {
        ////console.error(err);.log(this.empType);
        this.isSoleCon = true;
      }
      var mainIndex = 0;

      for (var item of this.calenderData) {
        this.totalTime = 0;

        this.specDay = item["timeCardWeekList"];
        var index = 0;
        this.datekey = [];
        for (var intem of this.specDay) {
          //this.dateData.push(intem);
          var str = intem.day.substring(3, 6);
          var str1 = intem.day.substring(0, 2);
          var calendarDay = str + " " + str1;

          this.dateData["cDate_" + mainIndex + "_" + index] = intem.CalendarDay;
          this.dateData["cDay_" + mainIndex + "_" + index] = calendarDay;
          this.dateData["hrx_" + mainIndex + "_" + index] = intem.hrExp;
          this.dateData["hrOt_" + mainIndex + "_" + index] = intem.hrOvertime;
          this.dateData["hrReg_" + mainIndex + "_" + index] = intem.hrReg;
          this.dateData["iCycle_" + mainIndex + "_" + index] = intem.isCycle;
          if (intem.isCycle == "true") {
            if (intem.hrWork) {
              this.dateData["hrWrk_" + mainIndex + "_" + index] = intem.hrWork;
            } else {
              this.dateData["hrWrk_" + mainIndex + "_" + index] = 0;
            }
          }
          this.dateData["note_" + mainIndex + "_" + index] = intem.note;
          this.dateData["tCard_" + mainIndex + "_" + index] =
            intem.timeCardStatus;
          this.dateData["tError_" + mainIndex + "_" + index] = -1;
          if (intem.timeCardStatus == 1) {
            //////console.error(err);.log(intem.hrWork );

            this.totalTime = this.totalTime + parseInt(intem.hrWork);
            //////console.error(err);.log(intem,this.totalTime);
          }
          this.datekey.push({
            calendarDay: "cDate_" + mainIndex + "_" + index,
            day: "cDay_" + mainIndex + "_" + index,
            hrExp: "hrx_" + mainIndex + "_" + index,
            hrOvertime: "hrOt_" + mainIndex + "_" + index,
            hrReg: "hrReg_" + mainIndex + "_" + index,
            hrWork: "hrWrk_" + mainIndex + "_" + index,
            note: "note_" + mainIndex + "_" + index,
            isCycle: "iCycle_" + mainIndex + "_" + index,
            timeCardError: "tError_" + mainIndex + "_" + index,
          });
          index++;
          //////console.error(err);.log(totalTime);
          //////console.error(err);.log(this.datekey,this.dateData);
        }
        this.calenderData[mainIndex]["timeCardWeekList"] = this.datekey;
        mainIndex++;
      }

      // Utilization
      this.VacationConfigDetails = this.cookie.getObject(
        "VacationConfigDetails"
      );
      var IsUtlization = this.VacationConfigDetails[0].Utilization[0].IsExist;
      if (IsUtlization == "Yes") {
        if (this.result.response["workStreamUtilizationList"] != null) {
          var lstGetWorkStream = [];
          this.DivWorkStream = true;
          var url =
            "getWorkStreamList?userId=" +
            this.userId +
            "&workContractId=" +
            this.wrkContId;
          var data1 = "";
          this.http.postMethod(url, data1, true, true).subscribe((data1) => {
            if (data1["status"] == "Y") {
              this.WorkStreamList = [];
              lstGetWorkStream = data1["response"]["list"];
              var Tot1 = 0;
              for (
                let i = 0;
                i < this.result.response["workStreamUtilizationList"].length;
                i++
              ) {
                var details = lstGetWorkStream.filter(
                  (x) =>
                    x.id ===
                    this.result.response["workStreamUtilizationList"][i]
                      .workStreamId
                );

                if (details.length > 0) {
                  var TotolHrs = this.totalRegular;
                  var totPer1 = 0;
                  var EnterVal = parseFloat(
                    this.result.response["workStreamUtilizationList"][i].hours
                  );
                  this.WorkStreamList.push({
                    id: details[0].id,
                    description: details[0].description,
                    txtUtilizationHours:
                      this.result.response["workStreamUtilizationList"][i]
                        .hours,
                  });
                }
              }
            }
          });
        }
      } else {
        this.DivWorkStream = false;
      }
    }
  }

  dataLoader(results) {
    ////console.error(err);.log(results);
    this.result = results;
    this.hadAttach = false;
    this.isLoading = false;
    if (this.result["status"] == "N") {
      this.msgs = [];
      this.msgs.push({ severity: "error", detail: this.result["message"] });
      let olderUrl = this.cookie.get("_previousUrl");

      setTimeout(() => this.router.navigate([olderUrl]), 3000);
    } else {
      //////console.error(err);.log(this.result.response.invoiceContractor['companyFullname']);
      if (
        this.result.response.invoiceContractor &&
        this.result.response.invoiceContractor["companyFullname"]
      ) {
        ////console.error(err);.log("hii");
        this.companyName =
          this.result.response.invoiceContractor["companyFullname"];
      } else {
        this.companyName = "";
      }
      //////console.error(err);.log(this.companyName);
      if (this.result.response.invoiceContractor) {
        if (this.result.response.invoiceContractor) {
          this.attachedDocId = this.result.response.invoiceContractor["docId"];
          this.attachedDocname =
            this.result.response.invoiceContractor["docName"];
          this.docName = this.result.response.invoiceContractor["docName"];
          this.docId = this.result.response.invoiceContractor["docId"];
          this.workcontractstartDate = this.result.response["startDt"];
          this.contractid =
            this.result.response.invoiceContractor["contractId"];
          let now = moment(this.workcontractstartDate); // add this 2 of 4
          this.workcontractstartDate = now.format("DD-MMM-YYYY");
          this.workcontractendDate = this.result.response["endDt"];

          let to = moment(this.workcontractendDate);
          this.workcontractendDate = to.format("DD-MMM-YYYY");
          if (this.attachedDocId) {
            this.hadAttach = true;
            this.isFile = true;
          }
        } else {
          this.hadAttach = true;
        }
        var TotalUtilHr = 0;

        var lstCalendar = results.response["calendarList"];
        if (lstCalendar != null) {
          for (let i = 0; i < lstCalendar.length; i++) {
            TotalUtilHr = TotalUtilHr + parseFloat(lstCalendar[i].weekWorkHr);
          }
        }
        //this.TotalHours = TotalUtilHr;

        this.totalRegular = TotalUtilHr.toFixed(2);
        this.contratorname =
          this.result.response.invoiceContractor["contractorFirstName"] +
          this.result.response.invoiceContractor["contractorLastName"];

        this.contractnumber = this.result.response["financialCode4"];
        this.workcontractid = this.result.response["financialCode1"];
        this.workContractDesc = this.result.response["workContractDesc"];
        //this.workcontractid=this.result.response.invoiceContractor.workContractId;

        //this.totalRegular = this.result.response.invoiceContractor['totalRegularHour'];
        this.totalOvertime =
          this.result.response.invoiceContractor["totalOvertimeHour"];
        this.startDate = this.result.response.invoiceContractor["invStartDt"];
        //this.startDate = this.result.response.invoiceContractor['invStartDt'];
        let now = moment(this.startDate); // add this 2 of 4
        this.startDate = now.format("DD-MMM-YYYY");
        this.endDate = this.result.response.invoiceContractor["invEndDt"];
        let to = moment(this.endDate);
        this.endDate = to.format("DD-MMM-YYYY");
        this.invoiceContractorId =
          this.result.response.invoiceContractor["invoiceContractorId"];
        //this.endDate = this.result.response.invoiceContractor['invEndDt'];
        this.invoiceCid =
          this.result.response.invoiceContractor["invoiceContractorId"];
      } else {
        this.totalRegular = 0;
        this.totalOvertime = 0;
      }
      if(this.result['response'].unit=="2-WEEK-SAT-FRI"){
        this.unitsFlag=true
      }   
      this.userroleId = this.result.response.identityBean["userTypeId"];
      this.clientApproved =
        this.result.response.timecardStatusCode["timecardStatusCode"];
      let preData = this.session.getContracts();
      this.empType = this.session.empType;
      ////console.error(err);.log(this.clientApproved);
      let isApprover = false;
      let isAdmin = false;
      let isManager = false;
      let isViewer = false;
      let isVendorcont = false;
      for (var roles of this.result.response.identityBean.roles) {
        if (roles["roleTypeId"] == 135) {
          //this.isapproveAlready = true;

          ////console.error(err);.log("135");
          isApprover = true;
          this.cookie.put("_roleTypename", "Approver");
          this.cookie.put("_roleType", roles["roleTypeId"]);
          this.checkApproved();
        } else {
          if (roles["roleTypeId"] == 131) {
            //this.isapproveAlready = true;
            ////console.error(err);.log("131");
            isManager = true;
            //this.isapproveAlready = false;
            this.cookie.put("_roleTypename", "Manager");
            this.cookie.put("_roleType", roles["roleTypeId"]);
            this.checkApproved();
            //break;
          } else if (roles["roleTypeId"] == 133) {
            ////console.error(err);.log("100");
            isViewer = true;
            this.isapproveAlready = true;
            this.cookie.put("_roleTypename", "Viewer");
            this.cookie.put("_roleType", roles["roleTypeId"]);
          } else if (roles["roleTypeId"] == 100) {
            ////console.error(err);.log("100");
            isAdmin = true;
            this.isapproveAlready = true;
            this.cookie.put("_roleTypename", "Viewer");
            this.cookie.put("_roleType", roles["roleTypeId"]);
          } else if (roles["roleTypeId"] == 138) {
            isVendorcont = true;
            this.cookie.put("_roleTypename", "Viewer");
            this.cookie.put("_roleType", roles["roleTypeId"]);
          }
        }
      }
      ////console.error(err);.log(isApprover,isAdmin,this.isapproveAlready)
      if (isApprover || (isApprover && isAdmin)) {
        if (this.userroleId == 400 && this.clientApproved == 1) {
          ////console.error(err);.log("approver", this.isEdit,this.submitSheet)
          this.isEdit = true;
          this.submitSheet = true;
        } else if (
          (this.userroleId == 400 && this.clientApproved == 2) ||
          (this.userroleId == 300 && this.clientApproved == 3)
        ) {
          this.submitSheet = false;
          this.isEdit = false;
          this.isapproveAlready = false;
        } else {
          this.submitSheet = false;
          this.isEdit = false;
          this.isapproveAlready = true;
        }
      } else if (isVendorcont || (isVendorcont && isAdmin)) {
        if (this.clientApproved == 1 && this.userroleId == 450) {
          this.isEdit = true;
          this.submitSheet = true;
        } else {
          this.submitSheet = false;
          this.isEdit = false;
          this.isapproveAlready = true;
        }
      } else if (isManager) {
        if (this.clientApproved == 3) {
          //this.submitSheet = false;
          // this.isEdit = false;
          this.isapproveAlready = false;
        } else {
          //this.submitSheet = false;
          //this.isEdit = false;
          this.isapproveAlready = true;
        }
      } else {
        this.isapproveAlready = true;
      }
      ////console.error(err);.log(this.clientApproved);

      //this.startDate = preData['period']['targetDateStr'];

      this.wrkContId = preData["params"]["workContractId"];

      var uerType = this.cookie.get("_usrtyd");
      this.roleTypeId = this.cookie.get("_roleType");

      this.buserId = this.result.response.identityBean["contractorId"];
      if (!this.buserId) {
        this.buserId = this.result.response.identityBean["userId"];
        if (!this.buserId) {
          this.buserId = this.cookie.get("_usrId");
        }
      }

      this.calenderData = this.result.response["calendarList"];
      //new code
      if (this.calenderData.length > 0) {
        let preData = this.session.getContracts();
        let userId = preData["params"]["userId"];
        let VstartDate = preData["period"]["targetDateStr"];
        let VEndDate = preData["period"]["toDateStr"];
        var VEndDates = this.Datepipe.transform(VEndDate, "dd-MMM-yyyy");
        this.buserId = this.cookie.get("_busrId");
        let workContractId = (this.wrkContId =
          preData["params"]["workContractId"]);
        var data;
        var url =
          "getConsultantVacationCalendarList?userId=" +
          userId +
          "&vacationStart=" +
          VstartDate +
          "&vacationEnd=" +
          VEndDates +
          "&workContractId=" +
          workContractId;
        this.http.postMethod(url, data, true, true).subscribe((data) => {
          if (data["status"] == "Y") {
            this.Vacationlst = data["response"]["list"];
            //holiday
            var data1 = "";
            var url =
              "getHolidayList?userId=" +
              this.buserId +
              "&workContractId=" +
              workContractId +
              "&dateStartStr=" +
              VstartDate +
              "&dateEndStr=" +
              VEndDates;
            this.http.postMethod(url, data1, true, true).subscribe((data1) => {
              if (data1["status"] == "Y") {
                var IsHoliday =
                  this.VacationConfigDetails[0].HolidayConfig[0]
                    .IsHolidayConfig;
                if (IsHoliday == "Yes") {
                  var HolidayList = data1["response"]["list"];
                  if (HolidayList.length > 0) {
                    for (let j = 0; j < HolidayList.length; j++) {
                      var Hdate = HolidayList[j].holidayDate.substring(0, 10);
                      let now = moment(Hdate); // add this 2 of 4
                      HolidayList[j].holidayDate = now.format("DD-MMM-YYYY");
                    }

                    if (this.calenderData.length > 0) {
                      for (let i = 0; i < this.calenderData.length; i++) {
                        for (
                          let j = 0;
                          j < this.calenderData[i].timeCardWeekList.length;
                          j++
                        ) {
                          var CalendarDay =
                            this.calenderData[i].timeCardWeekList[j].day;

                          var details;
                          details = HolidayList.filter(
                            (x) => x.holidayDate === CalendarDay
                          );
                          if (details.length > 0) {
                            this.calenderData[i].timeCardWeekList[j].isCycle =
                              false;
                            this.calenderData[i].timeCardWeekList[j][
                              "HolidayDt"
                            ] = details[0].holidayDate;
                            this.calenderData[i].timeCardWeekList[j][
                              "IsHoliday"
                            ] = "true";
                            this.calenderData[i].timeCardWeekList[j][
                              "description"
                            ] = details[0].description;
                            this.calenderData[i].timeCardWeekList[j][
                              "HolidayColor"
                            ] = this.HolidayColor;
                          }
                        }
                      }
                    }
                  }
                }

                //

                var IsVacations =
                  this.VacationConfigDetails[0].VacationConfig[0]
                    .IsVacationConfig;
                if (IsVacations == "Yes") {
                  if (this.Vacationlst.length > 0) {
                    //for (let v = 0; v > this.Vacationlst.length; v++) {
                    //  //this.VacationsLists.push(this.Vacationlst[v].vacationDateStr)
                    //  this.VacationsLists.push(this.Vacationlst[v].vacationDateStr);
                    //}
                  }
                  if (
                    this.calenderData.length > 0 &&
                    this.Vacationlst.length > 0
                  ) {
                    for (let i = 0; i < this.calenderData.length; i++) {
                      for (
                        let j = 0;
                        j < this.calenderData[i].timeCardWeekList.length;
                        j++
                      ) {
                        var CalendarDay =
                          this.calenderData[i].timeCardWeekList[j].day;
                        var CalendarDay1 = CalendarDay.toUpperCase();
                        var details;
                        details = this.Vacationlst.filter(
                          (x) =>
                            x.vacationDateStr.toUpperCase() === CalendarDay1
                        );
                        if (details.length > 0) {
                          this.calenderData[i].timeCardWeekList[j]["Vdate"] =
                            details[0].vacationDateStr;
                          this.calenderData[i].timeCardWeekList[j].isCycle =
                            "true";
                          this.calenderData[i].timeCardWeekList[j][
                            "IsVacation"
                          ] = "true";
                          this.calenderData[i].timeCardWeekList[j][
                            "vacationType"
                          ] = details[0].vacationType;
                          this.calenderData[i].timeCardWeekList[j]["unit"] =
                            details[0].unit;
                          this.calenderData[i].timeCardWeekList[j][
                            "vacationStatus"
                          ] = details[0].vacationStatus;
                          this.calenderData[i].timeCardWeekList[j][
                            "createdDateStr"
                          ] = details[0].createdDateStr;
                          this.calenderData[i].timeCardWeekList[j]["value"] =
                            details[0].value;

                          var vacationclass = this.lstvacationTypes.filter(
                            (x) => x.value === details[0].vacationType
                          );
                          this.calenderData[i].timeCardWeekList[j][
                            "className"
                          ] = vacationclass[0].className;
                          if (this.ColorConfig != null) {
                            var vacationColor = this.ColorConfig.filter(
                              (x) =>
                                x.className ===
                                this.calenderData[i].timeCardWeekList[j]
                                  .className
                            );
                            // this.calenderData[i].timeCardWeekList[j]['Color'] = vacationColor[0].CalendarVacationColor;
                            if (
                              details[0].vacationStatus ==
                              "Applied(Approval Pending)"
                            ) {
                              this.calenderData[i].timeCardWeekList[j][
                                "Color"
                              ] = this.PendingColor;
                            } else {
                              this.calenderData[i].timeCardWeekList[j][
                                "Color"
                              ] = vacationColor[0].CalendarVacationColor;
                            }
                          }
                        } else {
                          this.calenderData[i].timeCardWeekList[j][
                            "IsVacation"
                          ] = "false";
                        }
                      }
                    }
                  } else {
                    for (let i = 0; i < this.calenderData.length; i++) {
                      for (
                        let j = 0;
                        j < this.calenderData[i].timeCardWeekList.length;
                        j++
                      ) {
                        this.calenderData[i].timeCardWeekList[j]["IsVacation"] =
                          "false";
                      }
                    }
                  }
                  //

                  //
                }

                if (this.attachedDocId) {
                  this.hadAttach = true;
                  this.isFile = true;
                }
                this.nxtDate = this.result.response["nextDate"];
                this.preDate = this.result.response["priviousDate"];
                this.invoiceCid =
                  this.result.response.invoiceContractor["invoiceContractorId"];
                this.notesArray = this.result.response.noteList;
                //for(var note of this.notesArray) {
                //    if(note['note']!=''){
                //note['note']= note['note'].split(':')[1];
                //note['note']= note['note'].split('&nbsp;')[0];
                //}
                //	}
                var EmptyStr = "&nbsp;";
                for (var note of this.notesArray) {
                  //    if(note['note']!=''){
                  //note['note'] = note['note'].split(':')[1];
                  //note['note'] = note['note'].split('&nbsp;')[0];
                  let now = moment(note["noteDate"]); // add this 2 of 4
                  note["noteDate"] = now.format("DD-MMM-YYYY h:mm a");
                  note["note"] = note["note"].replace(/&nbsp;/g, "");
                  var FirstStr1 = note["note"].split(":")[0];
                  var FirstStr2 = note["note"].split(":")[1];

                  if (FirstStr2 == EmptyStr) {
                    note["note"] = FirstStr1;
                  } else {
                    if (FirstStr2 == " undefined") {
                      note["note"] = FirstStr1;
                    } else {
                      note["note"] = note["note"];
                    }
                  }
                  if (note["note"] == "(SUBMISSION - VENDOR_APPROVED) ") {
                    note["note"] = "(SUBMISSION - Submit for Approval)";
                  }
                  //}
                }
                for (var i = 0; i < this.notesArray.length; i++) {
                  let now = moment(this.notesArray[i].notedate);
                  this.notesArray[i].notedate = now.format("dd-mmm-yyyy");
                  let to = moment(this.notesArray[i].notedate);
                }
                if (this.empType == "E") {
                  ////console.error(err);.log(this.empType);
                  this.isEmpCon = true;
                } else {
                  ////console.error(err);.log(this.empType);
                  this.isSoleCon = true;
                }
                var mainIndex = 0;
                for (var item of this.calenderData) {
                  this.totalTime = 0;

                  this.specDay = item["timeCardWeekList"];
                  var index = 0;
                  this.datekey = [];
                  for (var intem of this.specDay) {
                    //this.dateData.push(intem);
                    var str = intem.day.substring(3, 6);
                    var str1 = intem.day.substring(0, 2);
                    this.CalDate = str + " " + str1;
                    //let now = moment(intem.day); // add this 2 of 4
                    //this.CalDate = now.format('MMM-DD-YYYY');
                    this.dateData["cDate_" + mainIndex + "_" + index] =
                      intem.calendarDay;
                    //this.dateData['cDay_' + mainIndex + "_" + index] = intem.day;
                    this.dateData["cDay_" + mainIndex + "_" + index] =
                      this.CalDate;
                    this.dateData["hrx_" + mainIndex + "_" + index] =
                      intem.hrExp;
                    this.dateData["hrOt_" + mainIndex + "_" + index] =
                      intem.hrOvertime;
                    this.dateData["hrReg_" + mainIndex + "_" + index] =
                      intem.hrReg;
                    this.dateData["iCycle_" + mainIndex + "_" + index] =
                      intem.isCycle;
                    this.dateData["IsVacation_" + mainIndex + "_" + index] =
                      intem.IsVacation;
                    this.dateData["vacationType_" + mainIndex + "_" + index] =
                      intem.vacationType;
                    this.dateData["unit_" + mainIndex + "_" + index] =
                      intem.value + " " + intem.unit;
                    this.dateData["vacationStatus_" + mainIndex + "_" + index] =
                      intem.vacationStatus;
                    this.dateData["createdDateStr_" + mainIndex + "_" + index] =
                      intem.createdDateStr;

                    this.dateData["className_" + mainIndex + "_" + index] =
                      intem.className;
                    this.dateData["Color_" + mainIndex + "_" + index] =
                      intem.Color;

                    this.dateData["HolidayDt_" + mainIndex + "_" + index] =
                      intem.HolidayDt;
                    this.dateData["IsHoliday_" + mainIndex + "_" + index] =
                      intem.IsHoliday;
                    this.dateData["description_" + mainIndex + "_" + index] =
                      intem.description;
                    this.dateData["HolidayColor_" + mainIndex + "_" + index] =
                      intem.HolidayColor;

                    //console.log(this.dateData['iCycle_'+mainIndex+"_"+index]);
                    //////console.error(err);.log(intem.hrWork)
                    if (intem.isCycle == "true") {
                      if (intem.note) {
                        this.dateData["note_" + mainIndex + "_" + index] =
                          intem.note;
                      } else {
                        this.dateData["note_" + mainIndex + "_" + index] =
                          intem.note;
                      }

                      // console.log("sdsd");
                      if (intem.hrWork) {
                        this.dateData["hrWrk_" + mainIndex + "_" + index] =
                          intem.hrWork;
                      } else {
                        this.dateData["hrWrk_" + mainIndex + "_" + index] = 0;
                      }
                    }
                    //else {
                    //  this.dateData['IsVacation' + mainIndex + "_" + index] = true;
                    //  this.dateData['vacationType' + mainIndex + "_" + index] = intem.vacationType;
                    //}
                    //var splittedHr = '"'+intem.hrWork+'"';
                    // if(intem.hrWork){
                    // splittedHr = splittedHr.toString().split('.')[0];
                    // if(splittedHr.length==2) {
                    //     this.dateData['hrWrk_'+mainIndex+"_"+index] = intem.hrWork;

                    // } else {
                    //     this.dateData['hrWrk_'+mainIndex+"_"+index] = intem.hrWork;
                    // }
                    // } else {
                    //     //this.dateData['hrWrk_'+mainIndex+"_"+index] = 0.00;
                    // }

                    this.dateData["tCard_" + mainIndex + "_" + index] =
                      intem.timeCardStatus;
                    this.dateData["tError_" + mainIndex + "_" + index] = -1;
                    if (intem.timeCardStatus == 1) {
                      //////console.error(err);.log(intem.hrWork );

                      this.totalTime = this.totalTime + parseInt(intem.hrWork);
                      //////console.error(err);.log(intem,this.totalTime);
                    }
                    if (intem.IsVacation == "true") {
                      this.datekey.push({
                        calendarDay: "cDate_" + mainIndex + "_" + index,
                        day: "cDay_" + mainIndex + "_" + index,
                        hrExp: "hrx_" + mainIndex + "_" + index,
                        hrOvertime: "hrOt_" + mainIndex + "_" + index,
                        hrReg: "hrReg_" + mainIndex + "_" + index,
                        hrWork: "hrWrk_" + mainIndex + "_" + index,
                        note: "note_" + mainIndex + "_" + index,
                        isCycle: "iCycle_" + mainIndex + "_" + index,
                        timeCardStatus: "tCard_" + mainIndex + "_" + index,
                        timeCardError: "tError_" + mainIndex + "_" + index,
                        IsVacation: "IsVacation_" + mainIndex + "_" + index,
                        vacationType: "vacationType_" + mainIndex + "_" + index,
                        Vunit: "unit_" + mainIndex + "_" + index,
                        vacationStatus:
                          "vacationStatus_" + mainIndex + "_" + index,
                        createdDateStr:
                          "createdDateStr_" + mainIndex + "_" + index,
                        className: "className_" + mainIndex + "_" + index,
                        Color: "Color_" + mainIndex + "_" + index,
                      });
                      index++;
                    } else if (intem.IsHoliday == "true") {
                      this.datekey.push({
                        calendarDay: "cDate_" + mainIndex + "_" + index,
                        day: "cDay_" + mainIndex + "_" + index,
                        hrExp: "hrx_" + mainIndex + "_" + index,
                        hrOvertime: "hrOt_" + mainIndex + "_" + index,
                        hrReg: "hrReg_" + mainIndex + "_" + index,
                        hrWork: "hrWrk_" + mainIndex + "_" + index,
                        note: "note_" + mainIndex + "_" + index,
                        isCycle: "iCycle_" + mainIndex + "_" + index,
                        timeCardStatus: "tCard_" + mainIndex + "_" + index,
                        timeCardError: "tError_" + mainIndex + "_" + index,
                        HolidayDt: "HolidayDt__" + mainIndex + "_" + index,
                        IsHoliday: "IsHoliday_" + mainIndex + "_" + index,
                        description: "description_" + mainIndex + "_" + index,
                        HolidayColor: "HolidayColor_" + mainIndex + "_" + index,
                      });
                      index++;
                    } else {
                      this.datekey.push({
                        calendarDay: "cDate_" + mainIndex + "_" + index,
                        day: "cDay_" + mainIndex + "_" + index,
                        hrExp: "hrx_" + mainIndex + "_" + index,
                        hrOvertime: "hrOt_" + mainIndex + "_" + index,
                        hrReg: "hrReg_" + mainIndex + "_" + index,
                        hrWork: "hrWrk_" + mainIndex + "_" + index,
                        note: "note_" + mainIndex + "_" + index,
                        isCycle: "iCycle_" + mainIndex + "_" + index,
                        timeCardStatus: "tCard_" + mainIndex + "_" + index,
                        timeCardError: "tError_" + mainIndex + "_" + index,
                        IsVacation: "IsVacation_" + mainIndex + "_" + index,
                        vacationType: "vacationType_" + mainIndex + "_" + index,
                      });
                      index++;
                    }
                    //////console.error(err);.log(totalTime);
                    //////console.error(err);.log(this.datekey,this.dateData);
                  }
                  this.calenderData[mainIndex]["timeCardWeekList"] =
                    this.datekey;
                  //this.temp = "12:12";
                  mainIndex++;
                }
              }
            });
          }
        });
      }

      //end code
    }
  }

  checkApproved() {
    if (
      this.clientApproved == 4 ||
      this.clientApproved == 6 ||
      this.clientApproved == 1
    ) {
      this.isapproveAlready = true;
    } else {
      ////console.error(err);.log("coming in");
      this.isapproveAlready = false;
    }
  }

  /**
   * [Enter Time]
   * @param {[String]} contId
   */
  enterTime(index, subindex) {
    //////console.error(err);.log(index,subindex);
    this.active = index;
    this.subActive = subindex;
  }
  checkHour(i, j, item) {
    if (item) {
      this.dateData["tError_" + i + "_" + j] = -1;
      if (Number(item) > 24) {
        this.dateData["tError_" + i + "_" + j] = i * 10 + j * 1;
        ////console.error(err);.log(this.dateData['tError_'+i+"_"+j], i, j)
        this.msgs = [];
        this.msgs.push({
          severity: "error",
          detail: "kindly enter below 24 hours",
        });
      } else {
        this.addTime(i, j, item);
      }
    }
  }

  addTime(i, j, item) {
    ////console.error(err);.log(i,j,Number(item),Number(this.calenderData));
    // ////console.error(err);.log(this.calenderData[i]['timeCardWeekList']);
    var total = 0;
    for (var k = 0; k < this.calenderData[i]["timeCardWeekList"].length; k++) {
      var time =
        this.dateData[this.calenderData[i]["timeCardWeekList"][k].hrWork];
      if (time) {
        ////console.error(err);.log(total)
        total += Number(time);
      }
      //////console.error(err);.log(k);
      // if(k==j) {
      //     this.calenderData[i]['timeCardWeekList'][k].hrWork=parseInt(item);
      //     this.dateData['hrReg_'+i+"_"+k] = item;
      //     //////console.error(err);.log(this.dateData);

      // }
      // this.totalTime = parseInt(this.dateData['hrReg_'+i+"_"+k])+this.totalTime;
      // ////console.error(err);.log(parseInt(this.dateData['hrReg_'+i+"_"+k]))
      // // if(this.calenderData[i]['timeCardWeekList'][k].timeCardStatus==1) {
      // //     this.totalTime=this.totalTime+parseInt(this.calenderData[i]['timeCardWeekList'][k].hrWork);
      // //     ////console.error(err);.log(this.totalTime);
      // //     //////console.error(err);.log(this.dateData[intem.hrWork]);
      // // }
    }
    this.calenderData[i].weekWorkHr = total;
    this.hoursdays = this.totalRegular / this.dayhours;
    var TotalHourlst = [];
    var Totals = 0;
    for (let i = 0; i <= 6; i++) {
      for (let j = 0; j <= 6; j++) {
        var Timeslot = "hrWrk" + "_" + i + "_" + j;
        var Hour = this.dateData["hrWrk" + "_" + i + "_" + j];
        if (Hour) {
          TotalHourlst.push({ TimeSlot: Timeslot, Hours: Hour });
          Totals += Number(Hour);
        }
      }
    }
    this.totalRegular = Totals.toFixed(2);
  }
  getTotal() {
    let total = 0;
    for (var i = 0; i < this.calenderData.length; i++) {
      if (this.calenderData[i].hrWork) {
        total += this.calenderData[i].hrWork;
        this.totalTime = total;
        //////console.error(err);.log(this.totalTime);
      }
    }
    return total;
  }
  /**
   * [Go back to contract]
   */
  goBack() {
    //this.router.navigate(['/client/home']);
    this.location.back();
  }
  cancel() {
    this.location.back();
  }

  /**
     File Drag and Drop Event
	*/
  public files: UploadFile[] = [];

  public dropped(event: UploadEvent) {
    this.files = event.files;
    ////console.error(err);.log(this.files);
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          ////console.error(err);.log(droppedFile.relativePath, file,file['name']);
          const formData = new FormData();
          formData.append("fileData", file, File["name"]);
          //this.fileData=droppedFile.relativePath;
          ////console.error(err);.log(this.file.fileData);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        ////console.error(err);.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    ////console.error(err);.log(event);
  }

  public fileLeave(event) {
    ////console.error(err);.log(event);
  }
  public uploadDoc() {
    ////console.error(err);.log("Fff");
    var link = document.createElement("input.image");
    this.fileElement.nativeElement.click();
    ////console.error(err);.log(this.fileElement);
  }

  showFile() {
    this.isUpload = true;
    let file = this.fileContent.nativeElement.files[0];
    ////console.error(err);.log(file.name);
    this.uploadFile = file.name;
  }
  /**
   * [Delete File]
   */
  deleteFile() {
    this.isUpload = false;
    this.file.fileData = "";
  }

  /* File to base 64 convertion */
  getBase64(file) {
    ////console.error(err);.log(file);
    this.fileExten = file["type"];
    ////console.error(err);.log(this.fileExten.split('/')[1]);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      ////console.error(err);.log(reader.result);
      return reader.result;
    };

    reader.onerror = function (error) {
      ////console.error(err);.log('Error: ', error);
    };
  }

  allowedFormat(fileExtension) {
    switch (fileExtension) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
      case "image/gif":
        return "Y";
      //blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
      //break;
      case "text/plain":
        return "Y";

      case "application/pdf":
        return "Y";

      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "Y";
      //blob = new Blob([arrBuffer], {type: 'application/msword'});
      //break;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "Y";

      default:
        return "N";
      // break;
    }
  }

  /**
   * [upload description]
   * @param {[FormData]} form
   */
  onUpload(form) {
    ////console.error(err);.log(form);
    ////console.error(err);.log(form._directives);
    for (var i = 0; i < form._directives.length; i++) {
      ////console.error(err);.log(form._directives[i].valid);
      this.error_status = true;
    }

    if (form.valid) {
      //////console.error(err);.log(this.fileContent.nativeElement.files[0]);
      let file = this.fileContent.nativeElement.files[0];
      this.fileExten = file["type"];
      //let format = );
      var outPut = this.allowedFormat(this.fileExten);
      if (outPut == "Y") {
        this.display = false;
        this.isLoading = true;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          //////console.error(err);.log(reader.result);
          var filecontent = reader.result;
          var fileData = filecontent.toString().split(",")[1];
          // ////console.error(err);.log(fileData);

          var PSId = this.cookie.get("_PSId");
          var DocumentExtn = this.fileExten.split("/")[1];
          var DocumentTypeId = this.file.doctype;
          var DocumentName = this.file.docname;
          var ConsultantId = this.cookie.get("_usrId");
          var url =
            "uploadFileWithBase64?userId=" +
            ConsultantId +
            "&fileName=" +
            this.uploadFile;
          this.isUpload = true;
          //this.uploadFile= DocumentName;

          let data = "input=" + encodeURIComponent(JSON.stringify(fileData));
          var form = new FormData();
          form.append("input", fileData);
          this.http.postBackMethod(url, data, true, false).subscribe(
            (data) => {
              //////console.error(err);.log(data,data['response']['fileName']);

              ////console.error(err);.log(data['status']=='Y');
              if (data["status"] == "Y") {
                this.file.fileData = "";
                this.isFile = true;
                this.display = false;
                this.docName = data["response"]["fileName"];
                this.docId = data["response"]["docId"];
                ////console.error(err);.log(this.docName,this.docId);
                this.msgs = [];
                this.msgs.push({
                  severity: "success",
                  detail: "Document uploaded successfully",
                });
                //this.router.navigate(['/contractor/contracts']);
                this.attachedDocId = data["response"]["docId"];
                this.attachedDocname = data["response"]["fileName"];
                if (this.attachedDocId) {
                  this.hadAttach = true;
                }
              } else {
                this.msgs = [];
                this.msgs.push({ severity: "error", detail: data["message"] });
              }

              //this.isLoading = true;
            },
            (err) => {},
            () => {
              this.isLoading = false;
            }
          );
        };
        reader.onerror = function (error) {
          ////console.error(err);.log('Error: ', error);
        };
      } else {
        this.display = true;
        this.isUpload = false;
        this.file.fileData = "";
        this.error_status = true;
      }
    }
  }
  /********* Upload attachment to  Time sheet **********/
  uploadAttachment() {
    ////console.error(err);.log("ddd");
    this.display = true;
  }

  /**
   * [Convert base64 To ArrayBuffer]
   * @param {[Object]} data
   */
  base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  /********
   ** Delete Soft Old attachment
   **/
  deleteSoftAttach() {
    this.oldDocId = this.docId;
    this.oldDocName = this.docName;
    this.hadAttach = false;
    this.deleterFlag = true;
  }

  /********
   ** Delete Old attachment
   **/
  deleteOldAttach() {
    ////console.error(err);.log("Going to Delete .. He hehe")
    this.isLoading = true;
    let url =
      "removeAttachment?userId=" +
      this.buserId +
      "&workContractId=" +
      this.wrkContId +
      "&targetDateStr=" +
      this.startDate;
    var data = "";
    //////console.error(err);.log(date);
    this.http.postBackMethod(url, data, true, true).subscribe(
      (data) => {
        if (data["status"] == "Y") {
          ////console.error(err);.log(data);
          ////console.error(err);.log(data['status']=='Y');
          console.error(this.docId);
          if (this.docId == this.oldDocId) {
            this.isFile = false;
          }
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: "Document deleted successfully !!!",
          });
          //this.hadAttach = false;
          this.saveTimesheet();
        }
        //this.isLoading = true;
      },
      (
          err //console.error(err);.error(err),
        ) =>
        () => {
          this.isLoading = false;
        }
    );
  }

  /*** Cycle Rotator ***/
  rotateCycle(date) {
    this.isLoading = true;

    let url =
      "getTimesheetDetailByTargetDate?userId=" +
      this.userId +
      "&workContractId=" +
      this.wrkContId +
      "&targetDateStr=" +
      date;
    var data = "";
    ////console.error(err);.log(date);
    this.http.postBackMethod(url, data, true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        ////console.error(err);.log(data['status']=='Y');
        this.dataLoader(data);

        //this.isLoading = true;
      },
      (err) => {},
      () => {
        this.isLoading = false;
      }
    );
  }

  /********* Save Time sheet **********/

  acceptTimesheet() {
    debugger;
    var url;

    url =
      "approveTimesheet?userId=" +
      this.buserId +
      "&invoiceContractorId=" +
      this.invoiceContractorId +
      "&note=" +
      this.note;

    ////console.error(err);.log(url);
    var data = "";
    this.isLoading = true;
    this.http.postMethod(url, data, true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        if (data["status"] == "Y") {
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: "Timesheet approved Successfully",
          });
          //this.router.navigate(['/client/home']);
          setTimeout(() => this.location.back(), 3000);
        } else if (data["status"] == "N") {
          this.msgs = [];
          this.msgs.push({ severity: "error", detail: data["message"] });
          //this.router.navigate(['/client/home']);
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: "error",
            detail: "Please try again later",
          });
        }
      },
      (err) => {
        //console.error(err);.error(err);
        this.msgs = [];
        this.msgs.push({ severity: "error", detail: "Server Side Failure" });
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  /******** Print Time sheet ********/
  printTimesheet() {}
  /******** Download Time sheet ********/
  downTimesheet() {}

  downDoc(docId) {
    var url = "downAttachemntInfo?userId=" + this.buserId + "&docId=" + docId;
    //////console.error(err);.log(datamain,"ggg");
    this.isLoading = true;
    //var arrBuffer = this.base64ToArrayBuffer(data['document']);
    var blob;
    this.http.postMethod(url, "", true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        if (data["status"] == "Y") {
          var arrBuffer = this.base64ToArrayBuffer(
            data["response"]["contentBase64"]
          );
          var filename = data["response"]["fileName"];          
          var fileExtension= filename.substr(filename.lastIndexOf(".") + 1);
          ////console.error(err);.log(fileExtension);

          switch (fileExtension) {
            case "png":
            case "jpeg":
            case "jpg":
            case "PNG":
            case "JPG":
            case "JPEG":
            case "gif":
            case "GIF":
              blob = new Blob([arrBuffer], { type: "image/" + fileExtension });
              break;
            case "pdf":
            case "PDF":
              blob = new Blob([arrBuffer], {
                type: "application/" + fileExtension,
              });
              break;
            case "txt":
              blob = new Blob([arrBuffer], { type: "text/plain" });
              break;
            case "doc":
            case "DOC":
              ////console.error(err);.log("click");
              blob = new Blob([arrBuffer], { type: "application/msword" });
              break;
            case "docx":
            case "DOCX":
              ////console.error(err);.log("click");
              blob = new Blob([arrBuffer], {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              });
              break;
            case "xls":
            case "XLS":
              //console.log("click");
              blob = new Blob([arrBuffer], {
                type: "application/vnd.ms-excel",
              });
              break;
            case "xlsx":
            case "XLSX":
              //console.log("fileExtension");
              ////console.error(err);.log("click");
              blob = new Blob([arrBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              break;
            case "zip":
            case "ZIP":
              blob = new Blob([arrBuffer], {
                type: "application/x-zip-compressed",
              });
              break;
            case "eml":
            case "EML":
              blob = new Blob([arrBuffer], { type: "message/rfc822" });
              break;
            case "msg":
            case "MSG":
              blob = new Blob([arrBuffer], {
                type: "application/vnd.ms-outlook",
              });
              break;
            case "mbox":
            case "MBOX":
              blob = new Blob([arrBuffer], {
                type: "application/vnd.ms-outlook",
              });
              break;

            default:
            //$('#divFiles').text('File type: Unknown');
          }

          var url;
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            window.open(window.URL.createObjectURL(blob), "_blank");
          } else {
            url = window.URL.createObjectURL(blob);
            //window.open(objectUrl);
          }
          var link = document.createElement("a");
          link.href = url;
          link.download = filename;
          link.click();
          setTimeout(function () {
            //document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          }, 100);
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: filename.split(".")[0] + " Downloaded Successfully",
          });
          this.isLoading = false;
        } else {
          this.msgs = [];
          this.msgs.push({ severity: "error", detail: data["Message"] });
        }
        //this.isLoading = true;
      },
      (err) => {},
      () => {
        this.isLoading = false;
      }
    );
  }
  /********
     Decline Time sheet
    ********/
  declineTimesheet() {
    if (this.note != null || this.note != "" || this.note != undefined) {
      var notesDetails = encodeURIComponent(this.note);
    } else {
      notesDetails = "";
    }
    var url;

    //url = "rejectTimesheet?userId="+this.buserId+"&invoiceContractorId="+this.invoiceContractorId+"&note=Rejected this timesheet"+"&appCode=3";
    url =
      "rejectTimesheet?userId=" +
      this.buserId +
      "&invoiceContractorId=" +
      this.invoiceContractorId +
      "&note=" +
      notesDetails +
      "&appCode=3";

    ////console.error(err);.log(url);
    var data = "";
    this.isLoading = true;
    this.http.postMethod(url, data, true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        if (data["status"] == "Y") {
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: "Timesheet rejected Successfully",
          });
          //this.router.navigate(['/client/home']);
          setTimeout(() => this.location.back(), 3000);
          //setTimeout(() => this.router.navigate(['/client/home']), 3000)
        } else if (data["status"] == "N") {
          this.msgs = [];
          this.msgs.push({ severity: "error", detail: data["message"] });
          //this.router.navigate(['/client/home']);
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: "error",
            detail: "Please try again later",
          });
        }
      },
      (err) => {
        //console.error(err);.error(err);
        this.msgs = [];
        this.msgs.push({ severity: "error", detail: "Server Side Failure" });
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }
  downloadtimesheetpdf() {
    document.getElementById("pdfTable").style.display = "block";
    var element = document.getElementById("pdfTable");
    var opt = {
      margin: 1,
      filename: "timesheet.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1, dpi: 192, letterRendering: true },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };

    // New Promise-based usage:
    html2pdf()
      .set(opt)
      .from(element)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var total = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= total; i++) {
          pdf.setPage(i);
          pdf.setFontSize(10);
          pdf.setTextColor(50);
          pdf.text(
            "Page " + i + " of " + total,
            pdf.internal.pageSize.getWidth() / 1.1,
            pdf.internal.pageSize.getHeight() - 1.5
          );
        }
        console.log(total);
      })
      .save();
    setTimeout(function () {
      document.getElementById("pdfTable").style.display = "none";
    }, 50);
  }
  // downloadtimesheetpdf():void {

  //   document.getElementById('pdfTable').style.visibility="visible";

  //   const doc = new jsPDF('p','mm',[500,400]);
  //   var test={
  //     'background': '#FFFFFF',
  //     'color':'black'
  //   }

  // doc.addHTML(document.getElementById('pdfTable'),test, function() {
  //   doc.save('timesheet.pdf');

  // });
  // setTimeout(function()  {
  //   document.getElementById('pdfTable').style.visibility="hidden";
  // }, 50);

  // }
  calculaterhours() {
    let totalhours;
    let grandtotal;
    var lstCalendar = this.calenderData;
    if (lstCalendar != null) {
      for (let i = 0; i < lstCalendar.length; i++) {
        for (let j = 0; j < lstCalendar[i].timeCardWeekList.length; j++) {
          totalhours =
            parseFloat(lstCalendar[i].timeCardWeekList[j].hrWork) +
            parseFloat(lstCalendar[i].timeCardWeekList[j].hrOvertime);
          if (
            lstCalendar[i].timeCardWeekList[j].isCycle == "true" &&
            lstCalendar[i].timeCardWeekList[j].note != undefined
          ) {
            var daynotes = lstCalendar[i].timeCardWeekList[j].note;
          } else {
            daynotes = "";
          }
          this.downloadpdfhours.push({
            daynotes: daynotes,
            calendarDay: lstCalendar[i].timeCardWeekList[j].calendarDay,
            hrReg: lstCalendar[i].timeCardWeekList[j].hrWork,
            hrOvertime: lstCalendar[i].timeCardWeekList[j].hrOvertime,
            Totalhours: totalhours.toFixed(2),
            GrandTotal: grandtotal,
          });
        }
      }
      const list = this.downloadpdfhours;
      const firstHalf = list.slice(0, 20);
      const secondHalf = list.slice(20, 50);
      this.downloadpdfhours1 = firstHalf;
      this.downloadpdfhours2 = secondHalf;
      if (this.downloadpdfhours2.length == 0) {
        this.totalamount1 = true;
        this.pdfdiv2 = false;
      } else {
        this.totalamount2 = true;
        this.pdfdiv2 = true;
      }
    }
    grandtotal = this.totalRegular;

    //this.grandtotelhours=grandtotal.toFixed(2)
  }

  /********* 
    Save Time sheet 
    **********/

  saveTimesheet() {
    debugger;
    ////console.error(err);.log(this.startDate);
    let now = moment(this.startDate); // add this 2 of 4
    this.startDate = now.format("DD-MMM-YYYY");
    var queryString = Object.keys(this.dateData).map((key) => {
      let selKey = key.split("_");

      if (selKey[0] != "hrWrk" || this.dateData[key] == undefined) {
        return;
      } else {
        ////console.error(err);.log(this.dateData[key]);
        let testChar = ":";
        // if(this.dateData[key].indexOf(testChar) != -1) {

        // var val1 = this.dateData[key].split(':')[0];
        // var val2 = this.dateData[key].split(':')[1];
        // this.dateData[key] = val1+"."+val2;
        // } else {
        this.dateData[key] = this.dateData[key];
        //}
      }
      return "hours" + "=" + this.dateData[key];
    });

    ////console.error(err);.log(queryString);
    let hourStr = queryString.filter(Boolean).join("&");
    if (hourStr == "&" || hourStr == "undefined" || hourStr == "") {
      hourStr = "&hours=";
    }
    var url;
    if (this.note != null) {
      var NoteDetails = this.note.trim().replace(/\s\s+/g, " ");
      this.Notes = encodeURIComponent(NoteDetails);
    }
    ////console.error(err);.log(hourStr);
    if (this.note == "") {
      // this.note="";
      this.Notes = "";
    }

    if (!this.isFile) {
      url =
        "saveTimesheet?userId=" +
        this.buserId +
        "&workContractId=" +
        this.wrkContId +
        "&targetDateStr=" +
        this.startDate +
        "&note=" +
        this.Notes +
        "&" +
        hourStr +
        "&appCode=3";
      // url = "saveTimesheet?userId="+this.buserId+"&workContractId="+this.wrkContId+"&targetDateStr="+this.startDate+"&note="+this.note+"&"+hourStr+"&docName=image1543638424.jpg&docId=777857";
    } else {
      url =
        "saveTimesheet?userId=" +
        this.buserId +
        "&workContractId=" +
        this.wrkContId +
        "&targetDateStr=" +
        this.startDate +
        "&note=" +
        this.Notes +
        "&" +
        hourStr +
        "&docName=" +
        this.docName +
        "&docId=" +
        this.docId +
        "&appCode=3";
    }

    ////console.error(err);.log(url);
    var data = "";
    this.isLoading = true;
    this.http.postMethod(url, data, true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        if (data["status"] == "Y") {
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: "Timesheet updated Successfully",
          });
          let olderUrl = this.cookie.get("_previousUrl");
          //this.router.navigate([olderUrl]);
          setTimeout(() => this.location.back(), 3000);
        } else if (data["status"] == "N") {
          this.msgs = [];
          this.msgs.push({ severity: "error", detail: data["message"] });
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: "error",
            detail: "Please try again later",
          });
        }
      },
      (err) => {
        //console.error(err);.error(err);
        this.msgs = [];
        this.msgs.push({ severity: "error", detail: "Server Side Failure" });
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  /*********
     Submit Time sheet for Employee Contractor 
    **********/
  submitInvoice() {
    ////console.error(err);.log(this.startDate);
    let now = moment(this.startDate); // add this 2 of 4
    this.startDate = now.format("DD-MMM-YYYY");
    var queryString = Object.keys(this.dateData).map((key) => {
      let selKey = key.split("_");

      if (selKey[0] != "hrWrk" || this.dateData[key] == undefined) {
        return;
      } else {
        ////console.error(err);.log(this.dateData[key]);
        let testChar = ":";
        // if(this.dateData[key].indexOf(testChar) != -1) {

        // var val1 = this.dateData[key].split(':')[0];
        // var val2 = this.dateData[key].split(':')[1];
        // this.dateData[key] = val1+"."+val2;
        // } else {
        this.dateData[key] = this.dateData[key];
        //}
      }
      return "hours" + "=" + this.dateData[key];
    });
    //////console.error(err);.log(queryString);
    //////console.error(err);.log(queryString.join('&'));
    let hourStr = queryString.filter(Boolean).join("&");
    if (hourStr == "&") {
      hourStr = "hours=''";
    }
    if (this.note != null) {
      var NoteDetails = this.note.trim().replace(/\s\s+/g, " ");
      this.Notes = encodeURIComponent(NoteDetails);
    }
    var url;
    if (!this.isFile) {
      url =
        "submitTimesheet?userId=" +
        this.buserId +
        "&workContractId=" +
        this.wrkContId +
        "&targetDateStr=" +
        this.startDate +
        "&note=" +
        this.Notes +
        "&" +
        hourStr +
        "&appCode=3";
    } else {
      url =
        "submitTimesheet?userId=" +
        this.buserId +
        "&workContractId=" +
        this.wrkContId +
        "&targetDateStr=" +
        this.startDate +
        "&note=" +
        this.Notes +
        "&" +
        hourStr +
        "&docId=" +
        this.docId +
        "&docName=" +
        this.docName +
        "&appCode=3";
    }

    ////console.error(err);.log(url);
    var data = "";
    this.isLoading = true;
    this.http.postMethod(url, data, true, true).subscribe(
      (data) => {
        ////console.error(err);.log(data);
        if (data["status"] == "Y") {
          this.msgs = [];
          this.msgs.push({
            severity: "success",
            detail: "Timesheet submitted Successfully",
          });
          //this.router.navigate(['/client/timesheet_list']);
          setTimeout(() => this.location.back(), 3000);
        } else {
          this.msgs = [];
          this.msgs.push({
            severity: "error",
            detail: "Please try again later",
          });
        }
      },
      (err) => {
        //console.error(err);.error(err);
        this.msgs = [];
        this.msgs.push({ severity: "error", detail: "Server Side Failure" });
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  disMisspopUp() {
    // ////console.error(err);.log("hi");
    this.display = false;
    this.isUpload = false;
    //this.uploadForm.resetForm();
    this.file.fileData = "";
  }

  dateCheck(from, to, check) {
    var fDate, lDate, cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if (cDate <= lDate && cDate >= fDate) {
      return true;
    }
    return false;
  }
  public removeBrTag(side: string): string {
    return side.replace(/<br\s*\/?>/gi, ",");
  }
  GetJsonData() {
    this.http.GetClientJsonData().subscribe((res) => {
      this.backId = this.cookie.get("_busrId");
      this.puserId = this.cookie.get("_puserId");

      this.LandingPageDetails = res;

      const hideBankingInfo =
        this.LandingPageDetails[0].IsUnitsAndContractNumber;
      if (this.backId) {
        if (hideBankingInfo == undefined) {
          this.hidecontractunit = false;
        }
        this.backOfficemenu = this.cookie.getObject("backOfficemenu");
        const registerDomainId =
          this.result.response.identityBean.registeredMVDomain;
        this.BOCompanyList = hideBankingInfo[0].BOCompanyIDs;
        var isBOCompanyExists = this.BOCompanyList.indexOf(registerDomainId);
        for (let i = 0; i < this.BOCompanyList.length; i++) {
          if (this.BOCompanyList[i] == registerDomainId) {
            this.hidecontractunit = true;
          } else {
            this.hidecontractunit = false;
          }
        }

        if (registerDomainId == "34551" || registerDomainId == "2450") {
          this.hideindays = true;
        } else {
          this.hideindays = false;
        }
      }
    });
  }
  GetGrandTotal() {
    var total = 0;
    for (let i = 0; i < this.downloadpdfhours.length; i++) {
      if (!isNaN(this.downloadpdfhours[i].Totalhours)) {
        total += Number(this.downloadpdfhours[i].Totalhours);
      }
    }
    return total.toFixed(2);
  }
  GetWorkUnitDetails() {
    let preData = this.session.getContracts();
    let userId = preData["params"]["userId"];
    let workContractId =
      this.result.response.invoiceContractor["workContractId"];

    var data;
    var url =
      "getContractorMatrix?userId=" +
      userId +
      "&workContractorId=" +
      workContractId +
      "&invEndDtStr=" +
      this.workcontractendDate;
    this.http.postMethod(url, data, true, true).subscribe((data) => {
      if (data["status"] == "Y") {
        this.totalunits =
          parseFloat(data["response"]["openWorkContractUnit"]) +
          parseFloat(data["response"]["workContractUnit"]);
          if(data["response"]["remainWorkContractUnit"]!=undefined){
            let value=data["response"]["remainWorkContractUnit"]
            this.remainWorkContractUnit = Math.abs(value);
          }
          else{
            this.remainWorkContractUnit=0
          }
        
        this.usedWorkContractUnit = data["response"]["usedWorkContractUnit"];
        this.dayhours = data["response"]["hoursDay"];
        if (this.dayhours) {
          this.hoursdays = this.totalRegular / this.dayhours;
          var hours = this.hoursdays;
          this.hoursdays = hours;
        }
      }
    });
  }
  addNotes(i, j, calanderday) {
    this.notespoup = true;
    this.daynote = this.dateData[this.calenderData[i].timeCardWeekList[j].note];
    var startDate = this.startDate.substring(this.startDate.length - 4);

    this.calanderday =
      this.dateData[this.calenderData[i].timeCardWeekList[j].day];
    var day1 = calanderday.substring(0, 3);
    var day2 = calanderday.substring(calanderday.length - 2);

    this.calanderday = day2 + "-" + day1 + "-" + startDate;
  }
  closenotes() {
    this.notespoup = false;
  }
}
