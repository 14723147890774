//Angular modules list
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

//Custom modules list
import { ClientModule } from './client/client.module';
import { ContractorModule } from './contractor/contractor.module';
import { AccountModule } from './account/account.module';

//Components list
import { SignComponent } from './sign/sign.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NoAccessComponent } from './no-access/no-access.component';
import { VerifyComponent } from './verify/verify.component';

// Authentication guard list
import { ClientAuthGuard, ContractorAuthGuard, LoginAuthGuard, AccountEmpGuard } from './shared/services/auth-guard.service'; 

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forRoot([
            {     
                path: '',
                canActivate: [LoginAuthGuard],  
                component: SignComponent 
            },
            { 
                path:'client',
                canActivate: [ClientAuthGuard], 
                // Loading only when user access client section 
                loadChildren: './client/client.module#ClientModule' 
            },
            { 
                path:'contractor',
                canActivate: [ContractorAuthGuard], 
                // Loading only when user access contractor section  
                loadChildren: './contractor/contractor.module#ContractorModule'
            },{     
                path: 'account',
                //canActivate: [LoginAuthGuard],  
                // Loading only when user access client section and contractor section
                loadChildren: './account/account.module#AccountModule' 
            },{ 
                path:'verify', 
                // Account verification page
                component: VerifyComponent 
            },
            { 
                path:'restricted', 
                // No access page
                component: NoAccessComponent 
            },
            { 
                path:'**', 
                // Not found page
                component: NotFoundComponent 
            }
            
            // {
            //  path: 'careers',
            //  redirectTo: 'https://www.brainhunter.com/EN/JobseekerCan.html'
            //  // canActivate: [RedirectGuard],
            //  // data: {
            //  //   externalUrl: 'https://www.brainhunter.com/EN/JobseekerCan.html'
            //  // }
            // }
        ])
    ],
})

export class AppRoutingModule {}