import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import { trigger,  state, style, animate, transition } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Message } from 'primeng/api';
import { bounce } from 'ng-animate';
import * as moment from 'moment';
import { HttpService } from './../../shared/services/http.service';
import { InvokedataService } from './../../shared/services/invokedata.service';
import { SessionService } from './../../shared/services/sessiontoken.service';
import { filter } from 'rxjs-compat/operator/filter';
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
         	trigger('heroState', [
		    state('active',   style({transform: 'translateX(0%) scale(1.1)'})),
		    state('inactive',   style({transform: 'translateX(+11000%) scale(1)'})),
		    transition('inactive => active', animate('100ms ease-in')),
		    transition('active => inactive', animate('100ms ease-out')),
		    transition('void => inactive', [
		      style({transform: 'translateX(-100%) scale(1)'}),
		      animate(300)
		    ]),
		    transition('inactive => void', [
		      animate(300, style({transform: 'translateX(1100%) scale(1)'}))
		    ]),
		    transition('void => active', [
		      style({transform: 'translateX(0) scale(0)'}),
		      animate(300)
		    ]),
		    transition('active => void', [
		      animate(300, style({transform: 'translateX(0) scale(0)'}))
		  ])
   ])
   ]
})
export class ReportComponent implements OnInit {
	isBackoffice = false;
	isHeader = true;
	isUpload = false;
    isEmpty = true;   
	class = "";
	isContract = true;
	msgs: Message[] = [];
	isLoading: boolean = false;
	userId;
	backUserid;
	timesheetList;
	
	multiTimesheetList;
	result;
	dataresult
	multitimesheet
	bounce: any;
	filterShow :boolean = false;
	approver :boolean;
	approver1 :boolean;
	name: string;
	state = 'inactive';
	report= {
		typeTimesheet:true,
		trackingId:'',
		endDate:'',
		startDate:'',
		typeExpense: true
	};
	endDate;
	startDate;
	trackingId;
	statusList = [];
	filData ={
		typeExpense: "Y",
		typeTimesheet:"Y"
	}
	timesheet_status: boolean = false;
	expense_status: boolean = false;
	p: number = 1;
	k: number = 1;
    collection: any[];
    pageSize =7; 
    total;
    userType;
    buserId;
    isOld;
    isContractor: boolean = false;
    preselStatusList = [];
	myHeadCompanyId;
	workContractList;
	lstContractName=[];
	Resulsts: string[];
	ContractName;
	multiapprover: string;
	resultmulti;
	expenseFlag: boolean;
	
	constructor(private data:InvokedataService, private http:HttpService, private session:SessionService, private cookie:CookieService, private route:ActivatedRoute, private router:Router, private location: Location,private Datepipe:DatePipe) { }

	ngOnInit() { 
		debugger
		
		window.document.title= "Reports - Connect ";
		this.buserId = this.cookie.get('_busrId');
		this.multitimesheet = this.cookie.get('_projectModel');
		this.myHeadCompanyId = this.cookie.get('_myHeadCompanyId');
		this.multiapprover = this.cookie.get('_ApproverProjectModel');
		//console.error(err);.log(this.buserId);
		let contractor = Number(this.cookie.get('_usrtyd'));
		if(contractor == 500) {
			this.isContractor = true;
			
		}
		if(this.buserId) {
			
			    //console.error(err);.log(this.buserId);
				this.isOld = true;
				// var filterObj = this.data.getReportsFilter();
				// //console.error(err);.log(filterObj);
				// // this.report = filterObj.report;
				// // this.preselStatusList = filterObj.statusList;
				// filterObj.passedVal.typeExpense="Y"
				// filterObj.passedVal.typeTimesheet="Y"
				
				if(this.multitimesheet=="M" ){
this.getMultiTimesheetReportdata(this.filData);
				}
				else if(this.multiapprover=="M"){
				this.getApproverMultiTimesheetReportdata(this.filData)
				}
				else if(this.multiapprover=="S" ||this.multiapprover==undefined || this.multiapprover=="T" ){
					this.getReportdata(this.filData);
				}
				
				
				
				this.isEmpty = false;
			} else {
				this.isOld = false;
				this.isEmpty = true;
			}
		//
		this.myHeadCompanyId = this.cookie.get('_myHeadCompanyId');
		//this.GetVacationReport();
		//get the workcontract list
		if(this.backUserid){
		var url = "getWorkContractListForVacation?userId=" + this.backUserid;
		var data = "";
		this.http.postMethod(url, data, true, true).subscribe(
		  data => {
			this.workContractList = data['response']['list']
			if (this.workContractList.length > 0) {
			  for (let i = 0; i < this.workContractList.length; i++) {
				this.lstContractName.push(this.workContractList[i].createdByName)
			  }
			}
		  });
		}
		//
	}
	searchContractname(event) {
		this.Resulsts = this.lstContractName
		  .filter(data => data.toString()
			.toLowerCase()
			.indexOf(event.query.toString().toLowerCase()) !== -1);
	  }
	getReportdata(filData){
		
		this.filData = filData;
		this.isLoading = true;
		this.userId = this.cookie.get('_usrId');
		this.backUserid = this.cookie.get('_busrId'); 
		this.isHeader = true;
		let jsonSearchTimesheetVOStr= this.filData;
		var url = "searchTimesheet?userId="+this.backUserid+"&jsonSearchTimesheetVOStr="+encodeURIComponent(JSON.stringify(jsonSearchTimesheetVOStr));
		//searchTimesheet?userId=33831&jsonSearchTimesheetVOStr= { "typeExpense":"Y", "typeTimesheet":"Y" } 
		var data = "";
	 	this.http.postMethod(url, data,true,true).subscribe(
	    data => {
	    	if(data['status']=='Y') {
	    		this.pageChanged(1);
	    		this.timesheetList = data['response']['timesheetList'];
	    		this.collection = this.timesheetList;
	    		for(var i = 0; i < this.timesheetList.length; i++) {
	    			let now = moment(this.timesheetList[i].invStartDt); // add this 2 of 4
				this.timesheetList[i].invStartDt=now.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invStartDt);
				let to = moment(this.timesheetList[i].invEndDt); // add this 2 of 4
				this.timesheetList[i].invEndDt=to.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invEndDt);
	    		}
	    		this.total=this.collection.length;
	    		var statusList = data['response']['statusList'];
	    		for(var i = 0; i < statusList.length; i++) {
	    			for(var j = 0; j < this.preselStatusList.length; j++) {

	    				if(statusList[i].desc == this.preselStatusList[j].desc && this.preselStatusList[j].checked) {
	    					statusList[i].checked = true;
	    				}
	    			}
	    		} 
	    		this.statusList = statusList;
	    		////console.error(err);.log(this.preselStatusList, this.statusList );
	    		this.userType =  data['response']['identityBean']['userTypeId'];
	    	}
			
			var Opentimesheet=this.timesheetList.filter(x=>x.status=='OPEN')
			var vendartimesheet=this.timesheetList.filter(x=>x.status=='VENDOR_APPROVED')
			var clienttimesheet=this.timesheetList.filter(x=>x.status=='CLIENT_APPROVED')
			var invoicetimesheet=this.timesheetList.filter(x=>x.status=='INVOICED')
		Opentimesheet=Opentimesheet.concat(vendartimesheet,clienttimesheet,invoicetimesheet)
		this.timesheetList=Opentimesheet
			var timesheetList=this.timesheetList.filter(x=>x.expenseFlag=='Y')
			if(timesheetList.length!=0){
				this.expenseFlag=true
			}
			else{
				this.expenseFlag=false
			}
	    	//this.listDoc= data['PSDocuments'];
	    	//this.uploadDoc= data['ConsultantDocuments'];
	    	//this.uploadDoc.Createdate =filecontent.split(',')[1]
	    	
	    //this.isLoading = true;
	    },err => {},
	    () => {this.isLoading = false;
	});
	
	}
	goBack() {
		this.location.back();
	}
	showFilter() {
		this.filterShow=true;
	}
	toggleState() {
     this.state = this.state === 'active' ? 'inactive' : 'active';
  	}
  	pageChanged(event) {
		
  		////console.error(err);.log(event);
  		this.p=event;
		
		
  	}
	  pageChangedmulti(event) {
		
		this.p=event;
		
		const res = [];
			var chunkSize=7
			for (let i = 0; i < this.multiTimesheetList.length; i += chunkSize) {
				const chunk = this.multiTimesheetList.slice(i, i + chunkSize);
				res.push(chunk);
			}
			
			

	}
	pageChangedapprovermulti(event) {
		this.p=event;
		const res = [];
			var chunkSize=7
			for (let i = 0; i < this.resultmulti.length; i += chunkSize) {
				const chunk = this.resultmulti.slice(i, i + chunkSize);
				res.push(chunk);
			}

	}
  	selectedOptions() { // right now: ['1','3']
    	return this.statusList
              .filter(opt => opt.checked)
              .map(opt => opt.typeCode.toString())
  	}
  	onFilter(form) {
		  
  		//////console.error(err);.log(form);
  	
  		////console.error(err);.log(this.report);
  		var dataTopass={ };
  		//dataTopass['typeTimesheet']="N";
  		//dataTopass['typeExpense']="N";
  		if(this.report['typeTimesheet']==true) {
  			dataTopass['typeTimesheet']="Y";
  		} 
  		if(this.report['typeExpense']==true) {
  			dataTopass['typeExpense']="Y";
  		} 
  		// for(var value in this.report) {
  		// 	////console.error(err);.log(value, this.report[value]);
  		// 	if(this.report[value]==true) {
  		// 		dataTopass[value]="Y";
  		// 	}
  		// 	else if(this.report[value]==false){
  		// 		dataTopass[value]="N";
  		// 	}
  		// }
  		////console.error(err);.log(this.report);
  		dataTopass['selectedStatusCodes']= this.selectedOptions();
  		if(this.report['endDate']!='') {
  		var endate = moment(this.report['endDate']); 
    	dataTopass['endDateStr'] = endate.format('DD-MMM-YYYY');
    	} else {
    		dataTopass['endDateStr']="";
    	}
    	if(this.report['startDate']!='') {
    	var stdate = moment(this.report['startDate']); 
    	dataTopass['startDateStr'] = stdate.format('DD-MMM-YYYY');
    	} else {
    		dataTopass['startDateStr']="";
    	}
		
		if(this.ContractName!=null){
			var contractNames=  this.ContractName.split(" ");
			if(contractNames.length > 1){
				dataTopass['contractLastName']=contractNames[1];
				dataTopass['contractFirstName']=contractNames[0];
			}
			else{
				dataTopass['contractFirstName']=contractNames[0];
				dataTopass['contractLastName']="";
			}
		}
    	this.preselStatusList = this.statusList;
		
    	this.data.setReportsFilter(dataTopass, this.report, this.statusList);
    	////console.error(err);.log(JSON.stringify(dataTopass));
		if(this.multitimesheet=="M"){
			this.getMultiTimesheetReportdata(dataTopass)
			form.resetForm();
			
		}
		else if( this.multiapprover=="M"){

form.resetForm();
		}
		else if( this.multiapprover=="S"){

			form.resetForm();
					}
		else{
			this.getReportdata(dataTopass);
			form.resetForm();
		}
		
		//
		this.toggleState();
		
  	}
	  getMultiTimesheetReportdata(filData){
		
	
		this.filData = filData;
		this.isLoading = true;
		this.userId = this.cookie.get('_usrId');
		this.backUserid = this.cookie.get('_busrId'); 
		this.isHeader = true;
		let jsonSearchTimesheetVOStr= this.filData;
		var url = "searchTimesheet?userId="+this.backUserid+"&jsonSearchTimesheetVOStr="+encodeURIComponent(JSON.stringify(jsonSearchTimesheetVOStr));
		//searchTimesheet?userId=33831&jsonSearchTimesheetVOStr= { "typeExpense":"Y", "typeTimesheet":"Y" } 
		var data = "";
	 	this.http.postMethod(url, data,true,true).subscribe(
	    data => {
	    	if(data['status']=='Y') {
				
	    		
	    		this.dataresult = data['response']['timesheetList'];
	    		this.collection = this.dataresult;
	    		for(var i = 0; i < this.dataresult.length; i++) {
	    		let now = moment(this.dataresult[i].invStartDt); // add this 2 of 4
				this.dataresult[i].invStartDt=now.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invStartDt);
				let to = moment(this.dataresult[i].invEndDt); // add this 2 of 4
				this.dataresult[i].invEndDt=to.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invEndDt);
	    	
				if(this.dataresult[i].unit=="MONTH"){
					this.dataresult[i].unit="Monthly"	
				}
				else if(this.dataresult[i].unit=="1/2-MONTH"){
					this.dataresult[i].unit="Semi-Monthly"	
				}
				else if(this.dataresult[i].unit=="2-WEEK"){
					this.dataresult[i].unit="Bi-Weekly"	
				}
				else if(this.dataresult[i].unit=="WEEK"){
					this.dataresult[i].unit="Weekly"	
				}
			}
				
				sessionStorage.setItem('_projectslist',JSON.stringify (this.dataresult));
				this.timesheetList = data['response']['timesheetList'];
	    		
	    		for(var i = 0; i < this.timesheetList.length; i++) {
	    			let now = moment(this.timesheetList[i].invStartDt); // add this 2 of 4
				this.timesheetList[i].invStartDt=now.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invStartDt);
				let to = moment(this.timesheetList[i].invEndDt); // add this 2 of 4
				this.timesheetList[i].invEndDt=to.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invEndDt);
	    		}
				var Opentimesheet=this.dataresult.filter(x=>x.status=='OPEN')
			var vendartimesheet=this.dataresult.filter(x=>x.status=='VENDOR_APPROVED')
			var clienttimesheet=this.dataresult.filter(x=>x.status=='CLIENT_APPROVED')
			var invoicetimesheet=this.dataresult.filter(x=>x.status=='INVOICED')
		Opentimesheet=Opentimesheet.concat(vendartimesheet,clienttimesheet,invoicetimesheet)
		this.dataresult=Opentimesheet
	    		var timesheetList=this.timesheetList.filter(x=>x.expenseFlag=='Y')
			if(timesheetList.length!=0){
				this.expenseFlag=true
			}
			else{
				this.expenseFlag=false
			}
				
	    		var statusList = data['response']['statusList'];
	    		for(var i = 0; i < statusList.length; i++) {
	    			for(var j = 0; j < this.preselStatusList.length; j++) {

	    				if(statusList[i].desc == this.preselStatusList[j].desc && this.preselStatusList[j].checked) {
	    					statusList[i].checked = true;
	    				}
	    			}
	    		} 
			
				const groupedunitCollection = this.dataresult.reduce((previous, current)=> {
					////console.error(err);.log(previous, current)
				 if(!previous[current['groupBy']]) {
					 previous[current['groupBy']] = [current];
				 } else {
					 previous[current['groupBy']].push(current);
				 }
				 
				 return previous;
		 }, {});
				

		
		 
		 this.multiTimesheetList=Object.keys(groupedunitCollection).map(key => ({ value: groupedunitCollection[key] }));

		 ;
		
		
		for(let i=0;i<this.dataresult.length;i++){
			if(this.dataresult[i].expenseFlag=='Y'){
				this.multiTimesheetList.push({value:this.dataresult[i]})
			}
		}
		
		this.total=this.multiTimesheetList.length
		 
			
		
		
		
		
		 this.statusList = statusList;
	    		////console.error(err);.log(this.preselStatusList, this.statusList );
	    		this.userType =  data['response']['identityBean']['userTypeId'];
	    	}
	    	//this.listDoc= data['PSDocuments'];
	    	//this.uploadDoc= data['ConsultantDocuments'];
	    	//this.uploadDoc.Createdate =filecontent.split(',')[1]
	    	
	    //this.isLoading = true;
	    },err => {},
	    () => {this.isLoading = false;
	});
	}
	groupBy( array , f )
{
  var groups = {};
  array.forEach( function( o )
  {
    var group = JSON.stringify( f(o) );
    groups[group] = groups[group] || [];
    groups[group].push( o );  
  });
  return Object.keys(groups).map( function( group )
  {
    return groups[group]; 
  })
}
	getApproverMultiTimesheetReportdata(filData){
		
		
		this.filData = filData;
		this.isLoading = true;
		this.userId = this.cookie.get('_usrId');
		this.backUserid = this.cookie.get('_busrId'); 
		this.isHeader = true;
		let jsonSearchTimesheetVOStr= this.filData;
		var url = "searchTimesheet?userId="+this.backUserid+"&jsonSearchTimesheetVOStr="+encodeURIComponent(JSON.stringify(jsonSearchTimesheetVOStr));
		//searchTimesheet?userId=33831&jsonSearchTimesheetVOStr= { "typeExpense":"Y", "typeTimesheet":"Y" } 
		var data = "";
	 	this.http.postMethod(url, data,true,true).subscribe(
	    data => {
	    	if(data['status']=='Y') {
	    		
	    		this.dataresult = data['response']['timesheetList'];
	    		this.collection = this.dataresult;
	    		for(var i = 0; i < this.dataresult.length; i++) {
	    		let now = moment(this.dataresult[i].invStartDt); // add this 2 of 4
				this.dataresult[i].invStartDt=now.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invStartDt);
				let to = moment(this.dataresult[i].invEndDt); // add this 2 of 4
				this.dataresult[i].invEndDt=to.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invEndDt);
	    	
				if(this.dataresult[i].unit=="MONTH"){
					this.dataresult[i].unit="Monthly"	
				}
				else if(this.dataresult[i].unit=="1/2-MONTH"){
					this.dataresult[i].unit="Semi-Monthly"	
				}
				else if(this.dataresult[i].unit=="2-WEEK"){
					this.dataresult[i].unit="Bi-Weekly"	
				}
				else if(this.dataresult[i].unit=="WEEK"){
					this.dataresult[i].unit="Weekly"	
				}
			}
				
				sessionStorage.setItem('_projectslist',JSON.stringify (this.dataresult));
				this.timesheetList = data['response']['timesheetList'];
	    		
	    		for(var i = 0; i < this.timesheetList.length; i++) {
	    			let now = moment(this.timesheetList[i].invStartDt); // add this 2 of 4
				this.timesheetList[i].invStartDt=now.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invStartDt);
				let to = moment(this.timesheetList[i].invEndDt); // add this 2 of 4
				this.timesheetList[i].invEndDt=to.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invEndDt);
	    		}
				var Opentimesheet=this.dataresult.filter(x=>x.status=='OPEN')
			var vendartimesheet=this.dataresult.filter(x=>x.status=='VENDOR_APPROVED')
			var clienttimesheet=this.dataresult.filter(x=>x.status=='CLIENT_APPROVED')
			var invoicetimesheet=this.dataresult.filter(x=>x.status=='INVOICED')
		Opentimesheet=Opentimesheet.concat(vendartimesheet,clienttimesheet,invoicetimesheet)
		this.dataresult=Opentimesheet
	    		var timesheetList=this.timesheetList.filter(x=>x.expenseFlag=='Y')
			if(timesheetList.length!=0){
				this.expenseFlag=true
			}
			else{
				this.expenseFlag=false
			}
			
	    		var statusList = data['response']['statusList'];
	    		for(var i = 0; i < statusList.length; i++) {
	    			for(var j = 0; j < this.preselStatusList.length; j++) {

	    				if(statusList[i].desc == this.preselStatusList[j].desc && this.preselStatusList[j].checked) {
	    					statusList[i].checked = true;
	    				}
	    			}
	    		} 
				const groupedunitCollection = this.dataresult.reduce((previous, current)=> {
					////console.error(err);.log(previous, current)
				 if(!previous[current['groupBy']]) {
					 previous[current['groupBy']] = [current];
				 } else {
					 previous[current['groupBy']].push(current);
				 }
				 
				 return previous;
		 }, {});
				

		
		 
		 this.resultmulti=Object.keys(groupedunitCollection).map(key => ({ value: groupedunitCollection[key] }));

		 ;
		
		
		for(let i=0;i<this.timesheetList.length;i++){
			if(this.timesheetList[i].expenseFlag=='Y'){
				this.resultmulti.push({value:this.timesheetList[i]})
			}
		}
		
		this.total=this.resultmulti.length
		 
			
		
			 

		
		
		 
		
		 

		 
		 this.statusList = statusList;
	    		////console.error(err);.log(this.preselStatusList, this.statusList );
	    		this.userType =  data['response']['identityBean']['userTypeId'];
	    	}
	    	//this.listDoc= data['PSDocuments'];
	    	//this.uploadDoc= data['ConsultantDocuments'];
	    	//this.uploadDoc.Createdate =filecontent.split(',')[1]
	    	
	    //this.isLoading = true;
	    },err => {},
	    () => {this.isLoading = false;
	});
	}

  	getTimesheet(workContractId,targetDateStr,toDateStr,isExpense,expenseReportId,status,contractorId,invoiceContractorId,contractId) {

          //this.isLoading=true;
		//console.log(workContractId,targetDateStr,toDateStr,isExpense,expenseReportId);
		if(isExpense=="N") {
				// let now = moment(targetDateStr); // add this 2 of 4
		  //   	targetDateStr = now.format('DD-MMM-YYYY');
		  //   	let toendDate = moment(toDateStr);
		  //   	toDateStr = toendDate.format('DD-MMM-YYYY')

				//////console.error(err);.log('hello world', targetDateStr);
				
				targetDateStr =targetDateStr.replace('--','-');
				var userId = this.session.backuserId;

				let params = {userId,workContractId, targetDateStr,invoiceContractorId,contractId,status};
				let period ={targetDateStr,toDateStr};
				let responseDate = {params,period}
				var result = this.session.setContract(responseDate);
				//console.log(result,this.userType);
				//this.session.startDate(startDate);
				if(result == "Y" ) {
					
                  if ((this.userType == 500)) {
						//console.error("coming");
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.cookie.put('_isReport', "on");
						if(this.multitimesheet=='M'){
							this.cookie.put('_status',status)
						this.router.navigate(['/contractor/timesheetNew/Multi_timesheet_view']);
						
						}
						else{
							this.cookie.put('_status',status)
							this.router.navigate(['/contractor/timesheet/timesheet_view']);
							
						}
						
					} 
		// added by Naveen			
		else if( (this.userType == 450)){
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.cookie.put('_isReport', "on");						
						this.router.navigate(['/client/reports/timesheet']);
					}
				//add by Naveen	
					
					else {
					
						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						//this.router.navigate(['/contractor/timesheet/timesheet_view']);
						if(this.multitimesheet=='M' || this.multiapprover=='M'){
						
							this.router.navigate(['/client/reports/Multi_timesheet_view']);
							}
							else{
								this.router.navigate(['/client/reports/timesheet']);
							}
						
					}
				}
			}
		else {
			//expenseReportId = 
			////console.error(err);.log("E",status);
			var callDetail;
			if(status=="OPEN") {
				callDetail = "open";
			} else 
			{
				callDetail = "closed";

			}
          if ((this.userType == 500) || (this.userType == 450)) {
			var result = this.session.setExpense(expenseReportId,callDetail);
			if(result == "Y" ) {
				if(status=="VENDOR_APPROVED") {
						////console.error(err);.log(this.userType);
					this.cookie.put('status', "");
					var olderUrl = this.router.url;
					this.cookie.put('_previousUrl', olderUrl);
					if(this.userType == 500){
						this.router.navigate(['/contractor/exp_list/expense_list']); 
					}
					else{
						this.router.navigate(['/client/exp_list/expemp/expense_list']); 
					}
					}
					
				else if(status=="CLIENT_APPROVED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "CLIENT_APPROVED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						if(this.userType == 500){
						this.router.navigate(['/contractor/exp_list/expense_list']); 
					}
					else{
						this.router.navigate(['/client/exp_list/expemp/expense_list']); 
					}
						} 
					}
					else if(status=="INVOICED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "INVOICED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/contractor/exp_list/expense_list']);
						} 
					}	
					else {
					////console.error(err);.log(this.userType);
					this.cookie.put('status', "");
					var olderUrl = this.router.url;
					this.cookie.put('_previousUrl', olderUrl);
					this.cookie.put('_isReport', "on");
					this.router.navigate(['/contractor/exp_list/expense_list']);
					}
				} 
			} else {

				if(status=="VENDOR_APPROVED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "VENDOR_APPROVED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/client/mypending-approvals/invoice_view']);
						} 
					}
				else if(status=="CLIENT_APPROVED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "CLIENT_APPROVED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/client/reports/invoice_view']);
						} 
					}
				else if(status=="INVOICED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "INVOICED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/client/reports/invoice_view']);
						} 
					}
				else if(status=="READY_TO_APPROVE") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "READY_TO_APPROVE");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/client/mypending-approvals/invoice_view']);
						} 
					}			
					else {
					this.cookie.put('status', "");
					var result = this.session.setExpenseclient(expenseReportId,callDetail,contractorId);	
					if(result == "Y" ) {
					////console.error(err);.log(this.userType);
					var olderUrl = this.router.url;
					this.cookie.put('_previousUrl', olderUrl);

					this.router.navigate(['/client/exp_list/expemp/expense_list']);
					}
					}
				}
			}
			
		}
		getTimesheetmulti(workContractId,targetDateStr,toDateStr,isExpense,expenseReportId,status,contractorId,invoiceContractorId,contractId) {

			//this.isLoading=true;
		  //console.log(workContractId,targetDateStr,toDateStr,isExpense,expenseReportId);
		  if(isExpense=="N") {
				  // let now = moment(targetDateStr); // add this 2 of 4
			//   	targetDateStr = now.format('DD-MMM-YYYY');
			//   	let toendDate = moment(toDateStr);
			//   	toDateStr = toendDate.format('DD-MMM-YYYY')
  
				  //////console.error(err);.log('hello world', targetDateStr);
				  
				  targetDateStr =targetDateStr.replace('--','-');
				  var userId = this.session.backuserId;
  
				  let params = {userId,workContractId, targetDateStr,invoiceContractorId,contractId,status};
				  let period ={targetDateStr,toDateStr};
				  let responseDate = {params,period}
				  var result = this.session.setContract(responseDate);
				  //console.log(result,this.userType);
				  //this.session.startDate(startDate);
				  if(result == "Y" ) {
					  
					if ((this.userType == 500)) {
						  //console.error("coming");
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  this.cookie.put('_isReport', "on");
						  if(this.multitimesheet=='M'){
							  this.cookie.put('_status',status)
						  this.router.navigate(['/contractor/timesheetNew/Multi_timesheet_view']);
						  
						  }
						  else{
							  this.cookie.put('_status',status)
							  this.router.navigate(['/contractor/timesheet/timesheet_view']);
							  
						  }
						  
					  } 
		  // added by Naveen			
		  else if( (this.userType == 450)){
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  this.cookie.put('_isReport', "on");						
						  this.router.navigate(['/client/reports/timesheet']);
					  }
				  //add by Naveen	
					  
					  else {
					  
						  ////console.error(err);.log(this.userType);
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  //this.router.navigate(['/contractor/timesheet/timesheet_view']);
						  if(this.multitimesheet=='M' || this.multiapprover=='M'){
						  
							  this.router.navigate(['/client/reports/Multi_timesheet_view']);
							  }
							  else{
								  this.router.navigate(['/client/reports/timesheet']);
							  }
						  
					  }
				  }
			  }
		  else {
			  //expenseReportId = 
			  ////console.error(err);.log("E",status);
			  var callDetail;
			  if(status=="OPEN") {
				  callDetail = "open";
			  } else 
			  {
				  callDetail = "closed";
  
			  }
			if ((this.userType == 500) || (this.userType == 450)) {
			  var result = this.session.setExpense(expenseReportId,callDetail);
			  if(result == "Y" ) {
				  if(status=="VENDOR_APPROVED") {
						  ////console.error(err);.log(this.userType);
					  this.cookie.put('status', "");
					  var olderUrl = this.router.url;
					  this.cookie.put('_previousUrl', olderUrl);
					  if(this.userType == 500){
						  this.router.navigate(['/contractor/exp_list/expense_list']); 
					  }
					  else{
						  this.router.navigate(['/client/exp_list/expemp/expense_list']); 
					  }
					  }
					  
				  else if(status=="CLIENT_APPROVED") {
						  var result = this.session.setExpense(expenseReportId,callDetail);
						  if(result == "Y" ) {
						  this.cookie.put('status', "CLIENT_APPROVED");
  
						  ////console.error(err);.log(this.userType);
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  if(this.userType == 500){
						  this.router.navigate(['/contractor/exp_list/expense_list']); 
					  }
					  else{
						  this.router.navigate(['/client/exp_list/expemp/expense_list']); 
					  }
						  } 
					  }
					  else if(status=="INVOICED") {
						  var result = this.session.setExpense(expenseReportId,callDetail);
						  if(result == "Y" ) {
						  this.cookie.put('status', "INVOICED");
  
						  ////console.error(err);.log(this.userType);
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  this.router.navigate(['/contractor/exp_list/expense_list']);
						  } 
					  }	
					  else {
					  ////console.error(err);.log(this.userType);
					  this.cookie.put('status', "");
					  var olderUrl = this.router.url;
					  this.cookie.put('_previousUrl', olderUrl);
					  this.cookie.put('_isReport', "on");
					  this.router.navigate(['/contractor/exp_list/expense_list']);
					  }
				  } 
			  } else {
  
				  if(status=="VENDOR_APPROVED") {
						  var result = this.session.setExpense(expenseReportId,callDetail);
						  if(result == "Y" ) {
						  this.cookie.put('status', "VENDOR_APPROVED");
  
						  ////console.error(err);.log(this.userType);
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  this.router.navigate(['/client/mypending-approvals/invoice_view']);
						  } 
					  }
				  else if(status=="CLIENT_APPROVED") {
						  var result = this.session.setExpense(expenseReportId,callDetail);
						  if(result == "Y" ) {
						  this.cookie.put('status', "CLIENT_APPROVED");
  
						  ////console.error(err);.log(this.userType);
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  this.router.navigate(['/client/reports/invoice_view']);
						  } 
					  }
				  else if(status=="INVOICED") {
						  var result = this.session.setExpense(expenseReportId,callDetail);
						  if(result == "Y" ) {
						  this.cookie.put('status', "INVOICED");
  
						  ////console.error(err);.log(this.userType);
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  this.router.navigate(['/client/reports/invoice_view']);
						  } 
					  }
				  else if(status=="READY_TO_APPROVE") {
						  var result = this.session.setExpense(expenseReportId,callDetail);
						  if(result == "Y" ) {
						  this.cookie.put('status', "READY_TO_APPROVE");
  
						  ////console.error(err);.log(this.userType);
						  var olderUrl = this.router.url;
						  this.cookie.put('_previousUrl', olderUrl);
						  this.router.navigate(['/client/mypending-approvals/invoice_view']);
						  } 
					  }			
					  else {
					  this.cookie.put('status', "");
					  var result = this.session.setExpenseclient(expenseReportId,callDetail,contractorId);	
					  if(result == "Y" ) {
					  ////console.error(err);.log(this.userType);
					  var olderUrl = this.router.url;
					  this.cookie.put('_previousUrl', olderUrl);
  
					  this.router.navigate(['/client/exp_list/expemp/expense_list']);
					  }
					  }
				  }
			  }
			  
		  }
}

