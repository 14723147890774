//Angular decorators list
import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Custom services list
import { CookieService } from 'ngx-cookie';
import { HttpService } from './../../shared/services/http.service';
import { SessionService } from './../../shared/services/sessiontoken.service';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { Message } from 'primeng/api';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx' ;


@Component({
	selector: 'app-listwithsidebar',
	templateUrl: './listwithsidebar.component.html',
	styleUrls: ['./listwithsidebar.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class ListwithsidebarComponent implements OnInit {
	 
	//Initial setup 
	@ViewChild(SignaturePad) signaturePad: SignaturePad;
	@ViewChild('fileElement')  fileElement:any;
    @ViewChild('fileContent')  fileContent:any;
	@Input('userId') userId:any;
	@Input('accessToken') accessToken:any;
	@Input('result') results:any;
	@Input('listDoc') listDoc:any;
	@Input('listBoDoc') listBoDoc:any;
	@Input('uploadDoc') uploadDoc:any;
	@Input('isLoading') isLoading:any;
	@Input('showUpload') showUpload:any;
	@Input('isHider') isHider:any;
	@Input('userComments') userComments:any;
	@Input('connectComments') connectComments:any;
	@Input('buploadDoc') buploadDoc:any;
	
	@Input('bshowUpload') bshowUpload:any;
	@Input('isHider') bisHider:any;
	@Input('userComments') buserComments:any;
	@Input('connectComments') bconnectComments:any;
	
	//showUpload
	signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 1,
    'canvasWidth': 300,
    'canvasHeight': 80
  	};
  	result;
	usertProdetails=[];
	newsign;
	display: boolean = false;
	//listDoc:any;
	isBackoffice = false;
	isHeader = true;
	isUpload = false;
	closedoc=false
    isEmpty = false;  
	bisUpload = false;
    bisEmpty = false; 
	closeBoDoc:any
divhide:boolean
bdivhide:boolean
ismappeddivhide1:boolean
ismappeddivhide2:boolean=true
ismapped=false
	class = "";
	isContract = true;
	msgs: Message[] = [];
	eSign;
	doc;
	newSignenable = true;
	p: number = 1;
    collection: any[];
    pageSize =7; 
    total;
    active;
	bactive;
    contractId;
	listFirst
    isZero = false;
	bisZero = false;
    showOlder = false;
    file = {
        doctype:null,
        docname:null,
        fileData:'',
    }
    imageChangedEvent: any = '';
	croppedImage: any = '';
	hideSign : boolean = false;
	showCropper: boolean = false;
	commentInfo={
		Comments:""
	}
	cCRShow : boolean = false;
	bcCRShow : boolean = false;
	signUpdate : boolean = false;
	backId;
	workcontractIds;
	workList=[];

    //isHider= false;
	constructor(private http:HttpService, private session:SessionService, private cookie:CookieService,private route:ActivatedRoute, private router:Router) { }

	ngOnInit() {
		
		this.contractId = this.cookie.get('_puserId');
		this.backId = this.cookie.get('_busrId');
		window.document.title= "Contracts - Connect ";
		
		if(this.backId!=undefined&& this.contractId!=0){
			this.ismapped=true
			this.PortalContract()
			this.getBcontractdocuments(this.backId)
			

this.divhide=false
this.bdivhide=false
			//this.getOlderEngagements()
			if(this.result!=0){
				this.active=1
				this.bactive=null
			}
			else{
this.bactive=0
			}
			
		}
		else if(this.backId!=undefined && this.ismapped==false ){
          
			this.getBcontractdocuments(this.backId)
			
			
			this.bactive=0
			this.bdivhide=true
			this.divhide=false
		}
		else if(this.contractId!=0 && this.ismapped==false ){
          
			this.PortalContract()
			
			
			
			this.bdivhide=false
			this.divhide=true
		}
		
		
		// else if(this.workList.length==0){
		// 	this.showOlder=true
		// 	this.bdivhide=true
		// 	}
		
		


  	}

  	/**
  	 * [Get document detail]
  	 * @param {[String]} contId [contract id]
  	 */
	   PortalContract(){
		this.isLoading = true;
		var url = "onboarding/GetCurrentPlacementSummaries/" +this.contractId;
		////console.error(err);.log(url,this.isLoading);
		this.http.getMethod(url,this.isBackoffice).subscribe(
			result => {
			  var results=result
			  let olderUrl =this.cookie.get('_previousUrl');
				////console.error(err);.log(results.result.length);
				if(Object.keys(results).length != 0) {
					this.results = results;
					this.listFirst = Number(this.cookie.get('_PSId'));
				  if(!this.listFirst || olderUrl!='/contractor/upload') {
					this.listFirst = results[0].ref_placement_summary_id;
				  }
				  //this.listFirst = results.result[0].ref_placement_summary_id;
				  this.cookie.put('_PSId', this.listFirst);
				  //this.active=0;
				  //this.active=Number(this.cookie.get('_index'));
				  ////console.error(err);.log(olderUrl,this.active);
				  if(this.active==undefined) {
				  this.active=0;
				  }
				  this.isEmpty = false;
			   //this.divhide=true
			   olderUrl =this.cookie.get('_previousUrl');
			  ////console.error(err);.log(olderUrl);
			  	this.active=0;	  
				if(olderUrl=='/contractor/upload')
				{
				this.active=Number(this.cookie.get('_index'));
				//console.error(err);.log(olderUrl,this.active);
				if(this.active==undefined) {
				this.active=0;
				}	
				this.isUpload=true;
				this.closedoc=true
				this.cookie.remove('_previousUrl');
				this.cookie.remove('_index');

				}
				  //console.error(err);.log(this.listFirst,this.active,results.result[0].ref_placement_summary_id);
				  this.getDocs(this.listFirst,this.active);
	
				} else {
					this.isEmpty = true;
					this.showUpload = false;
					this.isZero = true;
					//this.divhide=true
					this.getOlderEngagements()
				} 
	
			},err => {},
				() => {this.isLoading = false;
			});
	  }
	getDocs(contId,index)	{
		//console.error(err);.log(contId,index);
		this.contractId = contId;
		////console.error(err);.log(this.contractId);
		this.isLoading = true;
		var user = this.userId;
		this.ismappeddivhide1=false
		this.ismappeddivhide2=true
		if(this.ismapped==false){
			this.bdivhide=false
			this.divhide=true
		}
		
		this.isHeader = true;
		this.active=index;
		this.bactive=null
		this.cookie.put('_index', index);
		if(this.contractId=="undefined") {
			this.contractId = this.cookie.get('_PSId');
		}
		else{
			 this.cookie.put('_PSId', contId);
		}
		var url = "onboarding/GetPSDetails/"+this.contractId+"/"+user;
		////console.error(err);.log(url,this.isLoading);
	    this.http.postMethod(url, user,this.isBackoffice,this.isHeader).subscribe(
	        data => {
	        	////console.error(err);.log(data);
	        	this.listDoc= data['PSDocuments'];
	        	////console.error(err);.log(this.listDoc.length);
	        	if(this.listDoc.length==0) {
                this.showUpload = false;
                this.isZero = true;
                this.isUpload= true;
				this.closedoc=true
              	} else {
              	this.isUpload= false;
				  this.closedoc=false
              	this.isZero = false;
              	this.showUpload = true;
              	}
	        	this.uploadDoc= data['ConsultantDocuments'];
	        	this.userComments = data['ConsultantComments'];
				this.connectComments = data['CCRComments'];
				//console.error(err);.log(this.connectComments);
	        	this.collection = this.uploadDoc;
	    		this.total=this.collection.length;
	    		if(this.listDoc.length==0) {
	    			this.cCRShow = false;
	    		//this.isZero = true;
                //this.showUpload = false;
                //this.isUpload= false; 
              	} else {
              		this.cCRShow = true;
              	//this.isZero = false;
              	//this.showUpload = true;
              	}
	    		if(this.collection.length==0) {
	    			this.isHider = false;
	    		//this.isZero = true;
                //this.showUpload = false;
                //this.isUpload= false; 
              	} else {
              		this.isHider = true;
              	//this.isZero = false;
              	//this.showUpload = true;
              	}
	        	//this.uploadDoc.Createdate =filecontent.split(',')[1] 
	        	
	        //this.isLoading = true;
	        },err => {},
	        () => {this.isLoading = false;
	    });
	}
	getBcontractdocuments(backId)
	{
		
    var url = "getWorkContractListWithStatus?userId=" + backId
    var data = "";
    var wrkConlist
    this.http.postMethod(url, data, true, true).subscribe(
      data => {
        if (data['status'] == 'Y') {
			this.workList = data['response']['list'];
		  
       
		  if(this.workList.length == 0) {
			this.bisEmpty = true;
			this.bshowUpload = false;
            this.bisZero = true;
		 this.bisUpload=true
		} else{
			this.bisEmpty = false;
			this.bisUpload=false
			
			  let olderUrl =this.cookie.get('_previousUrl');
			  ////console.error(err);.log(olderUrl);
			  if(this.workList.length!=0){
				var status="Open"
				
				this.getBcontractdoc(this.backId,this.workList[0].workContractId,this.bactive,status)
			}
			 
				this.active=0;
			    var closedlist=this.workList.filter(x=>x.workContractStatus=="Closed")
				if(closedlist.length!=0){
					this.bshowUpload=true
					this.showOlder=true
				}
			  		  
				if(olderUrl=='/contractor/upload')
				{
				this.active=Number(this.cookie.get('_index'));
				//console.error(err);.log(olderUrl,this.active);
				if(this.active==undefined) {
				this.active=0;
				}	
				//this.bisUpload=true;
				this.cookie.remove('_previousUrl');
				this.cookie.remove('_index');

				}
		}
         
         
        }
        else {
          this.msgs = [];
          this.msgs.push({ severity: 'error', detail: 'Please try again later' });
        }
      }, err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', detail: 'Server Side Failure' });
      })
	 
	  
  }
  bcontractclick(){
this.bisUpload=false
this.bactive=0
  }
  bClosedcontractclick(){
	this.bisUpload=true
	this.bactive=0
  }
  getBcontractdoc(backId,workcontractid,index,status){
	
backId=this.backId
this.userId=backId
this.isLoading = true;
this.workcontractIds=workcontractid
this.bactive=index;
this.active=null
this.ismappeddivhide1=true
this.ismappeddivhide2=false
if(this.ismapped==false){
	this.bdivhide=true;
	this.divhide=false;
}

this.cookie.put('_index', index);
var url = "getWorkContractDocList?userId=" + backId + '&workContractorId=' + workcontractid;
    var data = "";
    
    this.http.postMethod(url, data, true, true).subscribe(
      data => {
        if (data['status'] == 'Y') {
			var workContractStatus=this.workList.find(x=>x.workContractStatus==status)
        
		  if(workContractStatus.workContractStatus=="Open"){
			this.listBoDoc = data['response']['list'];
		  }
		  else {
			this.closeBoDoc=data['response']['list']
		  }
		  if(this.listBoDoc.length==0) {
			//this.bshowUpload = false;
			this.bisZero = true;
			//this.bisUpload= true;
			  } else {
			 // this.bisUpload= false;
			  this.bisZero = false;
			  //this.bshowUpload = true;
			  }
			this.buploadDoc= ''
			this.buserComments = ''
			this.bconnectComments = ''
			//console.error(err);.log(this.connectComments);
			this.collection = this.listBoDoc;
			this.total=this.collection.length;
			if(this.listBoDoc.length==0) {
				this.bcCRShow = false;
			//this.isZero = true;
			//this.showUpload = false;
			//this.isUpload= false; 
			  } else {
				  this.bcCRShow = true;
			  //this.isZero = false;
			  //this.showUpload = true;
			  }
			if(this.collection.length==0) {
				this.bisHider = false;
			//this.isZero = true;
			//this.showUpload = false;
			//this.isUpload= false; 
			  } else {
				  this.bisHider = true;
			  //this.isZero = false;
			  //this.showUpload = true;
			  }
			  
	}
        else {
          this.msgs = [];
          this.msgs.push({ severity: 'error', detail: 'Please try again later' });
        }
      }, err => {
        this.msgs = [];
		this.isLoading=false
        this.msgs.push({ severity: 'error', detail: 'Server Side Failure' });
      })
  }


	/**
	 * [Convert base64 To ArrayBuffer]
	 * @param {[Object]} data 
	 */
	base64ToArrayBuffer(data) {
	    var binaryString = window.atob(data);
	    var binaryLen = binaryString.length;
	    var bytes = new Uint8Array(binaryLen);
	    for (var i = 0; i < binaryLen; i++) {
	        var ascii = binaryString.charCodeAt(i);
	        bytes[i] = ascii;
	    }
	    return bytes;
	}


	/**
	 * Page Navigator
	 * @param {PageNumber} data 
	 */
	pageChanged(event) {
  		////console.error(err);.log(event);
  		this.p=event;

  	}
  	/**
	* [Go back to contract]
	*/
	goBack() {
		var userroleId = this.cookie.get("_usrtyd");
		var userRole=Number(userroleId)
		if (userRole == 300 || userRole == 450 || userRole == 400) {           
			this.router.navigate(["/client/home"]);
			}
			else
			{
			 this.router.navigate(["/contractor/home"])
			}
	}

	/**
	 * [Download document]
	 * @param {[Object]} data [requesting data]
	 */
	downDoc(data) {
	  	//////console.error(err);.log(data);
	  	this.isLoading = true;
	  	if(!this.isUpload) {
		  	var blob;
		  	var companyId = this.cookie.get('_compnyId');
		  	var url = "onboarding/DownloadCCRDocument/"+companyId;
		  	////console.error(err);.log(data);
		  	this.isHeader= true;
		    ////console.error(err);.log(url,this.isLoading);
	        this.http.postMethod(url, data,this.isBackoffice,this.isHeader).subscribe(
	            data1 => {
	            	////console.error(err);.log(data1);
	            	if(data1['SuccessFlag']==true) {
	            	////console.error(err);.log(data1);
	            	var arrBuffer = this.base64ToArrayBuffer(data1['Document']);
	            	var filename = data1['DocumentName'];
					var fileExtension =  filename.split('.')[1];
					console.log(fileExtension);
					switch (fileExtension) {
                        case 'png': case 'jpeg': case 'jpg': case "PNG" : case "JPG" : case "JPEG" : case "gif" : case "GIF" :
                            blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
                            break;
                        case 'pdf':  case 'PDF':
                            blob = new Blob([arrBuffer], {type: 'application/'+fileExtension});
                            break;
                        case 'txt':
                            blob = new Blob([arrBuffer], {type: 'text/plain'});
                            break;    
                        case 'doc': case 'DOC' :
                            ////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/msword'});
                            break;
                        case 'docx': case 'DOCX' :
                        	////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                            break;
                        case 'xls': case 'XLS' : 
                        	//console.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/vnd.ms-excel'});
                            break;
                        case 'xlsx' : case 'XLSX' :
	                        //console.log("fileExtension");
	                        blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
	                        break;
                        default:
                        //$('#divFiles').text('File type: Unknown');
                    }
					//var blob = new Blob([arrBuffer]);
					////console.error(err);.log(filename.split('.')[0]);
					var url
					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, filename);
					}else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
				    {
				        window.open(window.URL.createObjectURL(blob), "_blank");
						  
				    }else {
					url = window.URL.createObjectURL(blob);
					//window.open(objectUrl);
					}
					var link = document.createElement('a');
					link.href = url;
					link.download = filename.split('.')[0];
					//console.error(err);.log(url,link);
					link.click();
					setTimeout(function(){
						//document.body.removeChild(link);
						window.URL.revokeObjectURL(url);  
						}, 100);
					//window.open(window.URL.createObjectURL(blob), "_blank");
					
	            }else {
	            	this.msgs = [];
                    this.msgs.push({severity:'error', detail:'File Download Error'});
	            }
	        },err => {},
	            () => {this.isLoading = false;});
	  } else {
	  	var companyId = this.cookie.get('_compnyId');
	  	var PSId = this.cookie.get('_PSId');
	  	var ConsultantId = this.cookie.get('_usrId');
	  	var DocumentId = data['document_id'];
	  	//let IsHistory = false;
              let IsHistory;
              if (data['IsHistory'] == 1) {
                IsHistory = true;
              }
              else {
                IsHistory = false;
              }
	  	var url = "onboarding/DownloadConsultantDocument"+'/'+ConsultantId+'/'+DocumentId+'/'+PSId+'/'+IsHistory;
	  	let datamain ={ConsultantId : ConsultantId, DocumentId : DocumentId, PSId : PSId, IsHistory : IsHistory };
	  	//////console.error(err);.log(datamain,"ggg");
		//var arrBuffer = this.base64ToArrayBuffer(data['document']);
		//var blob;
		this.http.postMethod(url,'',false,true).subscribe(
                data => {
                    ////console.error(err);.log(data);
                    if(data['SuccessFlag']==true) {

					var arrBuffer = this.base64ToArrayBuffer(data['Document']);
					var filename = data['DocumentName'];
					var fileExtension =  filename.split('.')[1];
					////console.error(err);.log(fileExtension);
					
					switch (fileExtension) {
                        case 'png': case 'jpeg': case 'jpg': case "PNG" : case "JPG" : case "JPEG" : case "gif" : case "GIF" :
                            blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
                            break;
                        case 'pdf':  case 'PDF':
                            blob = new Blob([arrBuffer], {type: 'application/'+fileExtension});
                            break;
                        case 'txt':
                            blob = new Blob([arrBuffer], {type: 'text/plain'});
                            break;    
                        case 'doc': case 'DOC' :
                            ////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/msword'});
                            break;
                        case 'docx': case 'DOCX' :
                        	////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                            break;
                        case 'xls': case 'XLS' : 
                        	//console.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/vnd.ms-excel'});
                            break;
                        case 'xlsx' : case 'XLSX' :
	                        //console.log("fileExtension");
	                        blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
	                        break;
                        default:
						//$('#divFiles').text('File type: Unknown');
					}

					var url
					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(blob, filename);
					} else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
				    {
				        window.open(window.URL.createObjectURL(blob), "_blank");
						  
				    } else {
					url = window.URL.createObjectURL(blob);
					//window.open(objectUrl);
					}
					var link = document.createElement('a');
					link.href = url;
					link.download=data['DocumentName'];
					link.click();
					setTimeout(function(){
						//document.body.removeChild(link);
						window.URL.revokeObjectURL(url);  
						}, 100);
                    this.msgs = [];
                    this.msgs.push({severity:'success', detail: filename.split('.')[0]+" Downloaded Successfully"});
                    this.isLoading = false;
                } else {
                    this.msgs = [];
                    this.msgs.push({severity:'error', detail:data['Message']});
               	}
                //this.isLoading = true;
                },err => {},
                () => {this.isLoading = false;
            });
		
	  	}
	}

	downBoDoc(docId) {

        var url = "downAttachemntInfo?userId="+this.backId+"&docId="+docId;
          //////console.error(err);.log(datamain,"ggg");
        //var arrBuffer = this.base64ToArrayBuffer(data['document']);
        var blob;
        this.http.postMethod(url,'',true,true).subscribe(
                data => {
                    console.log(data);
                    if(data['status']=='Y') {

                    var arrBuffer = this.base64ToArrayBuffer(data['response']['contentBase64']);
                    var filename = data['response']['fileName'];
                    var fileExtension =  filename.split('.').pop()
                    console.log(fileExtension);
                    
                    switch (fileExtension) {
                        case 'png': case 'jpeg': case 'jpg': case "PNG" : case "JPG" : case "JPEG" : case "gif" : case "GIF" :
                            blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
                            break;
                        case 'pdf':  case 'PDF':
                            blob = new Blob([arrBuffer], {type: 'application/'+fileExtension});
                            break;
                        case 'txt':
                            blob = new Blob([arrBuffer], {type: 'text/plain'});
                            break;    
                        case 'doc': case 'DOC' :
                            ////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/msword'});
                            break;
                        case 'docx': case 'DOCX' :
                        ////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                            break;
                        case 'xls': case 'XLS' : 
                        //console.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/vnd.ms-excel'});
                            break;
                        case 'xlsx' : case 'XLSX' :
                        //console.log("fileExtension");
                        ////console.error(err);.log("click");
                        blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        break;

                        default:
                        //$('#divFiles').text('File type: Unknown');
                    }
                    var url;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
                    {
                        window.open(window.URL.createObjectURL(blob), "_blank");
                          
                    } else {
                    url = window.URL.createObjectURL(blob);
                    //window.open(objectUrl);
                    }
                    var link = document.createElement('a');
                    link.href = url;
                    link.download=filename;
                    link.click();
                    setTimeout(function(){
                        //document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);  
                        }, 100);
                    this.msgs = [];
                    this.msgs.push({severity:'success', detail: filename.split('.')[0]+" Downloaded Successfully"});
                    this.isLoading = false;
                } else {
                    this.msgs = [];
                    this.msgs.push({severity:'error', detail:data['Message']});
                   }
                //this.isLoading = true;
                },err => {},
                () => {this.isLoading = false;
            });
        
    }

	/**
	 * [Esign document]
	 * @param {[Object]} data [requesting data]
	 */ 

	signDoc() {
		//this.doc
		this.isLoading = true;
		////console.error(err);.log(this.doc);
		var companyId = this.cookie.get('_compnyId');
		var url = "onboarding/ConsultanteSign/"+companyId+"/"+this.userId;
		  this.http.postMethod(url, this.doc,this.isBackoffice,this.isHeader).subscribe(
	            data => {
	            	//////console.error(err);.log(data['SuccessFlag']);
	            	if(data['SuccessFlag']==true) {
	            		this.msgs = [];
                        this.msgs.push({severity:'success', detail:data['Message']});
                        this.getDocs(this.contractId,this.active)
                        //setTimeout(() => this.router.navigate(['/contractor/contracts']), 1000)
                        
	            	} else {
	            		this.msgs = [];
                        this.msgs.push({severity:'error', detail:"No access to Sign"});
                        this.getDocs(this.contractId,this.active)
	            	}
	            },err => //console.error(err);.error(err),
                () => {
                	this.isLoading = false;
                	});
		//////console.error(err);.log(doc);
	}


	/**
	 * Digital Signature Upload
	 * @param {[Object]} data 
	 */
	signComplete() {
		//////console.error(err);.log("fff");
		////console.error(err);.log(this.signaturePad.toDataURL());
		this.newsign = this.signaturePad.toDataURL();
		this.signUpdate = true;
		//this.eSign = this.newsign.split(',')[1];
		//////console.error(err);.log(this.eSign);

	}
	/**
	 * Digital Signature Upload
	 * @param {[Object]} data 
	 */
	signStart() {
		//this.signaturePad.clear();
		//////console.error(err);.log("fff");
		//////console.error(err);.log(this.signaturePad.toDataURL());

	}

	clearSignature() {
		this.newSignenable=true;
		this.signaturePad.clear();
		this.file.fileData = '';
	}

	
	/********* Upload attachment to  Time sheet **********/
  uploadAttachment(doc) {
 
			////console.error(err);.log("111");
			this.hideSign = false;
    		this.showCropper = false;
    		////console.error(err);.log(this.hideSign,this.showCropper);
			this.doc= doc;
			this.eSign='';
			if(!this.hideSign) {
			this.signaturePad.clear();
			}
			var url = "portal/GetProfileDetails/"+this.userId;
			var data="";
			this.http.postMethod(url, data,false,true).subscribe(
		            data => {
		            	////console.error(err);.log(data);
		            	this.usertProdetails = data as any;
						////console.error(err);.log(this.usertProdetails);
						this.eSign = this.usertProdetails['eSign'];
						this.signaturePad.fromDataURL("data:image/png;base64,"+this.eSign);
						////console.error(err);.log(this.eSign);
		            	
		            },err => {},
	                () => { //this.isLoading = false;	
	                		console.log("coming");
	                		this.display = true;
		                });
		
		

	}

	disMisspopUp() {
		this.display = false;
		this.hideSign = false;
    	this.showCropper = false;
		this.isUpload = false;
		if(!this.hideSign) {
		//this.clearSignature();
		}
		//this.clearSignature();
		//this.uploadForm.resetForm();
		//this.file.fileData = '';
	}

  updateSign() {
		this.hideSign = false;
    	this.showCropper = false;
    	this.display = false;
		if(this.signUpdate){
			console.log("Coming2")
		
		//this.display = false;
		if(this.newsign=='' || this.newsign == null) {
			this.usertProdetails['eSign']="";
		}
		
		if(this.newsign) {	
		this.eSign=this.newsign.split(',')[1];
		this.usertProdetails['eSign']=this.newsign.split(',')[1];
		}
		////console.error(err);.log(this.newSignenable);
		if(this.newSignenable) {
		////console.error(err);.log("Esign Updated");
		//this.display = false;
		//this.eSign=this.newsign.split(',')[1];
		this.usertProdetails['eSign']=this.eSign;
		this.isLoading=true;
		this.usertProdetails['CreatedBy']=this.userId; 
	   	this.usertProdetails['LastModifiedBy']= this.userId;	
   		var url = "portal/Updateprofile?";
		this.http.postMethod(url, this.usertProdetails,false, true).subscribe(
                data => {
                	// ////console.error(err);.log(data);
                	if(data['SuccessFlag']==true) {
                		this.signDoc();

                 	} else {
                    this.msgs = [];
                    this.msgs.push({severity:'error', detail:data['Message']});
                    }
                },err => {
							//console.error(err);.error(err);
							
						},
                () => {

                });
		}

		} else {

				this.signDoc();
		}
		

	}
	getOlderEngagements() {

		var url = "onboarding/GetOldPlacementsummaries/"+this.userId;
		this.http.getMethod(url,false).subscribe(
                data => {
                	////console.error(err);.log(data);
                	var oldArray = data;
                	////console.error(err);.log();
                	if(Object.keys(oldArray).length>0)
                	{
                		this.showOlder = true;
                	} 
                	// if(data['SuccessFlag']==true) {
                	// this.msgs = [];
                 //    this.msgs.push({severity:'success', detail:data['Message']});
                	// } else {
                 //    this.msgs = [];
                 //    this.msgs.push({severity:'error', detail:data['Message']});
                 //    }
                },err => {
							//console.error(err);.error(err);
							
						},
                () => {

                });

	}

	// Sign Attachements
  public uploadSign() {
    
    	//////console.error(err);.log("Fff");
    	var link = document.createElement('input.image');
    	this.fileElement.nativeElement.click();
    	//////console.error(err);.log(this.fileElement);
    }

    showFile() {
		//this.isUpload = true;
		this.signaturePad.clear();
		this.hideSign = true;
    	this.showCropper = true;
		this.fileChangeEvent(event);
		this.signUpdate = true;
		// let file=this.fileContent.nativeElement.files[0];
		// ////console.error(err);.log(this.fileContent.nativeElement.files);
		// var reader = new FileReader();
		// reader.readAsDataURL(file);
		// 	reader.onload = ()=> {
		// 	////console.error(err);.log(reader.result);
		// 	let filecontent : any = reader.result;
		// 	////console.error(err);.log()
		// 	var fileData = filecontent.split(',')[1];
		// 	if(filecontent!='') {
		// 	this.clearSignature();
		// 	this.fileElement.nativeElement.click();
		// 	//let element: HTMLElement = document.getElementsByClassName('canvas')[0] as HTMLElement;
		// 	//let page = document.querySelector('canvas');
		// 	//////console.error(err);.log(page);
  //   		//page.click();
		// 	//element.click();
		// 	// //this.newsign = filecontent;
		// 	////console.error(err);.log(filecontent);
		// 	this.signaturePad.fromDataURL(filecontent);
		// 	this.newsign = filecontent;
		// 	//this.signComplete();
		// 	}
		// }
		//this.uploadFile = file.name;
	}

	fileChangeEvent(event: any): void {
		    this.imageChangedEvent = event;
	}
	imageCropped(image: string) {
	    this.croppedImage = image;
	    this.newsign = image;
	   // ////console.error(err);.log(image);
	}
	imageLoaded() {
	    // show cropper
	}
	loadImageFailed() {
	    // show message
	}
	updateComment(form) {
		this.isLoading=true;
		var url = "onboarding/UpdateConsultantComments";
		this.commentInfo['ConsultantId']=Number(this.userId);
		this.commentInfo['PSId'] = Number(this.cookie.get('_PSId'));
		this.commentInfo['Comments']=this.userComments;
		var data = this.commentInfo;
		this.http.postMethod(url, data,false, true).subscribe(
                data => {
                		////console.error(err);.log(data);
	                	if(data['SuccessFlag']==true) {
	                	this.msgs = [];
	                    this.msgs.push({severity:'success', detail:data['Message']});
	                	} else {
	                    this.msgs = [];
	                    this.msgs.push({severity:'error', detail:data['Message']});
	                    }
                },err => {
							//console.error(err);.error(err);
							
						},
                () => {
                	this.isLoading = false;

                });

	}
}
