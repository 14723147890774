import { Component, OnInit } from '@angular/core';
import { HttpService } from './../../shared/services/http.service';
import { CookieService } from 'ngx-cookie';
import * as moment from 'moment';
@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {

	constructor(private cookie:CookieService,private http: HttpService) { }
	compId;
	announceList;
	announceLength;
	showRead;
	nowDate;
	isEmpty = true;
	ngOnInit() {
		this.compId = this.cookie.get('_compnyId');
		////console.error(err);.log(this.compId);
		if(this.compId != null || this.compId!=undefined) {
		this.isEmpty=false;
		this.getAnnouncements();
		}
		else {
			this.isEmpty=true;
		}
	}
	getAnnouncements(){
	var url="portal/GetAnnouncements/"+this.compId;
	    this.http.getMethod(url,false).subscribe(
	                data => {
	                	var keys = Object.keys(data);
						this.announceLength = keys.length;
						let now = moment(); // add this 2 of 4
						this.nowDate = now.format('YYYY-MM-DD');

    					//this.invoiceObj[list.date] = now.format('DD-MMM-YYYY');
						
	                	if(this.announceLength>0) {
	                		this.announceList=data;
	                		//console.error(err);.log(this.announceList);
	                		// for(var announce of this.announceList) {
	                		// 	let from = moment(announce.effective_from_date);
	                		// 	announce.effective_from_date= from.format('YYYY-MM-DD');
	                		// }
	                	}
	                	else{
	                    	this.isEmpty=true;
	                    }
	                },err => {
								
							},
	                () => {});
		
	}

}
