//Angular decorators list
import { Component } from '@angular/core';

@Component({
	selector: 'under-construction-root',
	templateUrl: './under-construction.component.html',
	styleUrls: ['./under-construction.component.css']
})

export class UnderConstructionComponent {
	ngOnInit() {
    window.document.title= "UnderConstruction - Connect ";
	}
}
