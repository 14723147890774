import { Component } from '@angular/core';

@Component({
  selector: 'not-found-root',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent {

	ngOnInit() {
    window.document.title= "OOPS!  - Connect ";
	}
}
