//Angular decorators list
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Custom Services list
import { HttpService } from './../../shared/services/http.service';
import { SessionService } from './../../shared/services/sessiontoken.service';

@Component({
  selector: 'app-listwithoutsidebar',
  templateUrl: './listwithoutsidebar.component.html',
  styleUrls: ['./listwithoutsidebar.component.css']
})
export class ListwithoutsidebarComponent implements OnInit {
	
	// Initial setup
	@Input('userId') userId:any;
	@Input('accessToken') accessToken:any;
	@Input('result') results:any;
	isEmpty: boolean = false;
	listDoc;
	p: number = 1;
    collection: any[];
    pageSize =7; 
    total;
	//isLoading = false;
	constructor(private http:HttpService, private session:SessionService,private route:ActivatedRoute, private router:Router) { }

	ngOnInit() {
		//this.collection.length=0;
		window.document.title= "Contracts - Connect ";
		////console.error(err);.log(this.results);
		if(this.results == 0) {
			this.isEmpty = true;
		} else{
			this.isEmpty = false;
			this.listDoc = this.results.response.list;
			this.collection = this.listDoc;
       	  	this.total=this.collection.length;
			 for(var list of this.listDoc) {
				list['projectParticipants']=list['projectParticipants'].split(', ##BO@@');
				//////console.error(err);.log(list['projectParticipantsStr']);
			}
		}
  	
  }
  /**
	 * Page Navigator
	 * @param {PageNumber} data 
	 */
	pageChanged(event) {
  		////console.error(err);.log(event);
  		this.p=event;

  	}
  /**
	* [Go back to contract]
	*/
	goBack() {
	this.router.navigate(['/']);
	}

}
