import { Component, OnInit, Input} from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../services/http.service';
import { SessionService } from '../services/sessiontoken.service';
import { NotificationComponent } from '../notifications/notifications.component';
import { CookieService } from 'ngx-cookie';
import {Message} from 'primeng/api';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // @Input() notification: NotificationComponent;
  // count;
	logoPath:string="assets/images/logo.png";
	userName;
	msgs: Message[] = [];
	display: boolean = false;
	updatePassdata= {
		oldPassword: '',
		password : ''
	};
	compId;
	confoError: boolean = false;
	userId;
	isLoading = false;
	portalId;
	LandingPageDetails;
	backOfficemenu;
	RegisterDomainId;
	BOCompanyList;
	constructor(private http: HttpService, private session: SessionService, private cookie:CookieService, private router: Router) { }

	ngOnInit() {
		this.compId = this.cookie.get('_compnyId');
		this.userId = this.cookie.get('_busrId');
		this.userName = this.cookie.get('_userName');
		this.portalId = this.cookie.get('_usrId');
		this.backOfficemenu = this.cookie.getObject('backOfficemenu');
		if(!this.userName){
			this.userName="New User";
		}
		if(this.backOfficemenu != null && this.backOfficemenu.response.backofficeLogin == "Y"){
			this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain;

			this.http.GetJsonData()
			.subscribe(
			res => {
				this.LandingPageDetails = res;
				const headerLogos = this.LandingPageDetails[0].HeaderLogo;
				this.BOCompanyList = headerLogos[0].BOCompanyIDs;
             	var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
				if(isBOCompanyExists >= 0){
					const mvLogoPath="assets/images/logo_"+this.RegisterDomainId +".png"
					this.http.getLogo(mvLogoPath).subscribe(logo=>{
						if(logo){
							this.logoPath = mvLogoPath;
						}		
					})
				}
			});
		}
		
    // this.count = this.notification.count;
    
		////console.error(err);.log(this.userName,this.userId);
	}

  logOut() {
		//////console.error(err);.log("hh");
    this.msgs = [];
		this.msgs.push({severity:'success', detail:'Logged out Successfully'});
		//this.router.navigate(['/client/home']);
		
		this.session.emptyDetails();
		this.cookie.remove('_acctok');
		this.cookie.remove('_usrId');
		this.cookie.remove('_usrtyd');
		this.cookie.remove('_exttime');
		this.cookie.remove('_compnyId');
		this.cookie.remove('_userName');
		this.cookie.remove('_busrId');
		this.cookie.remove('_empType');
		this.cookie.remove('contractDetails');
        this.cookie.remove('_PSId');
        this.cookie.remove('expenseReportId');
        this.cookie.remove('_isNew');
        this.cookie.remove('_empType');
        this.cookie.remove('_onbehalf');
        this.cookie.remove('_onbehalfType');
        this.cookie.remove('_previousUrl');
        this.cookie.remove('_roleTypename');
  		this.cookie.remove('_roleType');
  		this.cookie.remove("_addUser");
  		this.cookie.remove('_isFirst');
  		this.cookie.remove('_expOpen');
  		this.cookie.remove('status');
  		 this.cookie.remove("editUser");
		 this.cookie.remove("_projectModel",)
    setTimeout(() => {
    

      this.router.navigate(['/']);
         // window.location.reload();
        }, 1000);
    //this.router.navigate(['/'])
   

	}

	/********* Upload attachment to  Time sheet **********/
	updatePass() {
		//form.resetForm();
		//this.pwdChange.resetForm()
		////console.error(err);.log("ddd");
		this.display = true;
	}

	onPwdChange(form) {

        if(this.updatePassdata['password'] != this.updatePassdata['confopass']) {
             this.confoError=true;
             ////console.error(err);.log(this.confoError);
             return false;
           } else {
             this.confoError=false;
           }
       
       if(form.valid) {
		  
       	this.isLoading = true;
       	var url
       	if(this.userId) {
			  
			url="saveProfilePassword?userId="+this.userId+"&oldPassword="+this.updatePassdata['oldPassword']+"&newPassword="+this.updatePassdata['password']+"&UserIDfrom=BO&companyId="+this.compId;
       	}else {
       		
       		url="saveProfilePassword?userId="+this.portalId+"&oldPassword="+this.updatePassdata['oldPassword']+"&newPassword="+this.updatePassdata['password']+"&UserIDfrom=EM&companyId="+this.compId;
       	}
       
		let data =''; 
            this.http.postMethod(url,data,true,true).subscribe(
                    data => {
                        ////console.error(err);.log(data['status']);
                        if(data['status']=="Y") {
                        this.msgs = [];
                        this.msgs.push({severity:'success', detail:"Password updated Successfully!!"});
                       
                        setTimeout(() => { this.logOut();}, 400);
                        //form.resetForm();
                        this.display= false;
                        }
                        else{
                        this.msgs = [];
						
                        this.msgs.push({severity:'error', detail:data['message']});
                        }
                        
                    //this.isLoading = true;
                    },err => {},
                    () => {this.isLoading = false;});	
                }	
	}
	homeRedirect() {
		this.router.navigate(['/']);
	}
}
