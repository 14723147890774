import { Component, OnInit } from '@angular/core';
import { HttpService } from './../../shared/services/http.service';
import { InvokedataService } from './../../shared/services/invokedata.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import * as moment from 'moment';
@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.css']
})
export class ListNotificationComponent implements OnInit {

	constructor(private cookie:CookieService,private http: HttpService,private router: Router,private invoke:InvokedataService) { }
	compId;
	notificationList;
	notificationLength;
	showRead;
	nowDate;
	isEmpty = true;
	portalId;
	title;
	result;
	viewed = true;
	count;
  	viewmore = false;
  	show = 5;

	ngOnInit() {

		this.compId = this.cookie.get('_compnyId');
		this.portalId = this.cookie.get('_usrId');
		//console.log(this.portalId);
		if(this.compId != null || this.compId!=undefined) {
		this.isEmpty=false;
		this.getNotifications();

		}
		else {
			this.isEmpty=true;
		}
		// this.invoke.notificationCount.next("message");
		//console.log("count",this.invoke.notificationCount);
	}
	getNotifications(){
	this.count=0;
	var url="notification/GetUserNotifications/"+this.portalId+"/portal/0/0";
	//var url="portal/GetAnnouncements/"+this.compId;
	    this.http.getMethod(url,false).subscribe(
	                data => {
		                	var keys = Object.keys(data);
							this.notificationLength = keys.length;
							let now = moment(); // add this 2 of 4
							this.nowDate = now.format('YYYY-MM-DD');
	    					//this.invoiceObj[list.date] = now.format('DD-MMM-YYYY');
	                	if(this.notificationLength>0) {
	                		this.notificationList=data;
	                		//console.error(err);.log(this.announceList);
	                		for(var notification of this.notificationList) {
	                			let from = moment(notification.NotifcationDate);
	                			notification.NotifcationDate= from.format('YYYY-MM-DD, h:mm');
	                			if(notification.IsViewed == false){
	                				this.count = this.count + 1;
	                				//console.log("listgetcount",this.count);
	                				this.viewed = false;
	                			}
	                		}
	                		
	                	}
	                	else{
	                    	this.isEmpty=true;
	                    }
	                },err => {
								
							},
	                () => {});
		
	}

  gotoPage(title, id) {
			
			var url = "/notification/UpdateNotification";
			var data={
				'NotificationId':id,
				'IsViewed':true
			};
			//console.log(data);
			this.http.postMethod(url, data, false, true).subscribe(
			    data => {
			    	this.result=data;
			    	//console.log(this.result['SuccessFlag']);
			    	if(this.result['SuccessFlag']== true){
			    		this.getNotifications();
			    		
			    		if(title == "reports"){
						this.router.navigate(['/contractor/reports']);
						}else if( title == "timesheet"){
							this.router.navigate(['/contractor/timesheet']);
						}else if( title == "expense"){
							this.router.navigate(['/contractor/exp_list']);
						}else if( title == "contracts"){
							this.router.navigate(['/contractor/contracts']);
						}
						//console.log("listcount",this.count);
						if(this.count!=0){
						this.count = this.count - 1;
						}
						setTimeout(() => {
					    this.invoke.notificationCount.next(this.count);
					    }, 1000);
						
						// console.log("listcount",this.count);

			    	}

			    	
			    },err => {
							////console.error(err);.error(err);

							
						},
			    () => {});
			
	}

	viewMore(){
		this.show = this.notificationList.length;
		this.viewmore = true;
	}


	// viewMore(){
		
	// 	for(let i=this.counter+1;i<this.notificationList.length;i++){
	// 		//console.log(this.notificationList.length);
 //    		this.content.push(this.notificationList[i]);
 //    		if(i%5==0) break;

 //    		}
 //    		this.counter+=5;
 //    		//console.log('noti',this.notificationList.length,'counter',this.counter);
 //    		if(this.counter >= this.notificationList.length){
 //    			this.viewmore = true;
 //    		}
    		
	// }


}
