//Angular decorators list
import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Custom modules list
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogModule } from 'primeng/dialog';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
//Custom service list
import { HttpService } from './../../shared/services/http.service';
import { CookieService } from 'ngx-cookie';

import { Message } from 'primeng/api';
import { SessionService } from './../services/sessiontoken.service';
import { InvokedataService } from './../services/invokedata.service';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  encapsulation: ViewEncapsulation.None,
  
})
export class LandingComponent implements OnInit {
	
	//Initial setup 
	@ViewChild(SignaturePad) signaturePad: SignaturePad;
	signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
	'minWidth': 1,
	'canvasWidth': 300,
	'canvasHeight': 80
  };
  active;
  WorkContractId;
	dashMenu = [
			{ name:'My Pending Approvals' , icon :'/assets/images/icons/dash_myapprovals.png', routeUrl:'/client/mypending-approvals',class:'bg_blu',menuId:7},
			{ name:'Pending Approvals' , icon :'/assets/images/icons/dash_pending.png', routeUrl:'/client/pending-approvals',class:'bg_red',menuId:8},
			{ name:'Timesheet' , icon :'/assets/images/icons/dash_timesheet.png', routeUrl:'/client/timesheet_list',class:'bg_blu',menuId:11 },   
			{ name:'Expenses' , icon :'/assets/images/icons/dash_expense.png', routeUrl:'/client/exp_list',class:'bg_red',menuId:12},
			{ name:'Timesheet' , icon :'/assets/images/icons/dash_timesheet.png', routeUrl:'/client/emplist',class:'bg_blu',menuId:27 },   
			{ name:'Expense' , icon :'/assets/images/icons/dash_expense.png', routeUrl:'/client/expemp',class:'bg_red',menuId:28},
			{ name:'Users',icon :'/assets/images/icons/dash_users.png', routeUrl:'/client/users',class:'bg_blu',menuId:4},
			{ name:'Reports' , icon :'/assets/images/icons/dash_reports.png', routeUrl:'/client/reports',class:'bg_violet',menuId:6},
			{ name:'Contracts',icon :'/assets/images/icons/dash_contract.png', routeUrl:'/client/contracts',class:'bg_org',menuId:9},
			//{ name:'My Account',icon :'/assets/images/icons/dash_account.png', routeUrl:'/account/',class:'bg_green',menuId:1},
     { name: 'Leave Approvals', icon: '/assets/images/icons/LMS_Icon.png', routeUrl: '/client/pending-approval-vaction', class: 'menublue', menuId: 19 }

    ];
	limitedMenu = [];
	list = [];
	dashMenucon =  [
			{ name:'Timesheet' , icon :'/assets/images/icons/dash_timesheet.png', routeUrl:'/contractor/timesheet',class:'bg_blu',menuId:7, target: "_self" },
			{ name:'Expenses' , icon :'/assets/images/icons/dash_expense.png', routeUrl:'/contractor/exp_list',class:'bg_red',menuId:8, target: "_self"  },
			{ name:'Careers',icon :'/assets/images/icons/dash_careers.png', routeUrl:'/careers',class:'bg_blu',menuId:6, target:"_blank"},
			{ name:'Reports' , icon :'/assets/images/icons/dash_reports.png', routeUrl:'/contractor/reports',class:'bg_violet',menuId:9, target: "_self"  },
			{ name:'Contracts',icon :'/assets/images/icons/dash_contract.png', routeUrl:'/contractor/contracts',class:'bg_org',menuId:4 , target: "_self" }, 
			//{ name:'My Account',icon :'/assets/images/icons/dash_account.png', routeUrl:'/account',class:'bg_green',menuId:1, target: "_self"  }  
      { name: 'Raise Request', icon: '/assets/images/icons/dash_account.png', class: 'bg_green', menuId: 2, target: "_self" },
      { name: 'Leaves', icon: '/assets/images/icons/LMS_Icon.png', routeUrl: '/contractor/vacation-view', class: 'menublue', menuId: 19, target: "_self" }

    ];
	loginuserName ;	
	userRole;
	backuserProdetails;
	msgs: Message[] = [];
	newUser;
	isLoading = false;
	pending= false;
	isOld;
	backId;
	display: boolean = false;
	signdisplay: boolean = false;
	empDisplay: boolean = false;
	usertProdetails={
	'country': '',
	};
	usertEmpdetails={
		ExpiryDate: '',
		DOB:''
	};
	userType;
	isContractor;
	noSign;
	eSign;
	countryList;
	countryDesc;
	stateList;
	cityList;
	userId;
	isUpload;
	newsign;
	isBackoffice;
	isHeader;
	emptypes;
	popDisplay: boolean = false;
	profileDone: boolean = false;
	proDone : boolean = false;
  empDone: boolean = false;
  PasswordDisplay: boolean = false;
  divContactdisplay: boolean = false;
  divReferFreindDisplay: boolean = false;
  TaxForms;
  confoError: boolean = false;
  updatePassdata = {
    oldPassword: '',
    password: ''
  };
  ticketData = {
    FirstName: '',
    LastName: '',
    Email: '',
    DistributionList: '',
    Subject: null,
    Description: '',

  }
  modList;
  announceLength;
  compId;
  isEmpty: boolean=false;
  announceList;
  nowDate;
  LandingPageDetails;
  RegisterDomainId;
  EmgergeCompanyId;
  portalId;
  divClient;
  divConsultant;
  backOfficemenu;
  ConnectRecruiterId;
  ConnectRecruiter;
  PolicyList = [];
  QuickLinkList = [];
  OpporutunityList = [];
  EventList = [];
  PresentEventList = [];
  PortalCompanyList = [];
  BOCompanyList = [];
  SubBOCompanyList = [];
  Policy = [];
  hideRaiseRequests =[];
  hideBaningInfos =[];
  QuikLinks = [];
  QuickLinkSubBOID = [];
  OppSubBOID = [];
  Opportunities = [];
  tblQuickLinks;
  tblPolicy;
  tblOpportunity;
  OppCompList = [];
  eventlst = [];
  eventCompList = [];
  EventSubBOID = [];
  tblEvent;
  divVender;
  tblImg;
  imgPath;
  ImgCompList = [];
  RoleTypeId;
  workList = [];
  ContractIds: number;
  VacationDetails;
  NoCompanyInConfig: boolean = false;
  paidvacationBalance = {};
  NonpaidvacationBalance = {};
  SickvacationBalance = {};
  Currentyear;
  VacationBalance = [];
  divContrVacation: boolean = false;
  VacationConfig;
  puserId;
  linkTaxForms;
  linkFederal;
  linkProvince;
  LinkCompany;
  listVacationBalnce = [];
  AllVacationBalance = {};
  showBankingInfoMenu :boolean = true;
 
  contractno: boolean;
  empType: string;
  	constructor(private http: HttpService, private route:ActivatedRoute, private router:Router, private session:SessionService, private cookie: CookieService,private invokeData: InvokedataService ) {
  // 		this.invokeData.moduleList.subscribe(value => {
		// ////console.error(err);.log(value);
		// this.list=value;
		// });
  	 }
	
  	/**
  	 * [landing oninitial]
  	 */
  ngOnInit() {
  
		window.document.title= "BH Connect ";
		this.list = this.invokeData.getModules();
		////console.error(err);.log(this.list);
    this.isLoading = false;
    
    this.compId = this.cookie.get('_compnyId');
		this.loginuserName = this.cookie.get('_userName');
    this.userId = this.cookie.get('_usrId');
		//this.newUser = this.cookie.get('_isNew');
    this.backId = this.cookie.get('_busrId');
    this.puserId = this.cookie.get('_puserId');
    this.portalId=this.cookie.get('_usrId');
    this.empType =this.cookie.get('_empType');
    this.clearVacationContractId();
    this.getBackProfileinfo();
    this.getAnnouncements();
    this.getProfileinfo();// Ashok : added for ticket data filling 
    this.showHideMenuForPortalUsers(); //Ashok
    //this.GetJsonData();
    this.getContactOptions();
    this.GetVacationConfig();
    var d = new Date();
    this.Currentyear = d.getFullYear();
   // this.getWorkContractList();
    
    if (this.backId) {
      //this.LinkCompany = true;
			this.isOld = true;
		} else {
      this.isOld = false;
      //this.LinkCompany = false;
      
    }
		var uerType = this.cookie.get('_usrtyd');
        //var deuserId  = CryptoJS.AES.decrypt(uerType, 'e5(8`*DK8L`YW8T7');
        //this.userRole = deuserId.toString(CryptoJS.enc.Utf8);
    this.userRole = uerType;
    this.backOfficemenu = this.cookie.getObject('backOfficemenu');

    if (this.userRole == 500) {
      if (this.backOfficemenu.response.backofficeLogin == "Y") {
        this.getWorkContractList();
        this.GetJsonData();
      }
      else {
        this.GetVenderContractorJsonData(); this.isContractor = false; this.divVender = true;
      }
      }  //Direct contractor
    else if (this.userRole == 450) { this.GetVenderContractorJsonData(); this.isContractor = false; this.divVender = true;}  //Vender Contractor
    else if (this.userRole == 400)
    {
      this.GetVenderApproverJsonData();
    //  this.isContractor = false; this.divVender = true;
    }  //Vender Approver
    else if (this.userRole == 300)
    {
     
      if (this.backOfficemenu != null) {
          if (this.backOfficemenu.response.backofficeLogin == "Y") {
            this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain;
          //apporover menu config
          if (this.RegisterDomainId != 0) {
            this.http.GetVacationConfig()
              .subscribe(
                res => {
                  this.VacationConfig = res;
                  if (this.VacationConfig != null) {
                    var VacationMenuConfigeration = this.VacationConfig[0].VacationMenuConfig;
                    var VacationManuBOCompanyIDs = VacationMenuConfigeration[0].BOCompanyIDs;
                    var IsVacationsMenuExist = VacationManuBOCompanyIDs.indexOf(this.RegisterDomainId);
                    var VacationConfigeration = this.VacationConfig[0].VacationConfig;
                    var VacationBOCompanyIDs = VacationConfigeration[0].BOCompanyIDs;

                    if (IsVacationsMenuExist >= 0) {
                      this.VacationConfig[0].VacationMenuConfig[0].IsVacationMenuConfig = "Yes";
                      
                    }
                    else {
                      this.VacationConfig[0].VacationMenuConfig[0].IsVacationMenuConfig = "No";
                      this.limitedMenu = this.limitedMenu.filter(function (menulst) {
                        return menulst.menuId != 19;
                      });
                      
                    }
                              var HolBOCompanyIDs = this.VacationConfig[0].HolidayConfig[0].BOCompanyIDs;
                              var IsVacationsExist = VacationBOCompanyIDs.indexOf(this.RegisterDomainId);


                              if (IsVacationsExist >= 0) {
                                                     this.VacationConfig[0].VacationConfig[0].IsVacationConfig = "Yes";
                                                   }
                                                   else {
                                                     this.VacationConfig[0].VacationConfig[0].IsVacationConfig = "No";
                                                   }
                    //holiday config
                                         var IsHolidayExist = HolBOCompanyIDs.indexOf(this.RegisterDomainId);
                                         if (IsHolidayExist >= 0) {
                                           this.VacationConfig[0].HolidayConfig[0].IsHolidayConfig = "Yes";
                                         }
                                         else {
                                           this.VacationConfig[0].HolidayConfig[0].IsHolidayConfig = "No";
                                         }
                  }
                    //Utilization Config
                    var UtilizationConfigeration = this.VacationConfig[0].Utilization;
                    var utililBOCompanyIDs = UtilizationConfigeration[0].BOCompanyIDs;
                    var IsUtilizationExist = utililBOCompanyIDs.indexOf(this.RegisterDomainId);
                    if (IsUtilizationExist >= 0) {
                      this.VacationConfig[0].Utilization[0].IsExist = "Yes";
                    }
                    else {
                      this.VacationConfig[0].Utilization[0].IsExist = "No";
                    }

                    let vacation = this.session.GetVacationConfig(this.VacationConfig);

                })
            //
          }
          //end
          this.GetClientJsonData();
        }
      }
      
    }  
   
    if (this.userRole == 500) {
			//this.limitedMenu = this.dashMenucon;
			//this.isContractor = true;
          for (var i = 0; i < this.dashMenucon.length; i++) {
            if (this.dashMenucon[i].menuId !== 6 && this.dashMenu[i].menuId !== 1) {
              this.limitedMenu.push(this.dashMenucon[i]);
              //this.isContractor = true;
              //this.divConsultant = true;
            }
          } 
        
      this.backOfficemenu = this.cookie.getObject('backOfficemenu');
      if (this.backOfficemenu != null) {
        if (this.backOfficemenu.response.backofficeLogin == "Y") {
          //check for the bo logins
          this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain;
          if (this.RegisterDomainId != 0) {
            this.http.GetVacationConfig()
              .subscribe(
                res => {
                  this.VacationConfig = res;
                  if (this.VacationConfig != null) {
                    var VacationMenuConfigeration = this.VacationConfig[0].VacationMenuConfig;
                    var VacationManuBOCompanyIDs = VacationMenuConfigeration[0].BOCompanyIDs;
                    var IsVacationsMenuExist = VacationManuBOCompanyIDs.indexOf(this.RegisterDomainId);
                    if (IsVacationsMenuExist >= 0) {
                      this.VacationConfig[0].VacationMenuConfig[0].IsVacationMenuConfig = "Yes";
                      this.divContrVacation = true;
                    }
                    else {
                      this.VacationConfig[0].VacationMenuConfig[0].IsVacationMenuConfig = "No";
                      this.limitedMenu = this.limitedMenu.filter(function (menulst) {
                        return menulst.menuId != 19;
                      });
                      this.divContrVacation = false;
                    }
                  }
                })
            //
          }
          else {
            this.limitedMenu = this.limitedMenu.filter(function (menulst) {
              return menulst.menuId != 19;
            });
            this.divContrVacation = false;
          }
        }
        else {
          this.limitedMenu = this.limitedMenu.filter(function (menulst) {
            return menulst.menuId != 19;
          });
          this.divContrVacation = false;
        }
      }

      if (this.puserId != 0) {
        
        this.http.GetJsonData()
          .subscribe(
            res => {
              this.LandingPageDetails = res;
              var lstAccountTab = [];
              lstAccountTab = this.LandingPageDetails[0].AccountTab

              //get the employment type
debugger
              var url = "portal/GetPayrollInfo/" + this.puserId;
              this.http.getMethod(url, false).subscribe(
                data => {
                  // alert(data['EmploymentTypeId'])
                  if (data['EmploymentTypeId'] == 3) {
                    // this.linkTaxForms = true;

                    url = "portal/GetProfileDetails/" + this.puserId;
                    this.isLoading = true;
                    var mainData = "";
                    this.http.postMethod(url, mainData, false, true).subscribe(
                      mainData => {
                        let result: any = mainData;
                        var CountryID = mainData['CountryId']
                        var url = "portal/GetTaxForms/" + "" + this.puserId;
                        var data1 = "";
                        this.http.getMethod(url, false).subscribe(
                          data1 => {
                            if (data1 != []) {
                              //var TaxForms = data1;
                              this.TaxForms = data1;
                              for (let j = 0; j < lstAccountTab.length; j++) {

                                var lstEmergeCompanyID = [];
                                lstEmergeCompanyID = lstAccountTab[j].EmergeCompanyID;
                                var isInArray4 = lstEmergeCompanyID.indexOf(parseInt(this.compId));
                                var formdetails = this.TaxForms.filter(y => y.form_type === lstAccountTab[j].form_type);

                                if (isInArray4 >= 0 && formdetails.length > 0 ) {
                                  if (formdetails[0].form_type == "F") {
                                    this.linkFederal = true;
                                  }
                                  else if (formdetails[0].form_type == "P") {
                                    this.linkProvince = true;

                                  }

                                }
                                else {

                                }
                              }

                            }
                          })
                      });

                  }

                })

            })
      }




      //if (this.puserId != 0) {
      //  this.http.GetVacationConfig()
      //    .subscribe(
      //      res => {
      //        this.VacationConfig = res;
      //        if (this.VacationConfig != null) {
      //          var VacationMenuConfigeration = this.VacationConfig[0].VacationMenuConfig;
      //          var VacationManuBOCompanyIDs = VacationMenuConfigeration[0].BOCompanyIDs;
      //          var IsVacationsMenuExist = VacationManuBOCompanyIDs.indexOf(parseInt(this.puserId));
      //          if (IsVacationsMenuExist >= 0) {
      //            this.VacationConfig[0].VacationMenuConfig[0].IsVacationMenuConfig = "Yes";
      //            this.divContrVacation = true;
      //          }
      //          else {
      //            this.VacationConfig[0].VacationMenuConfig[0].IsVacationMenuConfig = "No";
      //            this.limitedMenu = this.limitedMenu.filter(function (menulst) {
      //              return menulst.menuId != 19;
      //            });
      //            this.divContrVacation = false;
      //          }
      //        }
      //      })
      //  //
      //}
      //else {
      //  this.limitedMenu = this.limitedMenu.filter(function (menulst) {
      //    return menulst.menuId != 19;
      //  });
      //  this.divContrVacation = false;
      //}
        }
    
        else {
			for(var i=0;i<this.dashMenu.length;i++)
			for(var j = 0; j< this.list.length;j++ )
			if(this.dashMenu[i].menuId == this.list[j].screenId) {
			this.limitedMenu.push(this.dashMenu[i]);
			}

		}
      this.backOfficemenu = this.cookie.getObject('backOfficemenu');
      
      if (this.backOfficemenu != null) {
        //this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain
        //this.EmgergeCompanyId = this.backOfficemenu.response.authenticateUser.companyID;

      }
      //new code


	}

	/**
  	 * [user Profile info]
  	 */
   getProfileinfo() {
    var userroleId = this.cookie.get('_usrtyd');
    var url;
    if(this.puserId > 0){
    url = "portal/GetProfileDetails/" + this.puserId;
    this.isLoading = true;
    var mainData = "";
    this.http.postMethod(url, mainData, false, true).subscribe(
      data => {
        //if(data['status']=='Y') {
        //////console.error(err);.log(data);

        let result: any = data;
        this.ticketData['ConsultantId'] = Number(this.puserId);
        this.ticketData['FirstName'] = result['FirstName'];
        this.ticketData['LastName'] = result['LastName'];
        this.ticketData['Email'] = result['EMail'];
        
      }, err => {
        ////console.error(err);.error(err);

      },
      () => { this.isLoading = false; });
    }
  }

	/**
  	 * [user Employee info]
  	 */
	getEmployeeinfo() {
		this.isLoading=true;
			var mainData="";
			this.isBackoffice = false;
			this.isHeader = false;
			let url = "portal/GetPayrollInfo/"+this.session.userId;
			this.http.getMethod(url,this.isBackoffice).subscribe(
			    data => {
			    	let result: any = data;
			    	this.empDone = true;
			    	this.usertEmpdetails = result;
					if(this.usertEmpdetails['DOB']) {
					let dob = moment(this.usertEmpdetails['DOB']); 
		    		this.usertEmpdetails['DOB'] = dob.format('DD-MMM-YYYY');
		    		}
		    		if(this.usertEmpdetails['ExpiryDate']) {
		    		let expiry = moment(this.usertEmpdetails['ExpiryDate']); 
		    		this.usertEmpdetails['ExpiryDate'] = expiry.format('DD-MMM-YYYY');
		    		}
					this.getEmploymenttype();
			    },err => {
							//console.error(err);.error(err);
							
						},
			    () => {this.isLoading = false;});

	}
	/**
  	 * [Get Employment Type]
  	 */
	getEmploymenttype()
	{
		var url = "portal/GetEmploymentType";
		this.http.getMethod(url, false).subscribe(
                    data => {
                    	////console.error(err);.log(data);
                    	this.emptypes=data;
                    	
                    },err => {
								//console.error(err);.error(err);
								
							},
                    () => {this.isLoading = false;});
	}

	/**
	 * Update Employee profile data
	 * @param {[Form]} data 
	 */
	submitEmpInfo(form) {
		for(var i=0;i<form._directives.length;i++)
        {
          //////console.error(err);.log(form._directives[i].valid);
          //this.error_status = true;
        }
       if(form.valid) {
       	this.isLoading=true; 
       	this.usertEmpdetails['ConsultantId']= this.session.userId;
       	if(this.usertEmpdetails['Email']==null) {
       		this.usertEmpdetails['Email']= "";
       	}
       	if(this.usertEmpdetails['LegalFirstName']==null) {
       		this.usertEmpdetails['LegalFirstName']= "";
       	}
       	if(this.usertEmpdetails['LegalLastName']==null) {
       		this.usertEmpdetails['LegalLastName']= "";
       	}

       	var url = "portal/ManagePayrollInfo?";
			this.http.postMethod(url, JSON.stringify(this.usertEmpdetails),false, true).subscribe(
                    data => {
                    	if(data['SuccessFlag']==true) {
                    	this.msgs = [];
                        this.msgs.push({severity:'success', detail:data['Message']});
                        this.empDisplay = false;
                    	} else {
                        this.msgs = [];
                        this.msgs.push({severity:'error', detail:data['Message']});
                        }
                    },err => {
								//console.error(err);.error(err);
								
							},
                    () => {this.isLoading = false;});
       }
   }
	/**
	 *Get Country List for Portal
	 */
	getCountry() {
		var stateID;
		////console.error(err);.log("gii");
		this.stateList=null;
		this.cityList=null;
		//////console.error(err);.log(this.usertProdetails['CountryId']);
		// if(this.usertProdetails['CountryId']==null) {
		// 	this.stateList=null;
		// 	this.cityList=null;
		// } else {
		this.isLoading=true;
		var url = "portal/GetCountry";
		 this.http.getMethod(url, false).subscribe(
                    data => {
                    	
                    	this.countryList=data;
                    	////console.error(err);.log(this.countryList);
                    	this.usertProdetails['CountryId']
                    	this.loadState(this.usertProdetails['CountryId']);
                    	//if(this.isContractor==false) {
						// for(var country of this.countryList) {
						// 	//////console.error(err);.log(country);
						// 	if(country.country==this.countryDesc) {
						// 	this.usertProdetails['CountryId']=country['country_id'];
						// 	//////console.error(err);.log(this.usertProdetails['CountryId']);

						// 	}
						// 	}countryList
							//var countryID = this.usertProdetails['CountryId'];
							//this.loadState(countryID);
							// stateID = this.usertProdetails['StateId'];
							// if(stateID !=null) {

							// 	//this.loadCity(stateID);
							// }
                    	//}
                    	
                    },err => {
								//console.error(err);.error(err);
								
							},
                    () => {
                    	this.isLoading = false;
                    });
		

	}

	/**
	 *Get State List for Portal 
	 * @param {[Country Id]} data 
	 */
	loadState(id) {
		if(this.usertProdetails['CountryId']==null) {
			this.stateList=null;
			this.cityList=null;
		} else {
			this.isLoading=true;
			////console.error(err);.log(id);
			var url = "portal/GetStates/"+id;
			this.http.getMethod(url, false).subscribe(
	                    data => {
	                    	//////console.error(err);.log(data);
	                    	this.stateList=data;
	                    	this.loadCity(this.usertProdetails['StateId']);
	                    },err => {
									//console.error(err);.error(err);
									
								},
	                    () => {
	                    	this.isLoading = false;});
		}
	}

	/**
	 *Get City List 
	 * @param {[State Id]} data 
	 */
	loadCity(id) {
		this.isLoading=true;
		////console.error(err);.log(id);
		var url = "portal/GetCity/"+id;
		this.http.getMethod(url, false).subscribe(
                    data => {
                    	//////console.error(err);.log(data);
                    	this.cityList=data;
                    	////console.error(err);.log(this.cityList);
                    	
                    },err => {
								//console.error(err);.error(err);
								
							},
                    () => {this.isLoading = false;});
	}


	/**
	 * Digital Signature Upload
	 * @param {[Object]} data 
	 */
	signComplete() {
		//////console.error(err);.log("fff");
		////console.error(err);.log(this.signaturePad.toDataURL());
		this.newsign = this.signaturePad.toDataURL();
		//this.eSign = this.newsign.split(',')[1];
		////console.error(err);.log(this.eSign);

	}

	clearSignature() {
		this.signaturePad.clear();
	}
	/**
	ESign Model Upload
	**/
	/********* Upload attachment to  Time sheet **********/
	uploadAttachment() {
		//////console.error(err);.log(i);
		this.signdisplay = true;
	}

	disMisspopUp() {
		this.display = false;
		this.isUpload = false;
		//this.clearSignature();
		//this.uploadForm.resetForm();
		//this.file.fileData = '';
	}
	disMissSignpopUp() {
		this.signdisplay = false;
		this.isUpload = false;
		//this.clearSignature();
		//this.uploadForm.resetForm();
		//this.file.fileData = '';
	}

	/**
	 * [Update E-Sign ]
	 *  
	 */
	updateSign() {
		////console.error(err);.log("Esign Updated");
		this.signdisplay = false;
		this.noSign = false;
		this.eSign=this.newsign.split(',')[1];
		this.usertProdetails['eSign']=this.newsign.split(',')[1];
	}

	/**
	 * Update profile data
	 * @param {[Form]} data 
	 */
	submitPersonal(form) {

		for(var i=0;i<form._directives.length;i++)
        {
          //////console.error(err);.log(form._directives[i].valid);
          //this.error_status = true;
        }
       if(form.valid) {
       	//this.selectedItem = this.countryList.find((x: any) => x.countryCode == this.usertProdetails['CountryId']); 
   		//////console.error(err);.log(this.selectedItem);

 
       	this.isLoading=true;
       	if(this.isContractor) {
       		if(this.usertProdetails['isAddressSameAsComp']==undefined)
       		{
       			this.usertProdetails['isAddressSameAsComp']="";
       		}
			for(var country of this.countryList) {
			   			if(country['country_id']==this.usertProdetails['CountryId'])
			   			{
			   				////console.error(err);.log(country['country']);
			   				this.usertProdetails['Country']=country['country'];
			   			}
			   	}
			   	////console.error(err);.log(this.usertProdetails['StateId'],this.stateList);
			for(var state of this.stateList) {
			   			if(state['state_id']==this.usertProdetails['StateId'])
			   			{
			   				////console.error(err);.log(state['state']);
			   				this.usertProdetails['State']=state['state'];
			   			}
			   	}
		   	for(var city of this.cityList) {
		   			if(city['city_id']==this.usertProdetails['CityId'])
		   			{
		   				////console.error(err);.log(city['city']);
		   				this.usertProdetails['City']=city['city'];
		   			}
		   	}
		   	if(this.usertProdetails['eSign']==null)
		   	{
		   		this.usertProdetails['eSign']="";
		   	}
		   	this.usertProdetails['CreatedBy']=this.userId; 
		   	this.usertProdetails['LastModifiedBy']= this.userId;	
       		var url = "portal/Updateprofile?";
			this.http.postMethod(url, this.usertProdetails,false, true).subscribe(
                    data => {
                    	if(data['SuccessFlag']==true) {
                    	this.msgs = [];
                        this.msgs.push({severity:'success', detail:data['Message']});
                    	} else {
                        this.msgs = [];
                        this.msgs.push({severity:'error', detail:data['Message']});
                        }
                    },err => {
								//console.error(err);.error(err);
								
							},
                    () => {this.isLoading = false;});

       	}
		
	 }	
		
	}
  //new changes
  GotoInfo(info) {
    this.backId = this.cookie.get('_busrId');
    if (info == "Personal") {
      this.router.navigate(['/account/myaccount']);
    }
    else if (info == "Banking") {
        this.router.navigate(['/account/bankdetails']);
    }
    else if (info == "password") {
      this.PasswordDisplay = true;
    }
    else if (info == "FederalTax") {
      this.router.navigate(['/account/federaltaxforms']);
    }
    else if (info == "ProvinceTax") {
      this.router.navigate(['/account/Provincetaxforms']);
    }
    else {
      
      if (this.backId != undefined) {
        this.router.navigate(['/account/compdetails']);

      }
      
    }
  }

  OpenNewLink(list)
  {
    if (list.Url != null && list.Url != "" && list.Id != 128) {
      window.open(list.Url);

      
    }
    else if (list.Id == 128) {
      this.divContactdisplay = true;
      this.ConnectRecruiter = this.ConnectRecruiterId;

    }
    else if (list.Id == 129) {
      this.divReferFreindDisplay = true;

    }

  }

  getAnnouncements() {
    this.compId = this.cookie.get('_compnyId');
    var url = "portal/GetAnnouncements/" + this.compId;
    this.http.getMethod(url, false).subscribe(
      data => {
        var keys = Object.keys(data);
        this.announceLength = keys.length;
        let now = moment(); // add this 2 of 4
        this.nowDate = now.format('YYYY-MM-DD');

        //this.invoiceObj[list.date] = now.format('DD-MMM-YYYY');

        if (this.announceLength > 0) {
          this.announceList = data;
          //console.error(err);.log(this.announceList);
          // for(var announce of this.announceList) {
          // 	let from = moment(announce.effective_from_date);
          // 	announce.effective_from_date= from.format('YYYY-MM-DD');
          // }
        }
        else {

        }
      }, err => {

      },
      () => { });

  }

  //Get direct contractor home page config Details
  GetJsonData() {
    
    
    this.http.GetJsonData()
      .subscribe(
        res => {
          this.LandingPageDetails = res;
          var LandingDetails = this.LandingPageDetails;

          this.backOfficemenu = this.cookie.getObject('backOfficemenu');
          if (this.backOfficemenu != null) {
            if (this.backOfficemenu.response.backofficeLogin == "Y") {
              //check for the bo logins
              this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain;
              //hide a raise request
              
              this.hideRaiseRequests = this.LandingPageDetails[0].HideRaiseRequest;
              this.BOCompanyList = this.hideRaiseRequests[0].BOCompanyIDs;
              var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if(isBOCompanyExists>=0){
                const index = this.limitedMenu.findIndex(l=> l.menuId == 2);// remove  menu
                this.limitedMenu.splice(index,1);
              }
              //Banking Info
              this.hideBaningInfos = this.LandingPageDetails[0].HideBankingInfo;
              this.BOCompanyList = this.hideBaningInfos[0].BOCompanyIDs;
              var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
              
              if(isBOCompanyExists>=0){
               this.showBankingInfoMenu = false;
               //console.log(this.showBankingInfoMenu)
              }
               //Banking Info
              

              //policy
              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].BOCompanyIDs;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray4 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].BOCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.RegisterDomainId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }
              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].BOCompanyIDs;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;
              var isInArray1 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray1 >= 0) {
                this.QuikLinks[0].Exist = "Yes";
                this.tblQuickLinks = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].BOCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist1 >= 0 && this.empType=="E") {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else if(IsItemExist1 >= 0){
                    
                    this.QuickLinkList[i].Exist = "Yes";
                    this.QuickLinkList[0].Exist = "No";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity

              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.OppCompList = this.Opportunities[0].BOCompanyIDs;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray2 = this.OppCompList.indexOf(this.RegisterDomainId);
              if (isInArray2 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].BOCompanyID;
                  const IsItemExist3 = this.OppSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].BOCompanyIDs;
              this.EventList = this.eventlst[0].Events;
              var isInArray3 = this.eventCompList.indexOf(this.RegisterDomainId);
              if (isInArray3 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].BOCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }

              //Imge
              var Companylst = this.LandingPageDetails[0].CompanyLogo;
              this.ImgCompList = Companylst[0].BOCompanyIDs
              var isInArray4 = this.ImgCompList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.tblImg = "Yes";
                this.imgPath = Companylst[0].ImgPath;
              }
              else {
                this.tblImg = "No";
              }

              //check if all table false navigate to vender screen

              if (this.tblPolicy == 'No' && this.tblOpportunity == 'No' && this.tblEvent == 'No' && this.tblImg == 'No') {
                this.LandingPageDetails = [];
                this.GetVenderContractorJsonData();
                this.isContractor = false;
                this.divVender = true;
                this.NoCompanyInConfig = true;
              } else {
                this.isContractor = true;
                this.divConsultant = true;
              }


              //Holiday Config
              this.http.GetVacationConfig()
                .subscribe(
                  res => {
                   
                    this.VacationConfig = res;
                    //var utililBOCompanyIDs = [];
                    // this.ColorConfig = this.VacationConfig[0].VacationType;
                    //let vacation = this.session.GetVacationConfig(this.VacationConfig);
                    if (this.VacationConfig != null) {
                      var VacationConfigeration = this.VacationConfig[0].VacationConfig;
                      var VacationBOCompanyIDs = VacationConfigeration[0].BOCompanyIDs;
                      var TimesheetConfigeration = this.VacationConfig[0].Timesheet;
                      var TimesheetBOCompnayIDs = TimesheetConfigeration[0].BOCompanyIDs;
                      var HolBOCompanyIDs = this.VacationConfig[0].HolidayConfig[0].BOCompanyIDs;
                      var IsVacationsExist = VacationBOCompanyIDs.indexOf(this.RegisterDomainId);
                      var TVacationMessgeConfigeration = this.VacationConfig[0].VacationAnnouncement;
                      var VMessageBOCompnayIDs = TVacationMessgeConfigeration[0].BOCompanyIDs;
                      

                     
                      if (IsVacationsExist >= 0) {
                        this.VacationConfig[0].VacationConfig[0].IsVacationConfig = "Yes";
                      }
                      else {
                        this.VacationConfig[0].VacationConfig[0].IsVacationConfig = "No";
                      }
                      //holiday config
                      var IsHolidayExist = HolBOCompanyIDs.indexOf(this.RegisterDomainId);
                      if (IsHolidayExist >= 0) {
                        this.VacationConfig[0].HolidayConfig[0].IsHolidayConfig = "Yes";
                      }
                      else {
                        this.VacationConfig[0].HolidayConfig[0].IsHolidayConfig = "No";
                      }

                      //Timesheet Validation
                      var IsTimesheeExist = TimesheetBOCompnayIDs.indexOf(this.RegisterDomainId);
                      if (IsTimesheeExist >= 0) {
                        this.VacationConfig[0].Timesheet[0].IsTimesheetValidation = "Yes";
                      }
                      else {
                        this.VacationConfig[0].Timesheet[0].IsTimesheetValidation = "No";
                      }
                      
                    // VacationDashboard Message
                    var IsVMessageExist = VMessageBOCompnayIDs.indexOf(this.RegisterDomainId);
                    if (IsVMessageExist >= 0) {
                      this.VacationConfig[0].VacationAnnouncement[0].IsMessageExist = "Yes";
                    }
                    else {
                      this.VacationConfig[0].VacationAnnouncement[0].IsMessageExist = "No";
                    }
                      




                      //Utilization Config
                      var UtilizationConfigeration = this.VacationConfig[0].Utilization;
                      var utililBOCompanyIDs = UtilizationConfigeration[0].BOCompanyIDs;
                      var IsUtilizationExist = utililBOCompanyIDs.indexOf(this.RegisterDomainId);
                      if (IsUtilizationExist >= 0) {
                        this.VacationConfig[0].Utilization[0].IsExist = "Yes";
                      }
                      else {
                        this.VacationConfig[0].Utilization[0].IsExist = "No";
                      }

                      let vacation = this.session.GetVacationConfig(this.VacationConfig);

                    }

                  });

             
            }


            else {
              
              //Policy
              this.EmgergeCompanyId = this.backOfficemenu.response.authenticateUser.companyID;
              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].EmergeCompanyID;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].EmergeCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.EmgergeCompanyId);
                  
                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }

              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].EmergeCompanyID;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;

              var isInArray1 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray1 >= 0) {
                this.tblQuickLinks = "Yes";
                this.QuikLinks[0].Exist = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].EmergeCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist1 >= 0) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity

              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.BOCompanyList = this.Opportunities[0].EmergeCompanyID;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray6 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray6 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].EmergeCompanyID;
                  const IsItemExist6 = this.OppSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist6 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }

              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].EmergeCompanyID;
              this.EventList = this.eventlst[0].Events;
              var isInArray5 = this.eventCompList.indexOf(this.EmgergeCompanyId);
              if (isInArray5 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].EmergeCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }


              //Img
              var Companylst = this.LandingPageDetails[0].CompanyLogo;
              this.ImgCompList = Companylst[0].EmergeCompanyID;
              var isInArray4 = this.ImgCompList.indexOf(this.EmgergeCompanyId);
              if (isInArray4 >= 0) {
                this.tblImg = "Yes";
                this.imgPath = Companylst[0].ImgPath;
              }
              else {
                this.tblImg = "No";
              }
            }
          }
      
        });

  }

  //Get direct client home page config Details

  GetClientJsonData() {

    this.http.GetClientJsonData()
      .subscribe(
        res => {
          this.LandingPageDetails = res;
          var LandingDetails = this.LandingPageDetails;

          this.backOfficemenu = this.cookie.getObject('backOfficemenu');
          if (this.backOfficemenu != null) {
            if (this.backOfficemenu.response.backofficeLogin == "Y" ) {
              //check for the bo logins
              this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain;
                 //hide a raise request
              
                 this.hideRaiseRequests = this.LandingPageDetails[0].HideRaiseRequest;
                 this.BOCompanyList = this.hideRaiseRequests[0].BOCompanyIDs;
                 var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
                 if(isBOCompanyExists>=0){
                   const index = this.limitedMenu.findIndex(l=> l.menuId == 2);// remove  menu
                   this.limitedMenu.splice(index,1);
                 }
                 //Banking Info
                 this.hideBaningInfos = this.LandingPageDetails[0].HideBankingInfo;
                 this.BOCompanyList = this.hideBaningInfos[0].BOCompanyIDs;
                 var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
                 
                 if(isBOCompanyExists>=0){
                  this.showBankingInfoMenu = false;
                  //console.log(this.showBankingInfoMenu)
                 }
   
             var roletypeList = [];
              roletypeList = this.backOfficemenu.response.identityBean.roles;
              if (roletypeList[0].roleTypeId == 100)
              { this.RoleTypeId = roletypeList[0].roleTypeId }
              else if (roletypeList[0].roleTypeId == 135) { this.RoleTypeId = roletypeList[0].roleTypeId;}
              else if (roletypeList[0].roleTypeId == 131) {
              this.RoleTypeId = roletypeList[0].roleTypeId;
               }
              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].BOCompanyIDs;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray4 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].BOCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.RegisterDomainId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }
              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].BOCompanyIDs;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;
              var isInArray1 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray1 >= 0) {
                this.QuikLinks[0].Exist = "Yes";
                this.tblQuickLinks = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].BOCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist1 >= 0) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity


              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.OppCompList = this.Opportunities[0].BOCompanyIDs;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray2 = this.OppCompList.indexOf(this.RegisterDomainId);
              if (isInArray2 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].BOCompanyID;
                  const IsItemExist3 = this.OppSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].BOCompanyIDs;
              this.EventList = this.eventlst[0].Events;
              var isInArray3 = this.eventCompList.indexOf(this.RegisterDomainId);
              if (isInArray3 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].BOCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }


              //Imge
              var Companylst = this.LandingPageDetails[0].CompanyLogo;
              this.ImgCompList = Companylst[0].BOCompanyIDs
              var isInArray4 = this.ImgCompList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.tblImg = "Yes";
                this.imgPath = Companylst[0].ImgPath;
              }
              else {
                this.tblImg = "No";
              }


              if (this.tblPolicy == 'No' && this.tblOpportunity == 'No' && this.tblEvent == 'No' && this.tblImg == 'No') {
                this.LandingPageDetails = [];
                this.GetVenderClientJsonData();
                this.isContractor = false;
                this.divVender = true;
                this.NoCompanyInConfig = true;
              } else {
               
                this.isContractor = true;
                this.divConsultant = true;
              }
             


            }




            else {
              //Policy
              this.EmgergeCompanyId = this.backOfficemenu.response.authenticateUser.companyID;
              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].EmergeCompanyID;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].EmergeCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.EmgergeCompanyId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }

              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].EmergeCompanyID;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;

              var isInArray1 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray1 >= 0) {
                this.tblQuickLinks = "Yes";
                this.QuikLinks[0].Exist = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].EmergeCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist1 >= 0) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity


              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.BOCompanyList = this.Opportunities[0].EmergeCompanyID;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray6 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray6 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].EmergeCompanyID;
                  const IsItemExist6 = this.OppSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist6 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].EmergeCompanyID;
              this.EventList = this.eventlst[0].Events;
              var isInArray5 = this.eventCompList.indexOf(this.EmgergeCompanyId);
              if (isInArray5 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].EmergeCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }

            }
          }


        });

  }

  //Get Vender contractor home page config Details

  GetVenderContractorJsonData() {

    this.http.GetVenderContractorJsonData()
      .subscribe(
        res => {
          this.LandingPageDetails = res;
          var LandingDetails = this.LandingPageDetails;

          this.backOfficemenu = this.cookie.getObject('backOfficemenu');
          if (this.backOfficemenu != null) {
            if (this.backOfficemenu.response.backofficeLogin == "Y" && this.NoCompanyInConfig==false) {
              
              //check for the bo logins
              this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain;
              //hide a raise request
              
              this.hideRaiseRequests = this.LandingPageDetails[0].HideRaiseRequest;
              this.BOCompanyList = this.hideRaiseRequests[0].BOCompanyIDs;
              var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if(isBOCompanyExists>=0){
                const index = this.limitedMenu.findIndex(l=> l.menuId == 2);// remove  menu
                this.limitedMenu.splice(index,1);
              }
              //Banking Info
              this.hideBaningInfos = this.LandingPageDetails[0].HideBankingInfo;
              this.BOCompanyList = this.hideBaningInfos[0].BOCompanyIDs;
              var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
              
              if(isBOCompanyExists>=0){
               this.showBankingInfoMenu = false;
               //console.log(this.showBankingInfoMenu)
              }
             
              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].BOCompanyIDs;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray4 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].BOCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.RegisterDomainId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }
              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].BOCompanyIDs;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;
              var isInArray1 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray1 >= 0) {
                this.QuikLinks[0].Exist = "Yes";
                this.tblQuickLinks = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].BOCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist1 >= 0) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity


              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.OppCompList = this.Opportunities[0].BOCompanyIDs;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray2 = this.OppCompList.indexOf(this.RegisterDomainId);
              if (isInArray2 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].BOCompanyID;
                  const IsItemExist3 = this.OppSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].BOCompanyIDs;
              this.EventList = this.eventlst[0].Events;
              var isInArray3 = this.eventCompList.indexOf(this.RegisterDomainId);
              if (isInArray3 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].BOCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }


              //Imge
              var Companylst = this.LandingPageDetails[0].CompanyLogo;
              this.ImgCompList = Companylst[0].BOCompanyIDs
              var isInArray4 = this.ImgCompList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.tblImg = "Yes";
                this.imgPath = Companylst[0].ImgPath;
              }
              else {
                this.tblImg = "No";
              }


            }




            else {
              //Policy
              this.EmgergeCompanyId = this.backOfficemenu.response.authenticateUser.companyID;
              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].EmergeCompanyID;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].EmergeCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.EmgergeCompanyId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }

              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].EmergeCompanyID;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;

              var isInArray1 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray1 >= 0) {
                
                this.QuikLinks[0].Exist = "Yes";
                this.tblQuickLinks = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].EmergeCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist1 >= 0 && this.empType==undefined) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else if(IsItemExist1 >= 0){
                    
                    this.QuickLinkList[i].Exist = "Yes";
                    this.QuickLinkList[0].Exist = "No";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity


              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.BOCompanyList = this.Opportunities[0].EmergeCompanyID;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray6 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray6 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].EmergeCompanyID;
                  const IsItemExist6 = this.OppSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist6 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].EmergeCompanyID;
              this.EventList = this.eventlst[0].Events;
              var isInArray5 = this.eventCompList.indexOf(this.EmgergeCompanyId);
              if (isInArray5 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].EmergeCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }



              //Imge
              var Companylst = this.LandingPageDetails[0].CompanyLogo;
              this.ImgCompList = Companylst[0].EmergeCompanyID;
              var isInArray4 = this.ImgCompList.indexOf(this.EmgergeCompanyId);
              if (isInArray4 >= 0) {
                this.tblImg = "Yes";
                this.imgPath = Companylst[0].ImgPath;
              }
              else {
                this.tblImg = "No";
              }
            
            }
          }


        });

  }

  //Get Vender Approver home page config Details

  GetVenderApproverJsonData() {

    this.http.GetVenderApproverJsonData()
      .subscribe(
        res => {
          this.LandingPageDetails = res;
          var LandingDetails = this.LandingPageDetails;

          this.backOfficemenu = this.cookie.getObject('backOfficemenu');
          if (this.backOfficemenu != null) {
            if (this.backOfficemenu.response.backofficeLogin == "Y" && this.NoCompanyInConfig==false) {
              //check for the bo logins
              this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain;
              this.hideRaiseRequests = this.LandingPageDetails[0].HideRaiseRequest;
              this.BOCompanyList = this.hideRaiseRequests[0].BOCompanyIDs;
              var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if(isBOCompanyExists>=0){
                const index = this.limitedMenu.findIndex(l=> l.menuId == 2);// remove  menu
                this.limitedMenu.splice(index,1);
              }
              //Banking Info
              this.hideBaningInfos = this.LandingPageDetails[0].HideBankingInfo;
              this.BOCompanyList = this.hideBaningInfos[0].BOCompanyIDs;
              var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
              
              if(isBOCompanyExists>=0){
               this.showBankingInfoMenu = false;
               //console.log(this.showBankingInfoMenu)
              }

              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].BOCompanyIDs;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray4 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].BOCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.RegisterDomainId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }
              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].BOCompanyIDs;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;
              var isInArray1 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray1 >= 0) {
                this.QuikLinks[0].Exist = "Yes";
                this.tblQuickLinks = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].BOCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist1 >= 0) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity


              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.OppCompList = this.Opportunities[0].BOCompanyIDs;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray2 = this.OppCompList.indexOf(this.RegisterDomainId);
              if (isInArray2 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].BOCompanyID;
                  const IsItemExist3 = this.OppSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].BOCompanyIDs;
              this.EventList = this.eventlst[0].Events;
              var isInArray3 = this.eventCompList.indexOf(this.RegisterDomainId);
              if (isInArray3 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].BOCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }


             


              //Imge
              var Companylst = this.LandingPageDetails[0].CompanyLogo;
              this.ImgCompList = Companylst[0].BOCompanyIDs
              var isInArray4 = this.ImgCompList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.tblImg = "Yes";
                this.imgPath = Companylst[0].ImgPath;
              }
              else {
                this.tblImg = "No";
              }

              if (this.tblPolicy == 'No' && this.tblOpportunity == 'No' && this.tblEvent == 'No' && this.tblImg == 'No') {
                this.LandingPageDetails = [];
                this.GetVenderApproverJsonData();
                this.isContractor = false;
                this.divVender = true;
                this.NoCompanyInConfig = true;
              } else {

                this.isContractor = true;
                this.divConsultant = true;
              }


            }




            else {
              //Policy
              this.EmgergeCompanyId = this.backOfficemenu.response.authenticateUser.companyID;
              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].EmergeCompanyID;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].EmergeCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.EmgergeCompanyId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }

              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].EmergeCompanyID;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;

              var isInArray1 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray1 >= 0) {
                this.tblQuickLinks = "Yes";
                this.QuikLinks[0].Exist = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].EmergeCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist1 >= 0) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity


              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.BOCompanyList = this.Opportunities[0].EmergeCompanyID;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray6 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray6 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                this.PresentEventList = [];
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].EmergeCompanyID;
                  const IsItemExist6 = this.OppSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist6 >= 0) {
                    
                    
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].EmergeCompanyID;
              this.EventList = this.eventlst[0].Events;
              var isInArray5 = this.eventCompList.indexOf(this.EmgergeCompanyId);
              if (isInArray5 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].EmergeCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }

            }
          }


        });

  }

  //Get Vender Client home page config Details
  GetVenderClientJsonData() {

    this.http.GetVenderClientJsonData()
      .subscribe(
        res => {
          this.LandingPageDetails = res;
          var LandingDetails = this.LandingPageDetails;

          this.backOfficemenu = this.cookie.getObject('backOfficemenu');
          if (this.backOfficemenu != null) {

            if (this.backOfficemenu.response.backofficeLogin == "Y" && this.NoCompanyInConfig == false) {
              //check for the bo logins
              this.RegisterDomainId = this.backOfficemenu.response.identityBean.registeredMVDomain;
              this.hideRaiseRequests = this.LandingPageDetails[0].HideRaiseRequest;
              this.BOCompanyList = this.hideRaiseRequests[0].BOCompanyIDs;
              var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if(isBOCompanyExists>=0){
                const index = this.limitedMenu.findIndex(l=> l.menuId == 2);// remove  menu
                this.limitedMenu.splice(index,1);
              }
              //Banking Info
              this.hideBaningInfos = this.LandingPageDetails[0].HideBankingInfo;
              this.BOCompanyList = this.hideBaningInfos[0].BOCompanyIDs;
              var isBOCompanyExists = this.BOCompanyList.indexOf(this.RegisterDomainId);
              
              if(isBOCompanyExists>=0){
               this.showBankingInfoMenu = false;
               //console.log(this.showBankingInfoMenu)
              }

              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].BOCompanyIDs;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray4 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].BOCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.RegisterDomainId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }
              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].BOCompanyIDs;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;
              var isInArray1 = this.BOCompanyList.indexOf(this.RegisterDomainId);
              if (isInArray1 >= 0) {
                this.QuikLinks[0].Exist = "Yes";
                this.tblQuickLinks = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].BOCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist1 >= 0) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity


              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.OppCompList = this.Opportunities[0].BOCompanyIDs;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray2 = this.OppCompList.indexOf(this.RegisterDomainId);
              if (isInArray2 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].BOCompanyID;
                  const IsItemExist3 = this.OppSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].BOCompanyIDs;
              this.EventList = this.eventlst[0].Events;
              var isInArray3 = this.eventCompList.indexOf(this.RegisterDomainId);
              if (isInArray3 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].BOCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.RegisterDomainId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }


              //Imge
              var Companylst = this.LandingPageDetails[0].CompanyLogo;
              this.ImgCompList = Companylst[0].BOCompanyIDs
              var isInArray4 = this.ImgCompList.indexOf(this.RegisterDomainId);
              if (isInArray4 >= 0) {
                this.tblImg = "Yes";
                this.imgPath = Companylst[0].ImgPath;
              }
              else {
                this.tblImg = "No";
              }
            }




            else {
              //Policy
              this.EmgergeCompanyId = this.backOfficemenu.response.authenticateUser.companyID;
              this.Policy = this.LandingPageDetails[0].Policy;
              this.BOCompanyList = this.Policy[0].EmergeCompanyID;
              this.PolicyList = this.Policy[0].Policies;
              var isInArray = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray >= 0) {
                this.Policy[0].Exist = "Yes";
                this.tblPolicy = "Yes";
                for (var i = 0; i < this.PolicyList.length; i++) {
                  this.SubBOCompanyList = this.PolicyList[i].EmergeCompanyID;
                  const IsItemExist = this.SubBOCompanyList.indexOf(this.EmgergeCompanyId);

                  if (IsItemExist >= 0) {
                    this.PolicyList[i].Exist = "Yes";
                  }
                  else { this.PolicyList[i].Exist = "No"; }
                }
              }
              else {
                this.Policy[0].Exist = "No";
                this.tblPolicy = "No";
              }

              //Quik links

              this.QuikLinks = this.LandingPageDetails[0].QuikLinks;
              this.BOCompanyList = this.QuikLinks[0].EmergeCompanyID;
              this.QuickLinkList = this.QuikLinks[0].QuickLink;

              var isInArray1 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray1 >= 0) {
                this.tblQuickLinks = "Yes";
                this.QuikLinks[0].Exist = "Yes";
                for (var i = 0; i < this.QuickLinkList.length; i++) {
                  this.QuickLinkSubBOID = this.QuickLinkList[i].EmergeCompanyID;
                  const IsItemExist1 = this.QuickLinkSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist1 >= 0) {
                    this.QuickLinkList[i].Exist = "Yes";
                  }
                  else { this.QuickLinkList[i].Exist = "No"; }
                }
              }
              else {
                this.QuikLinks[0].Exist = "No";
                this.tblQuickLinks = "No";
              }


              //Opportunity


              this.Opportunities = this.LandingPageDetails[0].Opportunities;
              this.BOCompanyList = this.Opportunities[0].EmergeCompanyID;
              this.OpporutunityList = this.Opportunities[0].Opportunity;
              var isInArray6 = this.BOCompanyList.indexOf(this.EmgergeCompanyId);
              if (isInArray6 >= 0) {
                this.Opportunities[0].Exist = "Yes";
                this.tblOpportunity = "Yes";
                for (var i = 0; i < this.OpporutunityList.length; i++) {
                  this.OppSubBOID = this.OpporutunityList[i].EmergeCompanyID;
                  const IsItemExist6 = this.OppSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist6 >= 0) {
                    this.OpporutunityList[i].Exist = "Yes";
                  }
                  else { this.OpporutunityList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblOpportunity = "No";
              }


              //events

              this.eventlst = this.LandingPageDetails[0].Events;
              this.eventCompList = this.eventlst[0].EmergeCompanyID;
              this.EventList = this.eventlst[0].Events;
              var isInArray5 = this.eventCompList.indexOf(this.EmgergeCompanyId);
              if (isInArray5 >= 0) {
                this.eventlst[0].Exist = "Yes";
                this.tblEvent = "Yes";
                for (var i = 0; i < this.EventList.length; i++) {
                  this.EventSubBOID = this.EventList[i].EmergeCompanyID;
                  const IsItemExist3 = this.EventSubBOID.indexOf(this.EmgergeCompanyId);
                  if (IsItemExist3 >= 0) {
                    this.PresentEventList.push({ 'Title': this.EventList[i].Title, 'Exist': "Yes" });
                    this.EventList[i].Exist = "Yes";
                  }
                  else { this.EventList[i].Exist = "No"; }
                }
              }
              else {
                this.Opportunities[0].Exist = "No";
                this.tblEvent = "No";
              }

              //Imge
              var Companylst = this.LandingPageDetails[0].CompanyLogo;
              this.ImgCompList = Companylst[0].EmergeCompanyID
              var isInArray5 = this.ImgCompList.indexOf(this.RegisterDomainId);
              if (isInArray5 >= 0) {
                this.tblImg = "Yes";
                this.imgPath = Companylst[0].ImgPath;
              }
              else {
                this.tblImg = "No";
              }


            }
          }


        });

  }

  OpenModal(dashItem) {
    if (dashItem.name == 'Raise Request') {
      this.ConnectRecruiter = null;
      this.divContactdisplay = true;

    }
  }

  getContactOptions() {
    this.compId = this.cookie.get('_compnyId');
    this.isLoading = true;
    var url = "portal/GetContactUsOptions/" + this.compId;
    this.http.getMethod(url, false).subscribe(
      data => {
        //////console.error(err);.log(data);
        if (data) {
          this.modList = data;

          for (var i = 0; i < this.modList.length; i++) {
            if (this.modList[i]['OptionId'] == 12) {
              this.ConnectRecruiterId = this.modList[i]['OptionId'];
              
            }
            this.modList[i]['Distribution_List'] = this.modList[i]['Distribution_List'].toString();

            ////////console.error(err);.log(setTimeout(() =>this.ticketData['DistributionList']="ashok.lakshmanan@brainhunter.com,anand.babu@brainhunter.com", 500));
          }
        } else {
        }
      }, err => {
        ////console.error(err);.error(err);
      },
      () => { this.isLoading = false; });
  }

  //contactUs
  getBackProfileinfo() {
    var uerType = this.cookie.get('_usrtyd');
    this.backId = this.cookie.get('_busrId');
    if (this.backId != undefined) {
      this.userRole = uerType;
      //var userroleId = this.cookie.get('_usrtyd');
      var url;
      url = "getProfile?userId=" + this.backId + "&userType=" + this.userRole;;
      this.isLoading = true;
      var mainData = "";
      this.http.postMethod(url, mainData, true, true).subscribe(
        data => {
          //if(data['status']=='Y') {
          //////console.error(err);.log(data);

          let result: any = data;
          this.ticketData['ConsultantId'] = Number(this.backId);
          this.ticketData['FirstName'] = result['response']['user']['firstName'];
          this.ticketData['LastName'] = result['response']['user']['lastName'];
          this.ticketData['Email'] = result['response']['user']['emailAddr'];

        }, err => {
          //console.error(err);.error(err);

        },
        () => { this.isLoading = false; });
    }
  }

  sendQuery(form) {
    for (var i = 0; i < form._directives.length; i++) {
      ////////console.error(err);.log(form._directives[i].valid,form._directives[i]);
      //this.error_status = true;
    }
    ////////console.error(err);.log(form.valid);
    if (form.valid) {
    
      var url = "portal/SubmitContactUs";
      const distributionList = this.modList.find(l=> l.OptionId == this.ConnectRecruiter).Distribution_List;
      this.ticketData['DistributionList'] = distributionList;
      var data = this.ticketData;
      this.isLoading = true;
      //////console.error(err);.log(data);
      this.http.postMethod(url, data, false, true).subscribe(
        data => {
          //////console.error(err);.log(data);
          if (data['SuccessFlag'] = true) {
            this.msgs = [];
            this.msgs.push({ severity: 'success', detail: data['Message'] });
             form.resetForm();
          } else {
            this.msgs = [];
            this.msgs.push({ severity: 'error', detail: data['Message'] });

          }
          this.divContactdisplay = false;

        }, err => {
          //console.error(err);.error(err);

        },
        () => {
          this.isLoading = false;
        });
    }

  }

  //update password
  onPwdChange(form) {
   
    if (this.updatePassdata['password'] != this.updatePassdata['confopass']) {
      this.confoError = true;

      return false;
    } else {
      this.confoError = false;
    }

    if (form.valid) {
      this.isLoading = true;
      var url

      if (this.backId) {
        url = "saveProfilePassword?userId=" + this.backId + "&oldPassword=" + this.updatePassdata['oldPassword'] + "&newPassword=" + this.updatePassdata['password'] + "&UserIDfrom=BO&companyId=" + this.compId;
      } else {
        //this.userId = 
        url = "saveProfilePassword?userId=" + this.portalId + "&oldPassword=" + this.updatePassdata['oldPassword'] + "&newPassword=" + this.updatePassdata['password'] + "&UserIDfrom=EM&companyId=" + this.compId;
      }
      //http://uat-bo.brainhunter.com/backoffice/BHBOContractorService/saveProfilePassword?userId=26623&oldPassword=Admin$123&newPassword=Test123&UserIDfrom=EM&companyId=7
      ////console.error(err);.log(url);
      //url="saveProfilePassword?userId="+this.userId+"&oldPassword="+this.updatePassdata['oldPassword']+"&newPassword="+this.updatePassdata['password']+"&userEmail="+this.updatePassdata['emailId'];
      let data = '';
      this.http.postMethod(url, data, true, true).subscribe(
        data => {
          if (data['status'] == "Y") {
            this.msgs = [];
            this.msgs.push({ severity: 'success', detail: "Password updated Successfully!!" });

            setTimeout(() => {
              this.logOut();
            }, 400);
            //form.resetForm();
            this.PasswordDisplay = false;
          }
          else {
            this.msgs = [];
            this.msgs.push({ severity: 'error', detail: data['message'] });
          }

          //this.isLoading = true;
        }, err => { },
        () => { this.isLoading = false; });
    }
  }

  //Logout
  logOut() {
    //////console.error(err);.log("hh");
    this.msgs = [];
    this.msgs.push({ severity: 'success', detail: 'Logged out Successfully' });
    //this.router.navigate(['/client/home']);

    this.session.emptyDetails();
    this.cookie.remove('_acctok');
    this.cookie.remove('_usrId');
    this.cookie.remove('_usrtyd');
    this.cookie.remove('_exttime');
    this.cookie.remove('_compnyId');
    this.cookie.remove('_userName');
    this.cookie.remove('_busrId');
    this.cookie.remove('_empType');
    this.cookie.remove('contractDetails');
    this.cookie.remove('_PSId');
    this.cookie.remove('expenseReportId');
    this.cookie.remove('_isNew');
    this.cookie.remove('_empType');
    this.cookie.remove('_onbehalf');
    this.cookie.remove('_onbehalfType');
    this.cookie.remove('_previousUrl');
    this.cookie.remove('_roleTypename');
    this.cookie.remove('_roleType');
    this.cookie.remove("_addUser");
    this.cookie.remove('_isFirst');
    this.cookie.remove('_expOpen');
    this.cookie.remove('status');
    this.cookie.remove("editUser");
    setTimeout(() => {
      this.router.navigate(['/']);
      window.location.reload();
    }, 0);


  }
  //
  getWorkContractList() {
    this.backId = this.cookie.get('_busrId');

    var url = "getWorkContractList?userId=" + this.backId + '&contractorId=' + this.backId;

    var data = "";
    var wrkConlist
    this.http.postMethod(url, data, true, true).subscribe(
      data => {
        if (data['status'] == 'Y') {
          wrkConlist = data['response']['list'];
          for (let i = 0; i < wrkConlist.length; i++) {
            this.ContractIds = wrkConlist[i].value;
            this.workList.push({ 'value': this.ContractIds, 'label': wrkConlist[i].label })
          }
         // hide expense menu 
         if(this.workList.length == 0){
          this.limitedMenu = this.limitedMenu.filter(function (menulst) {
            return menulst.menuId != 8;
          });       
         }
          this.getVacationReport();
        }
        else {
          this.msgs = [];
          this.msgs.push({ severity: 'error', detail: 'Please try again later' });
        }
      }, err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', detail: 'Server Side Failure' });
      })
  }
  //not userd so hided- ashok
  // getVacationReport1() {
  //   this.active = 0;
  //   this.backId = this.cookie.get('_busrId');
  //   this.isHeader = true;
  //   var url = "getWorkContractList?userId=" + this.backId;


  //   var data = "";
  //   this.http.postMethod(url, data, true, true).subscribe(
  //     data => {
  //       if (data['status'] == 'Y') {
  //         var wrkConlist = data['response']['list'];
       
  //         for (let i = 0; i < wrkConlist.length; i++) {
  //           var WorkContractId = wrkConlist[i].value;
  //     this.VacationDetails = [];
  //           var url = "getVacationReport?userId=" + this.backId + '&year=' + this.Currentyear + '&workContractId=' + wrkConlist[i].value;
  //     var data1 = "";
  //           this.http.postMethod(url, data1, true, true).subscribe(
  //             data1 => {
  //               if (data1['status'] == 'Y') {
  //                 this.VacationDetails = data1['response']['list'];
  //                 if (this.VacationDetails.length > 0) {
  //                   var accrued = 0;
  //                   var taken = 0;
  //                   var balance = 0;
  //                   //new
  //                   for (let i = 0; i < this.VacationDetails.length; i++) {
  //                     if (this.VacationDetails[i].vacationType == 'Paid Leave' || this.VacationDetails[i].vacationType == 'Sick Leave') {
  //                       this.VacationBalance.push({ 'vacationType': this.VacationDetails[i].vacationType, 'Accrued': this.VacationDetails[i].monthList[13].accrued, 'taken': this.VacationDetails[i].monthList[13].taken, 'balance': this.VacationDetails[i].monthList[13].balance, 'workContractId': WorkContractId})
  //                     }
  //                     else if (this.VacationDetails[i].vacationType != 'Non-paid Leave')
  //                     {
  //                       accrued = accrued + this.VacationDetails[i].monthList[13].accrued;
  //                       taken = accrued + this.VacationDetails[i].monthList[13].taken;
  //                       balance = accrued + this.VacationDetails[i].monthList[13].balance;
  //                       this.VacationBalance.push({ 'vacationType': 'Other vacations', 'Accrued': accrued, 'taken': taken, 'balance': balance, 'workContractId': WorkContractId })

  //                     }
  //                       }


  //                   //old
  //             //var PaidVacationsMonth = this.VacationDetails[0].monthList;
  //             //var NonPaidVacationsMonth = this.VacationDetails[1].monthList;
  //             //var SickMonth = this.VacationDetails[2].monthList;
  //             //var paidvacationBalances = PaidVacationsMonth[13];
  //             //var NonpaidvacationBalances = NonPaidVacationsMonth[13]
  //             //var SickivacationBalances = SickMonth[13]
  //             //var accrued = 0;
  //             //var taken = 0;
  //             //var balance = 0;
  //             //var vacations = {}
  //             //for (let j = 0; j < this.VacationDetails.length; j++) {
  //             //  if (this.VacationDetails[j].vacationType == 'Paid Vacation' || this.VacationDetails[j].vacationType == 'Sick') {
  //             //    this.VacationBalance.push({ 'vacationType': this.VacationDetails[j].vacationType, 'Accrued': this.VacationDetails[j].monthList[13].accrued, 'taken': this.VacationDetails[j].monthList[13].taken, 'balance': this.VacationDetails[j].monthList[13].balance })
  //             //  }
  //             //  else {
  //             //    accrued = accrued + this.VacationDetails[j].monthList[13].accrued;
  //             //    taken = taken + this.VacationDetails[j].monthList[13].taken;
  //             //    balance = balance + this.VacationDetails[j].monthList[13].balance;
  //             //    this.VacationBalance.push({ 'vacationType': 'Other vacations', 'Accrued': accrued, 'taken': taken, 'balance': balance })

  //             //  }
  //             //}

  //             //for (let m = 0; m < this.VacationBalance.length;m++) {
  //             //  //listVacationBalnce
               
  //             //}


  //             //this.VacationBalance.push({ 'PaidBalance': paidvacationBalances.balance, 'sickBalance': SickivacationBalances.balance, 'NonPaidBalance': NonpaidvacationBalances.balance, 'value': this.workList[i].value})
  //           }
  //         }
  //               else {
  //                 this.isEmpty = true;
  //           this.msgs = [];
  //           this.msgs.push({ severity: 'error', detail: 'Please try again later' });
  //         }

  //       }, err => {
  //         this.msgs = [];
  //         this.msgs.push({ severity: 'error', detail: 'Server Side Failure' });
  //       })
  //   }
  //       }

  //     })
  // }

  getVacationReport() {
    this.active = 0;
    this.backId = this.cookie.get('_busrId');
    this.isHeader = true;
    var url = "getWorkContractList?userId=" + this.backId;


    var data = "";
    this.http.postMethod(url, data, true, true).subscribe(
      data => {
        if (data['status'] == 'Y') {
          var wrkConlist = data['response']['list'];
          this.workList = [];
          //wrkConlist = data['response']['list'];
          for (let i = 0; i < wrkConlist.length; i++) {
            this.ContractIds = wrkConlist[i].value;
            this.workList.push({ 'value': this.ContractIds, 'label': wrkConlist[i].label })
          }
          if(this.workList.length > 0){//Ashok - verifying work contract exists
          this.WorkContractId = this.workList[0].value;
          this.VacationDetails = [];
          var url = "getVacationReport?userId=" + this.backId + '&year=' + this.Currentyear + '&workContractId=' + this.WorkContractId;
            var data1 = "";
            this.http.postMethod(url, data1, true, true).subscribe(
              data1 => {
                if (data1['status'] == 'Y') {
                  this.VacationDetails = data1['response']['list'];
                  if (this.VacationDetails.length > 0) {
                    var accrued = 0;
                    var taken = 0;
                    var balance = 0;
                    //new
                    for (let i = 0; i < this.VacationDetails.length; i++) {
                      if (this.VacationDetails[i].vacationType == 'Paid Leave' || this.VacationDetails[i].vacationType == 'Sick Leave') {
                        this.VacationBalance.push({ 'vacationType': this.VacationDetails[i].vacationType, 'accrued': this.VacationDetails[i].monthList[13].accrued, 'taken': this.VacationDetails[i].monthList[13].taken, 'balance': this.VacationDetails[i].monthList[13].balance, 'workContractId': this.WorkContractId })
                      }
                      else if (this.VacationDetails[i].vacationType != 'Non-paid Leave') {
                        accrued = accrued + this.VacationDetails[i].monthList[13].accrued;
                        taken = taken + this.VacationDetails[i].monthList[13].taken;
                        balance = balance + this.VacationDetails[i].monthList[13].balance;
                        var IsOtherVacation = this.VacationBalance.filter(x => x.vacationType === 'Other vacations')
                        if (IsOtherVacation.length > 0) {
                          for (let j = 0; j < this.VacationBalance.length; j++) {
                            if (this.VacationBalance[j].vacationType == 'Other vacations') {
                              this.VacationBalance[j].accrued = accrued;
                              this.VacationBalance[j].taken = taken;
                              this.VacationBalance[j].balance = balance;

                            }
                          }
                        }
                        else {
                          this.VacationBalance.push({ 'vacationType': 'Other vacations', 'accrued': accrued, 'taken': taken, 'balance': balance, 'workContractId': this.WorkContractId })

                        }

                      }
                    }
                    var IsOtherVacations = this.VacationBalance.filter(x => x.vacationType === 'Other vacations')
                    if (IsOtherVacations.length == 0) {
                      this.VacationBalance.push({ 'vacationType': 'Other vacations', 'accrued': 0, 'taken': 0, 'balance': 0, 'workContractId': this.WorkContractId })
                    }
                    var Sickvacation = this.VacationBalance.filter(x => x.vacationType === 'Sick Leave')
                    if (Sickvacation.length == 0) {
                      this.VacationBalance.push({ 'vacationType': 'Sick Leave', 'accrued': 0, 'taken': 0, 'balance': 0, 'workContractId': this.WorkContractId })
                    }
                    this.AllVacationBalance = {};
                    for (let j = 0; j < this.VacationBalance.length; j++) {
                      if (this.VacationBalance.length > 0) {
                        if (this.VacationBalance[j].vacationType == 'Paid Leave') {
                          this.AllVacationBalance['PaidAccrued'] = this.VacationBalance[j].accrued
                          this.AllVacationBalance['PaidTaken'] =  this.VacationBalance[j].taken
                          this.AllVacationBalance['PaidBalance'] =  + this.VacationBalance[j].balance
                        }
                        else if (this.VacationBalance[j].vacationType == 'Sick Leave') {
                          this.AllVacationBalance['SickAccrued'] = this.VacationBalance[j].accrued
                          this.AllVacationBalance['SickTaken'] = this.VacationBalance[j].taken
                          this.AllVacationBalance['SickBalance'] = + this.VacationBalance[j].balance
                        }
                        else {
                          this.AllVacationBalance['OthersAccrued'] = this.VacationBalance[j].accrued
                          this.AllVacationBalance['OthersTaken'] = this.VacationBalance[j].taken
                          this.AllVacationBalance['OthersBalance'] = + this.VacationBalance[j].balance
                        }
                      }
                    }


                    //old
                    //var PaidVacationsMonth = this.VacationDetails[0].monthList;
                    //var NonPaidVacationsMonth = this.VacationDetails[1].monthList;
                    //var SickMonth = this.VacationDetails[2].monthList;
                    //var paidvacationBalances = PaidVacationsMonth[13];
                    //var NonpaidvacationBalances = NonPaidVacationsMonth[13]
                    //var SickivacationBalances = SickMonth[13]
                    //var accrued = 0;
                    //var taken = 0;
                    //var balance = 0;
                    //var vacations = {}
                    //for (let j = 0; j < this.VacationDetails.length; j++) {
                    //  if (this.VacationDetails[j].vacationType == 'Paid Vacation' || this.VacationDetails[j].vacationType == 'Sick') {
                    //    this.VacationBalance.push({ 'vacationType': this.VacationDetails[j].vacationType, 'Accrued': this.VacationDetails[j].monthList[13].accrued, 'taken': this.VacationDetails[j].monthList[13].taken, 'balance': this.VacationDetails[j].monthList[13].balance })
                    //  }
                    //  else {
                    //    accrued = accrued + this.VacationDetails[j].monthList[13].accrued;
                    //    taken = taken + this.VacationDetails[j].monthList[13].taken;
                    //    balance = balance + this.VacationDetails[j].monthList[13].balance;
                    //    this.VacationBalance.push({ 'vacationType': 'Other vacations', 'Accrued': accrued, 'taken': taken, 'balance': balance })

                    //  }
                    //}

                    //for (let m = 0; m < this.VacationBalance.length;m++) {
                    //  //listVacationBalnce

                    //}


                    //this.VacationBalance.push({ 'PaidBalance': paidvacationBalances.balance, 'sickBalance': SickivacationBalances.balance, 'NonPaidBalance': NonpaidvacationBalances.balance, 'value': this.workList[i].value})
                  }
                }
                else {
                  this.isEmpty = true;
                  this.msgs = [];
                  this.msgs.push({ severity: 'error', detail: 'Please try again later' });
                }

              }, err => {
                this.msgs = [];
                this.msgs.push({ severity: 'error', detail: 'Server Side Failure' });
              })
            }
          //}
        }
       
      })
  }

  GetVacationReportDetails(result, index) {
    this.active = index;
    this.WorkContractId = result.value;
    this.VacationDetails = [];
    this.VacationBalance = [];
    var url = "getVacationReport?userId=" + this.backId + '&year=' + this.Currentyear + '&workContractId=' + result.value;
    var data1 = "";
    this.http.postMethod(url, data1, true, true).subscribe(
      data1 => {
        if (data1['status'] == 'Y') {
          this.VacationDetails = data1['response']['list'];
          if (this.VacationDetails.length > 0) {
            this.isEmpty = false;
            var accrued = 0;
            var taken = 0;
            var balance = 0;
            //new
            for (let i = 0; i < this.VacationDetails.length; i++) {
              if (this.VacationDetails[i].vacationType == 'Paid Leave' || this.VacationDetails[i].vacationType == 'Sick Leave') {
                this.VacationBalance.push({ 'vacationType': this.VacationDetails[i].vacationType, 'accrued': this.VacationDetails[i].monthList[13].accrued, 'taken': this.VacationDetails[i].monthList[13].taken, 'balance': this.VacationDetails[i].monthList[13].balance, 'workContractId': result.value })
              }
              else if (this.VacationDetails[i].vacationType != 'Non-paid Leave') {
                accrued = accrued + this.VacationDetails[i].monthList[13].accrued;
                taken = taken + this.VacationDetails[i].monthList[13].taken;
                balance = balance + this.VacationDetails[i].monthList[13].balance;
               var IsOtherVacation=  this.VacationBalance.filter(x => x.vacationType ==='Other vacations')
                if (IsOtherVacation.length > 0) {
                  for (let j = 0; j < this.VacationBalance.length; j++) {
                    if (this.VacationBalance[j].vacationType == 'Other vacations') {
                      this.VacationBalance[j].accrued = accrued;
                      this.VacationBalance[j].taken = taken;
                      this.VacationBalance[j].balance = balance;

                    }
                  }
                }
                else {
                  this.VacationBalance.push({ 'vacationType': 'Other vacations', 'accrued': accrued, 'taken': taken, 'balance': balance, 'workContractId': result.value })

                }

              }
            }

            var IsOtherVacations = this.VacationBalance.filter(x => x.vacationType === 'Other vacations')
            if (IsOtherVacations.length == 0) {
              this.VacationBalance.push({ 'vacationType': 'Other vacations', 'accrued': 0, 'taken': 0, 'balance': 0, 'workContractId': this.WorkContractId })
            }
            var Sickvacation = this.VacationBalance.filter(x => x.vacationType === 'Sick Leave')
            if (Sickvacation.length == 0) {
              this.VacationBalance.push({ 'vacationType': 'Sick', 'accrued': 0, 'taken': 0, 'balance': 0, 'workContractId': this.WorkContractId })
            }
            this.AllVacationBalance = {};
            for (let j = 0; j < this.VacationBalance.length; j++) {
              if (this.VacationBalance.length > 0) {
                if (this.VacationBalance[j].vacationType == 'Paid Leave') {
                  this.AllVacationBalance['PaidAccrued'] = this.VacationBalance[j].accrued
                  this.AllVacationBalance['PaidTaken'] = this.VacationBalance[j].taken
                  this.AllVacationBalance['PaidBalance'] = + this.VacationBalance[j].balance
                }
                else if (this.VacationBalance[j].vacationType == 'Sick Leave') {
                  this.AllVacationBalance['SickAccrued'] = this.VacationBalance[j].accrued
                  this.AllVacationBalance['SickTaken'] = this.VacationBalance[j].taken
                  this.AllVacationBalance['SickBalance'] = + this.VacationBalance[j].balance
                }
                else {
                  this.AllVacationBalance['OthersAccrued'] = this.VacationBalance[j].accrued
                  this.AllVacationBalance['OthersTaken'] = this.VacationBalance[j].taken
                  this.AllVacationBalance['OthersBalance'] = + this.VacationBalance[j].balance
                }
              }
            }


          }
          else {
            this.isEmpty = true;
          }
        }
        else {
         
          this.msgs = [];
          this.msgs.push({ severity: 'error', detail: 'Please try again later' });
        }

      }, err => {
        this.msgs = [];
        this.msgs.push({ severity: 'error', detail: 'Server Side Failure' });
      })
  }

  ApplyVacations(listsf) {
    this.WorkContractId;
    var listContract = {}
    listContract['label'] = '';
    listContract['value'] = this.WorkContractId;
    this.clearVacationContractId();
    let WorkcontractData = this.session.GetSelectedWorkcontract(listContract);
    this.router.navigate(['/contractor/vacation-view/apply-vacation']);
  }

  clearVacationContractId() {
    this.cookie.remove('VacationDetails');
  }

  GetVacationConfig() {
    this.http.GetVacationConfig()
      .subscribe(
        res => {
          this.VacationConfig = res;
          // this.ColorConfig = this.VacationConfig[0].VacationType;
          let vacation = this.session.GetVacationConfig(this.VacationConfig);

        });
  }
  showHideMenuForPortalUsers(){
    if(this.puserId > 0 && this.backId == undefined){
     //hide expense menu
      this.compId = this.cookie.get('_compnyId');
      this.http.GetJsonData()
      .subscribe(
        res => {
          this.LandingPageDetails = res;
          //hide a raise request
              
          this.hideRaiseRequests = this.LandingPageDetails[0].HideRaiseRequest;
          this.PortalCompanyList = this.hideRaiseRequests[0].EmergeCompanyIDs;
          var isCompanyExists = this.BOCompanyList.indexOf(this.compId);
             
          if(isCompanyExists>=0){
            const index = this.limitedMenu.findIndex(l=> l.menuId == 2);// remove  menu
            this.limitedMenu.splice(index,1);
    
          }
          //Banking Info
          this.hideBaningInfos = this.LandingPageDetails[0].HideBankingInfo;
          this.PortalCompanyList = this.hideBaningInfos[0].EmergeCompanyIDs;
          var isCompanyExists = this.BOCompanyList.indexOf(this.compId);
          
          if(isCompanyExists>=0){
           this.showBankingInfoMenu = false;
           //console.log(this.showBankingInfoMenu)
          }

          this.limitedMenu = this.limitedMenu.filter(function (menulst) {
            return menulst.menuId != 8;
          });       
        });
       
    }
    if(this.puserId > 0){
      this.hideContractMenu();
    }
  }
  hideContractMenu(){
    this.isLoading = true;
    var url = "onboarding/GetCurrentPlacementSummaries/" +this.puserId;
    ////console.error(err);.log(url,this.isLoading);
    this.http.getMethod(url,this.isBackoffice).subscribe(
        data => {
          if(Object.keys(data).length == 0){
            var url = "onboarding/GetOldPlacementsummaries/"+this.puserId;
            this.http.getMethod(url,this.isBackoffice).subscribe(
              oldData => {
                if(Object.keys(oldData).length == 0){
                  this.limitedMenu = this.limitedMenu.filter(function (menulst) {
                    return menulst.menuId = 4;
                  });        
                  
                }
         
              });
          }

        },err => {},
            () => {this.isLoading = false;
        });
  }
}

