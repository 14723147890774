//Angular decorators list
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {Location} from '@angular/common';
//Custom services list
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from './../../shared/services/http.service';
import { SessionService } from './../../shared/services/sessiontoken.service';
import {Message} from 'primeng/api';
import {DialogModule} from 'primeng/dialog';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {CalendarModule} from 'primeng/calendar';
import * as moment from 'moment';
class YourVisitor {
	exptype = String;
	country= String;
	currency= String;
	//nda_status;
	custom_fields = [];
	//nda_flag;
};

@Component({
	selector: 'app-expense-view',
	templateUrl: './expense-view.component.html',
	styleUrls: ['./expense-view.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class ExpenseViewComponent implements OnInit {

	
	// Initial setup
	@ViewChild('fileElement')  fileElement:any;
    @ViewChild('fileContent')  fileContent:any;
    note = '';
	result;
	userId = this.cookie.get('_usrId');
	accessToken = this.cookie.get('_acctok');
	msgs: Message[] = [];
	cntOptions;
	expType;
	workContractId;
	date3;
	maxDateValue = new Date();
	uniKeys;
	isLoading = false;
	invoiceObj = {};
	invoiceFields = [];
	expenseForm;
	expense = {
		   "expenseReportDesc":null,
		   "expenseReportName":null,
		   "expenseStatusCode":1,
		   "invCode":"",
		   "tax1Amount":0,
		   "tax1Id":-1,
		   "tax1Pct":0,
		   "tax2Amount":0,
		   "tax2Id":-1,
		   "tax2Pct":0,
		   "taxRate1Id":9,
		   "taxRate2Id":9,
		   "totalExpenseAmount":0,
		   "totalNetExpense":0,
		   "totalGSTITC":0,
		   "workContractId":'',
		   "clientName":'',
		   "contractId":null,
		   "tsAdmin":false,
		   "expenseAmount": 0,
		   "expenseReportId": '',
		   "contractorId":null,
		   "contractCode":null,
		   "chargeCurrencyCode":"CAD",
		   "itcCalculationMethod":2,
	};
	error_status;
	expenseCardList=[];
	deleteCount = 0;
	dynamicList = [];
	field = {};
	tmp = [];
	curList;
	currencyList;
	clientDetails;
	selectedClient=[];
	backUserId;
	isDisplay=false;
	display: boolean = false;
	file = {
        doctype:null,
        docname:null,
        fileData:'',
    }
    uploadFile;
    fileExten;
   	wrkContId;
	buserId;
	isUpload;
	isFile: boolean = false;
    fileName;
    docId;
    docPosition;
    docName;
    isdoneUpload = false;
    enabler = false;
    empType;
    isSoleCon : boolean = false;
    isEmpCon : boolean = false;
   	url;
   	expenseReportId;
   	enbsavCon : boolean = false;
   	subcon : boolean = false;
   	uploadForm;
   	submitUrl;
   	currentCount;
   	contractorId;
   	callDetail;
   	contractSelect : boolean = true;
   	isApproved : boolean = false;
   	approveList;
   	backuser;
   	accountName=null;
   	totalexpense=0;
   	updatedList = [];
  deletedArray = [];
  recieptDate;
	constructor(private http: HttpService, private session: SessionService,private cookie:CookieService,private route:ActivatedRoute, private router:Router, private location: Location) { }

  ngOnInit() {
		//////console.error(err);.log(this.location);
		window.document.title= "Expense List - Connect ";
		this.route.data.forEach((results:any) => {
			this.callDetail = this.cookie.get('_expOpen');
			this.result = results;
			////console.error(err);.log(this.result['onbehalf'], this.callDetail);
			this.session.getDetails();
			this.backUserId = this.session.backuserId;
			this.empType = this.session.empType;
			if(this.empType =='E') {
                    ////console.error(err);.log(this.empType);
                    this.isEmpCon = true;
                    //this.isEmp = true;
            } else {
					////console.error(err);.log(this.empType);
					this.isSoleCon = true;
			} 
			//////console.error(err);.log(this.backUserId);
			if(this.result.result['status']=='N') {
				this.msgs = [];
				this.msgs.push({severity:'error', detail:this.result['message']});
				this.router.navigate(['/client/home']);
			} else {
				//////console.error(err);.log(this.result.result['response']);
				////console.error(err);.log(this.callDetail);
				if(this.callDetail == "open") {
					// For OpenStatus Expense
					if(this.result['onbehalf']==true)
					{
						this.isSoleCon = false; 
						this.isEmpCon = true;
					}
					this.invoiceFields = [];
					this.enabler=true;
					this.expense.expenseReportName = this.result.result['response']['expReportVO'].expenseReportName;
					this.expense.expenseReportDesc = this.result.result['response']['expReportVO'].expenseReportDesc;
					this.expense.workContractId = this.result.result['response']['expReportVO'].workContractId;
					this.expense.totalExpenseAmount = this.result.result['response']['expReportVO']['expenseCardList']['totalExpenseAmount'];
					this.expense.totalNetExpense = this.result.result['response']['expReportVO']['expenseCardList']['totalExpenseAmount'];
					//this.expense.expenseAmount = this.result.result['response']['expReportVO']['expenseCardList'].totalExpenseAmount;
					this.cntOptions = this.result.result['response']['listCollection']['workContractList'];
					////console.error(err);.log(this.cntOptions,this.result.result['response']['listCollection']['expenseAccountCodeList']);
					//////console.error(err);.log(this.result.result['response']['expReportVO']['expenseReportId']);
					
					//
					this.expType = this.result.result['response']['listCollection']['expenseAccountCodeList'];
					////console.error(err);.log(this.expType);
					this.getcontData(this.expense.workContractId);
					var expenseReportId = this.result.result['response']['expReportVO']['expenseReportId'];
					if(expenseReportId)
					{
						this.expense.expenseReportId = expenseReportId;

					}
					
					this.expense.expenseAmount = this.result.result['response']['expReportVO']['expenseAmount'];
					this.expense.totalExpenseAmount = this.result.result['response']['expReportVO']['totalExpenseAmount'];
					////console.error(err);.log(this.expense.expenseAmount,this.expense.totalExpenseAmount);
					//this.curList = this.result.result['response']['listCollection']['currencyLocationList'];
					this.expenseCardList = this.result.result['response']['expReportVO']['expenseCardList'];
					if(this.expenseCardList.length>0) {
						for(var card of this.expenseCardList) {
							let now = moment(card.receiptDate); // add this 2 of 4
	    					card.receiptDate = now.format('DD-MMM-YYYY');
							this.invoiceObj['date'+(this.dynamicList.length)+this.deleteCount]=card.receiptDate;
							//this.invoiceObj['exptype'+(this.dynamicList.length)+this.deleteCount]=card.expenseType;
							this.invoiceObj['exptype'+(this.dynamicList.length)+this.deleteCount]=card.expenseTypeCode;
							let selectedItems= [{countryCode: card.countryCode,countryDesc:card.countryName,currencyCode:card.currencyCode,currencyDesc:card.currencyCodeDesc,stateList:[{provinceCode: card.provinceCode,provinceDesc : card.provinceName}]}];
							this.curList = selectedItems;
							// if(this.curList.length>0)
							// for(var contList of this.curList) {
							// if(contList.countryCode==card.countryCode) {
							// 	this.invoiceObj['country'+(this.dynamicList.length)+this.deleteCount]=contList;
							// 	////console.error(err);.log(this.invoiceObj['country'+(this.dynamicList.length)+this.deleteCount]);
							// }
							// }
							//this.invoiceObj['country'+(this.dynamicList.length)+this.deleteCount] = card.countryCode;
							this.invoiceObj['province'+(this.dynamicList.length)+this.deleteCount]=card.provinceCode;
							this.invoiceObj['currency'+(this.dynamicList.length)+this.deleteCount]=card.currencyCode;
							this.invoiceObj['exchange'+(this.dynamicList.length)+this.deleteCount]=card.expenseType;
							this.invoiceObj['description'+(this.dynamicList.length)+this.deleteCount]=card.expenseCardDesc;
							this.invoiceObj['totalamt'+(this.dynamicList.length)+this.deleteCount]=card.expenseAmount;
							this.invoiceObj['deleted'+(this.dynamicList.length)+this.deleteCount] = card.deleted;
							//if()
							this.invoiceObj['receiptDocName'+(this.dynamicList.length)+this.deleteCount]=card.receiptDocName;
							this.invoiceObj['receiptDocId'+(this.dynamicList.length)+this.deleteCount]=card.receiptDocId;
							////console.error(err);.log(card.receiptDocName);
							if(card.receiptDocName)
							{
								this.invoiceObj['isdoneUpload'+(this.dynamicList.length)+this.deleteCount]=true;
							}
							else {
								this.invoiceObj['isdoneUpload'+(this.dynamicList.length)+this.deleteCount]=false;
							}
							if(card.expenseCardId){
							this.invoiceObj['expenseCardId'+(this.dynamicList.length)+this.deleteCount] = card.expenseCardId;
							}
							////console.error(err);.log(card.expenseCardId);
							this.dynamicList.push({date:'date'+(this.dynamicList.length)+this.deleteCount, exptype:'exptype'+(this.dynamicList.length)+this.deleteCount,
								country:'country'+(this.dynamicList.length)+this.deleteCount,province:'province'+(this.dynamicList.length)+this.deleteCount,
								currency:'currency'+(this.dynamicList.length)+this.deleteCount,exchange:'exchange'+(this.dynamicList.length)+this.deleteCount
								,description:'description'+(this.dynamicList.length)+this.deleteCount,totalamt:'totalamt'+(this.dynamicList.length)+this.deleteCount, deleted:'deleted'+(this.dynamicList.length)+this.deleteCount,
								receiptDocName:'receiptDocName'+(this.dynamicList.length)+this.deleteCount,receiptDocId:'receiptDocId'+(this.dynamicList.length)+this.deleteCount,
								receiptUploadId:'receiptUploadId'+(this.dynamicList.length)+this.deleteCount,
								isdoneUpload:'isdoneUpload'+(this.dynamicList.length)+this.deleteCount,expenseCardId:'expenseCardId'+(this.dynamicList.length)+this.deleteCount});
							this.currentCount =this.dynamicList.length;
							this.updatedList = this.dynamicList;
						}
					}
					
						//this.getContryData();

						this.currencyList = this.result.result['response']['listCollection']['currencyCodeList'];
						//////console.error(err);.log(this.curList);
						// for(var i =0; i < this.expType.length; i++) {
						// 	if(this.expType[i]['accountName'] == "EX001" && this.expType[i]['expType'] != 'Expense') {
						// 		//////console.error(err);.log(this.tmp.indexOf('Expense'))
						// 		if(this.tmp.indexOf('Expense') == -1) {
						// 			this.expType[i].expType = "Expense";
						// 			this.tmp.push('Expense');
						// 		}
								
						// 	}
							
						// }
					}
					else {
						////console.error(err);.log("For Closed Status Expense");
						this.isDisplay = true;
						this.contractSelect = false;
						this.isApproved= true;
						this.expense.expenseReportName = this.result.result['response'].expenseReportName;
						this.expense.expenseReportDesc = this.result.result['response'].expenseReportDesc;
						this.expense.workContractId = this.result.result['response'].workContractId;
						this.expense.totalExpenseAmount = this.result.result['response']['expenseCardList']['totalExpenseAmount'];
						this.expense.totalNetExpense = this.result.result['response']['expenseCardList']['totalExpenseAmount'];
						//this.expense.expenseAmount = this.result.result['response']['expReportVO']['expenseCardList'].totalExpenseAmount;
						this.cntOptions = this.result.result['response']['workContractId'];
						this.expense.workContractId=this.result.result['response']['workContractId'];
						this.expense.clientName = this.result.result['response']['contractorName'];
						//this.expType = this.result.result['response']['listCollection']['expenseAccountCodeList'];
						this.expense.contractCode = this.result.result['response']['contractCode'];
						////console.error(err);.log(this.result.result['response']['contractCode']);
						this.expense.contractId = this.result.result['response']['contractId'];

						var expenseReportId = this.result.result['response']['expenseReportId'];
						if(expenseReportId)
						{
							this.expense.expenseReportId = expenseReportId;

						}
						
						this.expense.expenseAmount = this.result.result['response']['expenseAmount'];
						this.expense.totalExpenseAmount = this.result.result['response']['totalExpenseAmount'];
						////console.error(err);.log(this.expense.expenseAmount,this.expense.totalExpenseAmount);
						//this.curList = this.result.result['response']['listCollection']['currencyLocationList'];
						this.expenseCardList = this.result.result['response']['expenseCardList'];
                  this.approveList = this.result.result['response']['expenseApproveLogList'];
                  for (var note of this.approveList) {
                    let now = moment(note['createDate']); // add this 2 of 4
                    note['createDate'] = now.format('DD-MMM-YYYY h:mm a');

                  }

						if(this.expenseCardList.length>0) {
							for(var card of this.expenseCardList) {
								let now = moment(card.receiptDate); // add this 2 of 4
		    					card.receiptDate = now.format('DD-MMM-YYYY');
								this.invoiceObj['date'+(this.dynamicList.length)+this.deleteCount]=card.receiptDate;
								this.invoiceObj['exptype'+(this.dynamicList.length)+this.deleteCount]=card.expenseType;
								let selectedItems= [{countryCode: card.countryCode,countryDesc:card.countryName,currencyCode:card.currencyCode,currencyDesc:card.currencyCodeDesc,stateList:[{provinceCode: card.provinceCode,provinceDesc : card.provinceName}]}];
								this.curList = selectedItems;
								// if(this.curList.length>0)
								// for(var contList of this.curList) {
								// if(contList.countryCode==card.countryCode) {
								// 	this.invoiceObj['country'+(this.dynamicList.length)+this.deleteCount]=contList;
								// 	////console.error(err);.log(this.invoiceObj['country'+(this.dynamicList.length)+this.deleteCount]);
								// }
								// }
								//this.invoiceObj['country'+(this.dynamicList.length)+this.deleteCount] = card.countryCode;
								this.invoiceObj['province'+(this.dynamicList.length)+this.deleteCount]=card.provinceCode;
								this.invoiceObj['currency'+(this.dynamicList.length)+this.deleteCount]=card.currencyCode;
								this.invoiceObj['exchange'+(this.dynamicList.length)+this.deleteCount]=card.expenseType;
								this.invoiceObj['description'+(this.dynamicList.length)+this.deleteCount]=card.expenseCardDesc;
								this.invoiceObj['totalamt'+(this.dynamicList.length)+this.deleteCount]=card.expenseAmount;
								this.invoiceObj['deleted'+(this.dynamicList.length)+this.deleteCount] = card.deleted;
								//if()
								this.invoiceObj['receiptdocname'+(this.dynamicList.length)+this.deleteCount]=card.receiptDocName;
								this.invoiceObj['receiptDocId'+(this.dynamicList.length)+this.deleteCount]=card.receiptDocId;
								////console.error(err);.log(card.receiptDocName,card.receiptDocId);
								if(card.receiptDocName)
								{
									this.invoiceObj['isdoneUpload'+(this.dynamicList.length)+this.deleteCount]=true;
								}
								else {
									this.invoiceObj['isdoneUpload'+(this.dynamicList.length)+this.deleteCount]=false;
								}
								this.invoiceObj['expenseCardId'+(this.dynamicList.length)+this.deleteCount] = card.expenseCardId;
								////console.error(err);.log(card.expenseCardId);
								this.dynamicList.push({date:'date'+(this.dynamicList.length)+this.deleteCount, exptype:'exptype'+(this.dynamicList.length)+this.deleteCount,
									country:'country'+(this.dynamicList.length)+this.deleteCount,province:'province'+(this.dynamicList.length)+this.deleteCount,
									currency:'currency'+(this.dynamicList.length)+this.deleteCount,exchange:'exchange'+(this.dynamicList.length)+this.deleteCount
									,description:'description'+(this.dynamicList.length)+this.deleteCount,totalamt:'totalamt'+(this.dynamicList.length)+this.deleteCount,deleted:'deleted'+(this.dynamicList.length)+this.deleteCount,
									receiptDocName:'receiptDocName'+(this.dynamicList.length)+this.deleteCount,receiptDocId:'receiptDocId'+(this.dynamicList.length)+this.deleteCount,
									isdoneUpload:'isdoneUpload'+(this.dynamicList.length)+this.deleteCount,expenseCardId:'expenseCardId'+(this.dynamicList.length)+this.deleteCount});
								this.currentCount =this.dynamicList.length;
								this.updatedList = this.dynamicList;
							}
						}
						
							//this.getContryData();
							//this.currencyList = this.result.result['response']['listCollection']['currencyCodeList'];
							//////console.error(err);.log(this.curList);
							// for(var i =0; i < this.expType.length; i++) {
							// 	if(this.expType[i]['accountName'] == "EX001" && this.expType[i]['expType'] != 'Expense') {
							// 		//////console.error(err);.log(this.tmp.indexOf('Expense'))
							// 		if(this.tmp.indexOf('Expense') == -1) {
							// 			this.expType[i].expType = "Expense";
							// 			this.tmp.push('Expense');
							// 		}
									
							// 	}
								
							// }

					}
			//this.setIntial();
			
			////console.error(err);.log(this.expType, this.tmp);
		}

			//this.isLoading= false;
			//this.listFirst = results.result[0].ref_placement_summary_id;
	    });
		
	}
	/**
	* [Get Country & currency  List]
	*/
	getContryData() {
		var url = "getCurrencyLocationList?userId="+this.backUserId+"&contractorId="+this.contractorId;
        this.isLoading = true;
		var data ='';

        this.http.postMethod(url,data,true,true).subscribe(
                    data => { 
                    		//////console.error(err);.log(data);
                    		if(data['status']=='Y') {
                    			var count = 0;
								this.curList=data['response']["list"];
								//////console.error(err);.log(this.curList);
								if(this.expenseCardList.length>0) {
								for(var i=0 ;i<this.expenseCardList.length;i++) {
								if(this.curList.length>0)
								for(var contList of this.curList) {
								if(contList.countryCode==this.expenseCardList[i].countryCode) {
								this.invoiceObj['country'+i+count]=contList;
								for(var j=0;j<contList.stateList.length;j++) {
									//////console.error(err);.log(this.expenseCardList);
									if(this.expenseCardList[i].provinceCode==contList.stateList[j].provinceCode) {
									this.invoiceObj['province'+i+count]=contList.stateList[j];
									}
								}
								//////console.error(err);.log(this.invoiceObj['country'+i+count]);
									}
									}
									}
								}

                    		}
						//////console.error(err);.log(this.invoiceObj);
                    	},err => {
								//console.error(err);.error(err);
								this.msgs = [];
								this.msgs.push({severity:'error', detail:'Server Side Failure'});
								this.isLoading = false;
							},
                    () => {this.isLoading = false;});


	}
	getProvin(objData,cValue) {
		////console.error(err);.log(objData.target.value,cValue);
	}
	setIntial(){
			this.invoiceObj['date'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['exptype'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['country'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['province'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['currency'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['exchange'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['description'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['totalamt'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['receiptDocName'+(this.dynamicList.length)+this.deleteCount]='';
			this.invoiceObj['isdoneUpload'+(this.dynamicList.length)+this.deleteCount]=false;
			this.invoiceObj['deleted'+(this.dynamicList.length)+this.deleteCount] = false;
			this.dynamicList.push({date:'date'+(this.dynamicList.length)+this.deleteCount, exptype:'exptype'+(this.dynamicList.length)+this.deleteCount,
				country:'country'+(this.dynamicList.length)+this.deleteCount,province:'province'+(this.dynamicList.length)+this.deleteCount,
				currency:'currency'+(this.dynamicList.length)+this.deleteCount,exchange:'exchange'+(this.dynamicList.length)+this.deleteCount
				,description:'description'+(this.dynamicList.length)+this.deleteCount,totalamt:'totalamt'+(this.dynamicList.length)+this.deleteCount,deleted:'deleted'+(this.dynamicList.length)+this.deleteCount,
				receiptDocName:'receiptDocName'+(this.dynamicList.length)+this.deleteCount,receiptDocId:'receiptDocId'+(this.dynamicList.length)+this.deleteCount,
				receiptUploadId:'receiptUploadId'+(this.dynamicList.length)+this.deleteCount,
				isdoneUpload:'isdoneUpload'+(this.dynamicList.length)+this.deleteCount});
			this.currentCount =this.dynamicList.length;

	}
	/**
	* [Go back to contract]
	*/
	goBack() {

	this.location.back();
	}

	addExpense(form) {
		//////console.error(err);.log(form.valid);
        //////console.error(err);.log(form._directives);
        for(var i=0;i<form._directives.length;i++)
        {
          //////console.error(err);.log(form._directives[i].valid);
          //this.error_status = true;
        }
       
       if(form.valid) {
       		this.getContryData();
			this.invoiceFields = [];
			this.enabler=true;
			//////console.error(err);.log(this.dynamicList, this.invoiceFields);
			this.setIntial();
			//setTimeout(() => this.myValue = '0', 100);
			
			//////////console.error(err);.log(this.invoiceFields);
		}

	}
	getcontData(id) {
		//////console.error(err);.log(this.workContractId,id);
		this.selectedClient= [];
		this.isDisplay=false;
		this.clientDetails = this.cntOptions;
		////console.error(err);.log(this.clientDetails,this.expType);
		for(var client of this.clientDetails) {
			if(client['workContractId']==this.expense.workContractId && client['workContractId'] !=0 ) {
				//////console.error(err);.log(client);
				this.isDisplay = true;
				this.expense.workContractId=client.workContractId;
				this.expense.clientName=client.clientName;
				this.expense.contractCode=client.contractCode;
				this.expense.contractId=client.contractId;
				this.expense.contractorId = client.contractorId;
				this.contractorId= client.contractorId;
				// this.selectedClient.push(client);
				// ////console.error(err);.log(this.selectedClient);
				// this.backUserId= client['contractorId'];
				// ////console.error(err);.log(this.backUserId);
			}


		}
		for(var exty of this.expType) {

			////console.error(err);.log("exty",this.expense.workContractId,exty['workContractId']);
			if(exty['workContractId']==this.expense.workContractId && exty['workContractId'] !=0 ) {
				//this.accountName = exty.accountName;
				//////console.error(err);.log(this.accountName);
				this.tmp.push({'expenseTypeCode':Number(exty['accountCodeId']),'accountName':exty['accountName']});
			}
		}
		//var obj =this.wrkContract;
		//////console.error(err);.log(Object.values(obj));
		//this.clientDetails = this.cntOptions;
		//////console.error(err);.log(this.clientDetails);
		//this.clientDetails = this.cntOptions.filter(element => element['workContractId'] = '61510');

		
	}
	deleteExpense(i) {
		//++this.deleteCount;
		//this.dynamicList.splice(i, 1);
		this.invoiceObj[this.updatedList[i].deleted]=true;
		this.deletedArray.push(this.dynamicList[i]);
		
		console.log(this.dynamicList.splice(i, 1),this.deletedArray);
		//event.stopPropagation();

		//console.log(this.invoiceObj[this.updatedList[i].deleted]=true);
		console.log(this.invoiceObj,this.updatedList);
		--this.currentCount;
		if(this.currentCount<1)
		{
			this.enabler=false;

		}
	}
	uploadedDelete(i) {
        
        //console.error(err);.log(i);
        this.isUpload = false;
        this.file.fileData = '';
        //this.dynamicList[]
        this.invoiceObj[this.dynamicList[i]['receiptDocName']]=null;
        this.invoiceObj[this.dynamicList[i]['receiptDocId']]=null;
        this.invoiceObj[this.dynamicList[i]['receiptUploadId']]=null;
        this.invoiceObj[this.dynamicList[i]['isdoneUpload']] = false;
        console.log(this.dynamicList[i]);
        //this.invoiceObj[this.list[i][receiptDocName]]='';
    }
		
	/**
     File Drag and Drop Event
	*/
    public files: UploadFile[] = [];

    public dropped(event: UploadEvent) {
        this.files = event.files;
        //////console.error(err);.log(this.files);
        for (const droppedFile of event.files) {

          // Is it a file?
            if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                  // Here you can access the real file
                  //////console.error(err);.log(droppedFile.relativePath, file,file['name']);
                	  const formData = new FormData()
                  formData.append('fileData', file, File['name'])
                  //this.fileData=droppedFile.relativePath;
                  //////console.error(err);.log(this.file.fileData);
                 // this.file.fileData = file['name'];
                  //this.isUpload=true;
                  /**
                  // You could upload it like this:
                  const formData = new FormData()
                  formData.append('logo', file, relativePath)
                  **/
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
               // ////console.error(err);.log(droppedFile.relativePath, fileEntry);
            }
        }
    }

    public fileOver(event) {
        //////console.error(err);.log(event);
    }

    public fileLeave(event) {
        //////console.error(err);.log(event);
    }
	public uploadDoc() {
    	//////console.error(err);.log("Fff");
    	var link = document.createElement('input.image');
    	this.fileElement.nativeElement.click();
    	//////console.error(err);.log(this.fileElement);
    }

  showFile() {
		this.isUpload = true;
		let file=this.fileContent.nativeElement.files[0];
		//////console.error(err);.log(file.name);
		this.uploadFile = file.name;
	}
    /**
     * [Delete File]
     */
    deleteFile() {
        
        this.isUpload = false;
        this.file.fileData = '';

    }

    
    /* File to base 64 convertion */
    getBase64(file) {
        //////console.error(err);.log(file);
        this.fileExten = file['type'];
        //////console.error(err);.log(this.fileExten.split('/')[1]);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            //////console.error(err);.log(reader.result);
            return reader.result;
        };

        reader.onerror = function (error) {
            //////console.error(err);.log('Error: ', error);
        };
    }

    allowedFormat(fileExtension) {
        switch (fileExtension) {
                        case 'image/png': case 'image/jpeg': case 'image/jpg': case 'image/gif' :
                            return "Y";
                            //blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
                            //break;
                        case 'text/plain':
                            return "Y";

                        case 'application/pdf':
                            return "Y";

                        case 'application/msword': case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' :
                            return "Y";
                            //blob = new Blob([arrBuffer], {type: 'application/msword'});
                            //break;
                        case 'application/vnd.ms-excel': case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' :
                            return "Y";  
          case 'application/zip': case 'application/x-zip-compressed' : 
            return "Y";
          case 'message/rfc822':
            return "Y";
          case 'application/mbox':
            return "Y";
          case 'application/vnd.ms-outlook':
            return "Y";
          
                        default:
                            return "N";
                           // break;
                    }
    }

    /**
     * [upload description]
     * @param {[FormData]} form 
     */
  onUpload(form) {
        //////console.error(err);.log(form);
        
        //////console.error(err);.log(form._directives);
        for(var i = 0;i < form._directives.length; i++) {
           // ////console.error(err);.log(form._directives[i].valid);
            this.error_status = true;
        }
    
        if(form.valid) {
        	this.display = false; 
            this.isLoading = true;
            //////console.error(err);.log(this.fileContent.nativeElement.files[0]);
            let file=this.fileContent.nativeElement.files[0];
            //this.fileExten = file['type'];
            //var outPut = this.allowedFormat(this.fileExten);
          if (file['type'] == "") {
            var fileExtensions = file['name'].split('.')[1];
            if (fileExtensions == "msg") {
              //file['type'] = 'application/vnd.ms-outlook';
              this.fileExten = 'application/vnd.ms-outlook';
              var outPut = this.allowedFormat(this.fileExten);
            }
            else if (fileExtensions == "mbox") {
              this.fileExten = 'application/mbox';
              var outPut = this.allowedFormat(this.fileExten);
            }
          }

          else {
            this.fileExten = file['type'];
            var outPut = this.allowedFormat(this.fileExten);
          }
            if(outPut=="Y") {
            this.display = false; 
            this.isLoading = true;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = ()=> {
            //////console.error(err);.log(reader.result);
            var filecontent = reader.result;
            var fileData = filecontent.toString().split(',')[1];
           // ////console.error(err);.log(fileData);
            
            var PSId = this.cookie.get('_PSId');
            var DocumentExtn = this.fileExten.split('/')[1];
            var DocumentTypeId = this.file.doctype;
            var DocumentName  = this.file.docname;
           // ////console.error(err);.log()
            var url = 'uploadExpenseFileWithBase64?userId='+this.backUserId+"&fileName="+this.uploadFile;
            this.isUpload = true;
            //this.uploadFile= DocumentName;

            let data = "input="+encodeURIComponent(JSON.stringify(fileData));
            var form = new FormData();
            form.append('input',fileData);
            this.http.postBackMethod(url,data,true,false).subscribe(
                    data => {
                    	//////console.error(err);.log(data,data['response']['fileName']);
                    	
                       // ////console.error(err);.log(data['status']=='Y'); 
                        if(data['status']=='Y') {
                        this.isdoneUpload = true;
                        this.isFile = true;
                       	this.display = false;
                       	this.isUpload = false;
                       	this.invoiceObj['isdoneUpload'+(this.docPosition)+this.deleteCount]=true;
                       	this.docName = data['response']['fileName'];
                       	this.docId= data['response']['docId'];
                       	this.invoiceObj['receiptDocName'+(this.docPosition)+this.deleteCount]=this.docName;
                       	this.invoiceObj['receiptDocId'+(this.docPosition)+this.deleteCount]=this.docId;
                       	this.invoiceObj['receiptUploadId'+(this.docPosition)+this.deleteCount] = data['response']['receiptUploadId'];
                       	console.log(this.invoiceObj);
                       	////console.error(err);.log(this.docName,this.docId,this.invoiceObj['receiptUploadId'+(this.docPosition)+this.deleteCount]);
                        this.msgs = [];
                        this.msgs.push({severity:'success', detail:"Document uploaded successfully"});

                        //this.router.navigate(['/contractor/contracts']);
                        }
                        else{
                        this.msgs = [];
                        this.msgs.push({severity:'error', detail:data['message']});
                        }
                        
                    //this.isLoading = true;
                    },err => {},
                    () => {this.isLoading = false;});
            };
            reader.onerror = function (error) {
            //////console.error(err);.log('Error: ', error);
            };
            } else {
				this.display = true; 
                this.isUpload = false;
                this.file.fileData = '';
                this.error_status = true;

            }
        } 
    }
	/********* Upload attachment to  Time sheet **********/
	uploadAttachment(i) {
		//////console.error(err);.log(i);
		this.docPosition = i;
		this.display = true;
	}

	/**
     * [Convert base64 To ArrayBuffer]
     * @param {[Object]} data 
     */
    base64ToArrayBuffer(data) {
        var binaryString = window.atob(data);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

	/********* Download attachment  **********/
  downDoc(docId) {
		////console.error(err);.log(docId);
        var url = "downAttachemntInfo?userId="+this.backUserId+"&docId="+docId;
          //////console.error(err);.log(datamain,"ggg");
        //var arrBuffer = this.base64ToArrayBuffer(data['document']);
        var blob;
        this.http.postMethod(url,'',true,true).subscribe(
                data => {
                    ////console.error(err);.log(data);
                    if(data['status']=='Y') {

                    var arrBuffer = this.base64ToArrayBuffer(data['response']['contentBase64']);
                      var filename = data['response']['fileName'];
                      var fileExtension = filename.substr(filename.lastIndexOf(".") + 1);
                    //var fileExtension =  filename.split('.')[1];
                    ////console.error(err);.log(fileExtension);
                    
                    switch (fileExtension) {
                        case 'png': case 'jpeg': case 'jpg': case "PNG" : case "JPG" : case "JPEG" : case "gif" : case "GIF" :
                            blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
                            break;
                        case 'pdf':  case 'PDF':
                            blob = new Blob([arrBuffer], {type: 'application/'+fileExtension});
                            break;
                        case 'txt':
                            blob = new Blob([arrBuffer], {type: 'text/plain'});
                            break;     
                        case 'doc': case 'DOC' :
                            ////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/msword'});
                            break;
                        case 'docx': case 'DOCX' :
                        ////console.error(err);.log("click");
                        blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                        break;    
                        case 'xls': case 'XLS' : 
                            blob = new Blob([arrBuffer], {type: 'application/vnd.ms-excel'});
                            break;
                        case 'xlsx' : case 'XLSX' :
                        //console.log("fileExtension");
                        ////console.error(err);.log("click");
                        blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        break;
                      case 'zip': case 'ZIP' :
                        blob = new Blob([arrBuffer], { type: 'application/x-zip-compressed' });
                        break;
                      case 'eml': case 'EML':
                        blob = new Blob([arrBuffer], { type: 'message/rfc822' });
                        break;
                      case 'msg': case 'MSG':
                        blob = new Blob([arrBuffer], { type: 'application/vnd.ms-outlook' });
                        break;
                      case 'mbox': case 'MBOX':
                        blob = new Blob([arrBuffer], { type: 'application/vnd.ms-outlook' });
                        break;
                        default:
                        //$('#divFiles').text('File type: Unknown');
                    }

                    var url;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
				    {
				        window.open(window.URL.createObjectURL(blob), "_blank");
						  
				    } else {
                    url = window.URL.createObjectURL(blob);
                    //window.open(objectUrl);
                    }
                    var link = document.createElement('a');
                    link.href = url;
                    link.download=filename;
                    link.click();
                    setTimeout(function(){
						//document.body.removeChild(link);
						window.URL.revokeObjectURL(url);  
						}, 100);
                    this.msgs = [];
                    this.msgs.push({severity:'success', detail: filename.split('.')[0]+" Downloaded Successfully"});
                    this.isLoading = false;
                } else {
                    this.msgs = [];
                    this.msgs.push({severity:'error', detail:data['Message']});
                   }
                //this.isLoading = true;
                },err => {},
                () => {this.isLoading = false;
            });
        
    }
	disMisspopUp() {
		////console.error(err);.log("hi");
		this.display = false;
		this.isUpload = false;
		//this.uploadForm.resetForm();
		this.file.fileData = '';
	}

	getProvince(event)
	{
		////console.error(err);.log(event);
	}
	/********
	Submit Expense & Continue 
	@param {[expenseReportId]}

	********/

	saveexpCon() {
		this.subcon=true;
		this.submitUrl = 'saveExpense?userId=';
	}
	saveExp() {
		this.submitUrl = 'saveExpense?userId=';
	}
	subexpCon() {
		this.submitUrl = 'submitExpense?userId=';
	}
	/******* Submit Expense Call
	@param {[Formdata]}
	 **********/
	submitExpense(form) {
		if(this.result['onbehalf']==true)
		{
			this.backUserId =this.cookie.get('_onbehalf')
		}
       	this.expenseCardList=[];
		 for(var i=0;i<form._directives.length;i++)
        {
          ////console.error(err);.log(form._directives[i].valid);

          //this.error_status = true;
        }
       
       if(form.valid) {
       	//this.deletedArray.push(this.dynamicList);
       	for(i=0;i<this.dynamicList.length;i++){
       		this.deletedArray.push(this.dynamicList[i]);
       	}
       	console.log(this.deletedArray);
       	for (var list of this.deletedArray) {
       		if(this.invoiceObj[list.date]) {
			//let now = moment(this.invoiceObj[list.date]); 
   // 		this.invoiceObj[list.date] = now.format('DD-MMM-YYYY');
                      //changed by Raj
                      var dtRegex = new RegExp("^([0]?[1-9]|[1-2]\\d|3[0-1])-(JAN|FEB|MAR|APR|MAY|JUN|JULY|AUG|SEP|OCT|NOV|DEC)-[1-2]\\d{3}$", 'i');
                      if (dtRegex.test(this.invoiceObj[list.date]) == true) {
                        this.recieptDate = this.invoiceObj[list.date];
                      }
                      else {
                        let now = moment(this.invoiceObj[list.date]);
                        this.recieptDate = now.format('DD-MMM-YYYY');
                      }
    		}
    		if(this.invoiceObj[list.receiptDocName]=='' || this.invoiceObj[list.receiptDocName]==undefined)
    		{
    			////console.error(err);.log("ddd");
    			this.invoiceObj[list.receiptDocName]=null;
    		}
    		if(this.invoiceObj[list.description]=='' || this.invoiceObj[list.description]==undefined)
    		{
    			////console.error(err);.log("ddd");
    			this.invoiceObj[list.description]='';
    		}
    		// ////console.error(err);.log(this.curList.stateList);
    		// for(var dataCountry of this.curList.)
    		// {
    		// 	if(dataCountry.provinceCode == this.invoiceObj[list.province]) {
    		// 		this.invoiceObj[list.country]['stateList']['provinceDesc'] = dataCountry.provinceDesc;
    		// 	}
    		// }
    		//////console.error(err);.log(this.invoiceObj[list.country]['countryCode']);
       		this.expenseCardList.push({

       			countryCode:"CA",
       			currencyCode:"CAD",
       			exchangeRate:1,
       			expenseAmount:Number(this.invoiceObj[list.totalamt]),
       			expenseCardDesc:this.invoiceObj[list.description],
       			expenseTypeCode:Number(this.invoiceObj[list.exptype]),
       			gstHstITCValue:0,
       			netExpense: Number(this.invoiceObj[list.totalamt]),
       			provinceId:61,
       			receiptDate:1405396800000,
       			receiptDocName:this.invoiceObj[list.receiptDocName],
       			receiptDocId :this.invoiceObj[list.receiptDocId],
       			receiptUploadId : this.invoiceObj[list.receiptUploadId],
       			expenseAccountCodeId:Number(this.invoiceObj[list.exptype]),
                     // receiptDateStr: this.invoiceObj[list.date],
            receiptDateStr: this.recieptDate,
       			deleted:this.invoiceObj[list.deleted],
       			countryName:"Canada",
				currencyDesc:"CAD",
				expenseTypeDesc:"Expense",
				provinceName:"Ontario",
				expenseCardId: this.invoiceObj[list.expenseCardId]
				
			});
			////console.error(err);.log(this.invoiceObj[list.receiptDocId]);
			// if(this.invoiceObj[list.receiptDocId]){
			// 	this.expenseCardList['receiptDocId']=Number(this.invoiceObj[list.receiptDocId]);
			// 	this.expenseCardList['receiptUploadId'] = Number(this.invoiceObj[list.receiptUploadId]);

			// }
			this.totalexpense += Number(this.invoiceObj[list.totalamt]);
       	}
       	this.expense["totalExpenseAmount"] = Number(this.totalexpense.toFixed(2));
		var	sendingData = this.expense;
		sendingData["expenseCardList"]=this.expenseCardList;
		////console.error(err);.log(this.expenseCardList);
		var url = this.submitUrl+this.backUserId;
        this.isLoading = true;
		//var data ='';
		 let data = "jsonExpVOStr="+encodeURIComponent(JSON.stringify(sendingData));      
        this.http.postMethod(url,data,true,false).subscribe(
                    data => { 
                    	////console.error(err);.log(data);
                    		if(data['status']=='Y') {
                    	this.isLoading = false;
                        this.msgs = [];
                        this.msgs.push({severity:'success', detail:'Expense Added Successfully'});
                        //form.resetForm();
                        if(this.subcon == true){
                        	this.expenseReportId=data['response']['expenseReportId'];
                        	var result = this.session.setExpense(this.expenseReportId,"open");
							if(result == "Y" ) {
							//navi
							this.router.navigate(['contractor/exp_list/expinvoice_view']);
							//this.location.back();
							}
                        }
                        else {
                        	//this.location.back();
                        	this.expenseReportId=data['response']['expenseReportId'];
                        	var result = this.session.setExpense(this.expenseReportId,"open");
                        	if(this.submitUrl=="submitExpense?userId=")
                        	{
                        		//setTimeout(() => this.router.navigate(['']), 500);
                        		////console.error(err);.log("submitted")
                        		 setTimeout(() => {this.location.back();
        					      //window.location.reload();
        					  	 }, 200);

                        	} else {
								////console.error(err);.log("submitted else")
                        		setTimeout(() => {this.location.back();
        					     // window.location.reload();
        					  	 }, 200);

                        	}
                        	//setTimeout(() => this.router.navigate(['/contractor/expense_list']), 500);
             //            	setTimeout(() => {
             //            	 this.router.navigate(['/contractor/expense_list']);
        					//  window.location.reload();
        					// }, 600);
                        	//this.router.navigate(['contractor/home']);
                        }
                        //this.router.navigate(['client/invoice_view']);
                        }else{
                        this.msgs = [];
                        this.msgs.push({severity:'error', detail:'Please try again later'});
                        }
                    },err => {
								//console.error(err);.error(err);
								this.msgs = [];
								this.msgs.push({severity:'error', detail:'Server Side Failure'});
								this.isLoading = false;
							},
                    () => {this.isLoading = false;});
        }
	}
}

  
