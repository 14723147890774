import { Injectable } from '@angular/core';
import { Subject} from 'rxjs/Subject';
@Injectable()
export class InvokedataService {

    moduleList:Subject<any> = new Subject();
    notificationCount:Subject<any> = new Subject();
    list=[];
    passedVal = {
        typeExpense: "Y",
        typeTimesheet:"Y"
    }; 
    originalVal = {
        typeTimesheet:true,
        trackingId:'',
        endDate:'',
        startDate:'',
        typeExpense: true
    }
    statusList = [];
    constructor() { }

    setModules(list) {
        this.list= list;
        this.moduleList.next(list);
    }


    /**
    * [Set a Expense detail when move to new page]
    */
    getModules() {
        return this.list;
    }

    setReportsFilter(passedVal, originalVal, statusList) {
        this.passedVal = passedVal;
        this.originalVal = originalVal;
        this.statusList = statusList;
        ////console.error(err);.log(this.statusList);
    }

    getReportsFilter() {
        return {passedVal:this.passedVal, report: this.originalVal, statusList:this.statusList };
    }
}