//Angular decorators list
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {Location} from '@angular/common';
//Custom services list
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from './../../shared/services/http.service';
import { SessionService } from './../../shared/services/sessiontoken.service';
import {Message} from 'primeng/api';
import {DialogModule} from 'primeng/dialog';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {CalendarModule} from 'primeng/calendar';
import * as moment from 'moment';
import { trigger, state, style, animate, transition } from '@angular/animations';
class YourVisitor {
	exptype = String;
	country= String;
	currency= String;
	//nda_status;
	custom_fields = [];
	//nda_flag;
};

@Component({
	selector: 'app-expense-list',
	templateUrl: './expense-list.component.html',
	styleUrls: ['./expense-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('heroState', [
      state('active', style({ transform: 'translateX(0%) scale(1.1)' })),
      state('inactive', style({ transform: 'translateX(+11000%) scale(1)' })),
      transition('inactive => active', animate('100ms ease-in')),
      transition('active => inactive', animate('100ms ease-out')),
      transition('void => inactive', [
        style({ transform: 'translateX(-100%) scale(1)' }),
        animate(300)
      ]),
      transition('inactive => void', [
        animate(300, style({ transform: 'translateX(1100%) scale(1)' }))
      ]),
      transition('void => active', [
        style({ transform: 'translateX(0) scale(0)' }),
        animate(300)
      ]),
      transition('active => void', [
        animate(300, style({ transform: 'translateX(0) scale(0)' }))
      ])
    ])
  ]
})

export class ExpenseListComponent implements OnInit {

	
	// Initial setup
	@ViewChild('fileElement')  fileElement:any;
    @ViewChild('fileContent')  fileContent:any;
    isBackoffice = false;
	isHeader = true;
	isUpload = false;
    isEmpty = false;   
	class = "";
	isContract = true;
	msgs: Message[] = [];
	isLoading: boolean = false;
	userId;
	backUserid;
	timesheetList;
	bounce: any;
	filterShow :boolean = false;
	name: string;
	state = 'inactive';
	report= {
		typeTimesheet:true,
		trackingId:'',
		endDate:'',
		startDate:'',
		typeExpense: true
	};
	endDate;
	startDate;
	trackingId;
	statusList = [];
	filData ={
		typeExpense: "Y",
		typeTimesheet:"N",
		selectedStatusCodes:["1"]
	}
	timesheet_status: boolean = false;
	expense_status: boolean = false;
	p: number = 1;
    collection: any[];
    pageSize =7; 
    total;
    userType;
    buserId;
    isOld;
    isContractor: boolean = false;
    hideAdd : boolean = true;
    preselStatusList = [];
    exprouteUrl = "/client/expense";
    onBehalfId;
	display: boolean=false;
	deleteExpenseById: any;
	constructor(private http: HttpService, private session: SessionService,private cookie:CookieService,private route:ActivatedRoute, private router:Router, private location: Location) { }

  ngOnInit() {
		//////console.error(err);.log(this.location);
		window.document.title= "Expense List - Connect ";

		this.buserId = this.cookie.get('_busrId');
		//console.error(err);.log(this.buserId);
		//this.onBehalf = this.cookie.get('_onbehalf');
		 this.route.params.subscribe((params) => {
		 	
		 	this.onBehalfId = +params['user_id'];
		 	//console.error(err);.log(params,this.onBehalfId);
		 	if(this.onBehalfId) {
		 		//console.error(err);.log("error");
		 		this.buserId = this.onBehalfId;
		 		this.exprouteUrl = "/client/expemp/expense/"+this.onBehalfId;

		 	}
		});
		 //console.error(err);.log("fgg");
		let contractor = Number(this.cookie.get('_usrtyd'));
		if(contractor == 500) {
			this.isContractor = true;
			this.exprouteUrl = "/contractor/expense";
		}
		if(this.buserId) {
				this.isOld = true;
				//var filterObj = this.data.getReportsFilter();
				//this.report = filterObj.report;
				//this.preselStatusList = filterObj.statusList;
				this.checkWorkContracts();
				this.getReportdata(this.filData);
			} else {
				this.isOld = false;
				this.isEmpty = true;
			}
			
		
	}
	checkWorkContracts() {
		
		var url = "getWorkContractList?userId="+this.buserId;
		//searchTimesheet?userId=33831&jsonSearchTimesheetVOStr= { "typeExpense":"Y", "typeTimesheet":"Y" } 
		var data = "";
	 	this.http.postMethod(url, data,true,true).subscribe(
	    data => {
	    	//console.error(err);.log(data);
	    	var wrkConlist = data['response']['list'];
			//console.error(err);.log(wrkConlist,wrkConlist.length);
			if(data['status']=='Y') {
			if(wrkConlist.length>0)
			{
				//this.getExpenseDetails();
				this.hideAdd = false;
				//this.isLoading= false;
			} else {
				this.hideAdd = true;
			}
			}

	    })
	}
	getReportdata(filData){
		this.filData = filData;
		this.isLoading = true;
		this.userId = this.cookie.get('_usrId');
		this.backUserid = this.cookie.get('_busrId'); 
		if(this.onBehalfId) {
		 		////console.error(err);.log("error");
		 		this.backUserid = this.onBehalfId;
		}
		this.isHeader = true;
		let jsonSearchTimesheetVOStr= this.filData;
		var url = "searchTimesheet?userId="+this.backUserid+"&jsonSearchTimesheetVOStr="+encodeURIComponent(JSON.stringify(jsonSearchTimesheetVOStr));
		//searchTimesheet?userId=33831&jsonSearchTimesheetVOStr= { "typeExpense":"Y", "typeTimesheet":"Y" } 
		var data = "";
	 	this.http.postMethod(url, data,true,true).subscribe(
	    data => {
	    	if(data['status']=='Y') {
	    		this.pageChanged(1);
	    		this.timesheetList = data['response']['timesheetList'];
	    		this.collection = this.timesheetList;
	    		if(this.timesheetList.length>0){
					this.isEmpty = false;
	    		}else {
	    			this.isEmpty = true;
	    		}
	    		for(var i = 0; i < this.timesheetList.length; i++) {
	    		let now = moment(this.timesheetList[i].invStartDt); // add this 2 of 4
				this.timesheetList[i].invStartDt=now.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invStartDt);
				let to = moment(this.timesheetList[i].invEndDt); // add this 2 of 4
				this.timesheetList[i].invEndDt=to.format('DD-MMM-YYYY');
				////console.error(err);.log(this.timesheetList[i].invEndDt);
	    		}
	    		this.total=this.collection.length;
	    		var statusList = data['response']['statusList'];
	    		for(var i = 0; i < statusList.length; i++) {
	    			for(var j = 0; j < this.preselStatusList.length; j++) {

	    				if(statusList[i].desc == this.preselStatusList[j].desc && this.preselStatusList[j].checked) {
	    					statusList[i].checked = true;
	    				}
	    			}
	    		} 
	    		this.statusList = statusList;
	    		////console.error(err);.log(this.preselStatusList, this.statusList );
	    		this.userType =  data['response']['identityBean']['userTypeId'];
	    	}
	    	//this.listDoc= data['PSDocuments'];
	    	//this.uploadDoc= data['ConsultantDocuments'];
	    	//this.uploadDoc.Createdate =filecontent.split(',')[1] 
	    	
	    //this.isLoading = true;
	    },err => {},
	    () => {this.isLoading = false;
	});
	}
	pageChanged(event) {
  		////console.error(err);.log(event);
  		this.p=event;

  	}
  	goBack() {
		this.location.back();
	}

  getTimesheet(workContractId, targetDateStr, toDateStr, isExpense, expenseReportId, status, contractorId) {
		//this.isLoading=true;
		////console.error(err);.log(workContractId,targetDateStr,toDateStr,isExpense,expenseReportId);
		
			//expenseReportId = 
			////console.error(err);.log("E",status);
			var callDetail;
			if(status=="OPEN") {
				callDetail = "open";
			} else 
			{
				callDetail = "closed";

			}
    //By pervious working code if ((this.userType == 500) || (this.userType == 450)){
		if ((this.userType == 500)){
			var result = this.session.setExpense(expenseReportId,callDetail);
			if(result == "Y" ) {
				if(status=="VENDOR_APPROVED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "VENDOR_APPROVED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/contractor/exp_list/expinvoice_view']);
						} 
					}
				else if(status=="CLIENT_APPROVED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "CLIENT_APPROVED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/contractor/exp_list/expinvoice_view']);
						} 
					}
					else if(status=="INVOICED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "INVOICED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/contractor/exp_list/expinvoice_view']);
						} 
					}	
					else {
					////console.error(err);.log(this.userType);
					this.cookie.put('status', "");
					var olderUrl = this.router.url;
					this.cookie.put('_previousUrl', olderUrl);
					this.router.navigate(['/contractor/exp_list/expense_list']);
					}
				} 
			} else {
				if(status=="VENDOR_APPROVED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "VENDOR_APPROVED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/client/invoice_view']);
						} 
					}
				else if(status=="CLIENT_APPROVED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "CLIENT_APPROVED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/client/invoice_view']);
						} 
					}
				else if(status=="INVOICED") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "INVOICED");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/client/invoice_view']);
						} 
					}
				else if(status=="READY_TO_APPROVE") {
						var result = this.session.setExpense(expenseReportId,callDetail);
						if(result == "Y" ) {
						this.cookie.put('status', "READY_TO_APPROVE");

						////console.error(err);.log(this.userType);
						var olderUrl = this.router.url;
						this.cookie.put('_previousUrl', olderUrl);
						this.router.navigate(['/client/invoice_view']);
						} 
					}			
			   else {
					this.cookie.put('status', "");
					var result = this.session.setExpenseclient(expenseReportId,callDetail,contractorId);	
					if(result == "Y" ) {
					////console.error(err);.log(this.userType);
					var olderUrl = this.router.url;
					this.cookie.put('_previousUrl', olderUrl);
					this.router.navigate(['/client/exp_list/expemp/expense_list']);
					}
					}
				}
			
		}

  //new changes
  showFilter() {
    this.filterShow = true;
  }

  toggleState() {
    this.state = this.state === 'active' ? 'inactive' : 'active';
  }

  deleteExpense(expenseReportId:any){
	
	this.deleteExpenseById=expenseReportId;
	this.display=true;
  }
  OnDeleteExpenseReportById(){
	  
	 
	  let deleteExpenseUrl = "deleteExpenseReport?userId=" + this.buserId + "&expenseReportId=" + this.deleteExpenseById;
    var datas = '';
    this.http.postBackMethod(deleteExpenseUrl, datas, true, true).subscribe(
		 datas => {console.log(datas),
		this.display=false;
		this.getReportdata(this.filData);}
		 );
     
	  
  }
}

  
