//Angular decorators list
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
// Services List
import { HttpService } from './../../shared/services/http.service';
import { SessionService } from './../../shared/services/sessiontoken.service';
import * as moment from 'moment';
//Custom services list
import { CookieService } from 'ngx-cookie';
import {Message} from 'primeng/api';
import 'rxjs/Rx' ;

@Component({
	selector: 'app-olderengagement',
	templateUrl: './olderengagements.component.html',
	styleUrls: ['./olderengagements.component.css'],
	encapsulation: ViewEncapsulation.None
})

export class OlderEngagementComponent implements OnInit {
	
	//Initial setup 
	//@Input('userId') userId:any;
	//@Input('accessToken') accessToken:any;
	//@Input('result') results:any;
	//@Input('listDoc') listDoc:any;
	//@Input('uploadDoc') uploadDoc:any;
	//@Input('isLoading') isLoading:any;
	

	//listDoc:any;
	isBackoffice = false;
	isHeader = true;
	isUpload = false;
    isEmpty = false;   
	bisEmpty=false
	bisUpload = false;
	divhide:boolean
bdivhide:boolean
ismapped:boolean
	class = "";
	isContract = true;
	msgs: Message[] = [];
	result;
	listFirst;
	userId;
	ismappeddivhide1:boolean
ismappeddivhide2:boolean=true
	listDoc;
	uploadDoc;
	resultData;
	isLoading: boolean = false;
	p: number = 1;
    collection: any[];
    pageSize =7; 
    total;
    showUpload = true;
	bshowUpload = true;
    isZero = true;
	bisZero = true;
    active; 
	bactive;
	backId
	workList: any;
	listBoDoc: any;
	constructor(private http:HttpService, private session: SessionService,private cookie:CookieService,private route:ActivatedRoute, private router:Router,private location:Location) { }

	ngOnInit() {
		
		window.document.title= "Old Contracts - Connect ";
		this.backId = this.cookie.get('_busrId');
		this.userId = this.cookie.get('_usrId');
		this.route.data.forEach((results:any) => {
            ////console.error(err);.log(results.result.length);
            if(results.result.length>0) {
              this.isEmpty = false;
              this.resultData = results.result;
              this.active=0;
			  this.divhide=true
              ////console.error(err);.log(this.resultData);
              this.listFirst = results.result[0].ref_placement_summary_id;
              this.cookie.put('_PSId', this.listFirst);
              ////console.error(err);.log(this.listFirst);

              this.getDocs(this.listFirst,0);
			  
            } else {
             this.result = 0;
             this.isEmpty = true;
             this.isZero = false;
	         this.showUpload = false;
            } 
			if(this.backId!=undefined&& this.userId!=undefined){
				this.ismapped=true
				this.getBcontractdocuments(this.backId)
				//this.getOlderEngagements()
				this.divhide=false
				this.bdivhide=false
				//this.getOlderEngagements()
				if(this.result!=0){
					this.active=1
					this.bactive=null
				}
				else{
	this.bactive=0
				}
				
			}
			else if(this.backId!=undefined && this.ismapped==false ){
			  
				this.getBcontractdocuments(this.backId)
				this.bactive=0
				this.bdivhide=true
				this.divhide=false
			}
			
        });
       
        ////console.error(err);.log(this.userId);
		
  	}
	  getBcontractdocuments(backId)
	  {
		
	  var url = "getWorkContractListWithStatus?userId=" + backId
	  var data = "";
	  var wrkConlist
	  this.http.postMethod(url, data, true, true).subscribe(
		data => {
		  if (data['status'] == 'Y') {
			wrkConlist = data['response']['list'];
			var closedlist=wrkConlist.filter(x=>x.workContractStatus=="Closed")
			this.workList=closedlist
			if(this.workList.length!=0){
				
				this.getBcontractdoc(this.backId,this.workList[0].workContractId,this.bactive)
			}
			
			if(this.workList.length == 0) {
			  this.bisEmpty = true;
			  this.bshowUpload = false;
			  this.bisZero = true;
		  } else{
			  this.bisEmpty = false;
				let olderUrl =this.cookie.get('_previousUrl');
				////console.error(err);.log(olderUrl);
				
			   
				  this.active=0;
				
						  
				  if(olderUrl=='/contractor/upload')
				  {
				  this.active=Number(this.cookie.get('_index'));
				  //console.error(err);.log(olderUrl,this.active);
				  if(this.active==undefined) {
				  this.active=0;
				  }	
				  this.bisUpload=true;
				  this.cookie.remove('_previousUrl');
				  this.cookie.remove('_index');
  
				  }
		  }
		   
		   
		  }
		  else {
			this.msgs = [];
			this.msgs.push({ severity: 'error', detail: 'Please try again later' });
		  }
		}, err => {
		  this.msgs = [];
		  this.msgs.push({ severity: 'error', detail: 'Server Side Failure' });
		})
		
	}

  	/**
  	 * [Get document detail]
  	 * @param {[String]} contId [contract id]
  	 */
	getDocs(contId,index)	{
		if(this.ismapped==false){
			this.divhide=true
			this.bdivhide=false
		}
		
		
		this.isLoading = true;
		this.ismappeddivhide1=false
		this.ismappeddivhide2=true
		this.active=index;
		this.bactive=null
		//var user = this.userId;
		//////console.error(err);.log(user);
		this.isHeader = true;
		var url = "onboarding/GetPSDetails/"+contId+"/"+this.userId;
		////console.error(err);.log(url,this.isLoading);
	    this.http.postMethod(url, this.userId,this.isBackoffice,this.isHeader).subscribe(
	        data => {
	        	this.listDoc= data['PSDocuments'];
	        	////console.error(err);.log(this.listDoc.length);
	        	 if(this.listDoc.length==0) {
	                this.showUpload = false;
	                this.isZero = true;
                	////console.error(err);.log(this.isZero);
	              }else {
					for(var i = 0; i < this.listDoc.length; i++) {
					let now = moment(this.listDoc[i].created_date); // add this 2 of 4
					this.listDoc[i].created_date=now.format('DD-MMM-YYYY');
					}
	                this.isZero = false;
	                this.showUpload = true;
	              }
	        	this.uploadDoc= data['ConsultantDocuments'];
	        	for(var i = 0; i < this.uploadDoc.length; i++) {
					let now = moment(this.uploadDoc[i].created_date); // add this 2 of 4
					this.uploadDoc[i].created_date=now.format('DD-MMM-YYYY');
					}
	        	this.collection = this.uploadDoc;
	    		this.total=this.collection.length;
	        	
	        //this.isLoading = true;
	        },err => {},
	        () => {this.isLoading = false;
	    });
	}
	/**
	* [Go back to contract]
	*/
	goBack() {
		this.location.back();
	}
	downBoDoc(docId) {

        var url = "downAttachemntInfo?userId="+this.backId+"&docId="+docId;
          //////console.error(err);.log(datamain,"ggg");
        //var arrBuffer = this.base64ToArrayBuffer(data['document']);
        var blob;
        this.http.postMethod(url,'',true,true).subscribe(
                data => {
                    console.log(data);
                    if(data['status']=='Y') {

                    var arrBuffer = this.base64ToArrayBuffer(data['response']['contentBase64']);
                    var filename = data['response']['fileName'];
                    var fileExtension =  filename.split('.').pop()
                    console.log(fileExtension);
                    
                    switch (fileExtension) {
                        case 'png': case 'jpeg': case 'jpg': case "PNG" : case "JPG" : case "JPEG" : case "gif" : case "GIF" :
                            blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
                            break;
                        case 'pdf':  case 'PDF':
                            blob = new Blob([arrBuffer], {type: 'application/'+fileExtension});
                            break;
                        case 'txt':
                            blob = new Blob([arrBuffer], {type: 'text/plain'});
                            break;    
                        case 'doc': case 'DOC' :
                            ////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/msword'});
                            break;
                        case 'docx': case 'DOCX' :
                        ////console.error(err);.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                            break;
                        case 'xls': case 'XLS' : 
                        //console.log("click");
                            blob = new Blob([arrBuffer], {type: 'application/vnd.ms-excel'});
                            break;
                        case 'xlsx' : case 'XLSX' :
                        //console.log("fileExtension");
                        ////console.error(err);.log("click");
                        blob = new Blob([arrBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        break;

                        default:
                        //$('#divFiles').text('File type: Unknown');
                    }
                    var url;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
                    {
                        window.open(window.URL.createObjectURL(blob), "_blank");
                          
                    } else {
                    url = window.URL.createObjectURL(blob);
                    //window.open(objectUrl);
                    }
                    var link = document.createElement('a');
                    link.href = url;
                    link.download=filename;
                    link.click();
                    setTimeout(function(){
                        //document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);  
                        }, 100);
                    this.msgs = [];
                    this.msgs.push({severity:'success', detail: filename.split('.')[0]+" Downloaded Successfully"});
                    this.isLoading = false;
                } else {
                    this.msgs = [];
                    this.msgs.push({severity:'error', detail:data['Message']});
                   }
                //this.isLoading = true;
                },err => {},
                () => {this.isLoading = false;
            });
        
    }
	/**
	 * Page Navigator
	 * @param {PageNumber} data 
	 */
	pageChanged(event) {
  		////console.error(err);.log(event);
  		this.p=event;

  	}
	  getBcontractdoc(backId,workcontractid,index){
		
	backId=this.backId
	this.userId=backId
	this.isLoading = true;
	// this.workcontractIds=workcontractid
	this.bactive=index;
	this.active=null
	this.ismappeddivhide1=true
	this.ismappeddivhide2=false
	if(this.ismapped==false){
		this.bdivhide=true;
		this.divhide=false;
	}
	this.cookie.put('_index', index);
	var url = "getWorkContractDocList?userId=" + backId + '&workContractorId=' + workcontractid;
		var data = "";
		
		this.http.postMethod(url, data, true, true).subscribe(
		  data => {
			if (data['status'] == 'Y') {
			  this.listBoDoc = data['response']['list'];
			  if(this.listBoDoc.length==0) {
				this.bshowUpload = false;
				this.bisZero = true;
				this.bisUpload= true;
				  } else {
				  this.bisUpload= false;
				  this.bisZero = false;
				  this.bshowUpload = true;
				  }
				// this.buploadDoc= ''
				// this.buserComments = ''
				// this.bconnectComments = ''
				//console.error(err);.log(this.connectComments);
				this.collection = this.listBoDoc;
				this.total=this.collection.length;
				if(this.listBoDoc.length==0) {
					//this.bcCRShow = false;
				//this.isZero = true;
				//this.showUpload = false;
				//this.isUpload= false; 
				  } else {
					 // this.bcCRShow = true;
				  //this.isZero = false;
				  //this.showUpload = true;
				  }
				if(this.collection.length==0) {
					//this.bisHider = false;
				//this.isZero = true;
				//this.showUpload = false;
				//this.isUpload= false; 
				  } else {
					 // this.bisHider = true;
				  //this.isZero = false;
				  //this.showUpload = true;
				  }
				  
		}
			else {
			  this.msgs = [];
			  this.msgs.push({ severity: 'error', detail: 'Please try again later' });
			}
		  }, err => {
			this.msgs = [];
			this.isLoading=false
			this.msgs.push({ severity: 'error', detail: 'Server Side Failure' });
		  })
	  }
	/**
	 * [Convert base64 To ArrayBuffer]
	 * @param {[Object]} data 
	 */
	base64ToArrayBuffer(data) {
	    var binaryString = window.atob(data);
	    var binaryLen = binaryString.length;
	    var bytes = new Uint8Array(binaryLen);
	    for (var i = 0; i < binaryLen; i++) {
	        var ascii = binaryString.charCodeAt(i);
	        bytes[i] = ascii;
	    }
	    return bytes;
	}

	/**
	 * [Download document]
	 * @param {[Object]} data [requesting data]
	 */
	downDoc(data) {
	  	//////console.error(err);.log(data);
	  	this.isLoading = true;
	  	if(!this.isUpload) {
		  	var blob;
		  	var companyId = this.cookie.get('_compnyId');
		  	var url = "onboarding/DownloadCCRDocument/"+companyId;
		  	////console.error(err);.log(data);
		  	this.isHeader= true;
		    ////console.error(err);.log(url,this.isLoading);
	        this.http.postMethod(url, data,this.isBackoffice,this.isHeader).subscribe(
	            data1 => {
	            	////console.error(err);.log(data1);
	            	if(data1['SuccessFlag']==true) {
	            	////console.error(err);.log(data1);
	            	var arrBuffer = this.base64ToArrayBuffer(data1['Document']);
	            	var filename = data1['DocumentName'];
					var fileExtension =  filename.split('.')[1];
					////console.error(err);.log(fileExtension);
					switch (fileExtension) {
						case 'png': case 'jpeg': case 'jpg': case "PNG" : case "JPG" : case "JPEG" :
							blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
							break;
						case 'pdf':  case 'PDF':
							blob = new Blob([arrBuffer], {type: 'application/'+fileExtension});
							break;
						case 'doc': case 'DOC' :
							////console.error(err);.log("click");
							blob = new Blob([arrBuffer], {type: 'application/msword'});
							break;
						default:
						//$('#divFiles').text('File type: Unknown');
					}
					//var blob = new Blob([arrBuffer]);
					////console.error(err);.log(filename.split('.')[0]);
					var url;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
				    {
				        window.open(window.URL.createObjectURL(blob), "_blank");
						  
				    } else {
                    url = window.URL.createObjectURL(blob);
                    //window.open(objectUrl);
                    }
					var link = document.createElement('a');
					link.href = url;
					link.download = filename.split('.')[0];
					link.click();
	            }else {
	            	this.msgs = [];
                    this.msgs.push({severity:'error', detail:'File Download Error'});
	            }
	        },err => {},
	            () => {this.isLoading = false;});
	  } else {
	  	var companyId = this.cookie.get('_compnyId');
	  	var PSId = this.cookie.get('_PSId');
	  	var ConsultantId = this.cookie.get('_usrId');
	  	var DocumentId = data['document_id'];
	  	let IsHistory = false;
	  	var url = "onboarding/DownloadConsultantDocument"+'/'+ConsultantId+'/'+DocumentId+'/'+PSId+'/'+IsHistory;
	  	let datamain ={ConsultantId : ConsultantId, DocumentId : DocumentId, PSId : PSId, IsHistory : IsHistory };
	  	//////console.error(err);.log(datamain,"ggg");
		//var arrBuffer = this.base64ToArrayBuffer(data['document']);
		//var blob;
		 this.http.postMethod(url,'',false,true).subscribe(
                data => {
                    ////console.error(err);.log(data);
                    if(data['SuccessFlag']==true) {

					var arrBuffer = this.base64ToArrayBuffer(data['Document']);
					var filename = data['DocumentName'];
					var fileExtension =  filename.split('.')[1];
					////console.error(err);.log(fileExtension);
					
					switch (fileExtension) {
						case 'png': case 'jpeg': case 'jpg': case "PNG" : case "JPG" : case "JPEG" :
							blob = new Blob([arrBuffer], {type: 'image/'+fileExtension});
							break;
						case 'pdf':  case 'PDF':
							blob = new Blob([arrBuffer], {type: 'application/'+fileExtension});
							break;
						case 'doc': case 'DOC' :
							////console.error(err);.log("click");
							blob = new Blob([arrBuffer], {type: 'application/msword'});
							break;
						default:
						//$('#divFiles').text('File type: Unknown');
					}

					var url;
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                    } else if(navigator.userAgent.indexOf("Firefox") != -1 ) 
				    {
				        window.open(window.URL.createObjectURL(blob), "_blank");
						  
				    } else {
                    url = window.URL.createObjectURL(blob);
                    //window.open(objectUrl);
                    }
					var link = document.createElement('a');
					link.href = url;
					link.download=data['DocumentName'];
					link.click();
					setTimeout(function(){
						//document.body.removeChild(link);
						window.URL.revokeObjectURL(url);  
						}, 100);
                    this.msgs = [];
                    this.msgs.push({severity:'success', detail: filename.split('.')[0]+" Downloaded Successfully"});
                    this.isLoading = false;
                } else {
                    this.msgs = [];
                    this.msgs.push({severity:'error', detail:data['Message']});
               	}
                //this.isLoading = true;
                },err => //console.error(err);.error(err),
                () => {this.isLoading = false;
            });
		
	  	}
	}

	/**
	 * [Esign document]
	 * @param {[Object]} data [requesting data]
	 */ 

	signDoc(doc) {
		this.isLoading = true;
		////console.error(err);.log(doc);
		var companyId = this.cookie.get('_compnyId');
		var url = "onboarding/ConsultanteSign/"+companyId+"/"+this.userId;
		  this.http.postMethod(url, doc,this.isBackoffice,this.isHeader).subscribe(
	            data => {
	            	//////console.error(err);.log(data['SuccessFlag']);
	            	if(data['SuccessFlag']==true) {
	            		this.msgs = [];
                        this.msgs.push({severity:'success', detail:data['Message']});
                        
	            	} else {
	            		this.msgs = [];
                        this.msgs.push({severity:'error', detail:"No access to Sign"});
	            	}
	            },err => //console.error(err);.error(err),
                () => {this.isLoading = false;});
		//////console.error(err);.log(doc);
	}
}