//Angular decorators list
import { Component, OnInit, Input, Output, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import {Message} from 'primeng/api';
//import { DropdownModule, Dropdown, SelectItem } from 'primeng/primeng';
import {MultiSelectModule, SelectItem} from 'primeng/primeng';
import { CookieService } from 'ngx-cookie';
import * as CryptoJS from 'crypto-js';
import { HttpService } from './../../shared/services/http.service';
import { InvokedataService } from './../services/invokedata.service';

@Component({
		selector: 'app-add-user',
		templateUrl: './add-user.component.html',
		styleUrls: ['./add-user.component.css'],
		encapsulation: ViewEncapsulation.None
})
export class AddUserComponent implements OnInit {
  
		// Initial setup
		@Input('accMenu') menu:any;
		//hightlightMenu;
		list;
		backId;
		isOld;
		userId;
		cars;
		userDetails = {
			"status" : null
		};
		isLoading: boolean = false;
		msgs: Message[] = [];
		statusList;
		userType;
		roleTypes;
		usertypelist ;
		userrolesData = [];
		usertypeId= null;
		status = null;
		userRole =[];
		editUser;
		result;
		byUser;
		confoError;
		confirmPass;
		pageTitle="Add User";
		roldId;
		listRoles;
		constructor(private http: HttpService,private route:ActivatedRoute,private location: Location,private router: Router,private cookie:CookieService,private invokeData: InvokedataService) {
			this.roleTypes = [];
			//this.userDetails['usertypelist']= [{"label":"Vendor",value : 400},{"label":"Vendor-Contractor",value : 450}];
			
				
		}

	ngOnInit() {
		window.document.title= "Add User - Connect ";
		this.byUser = this.backId = this.cookie.get('_busrId');
		this.route.params.subscribe((params) => {
		 	////console.error(err);.log(params);
		 	if(params['user_id']) {
		 	this.userId = +params['user_id'];
		 	this.pageTitle= "Edit User";
		 	}
		 	
		});
		 ////console.error(err);.log(this.backId);
		 if(this.userId=="" || this.userId == undefined) {
		 		////console.error(err);.log("error");
		 		//this.backId = this.backId;
		 		
		 		this.editUser = false;

		 	} else {
		 		this.editUser = true;
		 		this.byUser = this.backId;
		 		this.backId = this.userId;
		 		////console.error(err);.log("one");
		 		
		 	}
			//  this.route.data.forEach((results:any) => {
				
			// 	this.result = results;
			// 	////console.error(err);.log(this.result);
			// });
		// this.list = this.invokeData.getModules();
		// 	////console.error(err);.log(this.list);
		// 	this.backId = this.cookie.get('_busrId');
		// 	////console.error(err);.log(this.backId);
		// 	if(this.backId) {
		// 		this.isOld = true;
		// 	} else {
		// 		this.isOld = false;
		// 	}
			var userroleId = this.cookie.get('_usrtyd');
			//var deuserId  = CryptoJS.AES.decrypt(userroleId, 'e5(8`*DK8L`YW8T7');
			//this.userType = deuserId.toString(CryptoJS.enc.Utf8);
			this.userType = userroleId;
			this.usertypelist = [{"label":"Vendor",value : 400},{"label":"Vendor-Contractor",value : 450}];
			this.getStatus();
			if(this.editUser) {
				this.getRoles();
				this.listuserRoles();
				

			} else {
				this.listuserRoles();
			}
			
			
			////console.error(err);.log(this.usertypelist,this.editUser,this.backId);
			
	}

	// /*
	// **Edit User Get details 
	// */
	// getUserDetails()
	// {
	// 	var url = "portal/GetProfileDetails/"
	// 	var data ='';      
	// 	this.http.postMethod(url,data,false,true).subscribe(
	// 	data => { 
	// 	////console.error(err);.log(data);
	// 	if(data['status']=='Y') {
	// 	this.statusList=data['response']["list"];

	// 	}

	// 	},err => {
	// 	//console.error(err);.error(err);
	// 	this.msgs = [];
	// 	this.msgs.push({severity:'error', detail:'Server Side Failure'});
	// 	this.isLoading = false;
	// 	},
	// 	() => {this.isLoading = false;});
	// }
	getStatus() {
		var url = "getUserStatusList?userId="+this.byUser;
		this.isLoading = true;
		var data ='';      
				this.http.postMethod(url,data,true,true).subscribe(
										data => { 
												////console.error(err);.log(data);
												if(data['status']=='Y') {
													this.statusList=data['response']["list"];

												}

											},err => {
								//console.error(err);.error(err);
								this.msgs = [];
								this.msgs.push({severity:'error', detail:'Server Side Failure'});
								this.isLoading = false;
							},
										() => {this.isLoading = false;});
	}

	getRoles() {
		var url = "getProfile?userId="+this.byUser+"&userType="+this.userType+"&countryCode=CA";
				this.isLoading = true;
		var data ='';      
				this.http.postMethod(url,data,true,true).subscribe(
										data => { 
												////console.error(err);.log(data);
												if(data['status']=='Y') {
													if(data['response']["rolesForUserTypeList"].length>0) {
														for(var i=0;i<data['response']["rolesForUserTypeList"].length;i++) {
															this.roleTypes[i]={label: data['response']["rolesForUserTypeList"][i]['role'], value:data['response']["rolesForUserTypeList"][i]['roleTypeId'].toString(),disabled: false}
														}
														////console.error(err);.log(this.roleTypes);
														
													}
													if(this.editUser) {
														this.getUserDetails();
													// 	this.userDetails['firstName']=data['response']['user']['firstName'];
													// 	this.userDetails['lastName']=data['response']['user']['lastName'];
													// 	this.userDetails['logonName']=data['response']['user']['logonName'];
													// 	////console.error(err);.log(this.userDetails)
													// 	this.userDetails['status']=data['response']['user']['userStatusCode']['typeCode'].toString();
													// 	this.usertypeId = data['response']['user']['userType']['userTypeCode'];
													// 	this.userDetails['userEmail'] = data['response']['user']['emailAddr'];
													// 	var selectedRoles = [];
													// 	//this.userrolesData = data['response']['user']['userType']['roles'];
													// 	//this.userrolesData=['133','100'];
													// 	for(var i=0;i<data['response']['user']['userType']['roles'].length;i++) {
													// 		selectedRoles.push(data['response']['user']['userType']['roles'][i]['roleTypeId'].toString());
													// 	}
													// 	this.userrolesData = selectedRoles;
													// 	//////console.error(err);.log(selectedRoles,this.userrolesData);
													// }
													}	

												}

											},err => {
								//console.error(err);.error(err);
								this.msgs = [];
								this.msgs.push({severity:'error', detail:'Server Side Failure'});
								this.isLoading = false;
							},
										() => {this.isLoading = false;});
	}

	/**
	 * List User Type and Role
	 * @param {UserId]} data 
	*/
	listuserRoles() {

		var url = "listUserRolesByCompanyType?userId="+this.byUser;
				this.isLoading = true;
		var data ='';      
				this.http.postMethod(url,data,true,true).subscribe(
										data => { 
											////console.error(err);.log(data);
											if(data['status']=='Y') {
												this.usertypelist=data['response'].list;
												////console.error(err);.log(this.usertypelist)
													// if(this.usertypelist.length>0) {
													// 	for(var i=0;i<this.usertypelist.length;i++) {
													// 		var listRoles=this.usertypelist[i]['roles'];
													// 		for(var j=0;j<listRoles.length;j++)
													// 		this.roleTypes[j]={label: listRoles[j].role, value:listRoles[j].roleTypeId.toString()}
													// 	}
													// 	////console.error(err);.log(this.roleTypes);
														
													// }
												}
										},err => {
								//console.error(err);.error(err);
								this.msgs = [];
								this.msgs.push({severity:'error', detail:'Server Side Failure'});
								this.isLoading = false;
							},
										() => {this.isLoading = false;});

	}

	loadRoles(id) {
		this.roleTypes=[];
		this.roldId = id;
		for(var i=0;i<this.usertypelist.length;i++) {
			if(this.usertypelist[i].userTypeCode==id) {
			this.listRoles=this.usertypelist[i]['roles'];

			for(var j=0;j<this.listRoles.length;j++)
				
					this.roleTypes[j]={label: this.listRoles[j].role, value:this.listRoles[j].roleTypeId.toString(),disabled: false}
							
			}
			}	
		//
		////console.error(err);.log(this.roleTypes);


	}
	formUsrName() {
		if(this.userDetails['firstName']!='' && this.userDetails['lastName']!='' && this.userDetails['logonName']==null) {
			this.userDetails['logonName'] = this.userDetails['firstName']+"."+this.userDetails['lastName'];
		}
	}

	onAccountSelect(value) {
		
		//let disableArray = ["131","133","135"];
		for(var j=0;j<this.listRoles.length;j++)
			{
			this.roleTypes[j]={label: this.listRoles[j].role, value:this.listRoles[j].roleTypeId.toString(), disabled: false}	
			}
		////console.error(err);.log(value)
		let disableArray = this.listRoles.map((x)=> String(x.roleTypeId));
		var data, curPos;
		for(var i = 0; i < disableArray.length; i++) {
			for(var j = 0;j <value.length; j++)  {
				if(value[j] == disableArray[i] && disableArray[i] != '100') {
					data = disableArray[i]
					curPos = i;
					break;
				} 
				
			}
		}
		if(!data) {
			return;
		}
		////console.error(err);.log(value,disableArray);
		//var data = value.toString();
		//var datas=value;
		this.userRole = data;
		//var a = JSON.stringify(data);
		//	a = a.split('[');
		//var b = a.split(',');
		////console.error(err);.log(this.userRole);
		//let curPos = disableArray.indexOf(data);
		if(curPos!=-1) {
			
				//this.listRoles=this.usertypelist[i]['roles'];
					for(var j=0;j<this.listRoles.length;j++)
					{
					////console.error(err);.log(data,this.listRoles[j].roleTypeId);
					if(data!=this.listRoles[j].roleTypeId && this.listRoles[j].roleTypeId != '100') { 
					////console.error(err);.log(data,this.listRoles[j].roleTypeId);
					this.roleTypes[j]={label: this.listRoles[j].role, value:this.listRoles[j].roleTypeId.toString(), disabled: true}
					}
					else {
					this.roleTypes[j]={label: this.listRoles[j].role, value:this.listRoles[j].roleTypeId.toString(), disabled: false}	
					}
				}
				}
		else {
			
		}

		
		// if(data=="135"){
		// 	//this.roleTypes=[];
		// 	for(var i=0;i<this.usertypelist.length;i++) {
		// 		if(this.usertypelist[i].userTypeCode==this.roldId) {
		// 		var listRoles=this.usertypelist[i]['roles'];
		// 			for(var j=0;j<listRoles.length;j++)
		// 			{
		// 			if(listRoles[j].roleTypeId==133 || listRoles[j].roleTypeId==131)
		// 			this.roleTypes[j]={label: listRoles[j].role, value:listRoles[j].roleTypeId.toString(), disabled: true}
		// 			}
		// 		}
		// 		}	
			
		// 	////console.error(err);.log(this.roleTypes);

		// } 
		// else {

		// 	for(var i=0;i<this.usertypelist.length;i++) {
		// 		if(this.usertypelist[i].userTypeCode==this.roldId) {
		// 		var listRoles=this.usertypelist[i]['roles'];
		// 			for(var j=0;j<listRoles.length;j++)
		// 			{
		// 			if(listRoles[j].roleTypeId==133 || listRoles[j].roleTypeId==131)
		// 			this.roleTypes[j]={label: listRoles[j].role, value:listRoles[j].roleTypeId.toString(), disabled: false}
		// 			}
		// 		}
		// 		}

		// }

		//var c = b.map(String);
		//////console.error(err);.log(c);
		//////console.error(err);.log(JSON.parse(this.userDetails['userRole']));
		}
	/**
	 * Add User
	 * @param {[Form - data]} data 
	*/
	addUser(form) {
		////console.error(err);.log("dddd",this.userrolesData);
		////console.error(err);.log(this.userDetails['userroles']);
		for(var i=0;i<form._directives.length;i++)
        {
          //////console.error(err);.log(form._directives[i].valid);
          //this.error_status = true;
        }
        if((this.userDetails['password'] != this.confirmPass)) {
             this.confoError=true;
             ////console.error(err);.log(this.confoError);
             return false;
           } else {
             this.confoError=false;
           }
       if(form.valid) {
       	this.isLoading = true;
       //	var userRoletype= this.userrolesData;
       	this.userDetails['userType'] = [this.usertypeId.toString()];
       	this.userDetails['userRole']=this.userrolesData;
       
       	////console.error(err);.log(this.userDetails);
       	var link;
       	if(this.editUser) {
       		link="updUser?userId="+this.byUser+"&byUserId="+this.backId;
       	} else {
	       	 link="addUser?userId="+this.backId;
	       	 this.userDetails['isAddressSameAsComp']="true";
	       }
	      var url = link+"&jsonUserVOStr="+encodeURIComponent(JSON.stringify(this.userDetails));
		var data ='';
		this.http.postMethod(url, data,true, true).subscribe(
                    data => {
                    	////console.error(err);.log(data);
                    	if(data['status']=="Y") {
                    	this.msgs = [];
                    	if(this.editUser) {
                    		this.msgs.push({severity:'success', detail:"User Updated Successfully"});
                    	}else {
                    		this.msgs.push({severity:'success', detail:"User Created Successfully"});
                    	}
                        setTimeout(() => this.router.navigate(['/client/users']), 500)
                    	} else{
                        this.msgs = [];
                        this.msgs.push({severity:'error', detail:data['message']});
                        }
                    },err => {
								//console.error(err);.error(err);
								
							},
                    () => {this.isLoading = false;});   

       }

	}
	/**
	* Get Edit User Details
	*/
	getUserDetails()
	{
		////console.error(err);.log(this.byUser,this.backId,this.userId);
		var url = "getUserById?userId="+this.byUser+"&byUserId="+this.backId;
		this.isLoading = true;
		var data ='';      
				this.http.postMethod(url,data,true,true).subscribe(
										data => { 
												////console.error(err);.log(data);
												if(data['status']=='Y') {
													//this.statusList=data['response']["list"];
													this.userDetails['firstName']=data['response']['firstName'];
													this.userDetails['lastName']=data['response']['lastName'];
													this.userDetails['logonName']=data['response']['logonName'];
													this.userDetails['userEmail']=data['response']['emailAddr'];
													////console.error(err);.log(this.userDetails)
													this.userDetails['status']=data['response']['userStatusCode']['typeCode'].toString();
													this.usertypeId = data['response']['userType']['userTypeCode'];
													if(this.usertypeId){
														this.loadRoles(this.usertypeId)
													}
													//this.userDetails['userEmail'] = data['response']['user']['emailAddr'];
													this.userDetails['password'] = data['response']['userPassword'];
													this.confirmPass = data['response']['userPassword'];
													var selectedRoles = [];
													//this.userrolesData = data['response']['user']['userType']['roles'];
													//this.userrolesData=['133','100'];
													for(var i=0;i<data['response']['roles'].length;i++) {
														selectedRoles.push(data['response']['roles'][i]['roleTypeId'].toString());
														////console.error(err);.log(selectedRoles);
													}
													this.onAccountSelect(selectedRoles);
													this.userrolesData = selectedRoles;


												}

											},err => {
								//console.error(err);.error(err);
								this.msgs = [];
								this.msgs.push({severity:'error', detail:'Server Side Failure'});
								this.isLoading = false;
							},
										() => {this.isLoading = false;});

	}
	

	/**
	* [Go back to contract]
	*/
	goBack() {
	this.location.back();
	}
}
