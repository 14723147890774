import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from './../../shared/services/http.service';
import { SessionService } from './../../shared/services/sessiontoken.service';
@Component({
  selector: 'app-exp-emp',
  templateUrl: './exp-emp.component.html',
  styleUrls: ['./exp-emp.component.css']
})
export class ExpEmpComponent implements OnInit {

  constructor(private http: HttpService,private cookie:CookieService,private route:ActivatedRoute, private router:Router,private session:SessionService) { }
  listData;
  ngOnInit() {
  		window.document.title= "Connect - Contractors List";
	  	this.route.data.forEach((results:any) => {
		////console.error(err);.log(results.result);
		if(results.result.status=="Y") {
			this.listData=results.result.response.list;
			////console.error(err);.log(this.listData);
		}
	  });

	}

	getNavigate(id,type) {
		////console.error(err);.log(id,type);
		this.cookie.put('_onbehalf', id);
		this.cookie.put('_onbehalfType', type);
		this.router.navigate(['/client/expemp/exp_list/'+id]);
	}

	/**
	* [Go back to contract]
	*/
	goBack() {
	this.router.navigate(['/']);
	}
}
