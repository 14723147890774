//Angular decorators list
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
//Services list
import { CookieService } from 'ngx-cookie';
import { HttpService } from './../../shared/services/http.service';
import { SessionService } from './../../shared/services/sessiontoken.service';
import 'rxjs/Rx' ;
import {Message} from 'primeng/api';
import {DialogModule} from 'primeng/dialog';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as moment from 'moment';

@Component({
	selector: 'app-invoicedata',
	templateUrl: './invoicedata.component.html',
	styleUrls: ['./invoicedata.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class InvoiceDataComponent implements OnInit {

	// Initial setup
	@ViewChild('fileElement')  fileElement:any;
    @ViewChild('fileContent')  fileContent:any;
	result;
	msgs: Message[] = [];
	billling: Array<{}>= [];
	payable: Array<{}>= [];
	invoiceDetailList: Array<{}>=[];
	payComp;
	clientComp;
	startDate;
	endDate;
	invouceNo;
	file = {
        doctype:null,
        docname:null,
        fileData:'',
    }
    isUpload;
    uploadFile;
    fileExten;
    display: boolean = false;
    error_status = false;
	isLoading = false;
	isFile: boolean = false;
    fileName;
    note;
    docId;
    docName;
    taxAmt;
    totalAmt;
    wrkContId;
    buserId;
    invoiceNo;
	constructor(private http:HttpService, private session:SessionService, private cookie:CookieService, private route:ActivatedRoute, private router:Router) { }

	ngOnInit() {
    window.document.title= "Invoice - Connect ";

  	this.route.data.forEach((results:any) => {
  		this.result=results.result;
  		////console.error(err);.log(results.result.response);
  		if(this.result['status']=='N') {
				this.msgs = [];
				this.msgs.push({severity:'error', detail:this.result['message']});
				this.router.navigate(['/contractor/timesheet']);
		} else {
				this.billling = this.result.response['billingAddressVO'];
				this.payable = this.result.response['payToAddressVO'];
				this.payComp = this.result.response['payToCompanyName'];
				this.clientComp = this.result.response['clientCompanyName'];
				this.startDate = this.result.response['startDt'];
				this.endDate =  this.result.response['endDt'];
				this.invoiceDetailList =  this.result.response['invoiceDetailList'];
				this.taxAmt =  this.result.response['taxAmount'];
				this.totalAmt =  this.result.response['totalAmount'];
				////console.error(err);.log(this.invoiceDetailList);
				let preData = this.session.getContracts();
				this.wrkContId = preData['params']['workContractId'];
				this.buserId = preData['params']['userId']

		}

  	});
  }

	goBack() {
		this.router.navigate(['/contractor/timesheet/timesheet_view']);
	}
	
	/********* Upload attachment to  Time sheet **********/
	uploadAttachment() {
		////console.error(err);.log("ddd");
		this.display = true;
	}

	/**
     File Drag and Drop Event
	*/
    public files: UploadFile[] = [];

    public dropped(event: UploadEvent) {
        this.files = event.files;
        ////console.error(err);.log(this.files);
        for (const droppedFile of event.files) {

          // Is it a file?
            if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                  // Here you can access the real file
                  ////console.error(err);.log(droppedFile.relativePath, file,file['name']);
                	  const formData = new FormData()
                  formData.append('fileData', file, File['name'])
                  //this.fileData=droppedFile.relativePath;
                  ////console.error(err);.log(this.file.fileData);
                 // this.file.fileData = file['name'];
                  //this.isUpload=true;
                  /**
                  // You could upload it like this:
                  const formData = new FormData()
                  formData.append('logo', file, relativePath)
                  **/
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                ////console.error(err);.log(droppedFile.relativePath, fileEntry);
            }
        }
    }

    public fileOver(event) {
        ////console.error(err);.log(event);
    }

    public fileLeave(event) {
        ////console.error(err);.log(event);
    }
	public uploadDoc() {
    	////console.error(err);.log("Fff");
    	var link = document.createElement('input.image');
    	this.fileElement.nativeElement.click();
    	////console.error(err);.log(this.fileElement);
    }

	showFile() {
		this.isUpload = true;
		let file=this.fileContent.nativeElement.files[0];
		////console.error(err);.log(file.name);
		this.uploadFile = file.name;
	}
    /**
     * [Delete File]
     */
    deleteFile() {
        
        this.isUpload = false;
        this.file.fileData = '';
    }
    
    /* File to base 64 convertion */
    getBase64(file) {
        ////console.error(err);.log(file);
        this.fileExten = file['type'];
        ////console.error(err);.log(this.fileExten.split('/')[1]);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            ////console.error(err);.log(reader.result);
            return reader.result;
        };

        reader.onerror = function (error) {
            ////console.error(err);.log('Error: ', error);
        };
    }

    /**
     * [upload description]
     * @param {[FormData]} form 
     */
    onUpload(form) {
        ////console.error(err);.log(form);
        ////console.error(err);.log(form._directives);
        for(var i = 0;i < form._directives.length; i++) {
            ////console.error(err);.log(form._directives[i].valid);
            this.error_status = true;
        }
    
        if(form.valid) {
        	this.display = false; 
            this.isLoading = true;
            //////console.error(err);.log(this.fileContent.nativeElement.files[0]);
            let file=this.fileContent.nativeElement.files[0];
            this.fileExten = file['type'];
            //let format = );
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = ()=> {
            //////console.error(err);.log(reader.result);
            var filecontent = reader.result;
            var fileData = filecontent.toString().split(',')[1];
           // ////console.error(err);.log(fileData);
            
            var PSId = this.cookie.get('_PSId');
            var DocumentExtn = this.fileExten.split('/')[1];
            var DocumentTypeId = this.file.doctype;
            var DocumentName  = this.file.docname;
            var ConsultantId = this.cookie.get('_busrId');
            var url = 'uploadFileWithBase64?userId='+ConsultantId+"&fileName="+this.uploadFile;
            this.isUpload = true;
            //this.uploadFile= DocumentName;

            let data = "input="+encodeURIComponent(JSON.stringify(fileData));
            var form = new FormData();
            form.append('input',fileData);
            this.http.postBackMethod(url,data,true,false).subscribe(
                    data => {
                    	////console.error(err);.log(data);
                    	
                        ////console.error(err);.log(data['status']=='Y');
                        if(data['status']=='Y') {
                        this.isFile = true;
                       	this.display = false;
                       	this.docName = data['response']['fileName'];
                       	this.docId= data['response']['docId'];
                       	////console.error(err);.log(this.docName,this.docId);
                        this.msgs = [];
                        this.msgs.push({severity:'success', detail:"Document uploaded successfully"});
                        //this.router.navigate(['/contractor/contracts']);
                        }
                        else{
                        this.msgs = [];
                        this.msgs.push({severity:'error', detail:data['message']});
                        }
                        
                    //this.isLoading = true;
                    },err => //console.error(err);.error(err),
                    () => {this.isLoading = false;});
            };
            reader.onerror = function (error) {
            ////console.error(err);.log('Error: ', error);
            };
        } 
    }


    /********* Save Time sheet **********/

	onInvoice(form) {
		this.startDate = moment(this.startDate);
    	this.startDate = this.startDate.format('DD-MMM-YYYY')
		////console.error(err);.log(form);
        ////console.error(err);.log(form._directives);
        for(var i = 0;i < form._directives.length; i++) {
            ////console.error(err);.log(form._directives[i].valid);
            this.error_status = true;
        }
    
        if(form.valid) {
        	this.display = false; 
            this.isLoading = true;

        var url;
        if(!this.isFile) {
			url = "submitTimesheetInvoice?userId="+this.buserId+"&workContractId="+this.wrkContId+"&targetDateStr="+this.startDate+"&invoiceNo="+this.invoiceNo+"&appCode=3";
        }
        else {
        	url = "submitTimesheetInvoice?userId="+this.buserId+"&workContractId="+this.wrkContId+"&targetDateStr="+this.startDate+"&invoiceNo="+this.invoiceNo+"&docId="+this.docId+"&docName="+this.docName+"&appCode=3";
        }
        
        ////console.error(err);.log(url);
        var data = '';
        this.isLoading = true;
        this.http.postMethod(url,data,true,true).subscribe(
                    data => {
                    	////console.error(err);.log(data);
                    	if(data['status']=='Y') {
                        this.msgs = [];
                        this.msgs.push({severity:'success', detail:'Timesheet updated Successfully'});
                        this.router.navigate(['/contractor/timesheet/timesheet_view']);
                        }
                        else{
                        this.msgs = [];
                        this.msgs.push({severity:'error', detail:'Please try again later'});
                        }
                    },err => {
								//console.error(err);.error(err);
								this.msgs = [];
								this.msgs.push({severity:'error', detail:'Server Side Failure'});
								this.isLoading = false;
							},
                    () => {this.isLoading = false;});
            
    	}
	}

    printTimesheet() {

    }
    declineTimesheet() {

    }
    acceptTimesheet() {
        
    }
    downTimesheet() {
        
    }
}
