//Angular modules list
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title }     from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NumberOnlyDirective } from './directives/number.directive';
//Third party modules list
import {ImageCropperModule} from 'ngx-image-cropper';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { GrowlModule } from 'primeng/growl';
import {AccordionModule} from 'primeng/accordion';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import {NgxPaginationModule} from 'ngx-pagination';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
//import * as moment from 'moment';
import {DialogModule} from 'primeng/dialog';
import {CalendarModule} from 'primeng/calendar';
import {MultiSelectModule} from 'primeng/multiselect';
import { SignaturePadModule } from 'angular2-signaturepad';
import {InputMaskModule} from 'primeng/inputmask';
//Components list
import { BsDropdownModule, CollapseModule  } from 'ngx-bootstrap';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { AnnouncementComponent } from './announcement/announcement.component';

//import { NotificationsListComponent } from './notifications-list/notifications-list.component';
import { NotificationComponent } from './notifications/notifications.component';
import { ListNotificationComponent } from './list-notifications/list-notifications.component';
import { ListwithsidebarComponent } from './listwithsidebar/listwithsidebar.component';
import { ListwithoutsidebarComponent } from './listwithoutsidebar/listwithoutsidebar.component';
import { LandingComponent } from './landing/landing.component';
import { TimesheetcycleComponent } from './timesheetcycle/timesheetcycle.component';
import { multiTimesheetcycleComponent } from './multitimesheetcycle/multitimesheetcycle.component';
import { AccHeaderComponent } from './acc-header/acc-header.component';
import { InvoiceDataComponent } from './invoicedata/invoicedata.component';
import { ReportComponent } from './report/report.component';
import { LoaderComponent } from './loader/loader.component';
import { OlderEngagementComponent } from './olderengagements/olderengagements.component';
import { UnderConstructionComponent } from './under-construction/under-construction.component';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FileDropModule } from 'ngx-file-drop';
import {NgPipesModule} from 'ngx-pipes';
import { EmpListComponent } from './emplist/emplist.component';
import { ExpEmpComponent } from './exp-emp/exp-emp.component';
import { ExpenseViewComponent } from './expense-view/expense-view.component';
import { ExpenseListComponent } from './expense-list/expense-list.component';
import { AddUserComponent } from './add-user/add-user.component';
import {MatSelectModule} from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import {TableModule} from 'primeng/table';
import {MatTooltipModule} from '@angular/material/tooltip';
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		HttpClientModule,
		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		ShowHidePasswordModule.forRoot(),
		GrowlModule,
		AccordionModule,
		DialogModule,
		FileDropModule,
		SignaturePadModule,
		CalendarModule,
		PaginatorModule,
		NgxPaginationModule ,
		MultiSelectModule,
		InputMaskModule,
		MatSelectModule,
		ImageCropperModule,
    NgbModule,
	TableModule,
	AutoCompleteModule,
	MatTooltipModule

	],
  	declarations: [
  		NumberOnlyDirective,
		HeaderComponent,
		MenuComponent,
		AnnouncementComponent,
		ListwithsidebarComponent,
		ListwithoutsidebarComponent,
		LandingComponent,
		TimesheetcycleComponent,
		multiTimesheetcycleComponent,
		ReportComponent,
		UnderConstructionComponent,
		LoaderComponent,
		InvoiceDataComponent,
		AccHeaderComponent,
		OlderEngagementComponent,
		EmpListComponent,
		ExpEmpComponent,
		ExpenseViewComponent,
		AddUserComponent,
		ExpenseListComponent,
		ListNotificationComponent,
		NotificationComponent
		],
    exports:[
    	RouterModule,
    	NumberOnlyDirective,
		FormsModule,
		HttpClientModule,
    	HeaderComponent,
		MenuComponent,
		AnnouncementComponent,
		NotificationComponent,
		ListNotificationComponent,
		ListwithsidebarComponent,
		ListwithoutsidebarComponent,
		LandingComponent,
		TimesheetcycleComponent,
		LoaderComponent,
		UnderConstructionComponent,
		GrowlModule,
		AccordionModule,
		TrimValueAccessorModule,
		DialogModule,
		InvoiceDataComponent,
		CalendarModule,
		MultiSelectModule,
		NgPipesModule,
		AccHeaderComponent,
		SignaturePadModule,
		BsDropdownModule,
		CollapseModule,
		NgxPaginationModule,
		ExpenseViewComponent,
		InputMaskModule,
		MessagesModule,
		MessageModule,
		MatSelectModule,
		ShowHidePasswordModule,
		ImageCropperModule,
		ExpenseListComponent,
		NotificationComponent



	]
	
})
export class SharedModule { }

